import React, { useState, useMemo } from 'react';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';

import Input from '../Input/Input';
import Select from '../Select/Select';
import Button from '../Button/Button';

import { listStatusesCheckIn } from '../../services/desks';
import { default as api } from '../../services/api';
import { cleaningOptions } from '../../services/lists-contact';

const { postCompany, putCompany } = api
const DEFAULT_AFTER_TIME = 15;
const DEFAULT_BEFORE_TIME = 5;
const DEFAULT_CHECKIN = 'disabled';
const DEFAULT_CLEANING = 'off';

export default function CompanyForm(props) {
  const { company } = props;
  const history = useHistory();

  const [optionCheckin, setOptionCheckin] = useState(company ? company.optionCheckin : DEFAULT_CHECKIN);
  const [optionCleaning, setOptionCleaning] = useState(company ? company.optionCleaning : DEFAULT_CLEANING);
  const defaultValues = useMemo(() => company ? { ...company } : null, [company]);

  const onSubmit = (values) => {
    const strId = company ? company.strId : nanoid();
    const request = company ? putCompany : postCompany;
    const newCompany = { ...values, strId, optionCleaning, optionCheckin };

    request(newCompany).then(() => {
      history.push('/root-admin/companies');
    }).catch((e) => {
      console.log(e);
    });
  };

  const { handleSubmit, register } = useForm(defaultValues ? { defaultValues } : {
    defaultValues: {
      optionCheckinTime: DEFAULT_BEFORE_TIME,
      optionCheckinExpireTime: DEFAULT_AFTER_TIME
    }
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-form">
        <div className="add-form__title">
          {company ? 'Edited' : 'Created'} Company
        </div>

        <Input
          inputRef={register}
          name="title"
          label="Name"
        />

        <Input
          inputRef={register}
          type="textarea"
          name="desc"
          label="Description"
        />

        <Select
          label="Check in"
          options={listStatusesCheckIn}
          value={{ value: optionCheckin, label: optionCheckin }}
          onChange={(option) => setOptionCheckin(option.value)}
        />

        <Input
          inputRef={register}
          name="optionCheckinTime"
          type="number"
          label="Check in before time, min"
        />

        <Input
          inputRef={register}
          name="optionCheckinExpireTime"
          type="number"
          label="Check in after time, min"
        />

        <Select
          label="Option cleaning"
          options={cleaningOptions}
          value={{ value: optionCleaning, label: optionCleaning }}
          onChange={(option) => setOptionCleaning(option.value)}
        />

        <Input
          inputRef={register}
          name="allowInvisibleBooking"
          type="checkbox"
          label="Invisible booking"
        />

        <Input
          inputRef={register}
          name="slug"
          label="Slug"
        />

        <Input
          inputRef={register}
          name="isDemo"
          type="checkbox"
          label="Is demo"
        />

        <Input
          inputRef={register}
          name="isVirtualOnly"
          type="checkbox"
          label="Is virtual only"
        />

        <Input
          inputRef={register}
          name="hasTeamsIntergration"
          type="checkbox"
          label="Has Teams intergration"
        />

        <Input
          inputRef={register}
          name="auth0ClientId"
          label="Auth0 Client Id"
        />

        <Button type="submit">
          {company ? 'Save' : 'Create'}
        </Button>
      </div>
    </form>
  );
};
