import React, { useEffect, useState } from 'react';
// import { find } from 'lodash';
import { Redirect, useParams } from 'react-router';

import useUser from '../../hooks/useUser';
import Loader from '../../components/Loader/Loader';

import { default as api } from '../../services/api';

const { fetchCompanyBySlug, fetchDeskByQRCode } = api

export default function QRCodeRedirect() {
  const { companySlug, qrCode } = useParams();

  const { isAdmin, user } = useUser();
  const [desk, setDesk] = useState(null);
  const [company, setCompany] = useState(null);
  const [isPending, setPending] = useState(true);
  const [isPendingCompany, setPendingCompany] = useState(true);
  const [err, setErr] = useState('')

  useEffect(() => {
    if (!company || !qrCode) {
      return
    }

    fetchDeskByQRCode(companySlug, qrCode)
    .then((result) => {
      setDesk(result.desk)
      setPending(false)
    }).catch((err) => {
      setPending(false);
      console.error(err);
      setErr(err.toString())
    });


    // fetchDesksForFloor(floorId).then((desks) => {
    //   const currentDesk = find(desks, (d) => d.qrId === qrCode);

    //   currentDesk && setDesk(currentDesk);
    //   setPending(false);
    // }).catch((err) => {
    //   setPending(false);
    //   console.error(err);
    // });

  }, [company, qrCode]);

  useEffect(() => {
    fetchCompanyBySlug(companySlug).then((company) => {
      setCompany(company);
      setPendingCompany(false);
    }).catch((err) => {
      setPendingCompany(false);
      console.error(err);
    });
  }, [companySlug]);

  if (isPending || isPendingCompany) {
    return <Loader />;
  }

  if (err) {
    return (
      <div className="b-qrcode-err">
        <div>Scan QR Error</div>
        <div>{err}</div>
      </div>
    )
  }

  if (!user || !company || (company.strId !== user.companyStrId && !isAdmin)) {
    if (!user && desk) {
      const { x, y, strId } = desk;
      localStorage.setItem('qrCodeDesk', JSON.stringify({ x, y, strId }));
    }

    return <Redirect to={`/login/${companySlug}`} />;
  }

  // return <div>temp</div>
  return <Redirect to={`/floor/${desk.floorStrId}${ desk ? `?x=${desk.x}&y=${desk.y}&qrCode=${desk.strId}` : '' }`} />;
};
