import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { toast } from 'react-toastify';

import { default as api } from '../../services/api';
import { CLEANED } from '../../constants/cleaningStatuses';
import { Link } from 'react-router-dom';
import { CleaningIcon } from '../../services/svg-icons';
import getTitleDesk from '../../services/get-title-desk';


const { putDesk } = api
export default function JanitorDesks(props) {
  const { list, mapFloors, mapBuildings, isMeeting, isNonBookable,
          setNonBookable, setMeetings, setHotdesks } = props;

  const utcOffset = moment().utcOffset();

  return (
    <div>
      {
        list.map((item, index) => {
          const { strId, id, floorStrId, x, y, booking } = item;
          const currentFloor = mapFloors[floorStrId];
          const { buildingStrId, title } = currentFloor;
          const currentBuilding = mapBuildings[buildingStrId];
          const { name } = currentBuilding;
          const desc = `${name}, ${title}`;
          const currentBooking = booking && booking[0];
          const currentUtcTime =  moment().add(utcOffset, 'minutes');

          const handleClick = () => {
            putDesk({ ...item, cleaningStatus: CLEANED }).then(() => {
              toast(`Hot desk #${id} cleaned!`);
              const newList = [...list.slice(0, index), ...list.slice(index + 1)];

              isNonBookable ? setNonBookable(newList) : isMeeting ? setMeetings(newList) : setHotdesks(newList);
            });
          };

          const { shortTitle, headerTitle } = getTitleDesk(item);
          let timeToStartBooking = 'No booking';

          if (currentBooking) {
            const timeFrom = moment(currentBooking.timeFrom);

            if (timeFrom.isAfter(moment())) {
              const currentDiffUtcTime = moment(timeFrom - currentUtcTime);
              const diffTime = timeFrom.set({ hours: 23, minutes: 59 }).diff(moment(), 'days');

              if (diffTime === 1) {
                timeToStartBooking = 'Tomorrow';
              }

              if (diffTime > 1) {
                timeToStartBooking = `${diffTime} days`;
              }

              if (diffTime < 1) {
                timeToStartBooking = currentDiffUtcTime.hours() ? currentDiffUtcTime.format('In H [h] mm [min]') : currentDiffUtcTime.format('In mm [min]');
              }
            } else {
              timeToStartBooking = 'Now';
            }
          }

          const classes = cx('janitor__item-desc', {
            'janitor__item-desc_now':  timeToStartBooking === 'Now'
          });

          return (
            <div key={strId} className="janitor__item">
              <Link to={`/floor/${floorStrId}?x=${x}&y=${y}&deskId=${strId}`}>
                <a className="janitor__item-link" href={`/floor/${floorStrId}?x=${x}&y=${y}&deskId=${strId}`}>
                  <div className="janitor__item-avatar">
                    <div className="janitor__item-avatar-name">{shortTitle}</div>
                    <div className="janitor__item-avatar-name">{id}</div>
                  </div>
                  <div className="janitor__item-text">
                    <div className="janitor__item-title">{headerTitle} #{id}</div>
                    <div className="janitor__item-desc">{desc}</div>
                    <div className={classes}>{timeToStartBooking}</div>
                  </div>
                </a>
              </Link>
              <CleaningIcon className="janitor__item-cleaning-icon" onClick={handleClick} />
            </div>
          );
        })
      }
    </div>
  );
};

