import { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { ContextUser } from '../context/user';
import { ADMIN, COMPANY_ADMIN, JANITOR } from '../constants/userRoles';
import { default as api } from '../services/api';

const {
  fetchBuildingsByCompany,
  fetchFloorsByBuilding,
  fetchVirtualFloorsByCompany,
} = api

async function fetchBuildingAndFloor(companyStrId) {
  const result = { building: null, floor: null, virtualFloor: null }

  const virtualFloors = await fetchVirtualFloorsByCompany(companyStrId)
  if (virtualFloors && virtualFloors.length > 0) {
    result.virtualFloor = virtualFloors[0]
  }

  const buildings = await fetchBuildingsByCompany(companyStrId)
  if (!buildings || buildings.length === 0) {
    return result
  }

  const firstBuilding = buildings[0]
  result.building = firstBuilding

  const floors = await fetchFloorsByBuilding(firstBuilding.strId)
  if (floors && floors[0]) {
    result.floor = floors[0]
  }

  return result
}

export default function useUser() {
  const history = useHistory();
  const { user, setUser } = useContext(ContextUser);

  const [error, setError] = useState('');

  const isAdmin = useMemo(() => user?.roles?.includes(ADMIN), [user]);
  const isJanitor = useMemo(() => user?.roles?.includes(JANITOR), [user]);
  const isCompanyAdmin = useMemo(() => user?.roles?.includes(COMPANY_ADMIN), [user]);

  const handleError = (err) => {
    console.error(err);
    setError(err);
  };

  const redirectToFloor = async (desk, redirectUser) => {
    const currentUser = user || redirectUser;

    if (!currentUser) {
      history.replace('/');
      return;
    }

    const { companyStrId } = currentUser;

    if (!companyStrId) {
      handleError('User is not linked to the company!');
      return
    }

    try {
      const { floor, virtualFloor } = await fetchBuildingAndFloor(companyStrId)

      if (floor) {
        let link = `/floor/${floor.strId}`;
        if (desk) {
          const { x, y, strId } = desk;
          link += `/?x=${x}&y=${y}&qrCode=${strId}`;
        }
        history.push(link);
        return
      }

      if (virtualFloor) {
        history.push(`/virtual/${virtualFloor.strId}`)
        return
      }

      if (currentUser.roles && currentUser.roles.indexOf('company-admin') > -1) {
        // redirect to admin
        history.push(`/admin/${companyStrId}/virtual`);
        return
      }

      handleError('No floors found for the company');
    } catch(err) {
      console.error(err);
      setError(err);
    }
  };

  return {
    user,
    error,
    setUser,
    isAdmin,
    isJanitor,
    isCompanyAdmin,
    redirectToFloor
  };
};
