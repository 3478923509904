import React, { useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';

import Input from '../Input/Input';
import Button from '../Button/Button';
import UploadFile from '../UploadFile/UploadFile';

import useUser from '../../hooks/useUser';
import { default as api } from '../../services/api';

const { postFloor, putFloor } = api

export default function FloorForm(props) {
  const { floor } = props;
  const history = useHistory();
  const { buildingId } = useParams();

  const { isAdmin, user } = useUser();
  const defaultValues = useMemo(() => floor ? { ...floor } : {}, [floor]);

  const [floorPlanImg, setImg] = useState(floor ? floor.floorPlanImg : '');

  const onSubmit = (values) => {
    const { width, height, ...restValues } = values;
    const strId = floor ? floor.strId : nanoid();
    const request = floor ? putFloor : postFloor;
    const newFloor = { ...restValues, strId, floorPlanImg, width: +width, height: +height };

    if (buildingId) {
      newFloor.buildingStrId = buildingId;
    }

    request(newFloor).then(() => {
      history.push(`/${isAdmin ? 'root-' : ''}admin${!isAdmin ? `/${user.companyStrId}` : ''}/floors`);
    }).catch((e) => {
      console.log(e);
    });
  };

  const { handleSubmit, register } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-form">
        <div className="add-form__title">
          {floor ? 'Edit' : 'Create new'} floor
        </div>

        <UploadFile
          src={floorPlanImg}
          title="Select floor background"
          onLoad={setImg}
        />

        <Input
          type="number"
          inputRef={register}
          name="number"
          label="Number"
        />

        <Input
          inputRef={register}
          name="title"
          label="Name"
        />

        <Input
          type="textarea"
          inputRef={register}
          name="desc"
          label="Description"
        />

        <Input
          type="number"
          inputRef={register}
          name="width"
          label="Width in px"
        />

        <Input
          type="number"
          inputRef={register}
          name="height"
          label="Height in px"
        />

        <Button type="submit">
          {floor ? 'Save' : 'Create'}
        </Button>
      </div>
    </form>
  );
};
