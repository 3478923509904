export default [
  {
    id: 1,
    name: 'repeatSunday',
    nameShort: 'su'
  },
  {
    id: 2,
    name: 'repeatMonday',
    nameShort: 'mo'
  },
  {
    id: 3,
    name: 'repeatTuesday',
    nameShort: 'tu'
  },
  {
    id: 4,
    name: 'repeatWednesday',
    nameShort: 'we'
  },
  {
    id: 5,
    name: 'repeatThursday',
    nameShort: 'th'
  },
  {
    id: 6,
    name: 'repeatFriday',
    nameShort: 'fr'
  },
  {
    id: 7,
    name: 'repeatSaturday',
    nameShort: 'sa'
  }
];
