import React from 'react';
import classNames from 'classnames';
import findIndex from 'lodash/findIndex';

import { ArrowLongIcon } from '../../services/svg-icons';

import './floor-pagination.css';

function FloorPagination(props) {
  const { floor, setFloor=() => {}, floors } = props;
  const floorNumber = findIndex(floors, (item) => item.id === floor.id);

  const isMax = floor.id === floors[floors.length - 1].id;
  const isMin = floor.id === floors[0].id;
  const classesButtonInc = classNames('floor-pagination__floor', { 'floor-pagination__disabled': isMin });
  const classesButtonDec = classNames('floor-pagination__floor', { 'floor-pagination__disabled': isMax });
  const handleDec = () => !isMin && setFloor(floors[floorNumber - 1]);
  const handleInc = () => !isMax && setFloor(floors[floorNumber + 1]);

  return (
    <div className="floor-pagination">
      <div className={classesButtonDec} onClick={handleInc}>
        <ArrowLongIcon />
      </div>
      {
        // Floors must be in reverse order
        floors.map((item, index) => {
          const handleClick = () => item.id !== floor.id && setFloor(item);
          const classesButtonFloor = classNames('floor-pagination__floor', {
            'floor-pagination__floor_active': item.id === floor.id
          });

          return <div className={classesButtonFloor} key={index} onClick={handleClick}>{item.number}</div>;
        }).reverse()
      }
      <div className={classesButtonInc} onClick={handleDec}>
        <ArrowLongIcon className="floor-pagination__down" />
      </div>
    </div>
  );
}

export default FloorPagination;
