import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import findIndex from 'lodash/findIndex';

// import { isMobile } from 'react-device-detect';
import {useMobile} from '../../hooks/useDetectMobile';

import Button from '../Button/Button';
import {ClearIcon, TimerIcon} from '../../services/svg-icons';
import generateTimes from '../../services/generateTimes';

import './time-picker.css';

function TimePickerItem(props) {
  const {
    item, startTime, setStartTime, setEndTime, isAll, index,
    startIndex, endIndex, firstBookingIndex
  } = props;
  const {h, m, startBooking, endBooking, prevStartBooking} = item;
  const isDisabled = (prevStartBooking && startIndex === null) ||
    ((index < startIndex || isAll || (firstBookingIndex !== null && index > firstBookingIndex)) &&
      !startBooking && !endBooking);

  const dist = index - startIndex;
  const isStart = startIndex !== null;

  // const moreData = !isAll && !startBooking && !endBooking && !isDisabled &&
  //                   (isStart && index === startIndex ?
  //                   'START' :
  //                   isStart && (endIndex === null || (index > endIndex && dist < 5)) && dist > 0 && dist < 5 ?
  //                   `${ dist * 30 } min`:
  //                   endIndex === index ?
  //                   'END' : null);

  let moreData = null
  if (!isAll && !startBooking && !endBooking && !isDisabled) {
    if (isStart && index === startIndex) {
      moreData = 'START'
    } else if (isStart && endIndex === null) {
      if (dist < 2) {
        moreData = `${dist * 30} min`
      }
      {
        const h = parseInt(dist / 2, 10)
        const m = dist % 2
        moreData = ''
        if (h > 0) {
          moreData += `${h} h`
        }
        if (m > 0) {
          moreData += ` ${m * 30} min`
        }
      }

    } else if (endIndex === index) {
      moreData = 'END'
    }
  }

  // if (prevStartBooking) {
  //   moreData += ' prev'
  // }

  const classes = classNames('time-picker__button', {
    'time-picker__button_select-first': index === startIndex && endIndex === null,
    'time-picker__button_select': !isAll && isStart && index > startIndex && index < endIndex,
    'time-picker__button_ghost': isStart && index !== startIndex && index !== endIndex && moreData,
    'time-picker__button_select-start': !isAll && startIndex === index && endIndex !== null,
    'time-picker__button_select-end': !isAll && endIndex === index,
    'time-picker__button_start-booking': startBooking,
    'time-picker__button_end-booking': endBooking
  });
  const handleClick = () => {
    if (isStart && index === startIndex) {
      setEndTime(null)
      setStartTime(null)
      return;
    }

    if (startBooking || endBooking || (startTime && +startTime.h === +item.h && +startTime.m === +item.m)) {
      return;
    }

    if (!startTime) {
      setStartTime(item);
    } else {
      setEndTime(item);
    }
  };

  return (
    <Button className={classes} onClick={handleClick} key={index} disabled={isDisabled}>
      {h}:{m}
      {moreData && <span className="time-picker__more-data">{moreData}</span>}
    </Button>
  );
}

function TimePicker(props) {
  const {
    deskStrId,
    onClick,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    booking,
    date,
    isRecurrent,
    recurrentData,
    recurrentIntervals
  } = props;

  const [isAll, setAll] = useState(false);
  const [isView, setView] = useState(false);
  const [currentTime, setTime] = useState('');
  const [endIndex, setEndIndex] = useState(null);
  const [startIndex, setStartIndex] = useState(null);
  const [firstBookingIndex, setFirstBookingIndex] = useState(null);
  const [times, isDisableAll] = generateTimes(date, booking, recurrentIntervals[deskStrId]);

  const classesAllButton = classNames('time-picker__button time-picker__button_big', {
    'time-picker__button_select-first': isAll
  });
  const handleAllClick = () => {
    if (isAll) {
      setAll(false);
      setStartTime(null);
      setEndTime(null);
      return
    }
    setAll(true);
    setStartTime(times[0]);
    setEndTime(times[times.length - 1]);
  };

  const clear = () => {
    setTime('');
    setEndIndex(null);
    setStartIndex(null);
    setFirstBookingIndex(null);
  }

  useEffect(() => {
    if (!startTime && !endTime) {
      clear()
      return
    }

    if (startTime && !endTime) {
      const index = findIndex(times, (item) => item.h === startTime.h && item.m === startTime.m && !item.startBooking && !item.endBooking);
      const bookingIndex = findIndex(times, (item, bIndex) => bIndex > index && item.startBooking);

      setStartIndex(index);
      bookingIndex > -1 && setFirstBookingIndex(bookingIndex);
    }

    if (endTime) {
      const index = findIndex(times, (item) => item.h === endTime.h && item.m === endTime.m && !item.startBooking && !item.endBooking);
      setEndIndex(index);
      setTime(`From ${startTime.h}:${startTime.m} until ${endTime.h}:${endTime.m}`);
      setView(false);
    }
  }, [startTime, endTime]);

  useEffect(() => {
    clear()
  }, [date]);


  const isMobile = useMobile()

  useEffect(() => {
    if (isView && !isMobile) {
      const popupEl = document.querySelector('.time-picker__popup');
      const {height, top} = popupEl.getBoundingClientRect();

      if (height + top > window.innerHeight) {
        popupEl.style.top = '-382px';
      } else {
        popupEl.style.top = '40px';
      }
    }
  }, [isView, isMobile]);

  const disabled = (!isRecurrent && !date) || (isRecurrent && !recurrentData);

  return (
    <div className="time-picker" onClick={onClick}>
      {isView && isMobile && <div className="popup__background"/>}
      <input className="input" value={currentTime} onClick={() => setView(!isView)} readOnly disabled={disabled}/>
      <TimerIcon className="time-picker__icon"/>
      {
        isView &&
        <div className="time-picker__popup">
          <header className="time-picker__popup-header">
            <div>Select time</div>
            <ClearIcon className="time-picker__popup-header_close-button" onClick={onClick}/>
          </header>
          <div className="time-picker__popup-body">
            <Button className={classesAllButton} onClick={handleAllClick} disabled={isDisableAll}>
              The Rest of The Day
            </Button>
            {
              times.map((item, index) => {
                return (
                  <TimePickerItem
                    key={index}
                    item={item}
                    isAll={isAll}
                    index={index}
                    endIndex={endIndex}
                    startTime={startTime}
                    setEndTime={setEndTime}
                    startIndex={startIndex}
                    setStartTime={setStartTime}
                    firstBookingIndex={firstBookingIndex}
                  />
                )
              })
            }
          </div>
        </div>
      }
    </div>
  )
}

export default TimePicker;
