import React from 'react';
import cx from 'classnames';

import { CalendarIcon } from '../../services/svg-icons';

import './date-picker-input.css';

function DatePickerInput(props) {
  const { value, onClick, isNotIcon, className } = props;
  const classes = cx('input', className);

  return (
    <div className="date-picker-input" onClick={onClick}>
      <input className={classes} value={value} readOnly/>
      {!isNotIcon && <CalendarIcon className="date-picker-input__icon"/>}
    </div>
  )
};

export default DatePickerInput;
