import React, { useEffect, useState } from 'react';
import find from 'lodash.find';
import cloneDeep from 'lodash/cloneDeep';
import { useHistory, useParams } from 'react-router';

import Button from '../Button/Button';
import AdminPopupBooking from '../AdminPopupBooking/AdminPopupBooking';

import { default as api } from '../../services/api';
import Tabs from '../Tabs/Tabs';
import { Link } from 'react-router-dom';
import useUser from '../../hooks/useUser';

const {
  fetchDesksForFloor,
  deleteDesk,
  fetchContactsByCompany,
  fetchBookingForFloor,
  fetchRecurrentBookingForFloor,
  fetchCompanyByStrId,
  fetchFloor
} = api

const NAMES = [
  'Id', 'Code', 'QrId', 'Kind', 'Status', 'Cleaning Status', 'Image', 'Member', 'Position', 'Scale', '', ''
];

const TABS = [
  { title: 'Desks' },
  { title: 'Meeting rooms' }
];

function AdminDesks() {
  const history = useHistory();
  const { isAdmin } = useUser();
  const { companyId, floorId } = useParams();

  const [desk, setDesk] = useState(null);
  const [desks, setDesks] = useState([]);
  const [floor, setFloor] = useState(null);
  const [company, setCompany] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [mapBooking, setMapBooking] = useState({});
  const [mapRecurrentBooking, setMapRecurrentBooking] = useState({});

  const getDesksByFloor = (id) => fetchDesksForFloor(id).then(setDesks);

  const handleError = (err) => {
    console.error(err);
  };

  const handleBookingLoad = (arr, setCb) => {
    if (arr.length) {
      const bookingForDesks = arr.reduce((list, item) => {
        const { entityId } = item;
        const desk = list[entityId];
        let booking = [item];

        if (desk) {
          booking = [...desk, item];
        }

        return { ...list, [entityId]: booking };
      }, {});

      setCb(bookingForDesks);
    }
  };

  const handleClickTab = (index) => {
    if (index) {
      history.push(`/${isAdmin ? 'root-' : ''}admin/${companyId}/floors/${floorId}/meetings`);
    }
  };

  const getBookingForFloor = (id) => fetchBookingForFloor(id)
    .then((res) => handleBookingLoad(res, setMapBooking))
    .catch(handleError);

  const getRecurrentBookingForfloor = (id) => fetchRecurrentBookingForFloor(id)
    .then((res) => handleBookingLoad(res, setMapRecurrentBooking))
    .catch(handleError);

  useEffect(() => {
    if (floorId) {
      fetchFloor(floorId).then(setFloor);
    }
  }, [floorId]);

  useEffect(() => {
    if (companyId) {
      fetchCompanyByStrId(companyId).then(setCompany);
    }
  }, [companyId]);

  useEffect(() => {
    if (company) {
      fetchContactsByCompany(company.strId).then(setContacts);
    }
  }, [company]);

  useEffect(() => {
    if (floor) {
      const { strId } = floor;

      getDesksByFloor(strId);
      getBookingForFloor(strId);
      getRecurrentBookingForfloor(strId);
    }
  }, [floor]);

  return (
    <div>
      <div className="breadcrumbs">
        {company && <span>{company.title}</span>}
        {floor && <span> / {floor.title}</span>}
      </div>

      <Tabs tabs={TABS} active={0} setActive={handleClickTab} />

      <table className="table">
        <thead>
          { renderHeader() }
        </thead>
        <tbody>
          { renderDesks(desks, setDesks, contacts, setDesk, companyId, floorId, isAdmin) }
        </tbody>
      </table>

      {
        desk &&
        <AdminPopupBooking
          desk={desk}
          contacts={contacts}
          booking={mapBooking}
          setMapBooking={setMapBooking}
          setMapRecurrentBooking={setMapRecurrentBooking}
          recurrentBooking={mapRecurrentBooking}
          onClose={() => setDesk(null)}
        />
      }
    </div>
  );
}

const renderHeader = () => {
  return (
    <tr>
      { NAMES.map((item, index) => <th key={index}>{item}</th>) }
    </tr>
  );
};

const renderDesks = (desks, setDesks, contacts, setDesk, companyId, floorId, isAdmin)=> {
  return (
    desks.map((item, index) => {
      const {
        id, status, img, strId, kind, contactStrId,
        x, y, scale, rotation, cleaningStatus, qrId
      } = item;
      const currentContact = find(contacts, (item) => item.strId === contactStrId);
      const newDesks = cloneDeep(desks);
      const handleDelete = () => {
        deleteDesk(strId);
        newDesks.splice(index, 1);

        setDesks(newDesks);
      };

      const bgStyle = {
        backgroundImage: `url(${img})`
      }

      return (
        <tr key={index}>
          <td
            style={{ cursor: 'pointer' }}
            onClick={() => setDesk(item)}
          >
            {id}
          </td>

          <td
            style={{ cursor: 'pointer' }}
            onClick={() => setDesk(item)}
          >
            {strId}
          </td>

          <td>{qrId}</td>
          <td>{kind}</td>
          <td>{status}</td>
          <td>{cleaningStatus}</td>
          <td><div className="b-admin-desk-image" style={bgStyle} ></div></td>
          <td>{currentContact && currentContact.name}</td>

          <td>
            <div>x {x}</div>
            <div>y {y}</div>
          </td>

          <td>
            <div>scale {scale}</div>
            <div>rotation {rotation}</div>
          </td>

          <td>
            <Link to={`/${isAdmin ? 'root-' : ''}admin/${companyId}/floors/${floorId}/desks/${strId}/edit`}>
              <Button type="edit">
                Edit
              </Button>
            </Link>
          </td>

          <td>
            <Button type="delete" onClick={handleDelete}>
              Delete
            </Button>
          </td>
        </tr>
      );
    })
  );
};

export default AdminDesks;
