// import './StartPage.css'
import React, { useEffect, useRef, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import * as microsoftTeams from "@microsoft/teams-js";

import find from 'lodash.find'

import useUser from '../../hooks/useUser';
import useTeamsFlag, { notifySuccess } from '../../hooks/useTeams'
import useAuth from '../../auth/useAuth'

// import { BigLogoIcon } from '../../services/svg-icons';

// import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button'
import Select from '../../components/Select/Select';

import useCompanies from '../../hooks/useCompanies';
import { default as api } from '../../services/api'

const { fetchVirtualFloorsByToken, fetchVirtualFloorsByCompany } = api
const getTypeLabel = (type) => type.title;
  const getTypeValue = (type) => type.strId;

export default function StartPage() {
  const { user } = useUser()
  const [userData, setUserData] = useState(null)
  const teamsFlag = useTeamsFlag()
  const [err, setErr] = useState('')

  const [virtualFloors, setVirtualFloors] = useState([])
  const [virtualFloor, setVirtualFloor] = useState(null);

  const {
    isAuthenticated,
    isLoading,
    // loginWithRedirect,
    getIdTokenClaims,
    // logout
  } = useAuth({ overrideSlug: true });

  // useEffect(() => {
  //   setFlagNotFound(false)
  // }, [companyName])

  useEffect(() => {
    if (userData) {
      return
    }

    if (teamsFlag === 'loading') {
      return
    }

    if (localStorage.forceAuthToken) {
      setUserData(JSON.parse(localStorage.forceAuthToken))
      localStorage.removeItem('forceAuthToken')
      return
    }

    if (!isLoading) {
      getIdTokenClaims()
      .then((r) => {
        if (teamsFlag === 'teams') {
          const { __raw: token } = r
          notifySuccess(r)
        } else {
          setUserData(r)
        }
      })
      .catch((err) => {
        console.log('auth err', err)
        setErr(err.toString())
      })
    }
  }, [isAuthenticated, isLoading, teamsFlag, userData])

  useEffect(() => {
    if (!userData) {
      return
    }

    // alert(userData)
    // for (const key in userData) {
    //   alert(`${key}: ${userData[key]}`)
    // }

    const { email, __raw: token } = userData
    console.log('configure for email', email)


    fetchVirtualFloorsByToken(token)
    .then((result) => {
      console.log('floors', result)
      setVirtualFloors(result.virtualFloors)
    })
    .catch((e) => {
      console.log('err', e)
    })

    // alert(email)
    // contactsPostCheckAvailability(email)
    // .then(({ isAvailable }) => {
    //   // alert('Done')
    //   if (!isAvailable) {
    //     setErr('email_not_available')
    //   }
    // })
    // .catch((err) => {
    //   alert(`Checking err ${err}`)

    //   setErr(err.toString())
    // })
  }, [userData])

  useEffect(() => {
    if (!user || !user.companyStrId) {
      return
    }

    fetchVirtualFloorsByCompany(user.companyStrId)
    .then((floors) => {
      setVirtualFloors(floors)
    })
    .catch((e) => {
      alert(`Err: ${e.toString()}`)
    })

  }, [user])

  useEffect(() => {
    if (!virtualFloors || !virtualFloor) {
      return
    }
    try {
      // alert(`virtualFloor: ${virtualFloor} `)
      // const floor = find(virtualFloors, { strId: virtualFloor })
      const floor = virtualFloor

      // alert(`Floor: ${floor}`)
      // alert('try init')

      microsoftTeams.initialize(() => {
        // alert('try inited')
        const contentUrl = `https://wkpgo-web-0.azurewebsites.net/virtual-read-only/${floor.strId}`
        // alert(`Content url: ${contentUrl}`)

        microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
          microsoftTeams.settings.setSettings({
            websiteUrl: "https://wkpgo-web-0.azurewebsites.net",
            contentUrl,
            entityId: `WorkipelagoTab${floor.strId}`,
            suggestedDisplayName: `${floor.title} | Workipelago`
          });
          saveEvent.notifySuccess();
        })
        microsoftTeams.settings.setValidityState(true)
      })
    } catch(e) {
      console.log('floor choose err', e)
    }
  }, [virtualFloors, virtualFloor])

  // if (redirectTo) {
  //   return <Redirect to={redirectTo} />
  // }

  // if (user && user.roles && hasRole(user.roles, 'admin')) {
  //   return <Redirect to="/root-admin" />
  // }

  // if (user) {
  //   return <Redirect to="/not-company-profile" />
  // }

  return (
    <div className="sp-container">
      <div className="login login__row login__start">
        <div className="sp-msg">
          <div className="sp-msg_header">
            Choose virtual floor:
          </div>
          {virtualFloors && virtualFloors.length > 0 && <div>
            <Select
              options={virtualFloors}
              value={virtualFloor}
              onChange={setVirtualFloor}
              getOptionLabel={getTypeLabel}
              getOptionValue={getTypeValue}
            />
          </div>}
        </div>
    </div>
    </div>
  )
}


/*
<div className="sp-msg_buttons">
            <Link to="/signup" >
              <Button>Sa</Button>
            </Link>
            <Link to="/login/demo">
              <Button type="ghost">View demo</Button>
            </Link>
          </div>
*/
