import { getDefaultPoints } from './default-points-meeting';
import { createMapItem } from './map-items';
import { default as api } from './api';

import { BOOKABLE, NON_BOOKABLE } from '../constants/meetingStatuses';

const {
  postDesk,
  deleteDesk,
  postMeeting,
  deleteMeeting
} = api

export default (props) => {
  const { floor, paletteItem, geometry, map, strId, isRemove } = props;
  const { width, height, img, type, status, id } = paletteItem;

  if (isRemove) {
    const el = map.querySelector(`[data-str-id='${strId}']`);
    map.removeChild(el)

    if (status === BOOKABLE || status === NON_BOOKABLE) {
      deleteMeeting(strId).then(console.log).catch((e) => {console.log(e)});
    } else {
      deleteDesk(strId).catch((e) => {console.log(e)});
    }
  } else {
    const item = {
      strId,
      x: geometry.x - parseInt(width / 2, 10),
      y: geometry.y - parseInt(height / 2, 10),
      rotation: 0,
      scale: '0.8',
      width,
      height,
      img,
      kind: type,
      paletteItemId: id,
      status,
      floorStrId: floor.strId
    }
    const el = createMapItem(item)
    map.appendChild(el)

    /* TODO: optimistic save */
    if (status === BOOKABLE || status === NON_BOOKABLE) {
      const points = JSON.stringify(getDefaultPoints(width, height));
      postMeeting({ ...item, points }).then(console.log).catch((e) => {console.log(e)});
    } else {
      postDesk(item).catch((e) => {console.log(e)});
    }
  }
}
