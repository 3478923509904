import React, {useEffect, useMemo, useState} from 'react';
import { nanoid } from 'nanoid';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import difference from 'lodash/difference';

import Input from '../Input/Input';
import Button from '../Button/Button';
import Select from '../Select/Select';
import UploadFile from '../UploadFile/UploadFile';

import useUser from '../../hooks/useUser';

import { default as api } from '../../services/api';

import { roles as globalRoles, statuses } from '../../services/lists-contact';
import { ADMIN } from '../../constants/userRoles';
import useCompanies from "../../hooks/useCompanies";

const {
  deleteRolesContact,
  getImgUrl,
  postContact,
  putContact,
  putRolesContact,
  postMailchimpIvintedList,
} = api
const DEFAULT_STATUS = 'available';

function getCurrentCompany(companies = [], params = '', user = {}) {
  const companyStrId = params || user.companyStrId
  if (companyStrId) {
    const curCompany = companies.find(i => i.strId === companyStrId)
    if (curCompany) {
      return curCompany
    }
  }
  return {
    withoutCompany: true,
    slug: "Users without company",
    title: "Users without company",
  }
}

export default function UserForm(props) {
  const { user } = props;
  const history = useHistory();
  const { companyId } = useParams();

  const { isAdmin } = useUser();
  const { companies } = useCompanies();
  const defaultValues = useMemo(() => user ? { ...user } : {
    phone: '',
  }, [user]);
  const formatedRoles = useMemo(() => user?.roles?.map((item) => ({ label: item, value: item })), [user]);
  const currentRoles = useMemo(() => !isAdmin ? globalRoles.filter((item) => item.value !== ADMIN) : globalRoles, [isAdmin]);

  const [img, setImg] = useState(user && user.img ? getImgUrl(user.img) : '');
  const [status, setStatus] = useState(user?.status ?? DEFAULT_STATUS);
  const [roles, setRoles] = useState(user ? formatedRoles : []);
  const [createUserErrorMessage, setCreateUserErrorMessage] = useState('')
  const [currentCompany, setCurrentCompany] = useState(getCurrentCompany(companies, companyId, user));
  const getTypeLabel = (type) => type.title;
  const getTypeValue = (type) => type.strId;
  const companiesOptions = [
    ...companies
  ];

  useEffect(() => {
    if (!currentCompany?.strId) {
      companiesOptions.push({
        withoutCompany: true,
        slug: "Users without company",
        title: "Users without company",
      })
    }
  }, [currentCompany, companiesOptions])

  const onSubmit = (values) => {
    const strId = user?.strId ?? nanoid();

    let request = putContact
    let isNewUser = false
    if (!user) {
      request = postContact
      isNewUser = true
    }

    // const request = user ? putContact : postContact;
    const newUser = { ...values, strId, status, img };
    const currentRoles = roles.map((item) => item.value);
    const oldRoles = user ? difference(user.roles, currentRoles) : [];
    const newRoles = user ? difference(currentRoles, user.roles) : currentRoles;

    newRoles.length && putRolesContact(strId, newRoles);
    oldRoles.length && deleteRolesContact(strId, oldRoles);

    if (companyId) {
      newUser.companyStrId = companyId;
    }

    if (isAdmin && currentCompany?.strId && currentCompany?.strId !== user?.companyStrId) {
      newUser.companyStrId = currentCompany.strId;
    }

    if (!newUser.phone) {
      newUser.phone = ''
    }

    request(newUser).then((result) => {
      if (isNewUser && result.strId) {
        postMailchimpIvintedList(result.strId)
      }
      setCreateUserErrorMessage('')
      history.push(`/${isAdmin ? 'root-' : ''}admin${!isAdmin ? `/${companyId}` : ''}/users`);
    }).catch((e) => {
      if (e.response && e.response.body && e.response.body.error === 'Email exist') {
        setCreateUserErrorMessage(e.response.body.error_description || 'Unknown error')
      }
      console.log(e);
    });
  };

  const { handleSubmit, register } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-form">
        <div className="add-form__title">
          {user ? 'Edited' : 'Created'} User
        </div>

        <UploadFile
          src={img}
          title="Selected avatar"
          onLoad={setImg}
        />

        <Input
          inputRef={register}
          name="name"
          label="Name"
        />

        <Input
          inputRef={register}
          name="position"
          label="Position"
        />

        <Select
          isMulti
          label="Roles"
          options={currentRoles}
          onChange={setRoles}
          value={roles}
        />

        <Select
          label="Status"
          options={statuses}
          onChange={(option) => setStatus(option.value)}
          value={{ value: status, label: status }}
        />

        {
          isAdmin &&
          <Select
            label="Company"
            options={companiesOptions}
            value={currentCompany}
            getOptionLabel={getTypeLabel}
            getOptionValue={getTypeValue}
            onChange={setCurrentCompany}
          />
        }

        <Input
          inputRef={register}
          name="message"
          label="Status message"
        />

        <Input
          inputRef={register}
          name="email"
          label="E-mail"
        />

        {/* <Input
          inputRef={register}
          name="phone"
          label="Phone"
        /> */}

        <Input
          inputRef={register}
          name="callLink"
          label="Call link"
        />

        <Input
          inputRef={register}
          name="chatLink"
          label="Chat link"
        />

        <Input
          inputRef={register}
          name="calendarLink"
          label="Calendar link"
        />

        <div>
          <Button type="submit">
            {user ? 'Save' : 'Create'}
          </Button>
          <p style={{
            visibility: createUserErrorMessage ? "visible" : "hidden",
            color: 'red'
          }}>
            {createUserErrorMessage}
          </p>
        </div>
      </div>
    </form>
  );
};
