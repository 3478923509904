import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import Select from 'react-select';
import cloneDeep from 'lodash/cloneDeep';
import DatePicker from 'react-datepicker';

// import { isMobile } from 'react-device-detect';
import { useMobile } from '../../hooks/useDetectMobile';

import daysWeek from '../../services/days-week'
import { CalendarIcon } from '../../services/svg-icons';
import repeatDateTypes from '../../services/repeat-date-types';
import getNameRecurrentDate from '../../services/get-name-recurrent-date';

import Button from '../Button/Button';
import DatePickerInput from '../DatePickerInput/DatePickerInput';
import DatePickerHeader from '../DatePickerHeader/DatePickerHeader';

import './recurrent-date-picker.css';

export default function RecurrentDatePicker({ recurrentData, setRecurrentData }) {
  const [date, setDate] = useState(null);
  const [count, setCount] = useState(1);
  const [isView, setView] = useState(false);
  const [days, setDays] = useState(daysWeek);
  const [endType, setEndType] = useState('never');
  const [repeatValue, setRepeatValue] = useState(1);
  const [repeatType, setRepeatType] = useState(repeatDateTypes[0]);

  const getTypeLabel = (type) => type.name;
  const getTypeValue = (type) => type.id;

  const isWeek = repeatType.name === 'week';
  let currentTime = '';

  if (recurrentData) {
    currentTime = getNameRecurrentDate(recurrentData, isWeek);
  }

  const isMobile = useMobile()

  const selectStyles = {
    control: provided => ({
      ...provided,
      width: isMobile ? 139 : 120,
      border: '1px solid #e3e3e3',
      height: isMobile ? 56 : 40
    })
  };

  const handleChangeEndType = ({target}) => setEndType(target.value);
  const getDisableButton = () => {
    const isGoodRepeatValue = repeatValue > 0;
    const isGoodDays = !isWeek || (isWeek && days.some((item) => item.isSelect));
    const isGoodEnd = endType === 'on' ? Boolean(date) : endType === 'after' ? count > 0 : true;

    return !(isGoodRepeatValue && isGoodDays && isGoodEnd);
  };
  const handleClickSave = () => {
    let repeatEnd = {};
    const repeatDays = days.reduce((list, item) => {
      if (item.isSelect) {
        return {...list, [item.name]: true};
      }
      return list;
    }, {});

    switch (endType) {
      case 'on':
        repeatEnd.repeatUntil = date;
        break;
      case 'after':
        repeatEnd.repeatUntilCount = +count;
        break;
      default:
        break;
    }

    const data = {
      ...repeatEnd,
      ...repeatDays,
      repeatCount: +repeatValue,
      repeatTimeSlice: repeatType.name,
    };

    setRecurrentData(data);
    setView(false);
  };

  const disabled = getDisableButton();

  useEffect(() => {
    if (isView && !isMobile) {
      const popupEl = document.querySelector('.recurrent-date-picker__popup');
      const { height, top } = popupEl.getBoundingClientRect();

      if (height + top > window.innerHeight) {
        popupEl.style.top = '-366px';
      }
    }
  }, [isView, isMobile]);

  return (
    <div className="recurrent-date-picker">
      {isView && isMobile && <div className="popup__background"/>}
      <input className="input" title={currentTime} value={currentTime} onClick={() => setView(!isView)} readOnly/>
      <CalendarIcon className="recurrent-date-picker__icon"/>
      {
        isView &&
        <div className="recurrent-date-picker__popup">
          <header className="recurrent-date-picker__popup-header">Recurrent date</header>
          <div className="recurrent-date-picker__popup-body">
            <div className="recurrent-date-picker__input-holder">
              <span className="recurrent-date-picker__label">Repeat every</span>
              <div className="recurrent-date-picker__inputs-repeat">
                <input
                  type="number"
                  value={repeatValue}
                  className="input recurrent-date-picker__input_mini"
                  onChange={({target}) => setRepeatValue(target.value)}
                />
                <Select
                  value={repeatType}
                  isSearchable={false}
                  styles={selectStyles}
                  onChange={setRepeatType}
                  options={repeatDateTypes}
                  getOptionValue={getTypeValue}
                  getOptionLabel={getTypeLabel}
                />
              </div>
            </div>
            {
              isWeek &&
              <div className="recurrent-date-picker__input-holder">
                <span className="recurrent-date-picker__label">Repeat days</span>
                <div className="recurrent-date-picker__inputs-repeat recurrent-date-picker__inputs-day">
                  {
                    days.map((item, index) => {
                      const { nameShort, isSelect } = item;
                      const classes = cx('recurrent-date-picker__day', {
                        'recurrent-date-picker__day_active': isSelect
                      });
                      const handleClick = () => {
                        const cloneDays = cloneDeep(days);
                        cloneDays[index].isSelect = !cloneDays[index].isSelect;

                        setDays(cloneDays);
                      };

                      return (
                        <div key={index} className={classes} onClick={handleClick}>
                          {nameShort}
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            }
            <div className="recurrent-date-picker__input-holder">
              <span className="recurrent-date-picker__label">Ends</span>
              <div>
                <div className="recurrent-date-picker__radio-holder">
                  <input
                    id="never"
                    name="end"
                    type="radio"
                    value="never"
                    checked={endType === 'never'}
                    onChange={handleChangeEndType}
                    className="recurrent-date-picker__radio"
                  />
                  <label htmlFor="never" className="recurrent-date-picker__radio-label">
                    Never
                  </label>
                </div>

                <div className="recurrent-date-picker__radio-holder">
                  <input
                    id="on"
                    name="end"
                    type="radio"
                    value="on"
                    checked={endType === 'on'}
                    onChange={handleChangeEndType}
                    className="recurrent-date-picker__radio"
                  />
                  <label htmlFor="on" className="recurrent-date-picker__radio-label">
                    On
                  </label>
                  <DatePicker
                    minDate={Date.now()}
                    customInput={<DatePickerInput className="recurrent-date-picker__until-count" isNotIcon={true}/>}
                    renderCustomHeader={DatePickerHeader}
                    selected={date}
                    onChange={setDate}
                    dateFormat="MMMM dd, yyyy"
                  />
                </div>

                <div className="recurrent-date-picker__radio-holder">
                  <input
                    id="after"
                    name="end"
                    type="radio"
                    value="after"
                    checked={endType === 'after'}
                    onChange={handleChangeEndType}
                    className="recurrent-date-picker__radio"
                  />
                  <label htmlFor="after" className="recurrent-date-picker__radio-label">
                    After
                  </label>
                  <input
                    value={count}
                    type="number"
                    onChange={({target}) => setCount(target.value)}
                    className="input recurrent-date-picker__until-count"
                  />
                </div>
              </div>
            </div>
          </div>
          <footer className="invitees-list__popup-footer">
            <Button onClick={handleClickSave} disabled={disabled}>
              Save date
            </Button>
          </footer>
        </div>
      }
    </div>
  );
};
