import React, { useState, Fragment } from 'react';
import moment from 'moment';
import Select from 'react-select';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';

// import { isMobile } from 'react-device-detect';
import { useMobile } from '../../hooks/useDetectMobile';

import Button from '../Button/Button';
import Switch from '../Switch/Switch';
import Loader from '../Loader/Loader';
import TimePicker from '../TimePicker/TimePicker';
import InviteesList from '../InviteesList/InviteesList';
import DatePickerInput from '../DatePickerInput/DatePickerInput';
import DatePickerHeader from '../DatePickerHeader/DatePickerHeader';
import RecurrentDatePicker from '../ReccurentDatePicker/ReccurentDatePicker';
import { NON_BOOKABLE, BOOKABLE } from '../../constants/meetingStatuses';
import useTeamsFlag from '../../hooks/useTeams'

export default function BookingForm({
  date,
  handleChangeDate,
  startTime,
  setStartTime,
  endTime,
  pendingIntervals,
  setEndTime,
  booking,
  status,
  title,
  setTitle,
  contacts,
  setMembers,
  isRecurrent,
  setRecurrent,
  isInvisible,
  isInactive, setInactive,
  setInvisible,
  recurrentData,
  setRecurrentData,
  recurrentIntervals,
  deskStrId,
  availabilityInvisible,
  user, setUser, isAdmin
}) {
  const [isOpen, setOpen] = useState(false);
  const [timeSelected, setTimeSelected] = useState(0);

  const teamsFlag = useTeamsFlag()
  const isMobile = useMobile()

  const checkTeams = () => {
    return isMobile && teamsFlag === 'teams'
  }

  const handleCalendarOpen = () => setOpen(true);
  const handleCalendarClose = () => setOpen(false);

  const getTypeLabel = (type) => type.name;
  const getTypeValue = (type) => type.strId;


  if (status === NON_BOOKABLE) {
    const onSet = (mins) => {
      setTimeSelected(mins)

      const today = moment().startOf('day').toDate()
      const now = moment()

      const fromHours = now.hours()
      const fromMins = now.minutes()

      now.add(mins, 'minutes')
      const toHours = now.hours()
      const toMitunes = now.minutes()

      handleChangeDate(today)
      setStartTime({ h: fromHours, m: fromMins })
      setEndTime({ h: toHours, m: toMitunes })
    }

    const onSet30 = () => onSet(30);
    const onSet60 = () => onSet(60);

    const cl30 = classNames('popup-booking_time-button', {
      'popup-booking_time-button__selected': timeSelected === 30,
    })

    const cl60 = classNames('popup-booking_time-button', {
      'popup-booking_time-button__selected': timeSelected === 60,
    })

    return (
      <div className="popup-booking__middle popup-booking__middle_non-bookable" id={"popup-booking"}>
        {
          availabilityInvisible && !checkTeams &&
          <div className="popup-booking__label" onClick={() => setInvisible(!isInvisible)}>
            <span>Invisible</span>
            <Switch isActive={isInvisible}/>
          </div>
        }
        <div className="popup-booking_button-line">
          <Button type="ghost-light" className={cl30} onClick={onSet30}>
            30 min
          </Button>
          <Button type="ghost-light" className={cl60} onClick={onSet60}>
            60 min
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="popup-booking__middle" >
      {
        pendingIntervals ?
        <div className="popup-booking__loader">
          <Loader isAbsolute/>
        </div> :
        <Fragment>
          {
            isAdmin &&
            <Fragment>
              <div className="popup-booking__input-holder">
                {!checkTeams() &&
                  <div className="popup-booking__label" onClick={() => {
                    setRecurrent(!isRecurrent);
                    setInactive(false)
                  }}>
                    <span>Recurrent</span>
                    <Switch isActive={isRecurrent}/>
                  </div>
                }
                {!checkTeams() &&
                <div className="popup-booking__label" onClick={() => {
                  setInactive(!isInactive);
                  setRecurrent(false);
                  setInvisible(false)
                }}>
                  <span>Inactive</span>
                  <Switch isActive={isInactive}/>
                </div>
                }
                {
                  availabilityInvisible && !checkTeams() &&
                  <div className="popup-booking__label" onClick={() => {setInvisible(!isInvisible);setInactive(false)}}>
                    <span>Invisible</span>
                    <Switch isActive={isInvisible}/>
                  </div>
                }
              </div>
              {
                !isInactive && !checkTeams() &&
                <div className="popup-booking__input-holder">
                  <label>
                    <div className="popup-booking__label">User</div>
                    <Select options={contacts} onChange={setUser} value={user}
                            getOptionLabel={getTypeLabel} getOptionValue={getTypeValue}/>
                  </label>
                </div>
              }
            </Fragment>
          }
          <div className="popup-booking__input-holder">
            {isOpen && isMobile && <div className="popup__background"/>}
            <div>
              <span className="popup-booking__label">
                <span>Date</span>
                {
                  availabilityInvisible && !checkTeams() && !isAdmin &&
                  <div className="popup-booking__label-right" onClick={() => setInvisible(!isInvisible)}>
                    <span>Invisible</span>
                    <Switch isActive={isInvisible}/>
                  </div>
                }
              </span>
              {
                isRecurrent ?
                <RecurrentDatePicker
                  recurrentData={recurrentData}
                  setRecurrentData={setRecurrentData}
                /> :
                <DatePicker
                  onCalendarClose={handleCalendarClose}
                  onCalendarOpen={handleCalendarOpen}
                  customInput={<DatePickerInput />}
                  minDate={Date.now()}
                  renderCustomHeader={DatePickerHeader}
                  selected={date}
                  onChange={handleChangeDate}
                  dateFormat="MMMM dd, yyyy"
                />
              }
            </div>
          </div>
          <div className="popup-booking__input-holder">
            <label>
              <span className="popup-booking__label">Time</span>
              <TimePicker
                date={date}
                endTime={endTime}
                booking={booking}
                deskStrId={deskStrId}
                startTime={startTime}
                setEndTime={setEndTime}
                isRecurrent={isRecurrent}
                setStartTime={setStartTime}
                recurrentData={recurrentData}
                recurrentIntervals={recurrentIntervals}
              />
            </label>
          </div>
          {
            status === BOOKABLE &&
            <Fragment>
              <div className="popup-booking__input-holder">
                <label >
                  <span className="popup-booking__label">Meeting Title</span>
                  <input className="input" value={title}  onChange={({target}) => setTitle(target.value)} />
                </label>
              </div>
              <label>
                <span className="popup-booking__label">Add invitees</span>
                <InviteesList contacts={contacts} setMembers={setMembers}/>
              </label>
            </Fragment>
          }
        </Fragment>
      }
    </div>
  )
}
