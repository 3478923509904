import React from 'react';

import Loader from '../Loader/Loader'
import useAuth from '../../auth/useAuth';
import { useHistory } from 'react-router';

export default function AuthLoader() {
  const history = useHistory()
  const { auth, isLoading } = useAuth()
  localStorage.removeItem('authAction')

  if (!isLoading && auth.client) {
    auth.client.getTokenSilently()
      .then(() => auth.client.getIdTokenClaims())
      .then(({ __raw: token }) => {
        if (!token) {
          throw new Error('User dont authorize')
        }
        localStorage.setItem('token', token)
        const pathReturn = localStorage.getItem('returnPath')
        if (pathReturn && pathReturn !== '/' && !pathReturn.startsWith('/floor')) {
          history.goBack()
          history.replace(localStorage.returnPath)
          localStorage.removeItem('returnPath')
        } else if (localStorage.getItem('returnTo')) {
          /** from startPage */
          history.replace(localStorage.getItem('returnTo'))
        } else {
          /** any other flows */
          history.replace('/login')
        }
      })
      .catch(err => {
        localStorage.clear()
        window.location.pathname = '/signin'
      })
  } else if (!isLoading && !auth.client) {
    localStorage.clear()
    window.location.pathname = '/signin'
  }

  return (<Loader/>)
}
