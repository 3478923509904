import moment from 'moment';

export default (item, date) => {
  const { timeFrom } = item;
  const currentDate = date || moment();
  const today = currentDate.clone().endOf('day');
  const tomorrow = currentDate.clone().add(1, 'day').endOf('day');
  const day = moment(timeFrom);
  const prefix = day < today ? 'today' : day < tomorrow ? 'tomorrow' : '';
  const dayName =  `${prefix}${prefix === 'tomorrow' ? ', ' : ''}${prefix !== 'today' ? day.format('dddd, MMMM DD, yyyy') : ''}`;

  return dayName;
};
