import React from 'react';
import classNames from 'classnames';

import listClassesForStatus from '../../services/list-classes-for-status';

import './popup-mini-info-desk.css';

import { default as api } from '../../services/api';

const { getImgUrl } = api

function PopupMiniInfoDesk(props) {
  const { x, y, contact } = props;
  const { name, position, img, status } = contact;
  const stylePopup = { left: `${x}px`, top: `${y}px` };
  const styleAvatar = { backgroundImage: `url(${getImgUrl(img)})` };
  const classes = classNames('popup-mini-info-desk', `popup-mini-info-desk_${listClassesForStatus[status]}`);

  return (
    <div className={classes} style={stylePopup}>
      <div className="popup-mini-info-desk__avatar" style={styleAvatar}/>
      <div className="popup-mini-info-desk__info">
        <div className="popup-mini-info-desk__name">{name}</div>
        <div className="popup-mini-info-desk__position">{position}</div>
      </div>
    </div>
  );
}

export default PopupMiniInfoDesk;
