import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';

import Input from '../Input/Input';
import Button from '../Button/Button';

import useUser from '../../hooks/useUser';
import { default as api } from '../../services/api';

const { postBuilding, putBuilding } = api
export default function BuildingForm(props) {
  const { building } = props;
  const history = useHistory();
  const { companyId } = useParams();

  const { isAdmin } = useUser();
  const defaultValues = useMemo(() => building ? { ...building } : {}, [building]);

  const onSubmit = (values) => {
    const strId = building ? building.strId : nanoid();
    const request = building ? putBuilding : postBuilding;
    const newBuilding = { ...values, strId };

    if (companyId) {
      newBuilding.companyStrId = companyId;
    }

    request(newBuilding).then(() => {
      history.push(`/${isAdmin ? 'root-' : ''}admin${!isAdmin ? `/${companyId}` : ''}/buildings`);
    }).catch((e) => {
      console.log(e);
    });
  };

  const { handleSubmit, register } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-form">
        <div className="add-form__title">
          {building ? 'Edited' : 'Create'} Building
        </div>

        <Input
          inputRef={register}
          name="name"
          label="Name"
        />

        <Input
          inputRef={register}
          name="desc"
          label="Description"
        />

        <Button type="submit">
          {building ? 'Save' : 'Create'}
        </Button>
      </div>
    </form>
  );
};
