import moment from 'moment';
import getTitleDesk from './get-title-desk';
import getNameRecurrentDate from './get-name-recurrent-date';

export default (booking, desk) => {
  const { timeTo, timeFrom, repeatTimeSlice } = booking;
  const isRecurrent = !!(repeatTimeSlice);
  const isWeek = repeatTimeSlice === 'week';
  const timeName = isRecurrent ?
    `${getNameRecurrentDate(booking, isWeek)}, ${moment(timeFrom).format('HH:mm')} - ${moment(timeTo).format('HH:mm')}` :
    `${moment(timeFrom).format('HH:mm')} - ${moment(timeTo).format('HH:mm')}`;
  const dayName = `${moment(timeFrom).format('DD MMMM YYYY')}`;
  const { shortTitle, headerTitle } = getTitleDesk(desk);

  return {
    shortTitle,
    headerTitle,
    timeName,
    dayName,
    isRecurrent
  };
};
