import
  React,
  {
    useState,
} from 'react';

import useAuth from '../../auth/useAuth'
import {
  Link,
  useParams,
} from 'react-router-dom';
import { useHistory } from 'react-router';

import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import { BigLogoIcon } from '../../services/svg-icons';
import useTeamsFlag, { goAndAuthenticate } from '../../hooks/useTeams'

import '../Login/login.css';

export default function SignUp(props) {
  const { slug } = useParams();
  const {
    isLoading,
    loginWithRedirect,
    getIdTokenClaims,
  } = useAuth({ slug });

  const history = useHistory();

  const [check, setCheck] = useState(false);
  const teamsFlag = useTeamsFlag()

  const login = () => {
    if (teamsFlag === 'loading') {
      return
    } if (teamsFlag === 'teams') {
      // localStorage.wkpgMode = 'signin'
      goAndAuthenticate({
        successCallback: async (result) => {
          // put token somewhere and redirect to /signup/company
          // alert(`success1 ${token}`)
          getIdTokenClaims()
            .then((res) => {
              localStorage.forceAuthToken = JSON.stringify(res)
              history.push(`/configure-virtual-floor/${slug}`)
            })

        },
        failureCallback: (reason) => {
          // alert(`err1 ${reason.toString()}`)
          // dont know how to handle
        },
      })
    } else {
      loginWithRedirect();
      // localStorage.wkpgMode = 'signin'
      localStorage.setItem('returnTo', `/configure-virtual-floor/${slug}`);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="login">
      <div className="login__form">
        <div className="login__form-wrapper">
          <header>
            <BigLogoIcon />
          </header>

          <footer>
            {
              (isLoading) ?
              <Loader isAbsolute /> :
              <Button
                disabled={!check}
                onClick={login}
                className="login__button"
              >
                Log in
              </Button>
            }
          </footer>

          <label>
            <input
              type="checkbox"
              checked={check}
              onChange={({target}) => setCheck(target.checked)}
            />
            <span>
            I agree to Workipelago <Link target="_blank" to="/documents/tos.pdf">Terms Of Service</Link> and <Link target="_blank" to="/documents/privacy.pdf">Privacy Policy</Link> and confirm<br/>I am over the age 18 years olds
            </span>
          </label>
        </div>
      </div>

      <div className="login__links">
        <a href="https://www.ofistic8.com" target="_blank" rel="noopener noreferrer">
          Workipelago website
        </a>
      </div>
    </div>
  );
};
