import React from 'react';

import getDayName from '../../services/get-day-name';

import BookingHistoryItem from '../BookingHistoryItem/BookingHistoryItem';

export default function BookingHistoryActive(props) {
  const { recurrentBookings, bookings, mapDesks, mapMeetings,
          id, setViewDialog, setCanceledData, isPending } = props;
  const isEmpty = !recurrentBookings.length && !Object.keys(bookings).length && !isPending;

  return (
    <div className="booking-history-active">
      {
        !!recurrentBookings.length && mapDesks && mapMeetings &&
        <>
          <div className="booking-history__day">
            Recurrent booking
          </div>
          <div className="booking-history-active__shedule">
            {
              recurrentBookings.map((item, index) => {
                const { kind, entityId } = item;
                const isMeeting = kind === 'meeting';
                const desk = isMeeting ? mapMeetings[entityId] : mapDesks[entityId];

                return (
                  <BookingHistoryItem key={index} id={id} item={item} desk={desk} isActiveBooking />
                );
              })
            }
          </div>
        </>
      }

      {
        Object.keys(bookings).map((item, index) => {
          const dayName = getDayName(bookings[item][0]);

          return (
            <div key={index} className="booking-history__shedule">
              <div className="booking-history__day">{dayName}</div>
              {
                bookings[item].map((booking, bookingIndex) => {
                  const { entityId, kind } = booking;
                  const isMeeting = kind === 'meeting';
                  const desk = isMeeting ? mapMeetings[entityId] : mapDesks[entityId];
                  const handleClickTrashIcon = ({ strId }) => {
                    setViewDialog(true);
                    setCanceledData({ strId, date: item });
                  };

                  return (
                    <BookingHistoryItem key={bookingIndex} isActiveBooking item={booking}
                                        id={id} desk={desk} onClickTrashIcon={handleClickTrashIcon} />
                  );
                })
              }
            </div>
          );
        })
      }

      {
        isEmpty &&
        <div className="booking-history__empty">
          No booking records
        </div>
      }
    </div>
  );
};
