import React from 'react';

import { BigLogoIcon } from '../services/svg-icons';

export default function Static(props) {
  const { data, children } = props;

  return (
    <div className="static-page">
      <div className="static-page__header">
        <BigLogoIcon />
      </div>

      <div className="static-page__container">
        {
          data ?
          <div className="static-page__container-item" dangerouslySetInnerHTML={{ __html: data }} /> :
          children
        }
      </div>
    </div>
  );
};
