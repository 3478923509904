import React from 'react';

import { ArrowIcon } from '../../services/svg-icons';

import './date-picker-header.css';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

function DatePickerHeader(props) {
  const { date, decreaseMonth, increaseMonth,
          prevMonthButtonDisabled, nextMonthButtonDisabled } = props;
  const year = date.getFullYear();
  const month = months[date.getMonth()];

  const onDescrease = (e) => {
    decreaseMonth()
  }

  const onIncrease = (e) => {
    increaseMonth()
  }

  return (
    <div className="date-picker-header">
      <span>{ month } { year }</span>
      <div className="date-picker-header__nav">
        <ArrowIcon
          className="date-picker-header__prev"
          onClick={onDescrease}
          disabled={prevMonthButtonDisabled}
        />
        <ArrowIcon
          onClick={onIncrease}
          disabled={nextMonthButtonDisabled}
        />
      </div>
    </div>
  )
};

export default DatePickerHeader;
