import React, { createContext, useState } from 'react';

export const ContextCompanies = createContext();

export const ContextCompaniesProvider = (props) => {
  const { children } = props;
  const [companies, setCompanies] = useState([]);

  return (
    <ContextCompanies.Provider value={{ companies, setCompanies }}>
      { children }
    </ContextCompanies.Provider>
  );
};
