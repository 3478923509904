import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import moment from 'moment';

import Tabs from '../../components/Tabs/Tabs';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader/Loader';
import JanitorDesks from '../../components/JanitorDesks/JanitorDesks';

import { default as api } from '../../services/api';
import useUser from '../../hooks/useUser';
import { HOTDESK } from '../../constants/deskStatuses';
import { toObject } from '../../services/entity-format';
import { getActualBooking } from '../../services/booking';
import { DESK_UPDATED } from '../../constants/socketStatuses';
import { NON_BOOKABLE, BOOKABLE } from '../../constants/meetingStatuses';
import { CalendarIcon, DeskIcon, ChatIcon } from '../../services/svg-icons';
import { WAITING_FOR_CLEANING } from '../../constants/cleaningStatuses';

import './janitor.css';

const {
  fetchFloors,
  fetchBuildings,
  fetchDesksForFloor,
  fetchFloorsByContact,
  fetchMeetingsForFloor,
  fetchBookingForFloor,
  createSocket,
} = api

const tabs = [
  {
    title: 'Hot desks',
    Icon: DeskIcon
  },
  {
    title: 'Non Bookable',
    Icon: CalendarIcon
  },
  {
    title: 'Meeting rooms',
    Icon: ChatIcon
  }
];

export default function Janitor() {
  const { user, isJanitor } = useUser();

  const [tabIndex, setTabIndex] = useState(0);
  const [meetings, setMeetings] = useState([]);
  const [hotdesks, setHotdesks] = useState([]);
  const [isPending, setPending] = useState(true);
  const [mapFloors, setMapFloors] = useState({});
  const [onlineTime, setOnlineTime] = useState(0);
  const [nonBookable, setNonBookable] = useState([]);
  const [mapBuildings, setMapBuildings] = useState({});
  const [isLoadDesks, setLoadDesks] = useState(true);

  const filterDesk = (item, status) => {
    return item.status === status && item.cleaningStatus === WAITING_FOR_CLEANING;
  };

  const setMapState = (items, cb) => {
    const { data } = toObject(items, { keyField: 'strId' });
    cb(data);
  };

  const isMeeting = tabIndex === 2;
  const isNonBookable = tabIndex === 1;
  const currentList = isNonBookable ? nonBookable : isMeeting ? meetings : hotdesks;

  useEffect(() => {
    if (!user || !isLoadDesks) {
      return;
    }

    Promise.resolve().then(async () => {
      let booking = [];
      let fullHotdesks = [];
      let fullMeetings = [];
      let fullBookable = [];

      fetchFloors().then((floors) => setMapState(floors, setMapFloors));
      fetchBuildings().then((buildings) => setMapState(buildings, setMapBuildings));

      const floorsByContact = await fetchFloorsByContact(user.strId);

      for (let floorByContact of floorsByContact) {
        const fetchDesks = fetchDesksForFloor(floorByContact);
        const fetchMeetings = fetchMeetingsForFloor(floorByContact);
        const fetchBooking = fetchBookingForFloor(floorByContact);
        const [currentDesks, currentMeetings, currentBooking] = await Promise.all([fetchDesks, fetchMeetings, fetchBooking]);

        booking = [...booking, ...currentBooking];
        fullHotdesks = [...fullHotdesks, ...currentDesks.filter((item) => filterDesk(item, HOTDESK))];
        fullMeetings = [...fullMeetings, ...currentMeetings.filter((item) => filterDesk(item, BOOKABLE))];
        fullBookable = [...fullBookable, ...currentMeetings.filter((item) => filterDesk(item, NON_BOOKABLE))];
      }

      const newDesks = getActualBooking(fullHotdesks, booking, []);

      newDesks.sort((a, b) => {
        let aTimeFrom = moment().add('year', 1);
        let bTimeFrom = moment().add('year', 1);

        if (a.booking) {
          aTimeFrom = a.booking[0].timeFrom;
        }

        if (b.booking) {
          bTimeFrom = b.booking[0].timeFrom;
        }

        return moment(aTimeFrom) - moment(bTimeFrom);
      });

      setPending(false);
      setHotdesks(newDesks);
      setMeetings(fullMeetings);
      setNonBookable(fullBookable);
      setLoadDesks(false)
    })
  }, [user, isLoadDesks]);

  useEffect(() => {
    if (!user || !isJanitor) {
      return;
    }

    const socket = createSocket();

    socket.on('connect', () => {

      socket.emit('subscribe', {
        subscriptions: [{ eventName: DESK_UPDATED }]
      });
    });

    socket.on(DESK_UPDATED, () => {
      setLoadDesks(true);
    });

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [user, isJanitor])

  useEffect(() => {
    const timer = setInterval(() => {
      setOnlineTime((time) => time + 1);
    }, 60 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (!isJanitor) {
    return (
      <div className="janitor">
        <span>This page is only available to janitor</span>
      </div>
    );
  }

  return (
    <div className="janitor">
      <div className="janitor__timer">{onlineTime}</div>
      <Header title={user?.name || ''}/>

      <main className="janitor__list">
        <Tabs active={tabIndex} setActive={setTabIndex} tabs={tabs}/>

        {isPending && <Loader />}

        <JanitorDesks list={currentList} mapFloors={mapFloors} mapBuildings={mapBuildings}
                      isMeeting={isMeeting} isNonBookable={isNonBookable} setNonBookable={setNonBookable}
                      setMeetings={setMeetings} setHotdesks={setHotdesks} />
      </main>
    </div>
  );
};
