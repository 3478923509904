import { default as api } from './api';
import { BOOKABLE, NON_BOOKABLE } from '../constants/meetingStatuses';

const {
  updateDesk,
  updateMeeting
} = api

export default async (target, position) => {
  const strId = target.getAttribute('data-str-id');
  const status = target.getAttribute('data-desk-status');
  const updateCb = (status === BOOKABLE || status === NON_BOOKABLE) ? updateMeeting : updateDesk;

  updateCb(strId, position);
}
