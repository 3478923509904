import find from 'lodash.find';
import { nanoid } from 'nanoid';

import insertItem from './insert-item';
import saveTargetPosition from './save-target-position';
import {
  findParent,
  findPopup,
  getPopupPosition,
} from './dom-utils';

import { PERMANENT, HOTDESK } from '../constants/deskStatuses';
import { BOOKABLE, NON_BOOKABLE } from '../constants/meetingStatuses';

const LIMIT_DRAG_POPUP = 100;

let GLOBAL_HANDLER = null;
let lastX = null;
let lastY = null;
let lastScale = null;
let currentDeskEl = null;

const isMapItem = (el) => el.getAttribute('data-type') === 'map-item';

const onSelectDesk = (deskEl, selectMapItem) => {
  if (currentDeskEl) {
    currentDeskEl.classList.remove('b-map-item__selected');
  }

  deskEl.classList.add('b-map-item__selected');
  currentDeskEl = deskEl;
  selectMapItem(deskEl);
}

export const constraits = {
  x: { max: 400 },
  y: { max: 400 },
  scale: { min: 0.3, max: 1, mid: 0.5 }
};

export const createMapHandler = (params) => {

  const {
    initX = null,
    initY = null,

    mapWrap,
    map,
    mapUnder,
    onDeskClicked,

    isAdmin,
    isViewAdminPanel,
    paletteItemRef,
    contacts,
    // isSummon,
    // summons,

    // mapX,
    // mapY,

    // viewMiniPopup,
    // viewPopup,
    // viewHotdeskPopup,

    selectMapItem,
    floor,

    // setMapX,
    // setMapY,

    setContact,
    setPopupX,
    setPopupY,
    setViewMiniPopup,
    closeAllPopups,

    pushAction
  } = params;
  const events = {};
  const popupFlags = {
    popup: false,
    mini: false,
    hotdesk: false
  };
  const startRect = mapWrap.getBoundingClientRect();
  const bookingPopup = document.querySelector('#popup-booking')


  let scale = (lastScale === null) ? constraits.scale.max : lastScale;
  // let x = constraits.x.max
  // let y = constraits.y.max
  // let x = -startRect.width / 2;
  // let y = -startRect.height / 2;
  let x = -(((floor.width / 2) * scale) - (startRect.width / 2))
  let y = -(((floor.height / 2) * scale) - (startRect.height / 2))

  if (floor.width < startRect.width) {
    x = (startRect.width - floor.width) / 2
  }
  if (floor.height < startRect.height) {
    y = (startRect.height - floor.height) / 2
  }

  if (initX !== null) {
    x = -initX + startRect.width / 2;
  }
  if (initY !== null) {
    y = -initY + startRect.height / 2;
  }

  if (lastX !== null) {
    x = lastX;
  }

  if (lastY !== null) {
    y = lastY;
  }
  // applyCoordinateConstraits()

  console.log('MapHandler', {
    initX,
    initY,
    lastX,
    lastY,

    x,
    y,

    width: startRect.width,
    height: startRect.height,
  })

  // (lastX === null) ? constraits.x.max : lastX
  // let y = (lastY === null) ? constraits.y.max : lastY

  let moveStart = null;
  let isMoved = false;
  let dragStart = null;
  let dragStartUnder = null;
  let prevSecondTouch = null;
  let moveStartY = 0;
  let oldParams = [];

  // const coordCenter = {
  //   x: floor.size.width,
  //   y: floor.size.height,
  // }

  const applyCoordinateConstraits = () => {
    x = Math.min(x, constraits.x.max);
    y = Math.min(y, constraits.y.max);
  }

  applyCoordinateConstraits();

  const applyScaleContraits = () => {
    scale = Math.min(scale, constraits.scale.max);
    scale = Math.max(scale, constraits.scale.min);
  }

  let lastRequestId = null;
  const applyTransform = () => {
    if (lastRequestId) {
      cancelAnimationFrame(lastRequestId);
    }

    lastRequestId = requestAnimationFrame(() => {
      map.style.transform = `translate3d(${x}px, ${y}px, 0) scale(${scale})`;
      mapUnder.style.transform = `translate3d(${x}px, ${y}px, 0) scale(${scale})`;

      if (scale < 0.45) {
        map.classList.add('b-map__small');
      } else if (map.classList.contains('b-map__small')) {
        map.classList.remove('b-map__small');
      }

      lastScale = scale;
    })
  }

  applyTransform();

  const onWheel = (e) => {
    /*
      Detect if mouse over popup
    */
    const popup = findPopup(e.target)
    if (popup) {
      return;
    }
    const diff = -e.deltaY * 0.001;

    const prevScale = scale;
    scale += diff;

    applyScaleContraits();

    x = (scale / prevScale ) * x - (startRect.width / 2 * scale - startRect.width / 2 * prevScale);
    y = (scale / prevScale ) * y - (startRect.height / 2 * scale - startRect.height / 2 * prevScale);

    applyCoordinateConstraits();
    applyTransform();

    // setScale(scale)
    if (events.scale) {
      events.scale(scale);
    }
    // setScaleDebounced(scale)

    e.preventDefault();
  }

  const onMouseDown = (e) => {
    const popup = findPopup(e.target);
    if (popup) {
      return;
    }

    const mapItem = findParent(e.target, 'b-map-item');


    if (mapItem && isAdmin && isViewAdminPanel) {
      const startPos = {
        x: parseInt(mapItem.style.left, 10),
        y: parseInt(mapItem.style.top, 10)
      };


      dragStart = {
        isMoved: false,
        target: mapItem,
        startMouse: {
          x: e.pageX,
          y: e.pageY
        },
        startPos,

        // offset: {
        //   x: e.offsetX,
        //   y: e.offsetY,
        // },
        x: startPos.x,
        y: startPos.y,
      };

      oldParams = [dragStart.target, {
        x: dragStart.x,
        y: dragStart.y
      }];

      dragStartUnder = mapUnder.querySelector(`#${mapItem.id}`);
    } else {
      moveStart = {
        mouse: {
          x: e.pageX,
          y: e.pageY,
        },
        map: {
          x,
          y,
        }
      };
    }
    isMoved = false;
  }

  const startTouch = (e) => {
    const { target, pageX, pageY, clientX, clientY, screenY } = e.targetTouches[0];
    const whiteListClasses = ['popup-booking__top', 'popup-booking__info-mobile', 'popup-info-desk__top', 'popup-info-desk__info'];
    const rect = target.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const offsetY = clientY - rect.top;

    if (whiteListClasses.includes(target.parentNode.className)) {
      moveStartY = screenY;
    }

    if (isMapItem(e.target) && isAdmin && !isViewAdminPanel) {
      // drag n drop
      // selectTarget(target, setItems, selectMapItem)
      dragStart = {
        target,
        offset: {
          x: offsetX,
          y: offsetY,
        }
      };

    } else {
      moveStart = {
        mouse: {
          x: pageX,
          y: pageY,
        },
        map: {
          x,
          y,
        }
      };
    }
    isMoved = false;
  }

  const onMouseUp = (e) => {
    const { target } = e;
    let currentParent = target.parentNode.parentNode;

    if (target.parentNode.className === 'popup-booking__info-mobile' ||
        target.parentNode.className === 'popup-info-desk__info') {
      currentParent = target.parentNode.parentNode.parentNode;
    }

    if (
      e.changedTouches &&
      e.changedTouches[0] &&
      moveStartY &&
      moveStartY < e.changedTouches[0].screenY
    ) {
      if (e.changedTouches[0].screenY - moveStartY > LIMIT_DRAG_POPUP) {
        currentParent.style.maxHeight = 0;
        const activeEl = document.querySelector('.b-map-item_active');
        activeEl && activeEl.classList.remove('b-map-item_active');
        closeAllPopups();
      } else {
        currentParent.style.bottom = 0;
      }
      moveStartY = 0;
    }

    if (!isMoved && (!dragStart || !dragStart.isMoved)) {
      const mapItem = findParent(target, 'b-map-item');
      if (mapItem) {
        // не помню, что я тут хотел. Кажется пока ничего?
        // Или rotation и id показывать хотя бы?
        // selectPaletteItem(null)
        // const rect = target.getBoundingClientRect()
        // selectTarget(target, setItems, selectMapItem);

        if (isAdmin && isViewAdminPanel) {
          onSelectDesk(mapItem, selectMapItem);
        }

        if ((isAdmin && !isViewAdminPanel) || !isAdmin) {
          onDeskClicked(mapItem);
        }
      } else if (paletteItemRef.current && !dragStart) {
        const currentStrId = nanoid();
        const paletteItem = paletteItemRef.current;
        const geometry = { x: e.offsetX, y: e.offsetY };
        const newParams = { map, floor, paletteItem, geometry, strId: currentStrId };
        const { className } = target;

        if (!className.includes('b-palette')) {
          pushAction({ type: 'insertItem', oldParams: [{...newParams, isRemove: true }], newParams: [newParams] });
          insertItem(newParams);
        }
      }
    } else if (dragStart) {
      const newParams = [dragStart.target, {
        x: dragStart.x,
        y: dragStart.y
      }];

      pushAction({ type: 'saveTargetPosition', oldParams, newParams });
      saveTargetPosition(...newParams);
    }

    // setMapX(x)
    // setMapY(y)
    lastX = x;
    lastY = y;

    prevSecondTouch = null;
    moveStart = null;
    dragStart = null;
    dragStartUnder = null;
  }

  const onMouseMove = (e) => {
    const { target, pageX, pageY } = e;
    const deskTypes = [HOTDESK, PERMANENT];
    // const meetingEl = target.getAttribute('data-desk-status') === 'meeting' ? target : target.parentNode;
    const isDesk = deskTypes.includes(target.getAttribute('data-type')) || deskTypes.includes(target.parentNode.getAttribute('data-type'));
    const isMeeting =
      target.getAttribute('data-desk-status') === BOOKABLE
      || target.getAttribute('data-desk-status') === NON_BOOKABLE
      || target.parentNode.getAttribute('data-desk-status') === BOOKABLE
      || target.parentNode.getAttribute('data-desk-status') === NON_BOOKABLE;

    if (dragStart) {
      // let x = offsetX - dragStart.offset.x
      // let y = offsetY - dragStart.offset.y
      const mouse_dx = pageX - dragStart.startMouse.x;
      const mouse_dy = pageY - dragStart.startMouse.y;

      let x = dragStart.startPos.x + mouse_dx / scale;
      let y = dragStart.startPos.y + mouse_dy / scale;

      // if (isMapItem(target)) {
      //   const left = parseInt(target.style.left, 10)
      //   const top = parseInt(target.style.top, 10)
      //   x += left
      //   y += top
      // }

      const xCorr = x % 5;
      const yCorr = y % 5;

      x -= xCorr;
      y -= yCorr;

      dragStart.isMoved = true;
      dragStart.x = x;
      dragStart.y = y;

      dragStart.target.style.left = `${x}px`;
      dragStart.target.style.top = `${y}px`;

      if (isMeeting && dragStartUnder) {
        dragStartUnder.style.left = `${x}px`;
        dragStartUnder.style.top = `${y}px`;
      }
    } else if (moveStart) {
      isMoved = true;

      const diffX = (pageX - moveStart.mouse.x);
      const diffY = (pageY - moveStart.mouse.y);

      x = moveStart.map.x + diffX;
      y = moveStart.map.y + diffY;

      applyCoordinateConstraits();
      applyTransform();
    }

    if (isDesk && scale < 0.45 && !popupFlags.hotdesk && !popupFlags.popup && !popupFlags.mini) {
      const strId = target.getAttribute('data-str-id') || target.parentNode.getAttribute('data-str-id');

      if (strId) {
        const contact = find(contacts, (item) => item.strId === strId);
        const popupPosition = getPopupPosition({ el: target });

        setContact(contact);
        setPopupX(popupPosition.x - 10);
        setPopupY(popupPosition.y - 40);
        setViewMiniPopup(true);
      }
    }

    if (!isDesk && popupFlags.mini) {
      setViewMiniPopup(false);
    }
  }

  const moveTouch = (e) => {
    const { target } = e;

    if (bookingPopup && bookingPopup.contains(e.target)) {
      return
    }
    const whiteList = ['popup-booking', 'input', 'invitees-list', 'time-picker', 'popup-info-desk', 'b-palette'];
    const { pageX, pageY, clientX, clientY, screenY } = e.targetTouches[0];

    if (moveStartY < screenY && screenY - moveStartY <= LIMIT_DRAG_POPUP) {
      if (target.parentNode.className === 'popup-booking__info-mobile' || target.parentNode.className === 'popup-info-desk__info') {
        target.parentNode.parentNode.parentNode.style.bottom = `${moveStartY - screenY}px`;
      }

      if (target.parentNode.className === 'popup-info-desk__top') {
        target.parentNode.parentNode.style.bottom = `${moveStartY - screenY}px`;
      }

      e.preventDefault();
      e.stopPropagation();
      return;
    }

    if (target.className && typeof(target.className) === 'string') {
      for (let item of whiteList) {
        if (target.className.includes(item)) {
          e.stopPropagation();
          return;
        }
      }
    }

    const rect = target.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const offsetY = clientY - rect.top;

    if (dragStart) {
      let x = offsetX - dragStart.offset.x;
      let y = offsetY - dragStart.offset.y;

      if (isMapItem(e.target)) {
        const left = parseInt(target.style.left, 10);
        const top = parseInt(target.style.top, 10);
        x += left;
        y += top;
      }

      const xCorr = x % 5;
      const yCorr = y % 5;

      x -= xCorr;
      y -= yCorr;

      dragStart.target.style.left = `${x}px`;
      dragStart.target.style.top = `${y}px`;
    } else if (moveStart) {
      isMoved = true;

      // window.debugL(`touches ${e.changedTouches.length}`)
      // window.debugL(`targetTouches ${e.targetTouches.length}`)

      let secondTouch = null;
      let dist = 0;

      if (e.targetTouches.length > 1) {
        secondTouch = e.targetTouches[1];
        dist = Math.sqrt(Math.pow(secondTouch.pageX - pageX, 2) + Math.pow(secondTouch.pageY - pageY, 2));
      } else {
        prevSecondTouch = null;
      }

      if (secondTouch && prevSecondTouch) {
        // zoom
        const distDiff = (dist - prevSecondTouch.dist) / dist;
        const prevScale = scale;

        scale += distDiff * scale;

        // if (scale < constraits.scale.max && scale > constraits.scale.min) {
        //   if (prevScale > scale) {
        //     x -= 10 * scale
        //     y -= 10 * scale
        //   } else {
        //     x += 10 * scale
        //     y += 10 * scale
        //   }
        // }

        applyScaleContraits();

        x = (scale / prevScale ) * x - (startRect.width / 2 * scale - startRect.width / 2 * prevScale);
        y = (scale / prevScale ) * y - (startRect.height / 2 * scale - startRect.height / 2 * prevScale);

        if (events.scale) {
          events.scale(scale);
        }

        // window.debugL(x)
      } else {
        const diffX = (pageX - moveStart.mouse.x);
        const diffY = (pageY - moveStart.mouse.y);

        x = moveStart.map.x + diffX;
        y = moveStart.map.y + diffY;
      }

      if (secondTouch) {
        prevSecondTouch = { dist, secondTouch, center: { x: (pageX + secondTouch.pageX) / 2 }, y: (pageY + secondTouch.pageY) / 2 };
      }

      applyCoordinateConstraits();
      applyTransform();
    }

    e.preventDefault();
  }

  const onTouchEnd = (e) => {
    const triggerContains = [
      bookingPopup,
      document.querySelector('.popup-info-desk__middle'),
      document.querySelector('.popup-info-desk__bottom')
    ].reduce((acc, i) => {
      if (i && i.contains(e.target)) {
        acc++
      }
      return acc
    }, 0)
    if (triggerContains || e.target.id.includes('id-for-touch-click')) {
      return
    }

    e.preventDefault()
    onMouseUp(e)
  }

  mapWrap.addEventListener('wheel', onWheel);
  mapWrap.addEventListener('mousedown', onMouseDown);
  mapWrap.addEventListener('mouseup', onMouseUp);
  mapWrap.addEventListener('mousemove', onMouseMove);
  mapWrap.addEventListener('touchstart', startTouch, false);
  mapWrap.addEventListener('touchend', onTouchEnd, false);
  mapWrap.addEventListener('touchmove', moveTouch, false);

  GLOBAL_HANDLER = {

    on: (eventName, func) => {
      events[eventName] = func;
    },

    resetPosition: () => {
      lastX = null;
      lastY = null;
      scale = 1;
    },

    setPosition: (newX, newY) => {
      lastX = newX;
      lastY = newY;
      x = newX;
      y = newY;

      applyCoordinateConstraits();
      applyTransform();
    },

    setScale: (newScale, isAdjustCoords = false) => {
      const prevScale = scale;
      scale = newScale;
      applyScaleContraits();

      if (isAdjustCoords) {
        x = (scale / prevScale ) * x - (startRect.width / 2 * scale - startRect.width / 2 * prevScale);
        y = (scale / prevScale ) * y - (startRect.height / 2 * scale - startRect.height / 2 * prevScale);
        applyCoordinateConstraits();
      }

      applyTransform();
    },

    getScale: () => scale,

    setPopupFlag: (name, value) => {
      popupFlags[name] = value;
    },

    navgigateTo: (newX, newY) => {
      closeAllPopups();

      /*
        x, y should become center of the screen
      */
      let rect = mapWrap.getBoundingClientRect();
      x = - newX + rect.width / 2;
      y = - newY + rect.height / 2;

      applyCoordinateConstraits();
      applyTransform();
    },

    destroy: () => {
      mapWrap.removeEventListener('wheel', onWheel);
      mapWrap.removeEventListener('mousedown', onMouseDown);
      mapWrap.removeEventListener('mouseup', onMouseUp);
      mapWrap.removeEventListener('mousemove', onMouseMove);
      mapWrap.removeEventListener('touchstart', startTouch);
      mapWrap.removeEventListener('touchend', onTouchEnd);
      mapWrap.removeEventListener('touchmove', moveTouch);
    }
  }

  return GLOBAL_HANDLER;
}

export const getMapHandler = () => GLOBAL_HANDLER;
