import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import Loader from '../../components/Loader/Loader';
import MeetingForm from '../../components/MeetingForm/MeetingForm';

import { default as api } from '../../services/api';

const { fetchMeeting } = api

export default function EditMeeting() {
  const { id } = useParams();

  const [meeting, setMeeting] = useState(null);

  useEffect(() => {
    if (id) {
      fetchMeeting(id).then(setMeeting);
    }
  }, [id]);

  return (
    <div>
      {
        meeting ?
        <MeetingForm meeting={meeting} /> :
        <Loader />
      }
    </div>
  );
};
