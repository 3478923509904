import React from 'react';

export function TrashIcon(props) {
  return (
    <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512 512" xmlSpace="preserve">
      <g transform="translate(1 1)">
        <path d="M489.667,84.333h-42.665H447h-68.675l-7.012-28.062C362.905,22.609,332.658-1,297.965-1h-85.931
          c-34.693,0-64.94,23.609-73.348,57.273l-7.012,28.06H63.002c0,0-0.001,0-0.001,0c0,0,0,0-0.001,0H20.333
          C8.551,84.333-1,93.885-1,105.667C-1,117.449,8.551,127,20.333,127h22.488l17.974,323.53C62.683,484.452,90.744,511,124.719,511
          h260.565c33.975,0,62.036-26.548,63.924-60.468L467.183,127h22.484c11.782,0,21.333-9.551,21.333-21.333
          C511,93.885,501.449,84.333,489.667,84.333z M180.081,66.614c3.663-14.664,16.838-24.948,31.954-24.948h85.931
          c15.116,0,28.291,10.284,31.953,24.946l4.428,17.721H175.653L180.081,66.614z M406.608,448.163
          c-0.63,11.311-9.993,20.17-21.324,20.17H124.719c-11.33,0-20.694-8.859-21.324-20.172L85.554,127h62.78h213.333h62.784
          L406.608,448.163z"/>
        <path d="M169.667,169.667c-11.782,0-21.333,9.551-21.333,21.333v213.333c0,11.782,9.551,21.333,21.333,21.333
          c11.782,0,21.333-9.551,21.333-21.333V191C191,179.218,181.449,169.667,169.667,169.667z"/>
        <path d="M255,169.667c-11.782,0-21.333,9.551-21.333,21.333v213.333c0,11.782,9.551,21.333,21.333,21.333
          s21.333-9.551,21.333-21.333V191C276.333,179.218,266.782,169.667,255,169.667z"/>
        <path d="M340.333,169.667C328.551,169.667,319,179.218,319,191v213.333c0,11.782,9.551,21.333,21.333,21.333
          c11.782,0,21.333-9.551,21.333-21.333V191C361.667,179.218,352.115,169.667,340.333,169.667z"/>
      </g>
    </svg>
  );
}

export function BigLogoIcon(props) {
  return (
    <svg {...props} width="203" height="32" viewBox="0 0 203 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 19C11 22.4478 12.3696 25.7544 14.8076 28.1924C17.2456 30.6304 20.5522 32 24 32C27.4478 32 30.7544 30.6304 33.1924 28.1924C35.6304 25.7544 37 22.4478 37 19L11 19Z" fill="#8B75BC"/>
      <path d="M19 17C19 14.6131 18.0518 12.3239 16.364 10.636C14.6761 8.94821 12.3869 8 10 8C7.61305 8 5.32387 8.94821 3.63604 10.636C1.94821 12.3239 1 14.6131 1 17L19 17Z" fill="#7851A8"/>
      <path d="M42.1673 4.97919C45.2617 8.1673 47 12.4913 47 17H21C21 14.0826 19.8411 11.2847 17.7782 9.22183C17.3631 8.80675 16.9183 8.42827 16.4487 8.08847C17.1177 6.96877 17.9157 5.92409 18.8328 4.97918C21.9271 1.79107 26.124 0 30.5 0C34.8761 0 39.073 1.79107 42.1673 4.97919Z" fill="#68359E"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M73.7983 18.5547L72.0007 10.2239H67.4695L65.6733 18.5547L64.4742 10.2239H60L63.0323 23.4491H68.2004L69.7355 15.9397L71.2705 23.4491H76.4378L79.4709 10.2239H74.996L73.7983 18.5547Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M89.3374 18.9191C88.8884 19.4484 88.2514 19.7133 87.4281 19.7133C86.5914 19.7133 85.9485 19.4484 85.5002 18.9191C85.0504 18.3899 84.8255 17.6769 84.8255 16.7801C84.8255 15.8715 85.0504 15.1519 85.5002 14.6227C85.9485 14.0935 86.5914 13.8292 87.4281 13.8292C88.2514 13.8292 88.8884 14.0935 89.3374 14.6227C89.7865 15.1519 90.0114 15.8715 90.0114 16.7801C90.0114 17.6769 89.7865 18.3899 89.3374 18.9191ZM90.8539 10.868C89.8175 10.2893 88.6753 10 87.428 10C86.1793 10 85.0349 10.2893 83.9918 10.868C82.9502 11.4474 82.1231 12.2534 81.512 13.2875C80.9003 14.3215 80.5947 15.4855 80.5947 16.7801C80.5947 18.0755 80.9003 19.2402 81.512 20.2735C82.1231 21.3069 82.9502 22.1166 83.9918 22.7019C85.0349 23.2872 86.1793 23.5795 87.428 23.5795C88.6635 23.5795 89.8027 23.2872 90.8443 22.7019C91.8867 22.1166 92.7108 21.3069 93.3159 20.2735C93.9211 19.2402 94.2236 18.0755 94.2236 16.7801C94.2236 15.4855 93.9211 14.3215 93.3159 13.2875C92.7108 12.2534 91.8896 11.4474 90.8539 10.868Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M102.424 15.6691C102.224 15.8499 101.924 15.94 101.525 15.94H99.878V13.6984H101.525C101.912 13.6984 102.209 13.798 102.415 13.9973C102.621 14.1966 102.723 14.4704 102.723 14.8188C102.723 15.2056 102.624 15.4883 102.424 15.6691ZM106.197 16.9113C106.69 16.2264 106.936 15.4418 106.936 14.5575C106.936 13.7353 106.746 12.9979 106.365 12.344C105.984 11.69 105.413 11.1734 104.653 10.794C103.891 10.4139 102.973 10.2242 101.9 10.2242H95.7212V23.4494H99.878V18.7041L102.387 23.4494H106.955L104.072 18.3868C104.996 18.0878 105.704 17.5963 106.197 16.9113Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M116.316 10.2238L112.422 15.809V10.2238H108.266V23.449H112.422V17.714L116.278 23.449H121.278L116.372 16.6497L121.278 10.2238H116.316Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M122.4 23.4491H126.556V10.2239H122.4V23.4491Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M133.764 15.9772H132.585V13.5488H133.764C134.626 13.5488 135.057 13.9533 135.057 14.763C135.057 15.5727 134.626 15.9772 133.764 15.9772ZM134.233 10.2237H128.429V23.4489H132.585V19.2462H134.233C135.306 19.2462 136.22 19.0469 136.976 18.6483C137.73 18.2498 138.302 17.7117 138.688 17.0327C139.075 16.3536 139.268 15.5971 139.268 14.763C139.268 13.3806 138.835 12.2786 137.968 11.4563C137.1 10.6348 135.855 10.2237 134.233 10.2237Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M140.58 23.449H149.416V20.1431H144.736V18.2749H148.855V15.155H144.736V13.5113H149.416V10.2238H140.58V23.449Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M155.146 10.2238H150.99V23.449H159.152V20.3106H155.146V10.2238Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M165.555 18.3306L166.753 14.6512L167.951 18.3306H165.555ZM164.375 10.2241L159.527 23.4493H163.889L164.544 21.4505H168.962L169.617 23.4493H173.998L169.168 10.2241H164.375Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M180.757 18.7418H184.07C183.484 19.5138 182.711 19.8999 181.75 19.8999C180.738 19.8999 179.99 19.6445 179.503 19.1337C179.015 18.6237 178.772 17.8516 178.772 16.8176C178.772 15.9208 178.99 15.2144 179.428 14.6978C179.865 14.1811 180.482 13.922 181.281 13.922C181.705 13.922 182.067 13.9944 182.367 14.1368C182.667 14.2807 182.898 14.4822 183.06 14.7443H187.441C187.241 13.3124 186.595 12.1786 185.503 11.3446C184.411 10.5106 182.997 10.0928 181.263 10.0928C179.902 10.0928 178.716 10.3762 177.705 10.9431C176.695 11.5099 175.917 12.3034 175.375 13.3249C174.831 14.3457 174.56 15.5104 174.56 16.8176C174.56 18.1255 174.831 19.2902 175.375 20.3102C175.917 21.3318 176.698 22.126 177.715 22.6928C178.732 23.2597 179.927 23.5424 181.3 23.5424C182.848 23.5424 184.127 23.1903 185.138 22.4869C186.149 21.7835 186.941 20.9398 187.516 19.956V15.8086H180.757V18.7418Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M197.382 18.9191C196.933 19.4484 196.296 19.7133 195.473 19.7133C194.636 19.7133 193.993 19.4484 193.545 18.9191C193.095 18.3899 192.87 17.6769 192.87 16.7801C192.87 15.8715 193.095 15.1519 193.545 14.6227C193.993 14.0935 194.636 13.8292 195.473 13.8292C196.296 13.8292 196.933 14.0935 197.382 14.6227C197.831 15.1519 198.056 15.8715 198.056 16.7801C198.056 17.6769 197.831 18.3899 197.382 18.9191ZM201.361 13.2875C200.756 12.2534 199.935 11.4474 198.899 10.868C197.862 10.2893 196.72 10 195.473 10C194.224 10 193.08 10.2893 192.037 10.868C190.995 11.4474 190.168 12.2534 189.557 13.2875C188.945 14.3215 188.64 15.4855 188.64 16.7801C188.64 18.0755 188.945 19.2402 189.557 20.2735C190.168 21.3069 190.995 22.1166 192.037 22.7019C193.08 23.2872 194.224 23.5795 195.473 23.5795C196.708 23.5795 197.848 23.2872 198.889 22.7019C199.932 22.1166 200.756 21.3069 201.361 20.2735C201.966 19.2402 202.269 18.0755 202.269 16.7801C202.269 15.4855 201.966 14.3215 201.361 13.2875Z" />
    </svg>
  );
}

export function UndoIcon(props) {
  return (
    <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.011 512.011">
      <path d="M511.136,286.255C502.08,194.863,419.84,128.015,328,128.015H192v-80c0-6.144-3.52-11.744-9.056-14.432
        c-5.568-2.656-12.128-1.952-16.928,1.92l-160,128C2.208,166.575,0,171.151,0,176.015s2.208,9.44,5.984,12.512l160,128
        c2.912,2.304,6.464,3.488,10.016,3.488c2.368,0,4.736-0.512,6.944-1.568c5.536-2.688,9.056-8.288,9.056-14.432v-80h139.392
        c41.856,0,80,30.08,84.192,71.712c4.832,47.872-32.704,88.288-79.584,88.288H208c-8.832,0-16,7.168-16,16v64
        c0,8.832,7.168,16,16,16h128C438.816,480.015,521.472,391.151,511.136,286.255z"/>
    </svg>
  );
}

export function EditIcon(props) {
  return (
    <svg {...props} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <polygon points="51.2,353.28 0,512 158.72,460.8"/>
      <rect x="89.73" y="169.097" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -95.8575 260.3719)" width="353.277" height="153.599"/>
      <path d="M504.32,79.36L432.64,7.68c-10.24-10.24-25.6-10.24-35.84,0l-23.04,23.04l107.52,107.52l23.04-23.04
        C514.56,104.96,514.56,89.6,504.32,79.36z"/>
    </svg>
  );
}

export function CleaningIcon(props) {
  return (
    <svg {...props} enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m495.718 1.582c-7.456-3.691-16.421-.703-20.142 6.694l-136.92 274.08-26.818-13.433c-22.207-11.118-49.277-2.065-60.396 20.083l-6.713 13.405 160.957 80.616 6.713-13.411c11.087-22.143 2.227-49.18-20.083-60.381l-26.823-13.435 136.919-274.077c3.706-7.412.703-16.421-6.694-20.141z"/>
        <circle cx="173" cy="497" r="15"/>
        <circle cx="23" cy="407" r="15"/>
        <circle cx="83" cy="437" r="15"/>
        <path d="m113 482h-60c-8.276 0-15-6.724-15-15 0-8.291-6.709-15-15-15s-15 6.709-15 15c0 24.814 20.186 45 45 45h60c8.291 0 15-6.709 15-15s-6.709-15-15-15z"/>
        <path d="m108.635 388.074c-6.563.82-11.807 5.845-12.92 12.349-1.113 6.519 2.153 12.993 8.057 15.952l71.675 35.889c12.935 6.475 27.231 9.053 41.177 7.573-1.641 6.65 1.479 13.784 7.852 16.992l67.061 33.589c5.636 2.78 12.169 1.8 16.685-2.197 2.347-2.091 53.436-48.056 83.3-98.718l-161.605-80.94c-36.208 48.109-120.363 59.39-121.282 59.511z"/>
      </g>
    </svg>
  );
}

export function IconVirtual(props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 16V2H6V8.61427C6.62755 8.34485 7.29868 8.15725 8 8.06515V4H22V14H18.1087C18.7205 14.5507 19.2129 15.2313 19.5418 16H24Z"/>
      <path d="M16 12H15.7047C15.2984 11.2481 14.7699 10.5725 14.1457 10H16V12Z"/>
      <path d="M10 6H12V8H10V6Z"/>
      <path d="M16 8H14V6H16V8Z"/>
      <path d="M18 6H20V8H18V6Z"/>
      <path d="M20 10H18V12H20V10Z"/>
      <path d="M9 10C11.73 10 14.0025 11.9425 14.5125 14.53C16.4625 14.665 18 16.27 18 18.25C18 20.32 16.32 22 14.25 22H4.5C2.0175 22 0 19.9825 0 17.5C0 15.1825 1.755 13.27 4.0125 13.03C4.95 11.23 6.8325 10 9 10Z"/>
    </svg>
  );
}
export function IconSummon(props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 10H5V7H3V10H0V12H3V15H5V12H8V10ZM18 11C19.66 11 20.99 9.66 20.99 8C20.99 6.34 19.66 5 18 5C17.68 5 17.37 5.05 17.09 5.14C17.66 5.95 17.99 6.93 17.99 8C17.99 9.07 17.65 10.04 17.09 10.86C17.37 10.95 17.68 11 18 11ZM13 11C14.66 11 15.99 9.66 15.99 8C15.99 6.34 14.66 5 13 5C11.34 5 10 6.34 10 8C10 9.66 11.34 11 13 11ZM19.62 13.16C20.45 13.89 21 14.82 21 16V18H24V16C24 14.46 21.63 13.51 19.62 13.16ZM13 13C11 13 7 14 7 16V18H19V16C19 14 15 13 13 13Z"/>
    </svg>
  );
}

export function BuildingsIcon(props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7V3H2V21H22V7H12ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4V5H6V7ZM10 19H8V17H10V19ZM10 15H8V13H10V15ZM10 11H8V9H10V11ZM10 7H8V5H10V7ZM20 19H12V17H14V15H12V13H14V11H12V9H20V19ZM18 11H16V13H18V11ZM18 15H16V17H18V15Z"
      />
    </svg>
  );
}

export function FloorsIcon(props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.99 18.54L4.62 12.81L3 14.07L12 21.07L21 14.07L19.37 12.8L11.99 18.54ZM12 16L19.36 10.27L21 9L12 2L3 9L4.63 10.27L12 16Z"/>
    </svg>
  );
}

export function LogoIcon(props) {
  return (
    <svg {...props} width="46" height="32" viewBox="0 0 46 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 19C10 22.4478 11.3696 25.7544 13.8076 28.1924C16.2456 30.6304 19.5522 32 23 32C26.4478 32 29.7544 30.6304 32.1924 28.1924C34.6304 25.7544 36 22.4478 36 19L10 19Z" fill="#8B75BC"/>
      <path d="M18 17C18 14.6131 17.0518 12.3239 15.364 10.636C13.6761 8.94821 11.3869 8 9 8C6.61305 8 4.32387 8.94821 2.63604 10.636C0.948212 12.3239 3.60419e-07 14.6131 0 17L18 17Z" fill="#7851A8"/>
      <path d="M41.1673 4.97919C44.2617 8.1673 46 12.4913 46 17H20C20 14.0826 18.8411 11.2847 16.7782 9.22183C16.3631 8.80675 15.9183 8.42827 15.4487 8.08847C16.1177 6.96877 16.9157 5.92409 17.8328 4.97918C20.9271 1.79107 25.124 0 29.5 0C33.8761 0 38.073 1.79107 41.1673 4.97919Z" fill="#68359E"/>
    </svg>
  );
}

export function LocationIcon(props) {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 1.5C6.0975 1.5 3.75 3.8475 3.75 6.75C3.75 10.6875 9 16.5 9 16.5C9 16.5 14.25 10.6875 14.25 6.75C14.25 3.8475 11.9025 1.5 9 1.5ZM9 8.625C7.965 8.625 7.125 7.785 7.125 6.75C7.125 5.715 7.965 4.875 9 4.875C10.035 4.875 10.875 5.715 10.875 6.75C10.875 7.785 10.035 8.625 9 8.625Z" fill="#989898"/>
    </svg>
  );
}

export function MessageIcon(props) {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15 1.5H3C2.175 1.5 1.5075 2.175 1.5075 3L1.5 16.5L4.5 13.5H15C15.825 13.5 16.5 12.825 16.5 12V3C16.5 2.175 15.825 1.5 15 1.5ZM6.75 8.25H5.25V6.75H6.75V8.25ZM9.75 8.25H8.25V6.75H9.75V8.25ZM12.75 8.25H11.25V6.75H12.75V8.25Z"/>
    </svg>
  );
}

export function PhoneIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0075 11.535C14.085 11.535 13.1925 11.385 12.36 11.115C12.0975 11.025 11.805 11.0925 11.6025 11.295L10.425 12.7725C8.3025 11.76 6.315 9.8475 5.2575 7.65L6.72 6.405C6.9225 6.195 6.9825 5.9025 6.9 5.64C6.6225 4.8075 6.48 3.915 6.48 2.9925C6.48 2.5875 6.1425 2.25 5.7375 2.25H3.1425C2.7375 2.25 2.25 2.43 2.25 2.9925C2.25 9.96 8.0475 15.75 15.0075 15.75C15.54 15.75 15.75 15.2775 15.75 14.865V12.2775C15.75 11.8725 15.4125 11.535 15.0075 11.535Z" fill="currentColor"/>
    </svg>
  );
}

export function ChatIcon(props) {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15 1.5H3C2.175 1.5 1.5 2.175 1.5 3V16.5L4.5 13.5H15C15.825 13.5 16.5 12.825 16.5 12V3C16.5 2.175 15.825 1.5 15 1.5ZM15 12H4.5L3 13.5V3H15V12Z"/>
    </svg>
  );
}

export function CalendarIcon(props) {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.75 9H9V12.75H12.75V9ZM12 0.75V2.25H6V0.75H4.5V2.25H3.75C2.9175 2.25 2.2575 2.925 2.2575 3.75L2.25 14.25C2.25 15.075 2.9175 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25H13.5V0.75H12ZM14.25 14.25H3.75V6H14.25V14.25Z"/>
    </svg>
  );
}

export function ArrowIcon(props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0001 6L8.59009 7.41L13.1701 12L8.59009 16.59L10.0001 18L16.0001 12L10.0001 6Z" fill="#E3E3E3"/>
    </svg>
  );
}

export function ArrowLongIcon(props) {
  return (
    <svg {...props} width="24" height="22" viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 11L12 4L19 11" strokeWidth="2" fill="none"/>
      <rect x="11" y="5" width="2" height="14" stroke="none"/>
    </svg>
  );
}

export function TimerIcon(props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" />
      <path d="M12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z" />
    </svg>
  );
}

export function SettingsIcon(props) {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.37333 3.23928C7.57333 3.21261 7.77999 3.19927 7.99999 3.19927C8.21333 3.19927 8.42666 3.21261 8.62666 3.24594L9.67999 1.89261C9.77333 1.77261 9.95333 1.73927 10.0867 1.81261L12.3 3.09261C12.4467 3.17261 12.4933 3.33927 12.4467 3.48594L11.8067 5.07928C12.06 5.41261 12.2733 5.76594 12.4333 6.15928L14.1267 6.39928C14.2867 6.42594 14.4 6.55928 14.4 6.71928L14.4 9.27928C14.4 9.43928 14.2867 9.56594 14.1267 9.59261L12.4333 9.83261C12.2733 10.2259 12.0533 10.5859 11.8067 10.9126L12.4467 12.5059C12.5 12.6526 12.4467 12.8193 12.3 12.8993L10.0867 14.1726C9.94666 14.2526 9.77333 14.2259 9.67999 14.0926L8.62666 12.7393C8.42666 12.7726 8.20666 12.7993 7.99999 12.7993C7.79333 12.7993 7.57333 12.7859 7.37333 12.7526L6.31999 14.1059C6.22666 14.2259 6.04666 14.2593 5.91333 14.1859L3.69999 12.9059C3.55333 12.8259 3.50666 12.6593 3.55333 12.5126L4.19333 10.9193C3.93999 10.5859 3.72666 10.2326 3.56666 9.83928L1.87333 9.59928C1.71333 9.56594 1.59999 9.43928 1.59999 9.27928L1.59999 6.71928C1.59999 6.55928 1.71333 6.42594 1.87333 6.40594L3.56666 6.16594C3.72666 5.77261 3.93999 5.41261 4.19333 5.08594L3.55333 3.49261C3.49999 3.34594 3.55333 3.17928 3.69999 3.09928L5.91333 1.81928C6.05999 1.73928 6.22666 1.77261 6.31999 1.89928L7.37333 3.23928ZM5.59999 7.99928C5.59999 9.31928 6.67999 10.3993 7.99999 10.3993C9.31999 10.3993 10.4 9.31928 10.4 7.99928C10.4 6.67928 9.31999 5.59928 7.99999 5.59928C6.67999 5.59928 5.59999 6.67928 5.59999 7.99928Z" fill="#989898"/>
    </svg>
  );
}

export function ClearIcon(props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"/>
    </svg>
  );
}

export function SearchIcon(props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"/>
    </svg>
  );
}

export function DeskIcon(props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 6H19V11H15V12H19H20H21V14H20V20H19V14H5V20H4V14H3V12H4H5H13V11H9V6Z"/>
    </svg>
  );
}

export function ProfileIcon(props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.4883 8.46154C15.4883 10.3769 13.9367 11.9231 12 11.9231C10.0633 11.9231 8.5 10.3769 8.5 8.46154C8.5 6.54615 10.0633 5 12 5C13.9367 5 15.4883 6.54615 15.4883 8.46154Z" fill="#E3E3E3"/>
      <path d="M5 17.6923C5 15.3846 9.66667 14.2308 12 14.2308C14.3333 14.2308 19 15.3846 19 17.6923V20H5V17.6923Z" fill="#E3E3E3"/>
    </svg>
  );
}

export function ScanIcon(props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 23.25V19.5V19.5C0 19.0858 0.335786 18.75 0.75 18.75V18.75C1.16421 18.75 1.5 19.0858 1.5 19.5V22.5H4.5C4.91421 22.5 5.25 22.8358 5.25 23.25V23.25C5.25 23.6642 4.91421 24 4.5 24H0.75V24C0.335786 24 0 23.6642 0 23.25V23.25ZM23.25 24H19.5V24C19.0858 24 18.75 23.6642 18.75 23.25C18.75 22.8358 19.0858 22.5 19.5 22.5H22.5V19.5C22.5 19.0858 22.8358 18.75 23.25 18.75C23.6642 18.75 24 19.0858 24 19.5V23.25C24 23.6642 23.6642 24 23.25 24ZM24 0.75V4.5C24 4.91421 23.6642 5.25 23.25 5.25V5.25C22.8358 5.25 22.5 4.91421 22.5 4.5V1.5H19.5V1.5C19.0858 1.5 18.75 1.16421 18.75 0.75V0.75V0.75C18.75 0.335786 19.0858 0 19.5 0H23.25V0C23.6642 0 24 0.335786 24 0.75V0.75ZM0.75 0H4.5C4.91421 0 5.25 0.335786 5.25 0.75V0.75C5.25 1.16421 4.91421 1.5 4.5 1.5H1.5V4.5C1.5 4.91421 1.16421 5.25 0.75 5.25C0.335786 5.25 0 4.91421 0 4.5V0.75V0.75C0 0.335786 0.335786 0 0.75 0V0ZM5.25 4.5H18.75C19.1642 4.5 19.5 4.83579 19.5 5.25V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5H5.25C4.83579 19.5 4.5 19.1642 4.5 18.75V5.25C4.5 4.83579 4.83579 4.5 5.25 4.5ZM6 6V18H18V6H6ZM7.5 7.5H16.5V16.5H7.5V7.5Z" fill="#E3E3E3"/>
    </svg>
  );
}

export function ExternalLink() {
  return (
    <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M20.25 15H18.75C18.5511 15 18.3603 15.079 18.2197 15.2197C18.079 15.3603 18 15.5511 18 15.75V21H3V6H9.75C9.94891 6 10.1397 5.92098 10.2803 5.78033C10.421 5.63968 10.5 5.44891 10.5 5.25V3.75C10.5 3.55109 10.421 3.36032 10.2803 3.21967C10.1397 3.07902 9.94891 3 9.75 3H2.25C1.65326 3 1.08097 3.23705 0.65901 3.65901C0.237053 4.08097 0 4.65326 0 5.25L0 21.75C0 22.3467 0.237053 22.919 0.65901 23.341C1.08097 23.7629 1.65326 24 2.25 24H18.75C19.3467 24 19.919 23.7629 20.341 23.341C20.7629 22.919 21 22.3467 21 21.75V15.75C21 15.5511 20.921 15.3603 20.7803 15.2197C20.6397 15.079 20.4489 15 20.25 15ZM22.875 0H16.875C15.8733 0 15.3727 1.21453 16.0781 1.92188L17.753 3.59672L6.32812 15.0173C6.22325 15.1219 6.14003 15.2461 6.08325 15.3828C6.02647 15.5195 5.99724 15.6662 5.99724 15.8142C5.99724 15.9623 6.02647 16.1089 6.08325 16.2456C6.14003 16.3824 6.22325 16.5066 6.32812 16.6111L7.39078 17.6719C7.4953 17.7768 7.61949 17.86 7.75624 17.9168C7.89298 17.9735 8.03959 18.0028 8.18766 18.0028C8.33572 18.0028 8.48233 17.9735 8.61908 17.9168C8.75582 17.86 8.88001 17.7768 8.98453 17.6719L20.4037 6.24938L22.0781 7.92188C22.7812 8.625 24 8.13281 24 7.125V1.125C24 0.826631 23.8815 0.540483 23.6705 0.329505C23.4595 0.118526 23.1734 0 22.875 0V0Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}


