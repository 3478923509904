import React from 'react';

import BookingHistoryItem from '../BookingHistoryItem/BookingHistoryItem';

function renderListBooking(props) {
  const { title, mapMeetings, mapDesks, booking } = props;

  return (
    <>
      <div className="booking-history__day">
        {title}
      </div>
      <div className="booking-history-archive__shedule">
        {
          booking.map((item, index) => {
            const { kind, entityId } = item;
            const isMeeting = kind === 'meeting';
            const desk = isMeeting ? mapMeetings[entityId] : mapDesks[entityId];

            return (
              <BookingHistoryItem key={index} item={item} desk={desk} />
            );
          })
        }
      </div>
    </>
  );
};

export default function BookingHistoryArchive(props) {
  const { completedBooking, canceledBooking, mapDesks, mapMeetings, isPending } = props;
  const isReady = mapDesks && mapMeetings;
  const isViewCompleted = !!completedBooking.length && isReady;
  const isViewCanceled = !!canceledBooking.length && isReady;
  const isEmpty = !completedBooking.length && !canceledBooking.length && !isPending;

  return (
    <div className="booking-history-archive">
      {
        isViewCompleted &&
        renderListBooking({ title: 'Completed', mapMeetings, mapDesks, booking: completedBooking })
      }

      {
        isViewCanceled &&
        renderListBooking({ title: 'Canceled', mapMeetings, mapDesks, booking: canceledBooking })
      }

      {
        isEmpty &&
        <div className="booking-history__empty">
          No booking records
        </div>
      }
    </div>
  );
};
