import React, { createContext, useState } from 'react';

export const ContextUser = createContext();

export const ContextUserProvider = (props) => {
  const { children } = props;
  const [user, setUser] = useState(null);

  return (
    <ContextUser.Provider value={{ user, setUser }}>
      { children }
    </ContextUser.Provider>
  );
};
