import React from 'react';

import './input.css';

export default function Input(props) {
  const { label, type, inputRef, noLabel, ...restProps } = props;

  if (noLabel) {
    return (
      <div className="add-form-input">
        <input ref={inputRef} type={type} {...restProps} />
      </div>
    )
  }

  return (
    <div className="add-form-input">
      <label>
        {label && <span>{label}</span>}

        {
          type === 'textarea' ?
          <textarea ref={inputRef} {...restProps} /> :
          <input ref={inputRef} type={type} {...restProps} />
        }
      </label>
    </div>
  );
};
