import moment from 'moment';
import findIndex from 'lodash/findIndex';

export default (date, booking, recurrent) => {
  const currentDate = moment(date);
  let bTimes = [];

  const setBlockedTimes = (item) => {
    const timeFrom = moment(item.timeFrom);
    const timeTo = moment(item.timeTo);
    let isLong = false;

    if (timeFrom.format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')) {
      const lastBTime = bTimes[bTimes.length - 1];

      if (lastBTime) {
        const { h, m } = lastBTime.to;
        if (timeFrom.format('HH') === h && timeFrom.format('mm') === m) {
          isLong = true;
          lastBTime.to = { h: timeTo.format('HH'),   m: timeTo.format('mm') };
        }
      }

      !isLong && bTimes.push({
        from: { h: timeFrom.format('HH'), m: timeFrom.format('mm') },
        to:   { h: timeTo.format('HH'),   m: timeTo.format('mm')   }
      });
    }
  };

  if (recurrent) {
    recurrent.intervals.forEach(setBlockedTimes);
  }
  booking.forEach(setBlockedTimes);

  let times = [];
  let time = { h: 8, m: 0 };

  while (time.h !== 22) {
    times.push({ h: time.h > 9 ? time.h : `0${time.h}`, m: time.m ? time.m : '00' });
    if (time.m === 0) {
      time.m += 30;
    } else {
      time.h += 1;
      time.m = 0;
    }
  }

  times.push({ h: 22, m: '00' });

  bTimes.forEach((item) => {
    const startIndex = findIndex(times, (time) => !time.startBooking && !time.endBooking && +time.h === +item.from.h && +time.m === +item.from.m);
    const endIndex = findIndex(times, (time) => !time.startBooking && !time.endBooking && +time.h === +item.to.h && +time.m === +item.to.m);

    if (!times[startIndex]) {
      return
    }

    const { h, m } = times[startIndex];
    const { h: he, m: me } = times[endIndex];
    const countSplice = endIndex - startIndex;
    const startIndexSplice = startIndex ? startIndex + 1 : startIndex;
    const endIndexSplice = endIndex === times.length - 1 || !startIndex ? countSplice : countSplice - 1;

    times.splice(startIndexSplice, endIndexSplice, { h, m, startBooking: true }, { h: he, m: me, endBooking: true });

    if (startIndexSplice > 0) {
      times[startIndexSplice - 1].prevStartBooking = true
    }
  });

  return [times, bTimes.length];
};
