import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import Input from '../Input/Input';
import { default as api } from '../../services/api';
import Button from '../Button/Button';
import { useParams } from 'react-router';

const {
  fetchCompanyExcludedDomainsByStrId,
  createCompanyExcludedDomainsByStrId,
  deleteCompanyExcludedDomainsByStrId,
  fetchCompanyBySlug,
} = api

function elemChips(domain, index, clickHandle) {
  return (
    <div key={index} className="chip">
      {domain}
      <span className="closebtn" onClick={() => clickHandle()}>&times;</span>
    </div>
  )
}

function AdminDomains() {
  const { companyId } = useParams()
  const [strId, setStrId] = useState('')
  const [error, setReqError] = useState('')
  const [success, setSuccess] = useState('')
  const [companyDomains, setCompanyDomains] = useState([]);
  const { handleSubmit, register, reset } = useForm();

  useEffect(() => {
    if (companyId || strId) {
      companyId && setStrId(companyId)
      fetchCompanyExcludedDomainsByStrId(companyId ? companyId : strId)
        .then(({ domains }) => {
          reset()
          if (!domains) {
            return setReqError('invalid request')
          }
          setReqError('')
          domains && setCompanyDomains(domains)
        })
        .catch(err => {
          reset()
          setReqError(err.message)
        })
    }
  }, [companyId, strId])

  function handleDelete(domain) {
    return () => {
      deleteCompanyExcludedDomainsByStrId(strId, { domain })
        .then(({ domains }) => {
          reset()
          if (!domains) {
            return setReqError('invalud request')
          }
          setReqError('')
          setSuccess(`Domain ${domain} is deleted`)
          domains && setCompanyDomains(domains)
        })
        .catch(err => {
          reset()
          setSuccess('')
          setReqError(err.message)
        })
    }
  }

  function onSubmit(value) {
    createCompanyExcludedDomainsByStrId(strId, value)
      .then(({ domains }) => {
        reset()
        if (!domains) {
          return setReqError('invalud request')
        }
        setReqError('')
        setSuccess(`Domain ${value.domain} is added`)
        domains && setCompanyDomains(domains)
      })
      .catch(err => {
        reset()
        setSuccess('')
        setReqError(err.message)
      })
  }

  function fetchCompany({ slug }) {
    fetchCompanyBySlug(slug)
      .then(({ strId }) => {
        reset()
        if (!strId) {
          return setReqError('invalud request')
        }
        setStrId(strId)
        setReqError('')
      })
      .catch(err => {
        reset()
        setSuccess('')
        setReqError(err.message)
      })
  }


  return (
    <div className="b-profile">
      {
        strId ?
          (<form onSubmit={handleSubmit(onSubmit)}>
          <div className="add-form">
            <div className="add-form__title add-form__title__with-header">
              Domain white list
            </div>
            {
              companyDomains.length ?
                companyDomains.map((domain, index) => elemChips(domain, index, handleDelete(domain))) :
                (<p style={{textAlign: 'center'}}>Empty</p>)
            }
            <div className="add-form__title add-form__title__with-header">
              Add domain
            </div>
            <Input
              inputRef={register}
              name="domain"
              lable="domain"
              placeholder='example: "@ofistic8.com" "@google.com"'
            />
            <div>
              <Button type="submit">
                {'Add'}
              </Button>
            </div>
            { error && <p style={{color: 'red'}}>{error}</p> }
            { success && <p style={{color: 'green'}}>{success}</p> }
        </div>
      </form>) :
        window.location.pathname.includes('root-admin') ?
        (<form onSubmit={handleSubmit(fetchCompany)}>
          <div className="add-form">
            <div className="add-form__title add-form__title__with-header">
              Select company by slug
            </div>
            <Input
              inputRef={register}
              name="slug"
              lable="slug"
            />
            <div>
              <Button type="submit">
                {'Select'}
              </Button>
            </div>
            { error && <p style={{color: 'red'}}>{error}</p> }
            { success && <p style={{color: 'green'}}>{success}</p> }
          </div>
        </form>) : 'Oops, how are you come to here?'
      }
    </div>
  )
}

export default AdminDomains
