import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import Loader from '../../components/Loader/Loader';
import DeskForm from '../../components/DeskForm/DeskForm';

import { default as api } from '../../services/api';

const { fetchContactsByCompany, fetchDesk } = api

export default function EditDesk() {
  const { id, companyId } = useParams();

  const [desk, setDesk] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if (id) {
      fetchDesk(id).then(setDesk);
    }
  }, [id]);

  useEffect(() => {
    if (companyId) {
      fetchContactsByCompany(companyId).then(setUsers)
    }
  }, [companyId]);

  return (
    <div>
      {
        desk && users ?
        <DeskForm desk={desk} users={users} /> :
        <Loader />
      }
    </div>
  );
};
