import React, { useRef, useState, Fragment } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';
import useAuth from '../../auth/useAuth'
import ContentLoader from 'react-content-loader';

import Button from '../Button/Button';

import useUser from '../../hooks/useUser';
import { LogoIcon } from '../../services/svg-icons';
import getNavList from '../../services/get-nav-admin-list';
import useOutsideClick from '../../hooks/useOutsideClick';

import { default as api } from '../../services/api'

import './nav-admin.css';

const { getImgUrl } = api
function NavAdmin(props) {
  const { active, isCompanyAdmin, userCompany, isAdmin, menuArg } = props;

  const ref = useRef(null);
  const { user } = useUser();
  const [isOpen, setOpen] = useState(false);

  const { logout } = useAuth();

  useOutsideClick(ref, () => setOpen(false));

  const handleClickLogout = () => {
    localStorage.clear();
    localStorage.setItem('dataCompany', JSON.stringify({ slug: userCompany?.slug, isAdmin }));
    logout({ returnTo: `${window.location.origin}/logout-redirect` });
  };

  const swithOpen = () => setOpen(!isOpen);

  let menu
  if (menuArg) {
    menu = menuArg
  } else {
    menu = getNavList(isAdmin, userCompany).filter((item) => (isCompanyAdmin && !item.onlyAdmin) || !isCompanyAdmin);
  }

  let link = '/';
  let userCompanyTitle = '';

  if (isAdmin) {
    userCompanyTitle = 'Workipelago';
    link = '/root-admin';
  } else if (userCompany) {
    userCompanyTitle = userCompany.title;
    link = `/admin/${userCompany.strId}`;
  }

  return (
    <div className="nav-admin">
      <div className="nav-admin__holder">
        <Link to={link} >
          <LogoIcon className="nav-admin__item"/>
        </Link>

        {
          menu.map((item, index) => {
            const { name, link } = item;
            const classesItem = cx('nav-admin__item', { 'nav-admin__item_active': link === active });

            return <Button className={classesItem} link={link} key={index}>{name}</Button>
          })
        }
        <a className="nav-admin__contact-us nav-admin__item" href="https://www.ofistic8.com/contact-us/" target="blank" >Contact Us</a>
        <div className="nav-admin__avatar" onClick={swithOpen} >
          <span className="nav-admin_profile">
            {
              user ?
              <Fragment>
                <img src={getImgUrl(user.img)} alt="avatar"/>
                <div className="nav-admin__text">
                  <div className="nav-admin__avatar__name" >{user.name}</div>
                  <div className="nav-admin__avatar__company" >{userCompanyTitle}</div>
                </div>
              </Fragment> :
              <ContentLoader
                width={128}
                height={30}
                viewBox="0 0 128 30"
                backgroundColor="#f3f3f3"
                foregroundColor="#cbcbcb"
              >
                <circle cx="15" cy="15" r="15" />
                <rect x="40" y="6" rx="3" ry="3" width="88" height="6" />
                <rect x="40" y="18" rx="3" ry="3" width="52" height="6" />
              </ContentLoader>
            }
          </span>

          {
            isOpen &&
            <div ref={ref} className="nav-admin__dropdown">
              <span onClick={handleClickLogout}>Logout</span>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default NavAdmin;
