import React, { useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import find from 'lodash.find';

import Input from '../Input/Input';
import Button from '../Button/Button';
import Select from '../Select/Select';
import UploadFile from '../UploadFile/UploadFile';

import useUser from '../../hooks/useUser';
import { default as api } from '../../services/api';

const { postVirtualFloor, putVirtualFloor, getImgUrl } = api
const DEFAULT_ARRANGEMENT = 3;

export default function VirtualRoomForm(props) {
  const { virtualRoom, users } = props;
  const history = useHistory();
  const { companyId } = useParams();

  const { isAdmin } = useUser();
  const defaultMembers = useMemo(() =>
    virtualRoom &&
    JSON.parse(virtualRoom.members).reduce((list, item) => {
      const currentMember = find(users, (m) => m.id === item);

      if (currentMember) {
        return [...list, currentMember];
      }

      return list;
    }, []),
    [virtualRoom]
  );

  const defaultValues = useMemo(() => virtualRoom ? { ...virtualRoom } : {}, [virtualRoom]);
  const [members, setMembers] = useState(virtualRoom ? defaultMembers : []);
  const [backgroundImg, setImg] = useState(virtualRoom ? getImgUrl(virtualRoom.backgroundImg) : '');

  const getTypeLabel = (type) => type.name;
  const getTypeValue = (type) => type.strId;

  const onSubmit = (values) => {
    const strId = virtualRoom ? virtualRoom.strId : nanoid();
    const request = virtualRoom ? putVirtualFloor : postVirtualFloor;
    const convertedMembers = JSON.stringify(members.map((item) => item.id));
    const newUser = {
      ...values,
      strId,
      backgroundImg,
      arrangement: '3',
      members: convertedMembers
    };

    if (companyId) {
      newUser.companyStrId = companyId;
    }

    request(newUser).then(() => {
      history.push(`/${isAdmin ? 'root-' : ''}admin${!isAdmin ? `/${companyId}` : ''}/virtual`);
    }).catch((e) => {
      console.log(e);
    });
  };

  const { handleSubmit, register } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-form">
        <div className="add-form__title">
          {virtualRoom ? 'Edit' : 'Create a'} Virtual floor
        </div>

        <Input
          inputRef={register}
          name="title"
          label="Name"
        />

        <Input
          type="textarea"
          inputRef={register}
          name="desc"
          label="Description"
        />

        <Input
          inputRef={register}
          name="backgroundColor"
          label="Background color in Hex (#FFF)"
        />

        <UploadFile
          src={backgroundImg}
          title="Selected background image"
          onLoad={setImg}
        />

        <Select
          isMulti
          label="Members"
          options={users}
          onChange={setMembers}
          value={members}
          getOptionLabel={getTypeLabel}
          getOptionValue={getTypeValue}
        />

        <Button type="submit">
          {virtualRoom ? 'Save' : 'Create'}
        </Button>
      </div>
    </form>
  );
};
