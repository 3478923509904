import React, { useState, useEffect, useMemo } from 'react';

import Loader from '../Loader/Loader';
import Button from '../Button/Button';
import Select from '../../components/Select/Select';

import { default as api } from '../../services/api';
import useUser from '../../hooks/useUser';
import useCompanies from '../../hooks/useCompanies';
import { toObject } from '../../services/entity-format';
import getBookingParams from '../../services/get-booking-params';

const {
  deleteBooking,
  deleteRecurrentBooking,
  fetchBookingForFloor,
  fetchBuildingsByCompany,
  fetchContactsByCompany,
  fetchDesksForFloor,
  fetchFloorsByBuilding,
  fetchMeetingsForFloor,
  fetchRecurrentBookingForFloor
} = api

const NAMES = ['Floor', 'Desk', 'Contact', 'Time', ''];

export default function AdminBooking() {
  const { isCompanyAdmin } = useUser();
  const { companies } = useCompanies();

  const savedCompany = JSON.parse(localStorage.getItem('currentCompany'));
  const defaultCompany = savedCompany || companies[0];

  const [isPending, setPending] = useState(true);
  const [floors, setFloors] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [booking, setBooking] = useState([]);
  const [rBooking, setRBooking] = useState([]);
  const [mapDesks, setMapDesks] = useState({});
  const [mapFloors, setMapFloors] = useState({});
  const [mapMeetings, setMapMeetings] = useState({});
  const [mapContacts, setMapContacts] = useState({});
  const [currentFloor, setCurrentFloor] = useState(null);
  const [currentContact, setCurrentContact] = useState(null);
  const [currentBuilding, setCurrentBuilding] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(defaultCompany);

  const getTypeLabel = (type) => type.title;
  const getTypeValue = (type) => type.strId;
  const getTypeName = (type) => type.name;
  const handleError = (err) => {
    setPending(false);
    console.error(err);
  };

  const filteredEntity = (entity) => entity.filter((item) => {
    const isGoodContact = item.ownerContactId === currentContact?.strId;
    const isGoodFloor = item.floorId === currentFloor?.strId;

    if (currentContact && currentFloor) {
      return isGoodContact && isGoodFloor;
    }

    if (currentContact) {
      return isGoodContact
    }

    if (currentFloor) {
      return isGoodFloor;
    }

    return true;
  });

  const currentBooking = useMemo(() => filteredEntity(booking), [booking, currentContact, currentFloor]);
  const currentRBooking = useMemo(() => filteredEntity(rBooking), [rBooking, currentContact, currentFloor]);
  const allBooking = [ ...currentBooking, ...currentRBooking ];

  const getDataByCompany = (id) => {
    setPending(true);

    Promise.all([
      fetchContactsByCompany(id),
      fetchBuildingsByCompany(id)
    ]).then(([ cs, b ]) => {
      const { data } = toObject(cs, { keyField: 'strId' });
      const currentBuilding = b[0];

      if (currentBuilding) {
        setCurrentBuilding(currentBuilding);
      }

      setBuildings(b);
      setContacts(cs);
      setMapContacts(data);
      setPending(false);
    }).catch(handleError);
  };

  const getDataByBuilding = ({ strId }) => {
    setCurrentFloor(null);
    setCurrentContact(null);
    setPending(true);

    fetchFloorsByBuilding(strId).then(async (fs) => {
      const { data } = toObject(fs, { keyField: 'strId' });
      setFloors(fs);
      setMapFloors(data);

      let allBooking = [];
      let allRBooking = [];
      let allMapDesks = {};
      let allMapMeetings = {};

      for (const f of fs) {
        const { strId: floorStrId } = f;
        const [bookings, rBookings, desks, meetings] = await Promise.all([
          fetchBookingForFloor(floorStrId),
          fetchRecurrentBookingForFloor(floorStrId),
          fetchDesksForFloor(floorStrId),
          fetchMeetingsForFloor(floorStrId)
        ]).catch(handleError);

        const { data: mapDesks } = toObject(desks, { keyField: 'strId' });
        const { data: mapMeetings } = toObject(meetings, { keyField: 'strId' });

        allMapDesks = { ...allMapDesks, ...mapDesks };
        allMapMeetings = { ...allMapMeetings, ...mapMeetings };
        allBooking = [ ...allBooking, ...bookings ];
        allRBooking = [ ...allRBooking, ...rBookings ];
      }

      setBooking(allBooking);
      setRBooking(allRBooking);
      setMapDesks(allMapDesks);
      setMapMeetings(allMapMeetings);
      setPending(false);
    }).catch(handleError);
  };

  useEffect(() => {
    if (currentCompany) {
      getDataByCompany(currentCompany.strId);
      localStorage.setItem('currentCompany', JSON.stringify(currentCompany));
    }
  }, [currentCompany]);

  useEffect(() => {
    if (currentBuilding) {
      getDataByBuilding(currentBuilding);
    }
  }, [currentBuilding]);

  return (
    <div>
      {
        !isCompanyAdmin &&
        <Select
          label="Company"
          options={companies}
          value={currentCompany}
          onChange={setCurrentCompany}
          getOptionLabel={getTypeLabel}
          getOptionValue={getTypeValue}
        />
      }

      <Select
        label="Building"
        options={buildings}
        value={currentBuilding}
        onChange={setCurrentBuilding}
        getOptionLabel={getTypeName}
        getOptionValue={getTypeValue}
      />

      <Select
        label="Floor"
        isClearable
        options={floors}
        value={currentFloor}
        getOptionLabel={getTypeLabel}
        getOptionValue={getTypeValue}
        onChange={setCurrentFloor}
      />

      <Select
        label="Contact"
        isClearable
        options={contacts}
        value={currentContact}
        getOptionLabel={getTypeName}
        getOptionValue={getTypeValue}
        onChange={setCurrentContact}
      />

      <table className="table">
        <thead>
          { renderHeader() }
        </thead>

        <tbody>
          {
            isPending ?
            <tr><td><Loader /></td></tr> :
            renderBooking({ booking: allBooking, mapContacts, mapDesks, mapMeetings, mapFloors, setBooking, setRBooking })
          }
        </tbody>
      </table>
    </div>
  );
};

const renderHeader = () => {
  return (
    <tr>
      { NAMES.map((item, index) => <th key={index}>{item}</th>) }
    </tr>
  );
};

const renderBooking = (props) => {
  const { booking, mapContacts, mapDesks, mapMeetings, mapFloors, setBooking, setRBooking } = props;

  return (
    booking.map((item, index) => {
      const { ownerContactId, entityId, floorId, strId, kind } = item;

      const currentContact = mapContacts[ownerContactId];
      const currentFloor = mapFloors[floorId];
      const currentEntity = mapDesks[entityId] || mapMeetings[entityId];
      const { headerTitle, timeName, dayName, isRecurrent } = getBookingParams(item, currentEntity);

      const handleDelete = () => {
        const deleteCb = isRecurrent ? deleteRecurrentBooking : deleteBooking;
        const newListBooking = booking.filter((b) => !b.repeatTimeSlice && b.strId !== strId);
        const newListRBooking = booking.filter((b) => b.repeatTimeSlice && b.strId !== strId);

        setBooking(newListBooking);
        setRBooking(newListRBooking);
        deleteCb(strId);
      };

      return (
        <tr key={index}>
          <td>
            {currentFloor?.title}
          </td>

          <td>
            {headerTitle} #{currentEntity?.id}
          </td>

          <td>
            {currentContact?.name}
            {
              currentContact && currentContact.email && <span className="b-email-inline">
                {currentContact?.email}
              </span>
            }
          </td>

          <td>
            {isRecurrent ? '' : `${dayName}\n`}
            {timeName}
          </td>

          <td>
            <Button type="delete" onClick={handleDelete}>
              Delete
            </Button>
          </td>
        </tr>
      );
    })
  );
};
