import React from 'react';
import cx from 'classnames';

import './tabs.css';

export default function Tabs(props) {
  const { active, setActive, tabs, isRound, isMini, isLight, notSticky } = props;
  const classesTabs = cx('tabs', {
    'tabs_round': isRound,
    'tabs_mini': isMini,
    'tabs_light': isLight,
    'tabs_not-sticky': notSticky
  });

  return (
    <div className={classesTabs}>
      {
        tabs.map((tab, index) => {
          const { Icon, title } = tab;
          const classes = cx('tab', {
            'tab_active': index === active
          });

          return (
            <div key={index} className={classes} onClick={() => setActive(index)}>
              {Icon && <Icon className="tab__icon" />}
              <div className="tab__title">{title}</div>
            </div>
          );
        })
      }
    </div>
  );
};
