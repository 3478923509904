import React, { useEffect, useMemo } from 'react';
import { Redirect, useLocation } from 'react-router';
import find from 'lodash.find';

import NavAdmin from '../components/NavAdmin/NavAdmin';

import useUser from '../hooks/useUser';
import useCompanies from '../hooks/useCompanies';

export default function AdminLayout(props) {
  const { children } = props;

  const location = useLocation();
  const { companies } = useCompanies();
  const { user, isAdmin, isCompanyAdmin, redirectToFloor } = useUser();

  const isRight = isAdmin || isCompanyAdmin;

  const userCompany = useMemo(() => {
    let currentUserCompany = null;

    if (user && user.companyStrId) {
      const company = find(companies, { strId: user.companyStrId });

      if (company) {
        currentUserCompany = company;
      }
    }

    return currentUserCompany;
  }, [user, companies]);

  useEffect(() => {
    if (isAdmin === false && isCompanyAdmin === false) {
      redirectToFloor();
      return;
    }
  }, [isAdmin, isCompanyAdmin, redirectToFloor]);

  if (!isRight) {
    return <Redirect to="/root-admin" />;
  }

  return (
    <div className="adm">
      <NavAdmin
        isAdmin={isAdmin}
        userCompany={userCompany}
        active={location.pathname}
        isCompanyAdmin={isCompanyAdmin}
      />
        <div className="adm__content">
          {children}
        </div>
    </div>
  );
};
