import React, { useState, useMemo, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';

import useAuth from '../../auth/useAuth'
import useUser from '../../hooks/useUser';
import useCompanies from '../../hooks/useCompanies'
import useTeamsFlag, { notifySuccess } from '../../hooks/useTeams'

import Input from '../Input/Input';
import Button from '../Button/Button';

import { default as api } from '../../services/api';

const {
  postCompany,
  postCompanyNew,
  // putContact,
  // putCompany,
  putRolesContact,
  contactsPostCheckAvailability,
  fetchProfileByToken,
} = api
const DEFAULT_AFTER_TIME = 15;
const DEFAULT_BEFORE_TIME = 5;
const DEFAULT_CHECKIN = 'disabled';
const DEFAULT_CLEANING = 'off';


export default function CompanyForm(props) {
  const { company } = props;
  const history = useHistory();
  const [err, setErr] = useState('')
  const [userData, setUserData] = useState(null)
  const [pending, setPending] = useState(false)
  const teamsFlag = useTeamsFlag()

  const { setUser } = useUser();
  const { getCompanyByStrId } = useCompanies()

  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getIdTokenClaims,
    logout
  } = useAuth({ overrideSlug: true });

  // const [optionCheckin, setOptionCheckin] = useState(company ? company.optionCheckin : DEFAULT_CHECKIN);
  // const [optionCleaning, setOptionCleaning] = useState(company ? company.optionCleaning : DEFAULT_CLEANING);
  const defaultValues = useMemo(() => company ? { ...company } : null, [company]);

  useEffect(() => {
    if (userData) {
      return
    }

    if (teamsFlag === 'loading') {
      return
    }

    if (localStorage.forceAuthToken) {
      setUserData(JSON.parse(localStorage.forceAuthToken))
      localStorage.removeItem('forceAuthToken')
      return
    }

    if (!isLoading) {
      getIdTokenClaims()
      .then((r) => {
        setUserData(r)
      })
      .catch((err) => {
        console.log('auth err', err)
        setErr(err.toString())
      })
    }
  }, [isAuthenticated, isLoading, teamsFlag, userData])

  useEffect(() => {
    if (!userData) {
      return
    }

    // alert(userData)
    // for (const key in userData) {
    //   alert(`${key}: ${userData[key]}`)
    // }

    const { email } = userData

    // alert(email)

    contactsPostCheckAvailability(email)
    .then(({ isAvailable }) => {
      // alert('Done')
      if (!isAvailable) {
        setErr('email_not_available')
      } else {
        setErr('')
      }
    })
    .catch((err) => {
      alert(`Checking err ${err}`)

      console.log('check availability err', err)
      setErr(err.toString())
    })
  }, [userData])

  const onLogout = () => {
    logout({ returnTo: `${window.location.origin}/logout-redirect` });
  }

  const onSubmit = async (values) => {
    const strId = company ? company.strId : nanoid();
    // const request = company ? putCompany : postCompany;
    const newCompany = {
      strId,
      optionCleaning: DEFAULT_CLEANING,
      optionCheckin: DEFAULT_CHECKIN,
      optionCheckinTime: DEFAULT_BEFORE_TIME,
      optionCheckinExpireTime: DEFAULT_AFTER_TIME,
      isDemo: false,
      allowInvisibleBooking: false,
      auth0ClientId: "",
      isVirtualOnly: true,
      hasTeamsIntergration: false,
      ...values,
    };

    try {
      if (pending) {
        throw new Error('Already pending')
      }
      setPending(true)
      const company = await postCompanyNew(newCompany)
      const { __raw: token } = userData

      const profile = await fetchProfileByToken(token, company.strId)

      await putRolesContact(profile.strId, ["company-admin"])

      const user = {...profile, roles: ["company-admin"]}

      setUser(user)

      localStorage.wkpgMode = ''
      localStorage.setItem('wkpg_slug', company.slug)
      localStorage.setItem('token', token)

      getCompanyByStrId(company.strId);
      setPending(false)
      history.push(`/admin/${company.strId}/users`);
    } catch(err) {
      alert(err)
      const error = err.response?.body?.error
      setErr(error)
      setPending(false)
    }
  };

  const { handleSubmit, register } = useForm(defaultValues ? { defaultValues } : {
    defaultValues: {
      optionCheckinTime: DEFAULT_BEFORE_TIME,
      optionCheckinExpireTime: DEFAULT_AFTER_TIME
    }
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-form">
        <div className="add-form__title">
          Create a company
        </div>

        <>
          <Input
            inputRef={register}
            name="title"
            label="Name"
          />

          <Input
            inputRef={register}
            type="textarea"
            name="desc"
            label="Description"
          />

          <Input
            inputRef={register}
            name="slug"
            label="Slug"
          />
          <Input
            inputRef={register}
            name="hasTeamsIntergration"
            type="checkbox"
            label="Has teams integration"
          />
          {
            err &&
            <div className="add-form_err" style={{marginBottom: '15px'}}>
              { err === 'email_not_available' &&
                <div>
                  Email not available. You can't use an email twice. Please use another
                  email for a new company.
                  <Button onClick={onLogout} >Log out</Button>
                </div>}
              { err !== 'email_not_available' &&
                <div>
                  {err}
                </div>
              }
            </div>
          }
          <Button type="submit" disabled={pending}>
            {company ? 'Save' : 'Create'}
          </Button>
        </>
      </div>
    </form>
  );
};
