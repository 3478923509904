import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

export function toArray(data = {}, ids = []) {
  return ids.map((id) => ({ ...data[id] }));
}

export function toObject(entry, options={}) {
  const { keyField='id', addField=false, addFieldName=false } = options;
  const ids = [];
  let data = {};
  const fields = {};

  if (isArray(entry)) {
    data = entry.reduce((list, item) => {
      !list[item[keyField]] && ids.push(item[keyField]);

      if (addField) {
        fields[item[addField]] = item[keyField];
      }

      return { ...list, [item[keyField]]: item };
    }, {});
  }

  if (isPlainObject(entry)) {
    ids.push(entry[keyField]);
    data = { [entry[keyField]]: entry };
  }

  const entity = { data, ids };

  if (addField) {
    entity[addFieldName] = fields;
  }

  return entity;
}
