import React, { useState, useEffect } from 'react';
import find from 'lodash.find';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import { useParams, useLocation, useHistory, Redirect } from 'react-router-dom';

import Nav from '../../components/Nav/Nav';
import Loader from '../../components/Loader/Loader';
import InfoPanel from '../../components/InfoPanel/InfoPanel';
import LinksButtons from '../../components/LinksButtons/LinksButtons';
import PopupInfoDesk from '../../components/PopupInfoDesk/PopupInfoDesk';
import PopupSettingsVirtual from '../../components/PopupSettingsVirtual/PopupSettingsVirtual';
// import { hasRole } from '../../components/StartPage/StartPage';

import useUser from '../../hooks/useUser';
import chunkArray from '../../services/chunk-array';
import listClassesForStatus from '../../services/list-classes-for-status';

import { default as api } from '../../services/api';

import './virtual.css';
import Scan from '../../components/Scan/Scan'

const {
  fetchVirtualFloorById,
  fetchFloorsByBuilding,
  fetchContactsByCompany,
  fetchBuildingsByCompany,
  fetchVirtualFloorsByCompany,
  getImgUrl,
  putVirtualFloor,
  fetchCompanyByStrId,
} = api

function hasRole(roles, role) {
  return Boolean(roles && roles.indexOf(role) > -1 )
}

function Virtual() {
  const history = useHistory();
  const location = useLocation();
  const {virtualId} = useParams();
  const {user, isAdmin, isCompanyAdmin} = useUser();

  const { protocol, hostname, port } = window.location;
  const url = `${protocol}//${hostname}${port ? ':' + port : ''}`;

  const [popupX, setPopupX] = useState(0);
  const [popupY, setPopupY] = useState(0);
  const [floors, setFloors] = useState([]);
  const [groups, setGroups] = useState([]);
  const [contact, setContact] = useState(null);
  const [buildings, setBuildings] = useState([]);
  const [virtualRoom, setVirtualRoom] = useState({});
  const link = `${url}${location.pathname}`;
  const [companyStrId, setCompanyStrId] = useState('');
  const [company, setCompany] = useState(null)
  const [virtualRooms, setVirtualRooms] = useState({});
  const [isViewVirtual, setViewVirtual] = useState(false);
  const [isSummon, setViewSummon] = useState(+localStorage.getItem('isSummon') || false);

  const [viewScan, setViewScan] = useState(false);
  const { backgroundColor, backgroundImg, arrangement } = virtualRoom;
  const classNameDesk = `virtual__group-${arrangement}`;
  const savedSummons = JSON.parse(localStorage.getItem('summons')) || [];
  const styleVirtual = backgroundImg ? { backgroundImage: `url(${backgroundImg})` } : { backgroundColor };

  const closePopup = () => setViewVirtual(false);
  const openPopup = () => setViewVirtual(true);
  const handleClickFloor = ({strId}) => {
    history.push(`/floor/${strId}`);
  };
  const handleClickBuilder = ({strId}) => {
    fetchFloorsByBuilding(strId).then((res) => {
      if (res.length) {
        history.push(`/floor/${res[0].strId}`);
      }
    });
  };

  const getGroupsByCompany = (id, members) => fetchContactsByCompany(id).then((contactsCompany) => {
    if (members) {
      const currentContacts = contactsCompany.filter((item) => JSON.parse(members).includes(item.id));
      // TODO: desk for 3 contacts
      const groups = chunkArray(currentContacts, 3);

      if (savedSummons.length) {
        const newGroups = groups.map((group) => group.map((item) => {
          const savedItem = find(savedSummons, (summon) => summon.strId === item.strId);
          return savedItem ? {...item, isSummon: true} : item;
        }));

        setGroups(newGroups);
      } else {
        setGroups(groups);
      }
    }
  });

  const clearGroups = () => {
    const newGroups = cloneDeep(groups).map((group) => group.map((item) => ({ ...item, isSummon: false })));
    setGroups(newGroups);
  };

  const getDataByVirtualFloor = (id, notVR) => fetchVirtualFloorById(id).then(async (res) => {
    const { members, companyStrId } = res;
    setCompanyStrId(companyStrId);

    if (!notVR) {
      fetchVirtualFloorsByCompany(companyStrId).then(setVirtualRooms);
      fetchBuildingsByCompany(companyStrId).then((resBuildings) => {
        setBuildings(resBuildings);
        if (resBuildings && resBuildings.length > 0) {
          fetchFloorsByBuilding(resBuildings[0].strId).then(setFloors);
        }
      });
    }

    setVirtualRoom(res);
    getGroupsByCompany(companyStrId, members);

    const currentCompany = await fetchCompanyByStrId(companyStrId)
    setCompany(currentCompany)
  })

  useEffect(() => {
    getDataByVirtualFloor(virtualId, true);
  }, []);

  useEffect(() => {
    getDataByVirtualFloor(virtualId);
  }, [location]);


  const addNewLink = (newLink) => {
    console.log('links prev', virtualRoom.links)
    console.log('new link', newLink)

    let existedList = []
    if (virtualRoom && virtualRoom.links && virtualRoom.links.list && virtualRoom.links.list.length > 0) {
      existedList = virtualRoom.links.list
    }
    existedList.push(newLink)

    const NewVirtualRoom = {
      ...virtualRoom,
      links: {
        list: existedList
      }
    }
    putVirtualFloor(NewVirtualRoom).then(data => setVirtualRoom(data));
  }

  const deleteLink = (link) => {
    if (window.confirm("Delete this link button?")) {
      const NewVirtualRoom = {
        ...virtualRoom,
        links: {
          list: [
            ...virtualRoom.links.list.filter(item => item !== link)
          ]
        }
      }
      putVirtualFloor(NewVirtualRoom).then(data => setVirtualRoom(data));
    }
  }

  if (!company || !companyStrId) {
    return <Loader />;
  }

    if (
      !user
      ||  (
        user.companyStrId !== company.strId
        && (!user.roles || !user.roles.includes('admin') )
      )
  ) {
    return <Redirect to="/" />;
  }

  // if (!user || user.companyStrId !== companyStrId) {
  //   return <Redirect to="/login" />;
  // }

  return (
    <div className="b-floor">
      <Nav
        companySlug={company?.slug}
        buildings={buildings}
        setBuilding={handleClickBuilder}
        setViewScan={setViewScan}
        floors={floors}
        setFloor={handleClickFloor}
        avatar={getImgUrl(user.img)}
        userName={user.name}
        isAdmin={isAdmin}
        isCompanyAdmin={isCompanyAdmin}
        userStrId={user?.strId}
        companyStrId={company?.strId}
        virtualFloors={virtualRooms}
      />

      {
        contact &&
        <PopupInfoDesk
          className="virtual__contact-popup"
          x={popupX} y={popupY}
          contact={contact}
          onClose={() => setContact(null)}
          company={company}
          closeBtn
        />
      }
      <div className="virtual" style={styleVirtual}>
        <InfoPanel
          userStrId={user.strId}
          onOpen={openPopup}
          isSummon={isSummon}
          summons={savedSummons}
          setSummons={clearGroups}
          virtualRoom={virtualRoom}
          virtualRooms={virtualRooms}
          setViewSummon={setViewSummon}
          company={company}
          hideOnFloor
        />
        {virtualRoom && <LinksButtons
          virtualRoom={virtualRoom}
          addLink={addNewLink}
          isAdmin={user && user.roles && (hasRole(user.roles, 'company-admin') || hasRole(user.roles, 'admin'))}
          deleteLink={deleteLink}
        />}
        {isViewVirtual && <PopupSettingsVirtual virtualRoom={virtualRoom} onClose={closePopup}/>}
        {renderGroups(groups, classNameDesk, setContact, isSummon, setPopupX, setPopupY, setGroups)}
      </div>

      {
        viewScan &&
        <Scan
          link={link}
          onClose={() => setViewScan(false)}
        />
      }
    </div>
  )
}

const renderGroups = (groups, className, setContact, isSummon, setPopupX, setPopupY, setGroups) => {
  const newGroups = cloneDeep(groups);
  return (
    <div className="virtual__groups">
      {
        groups.map((group, groupIndex) => {
          return (
            <div key={groupIndex} className={className}>
              {
                group.map((item, index) => {
                  const classes = classNames('virtual__avatar', {
                    'virtual__avatar_summon': item.isSummon && isSummon
                  });
                  const classesHolder = classNames('virtual__avatar-holder', `virtual__avatar-holder_${listClassesForStatus[item.status]}`);
                  const handleClick = ({target}) => {
                    if (isSummon) {
                      let savedSummons = JSON.parse(localStorage.getItem('summons')) || [];
                      const { strId } = newGroups[groupIndex][index];
                      const indexSummon = findIndex(savedSummons, (summon) => summon.strId === strId);

                      if (newGroups[groupIndex][index].isSummon) {
                        savedSummons = [...savedSummons.slice(0, indexSummon), ...savedSummons.slice(indexSummon + 1)];
                      } else {
                        savedSummons.push(newGroups[groupIndex][index]);
                      }

                      localStorage.setItem('summons', JSON.stringify(savedSummons));
                      newGroups[groupIndex][index].isSummon = !newGroups[groupIndex][index].isSummon;

                      setGroups(newGroups);
                    } else {
                      const { x, y } = target.getBoundingClientRect();

                      setContact(item);
                      setPopupX(x - 9);
                      setPopupY(y - 9);

                      // fetchContactLocationByStrId(item.strId)
                      // .then((res) => {
                      //   const locationName = getLocationString(res.location);
                      //   setLocation(locationName);
                      //   setContact(item);
                      //   setPopupX(x - 9);
                      //   setPopupY(y - 9);
                      // })
                      // .catch((e) => {
                      //   console.error(e)
                      // });
                    }
                  };

                  return (
                    <div key={index} className={`${className}-${index + 1}`} onClick={handleClick}>
                      <div className={classesHolder}>
                        <div className={classes} style={{backgroundImage: `url(${getImgUrl(item.img)})`}}/>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          );
        })
      }
    </div>
  );
};

export default Virtual;


/*
<Nav
        buildings={buildings}
        setBuilding={handleClickBuilder}
        floors={floors}
        setFloor={handleClickFloor}
        avatar={getImgUrl(user.img)}
        isAdmin={isAdmin}
        isCompanyAdmin={isCompanyAdmin}
      />
*/
