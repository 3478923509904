import React, { PureComponent } from 'react';

import './error-boundary.css';

export default class ErrorBoundary extends PureComponent {
  state = {
    error: false
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  render() {
    const handleClickRefresh = () => {
      localStorage.clear()
      window.location.reload();
    };

    if (this.state.error) {
      return (
        <div className="error__container">
          <div className="error">
            <div className="error__title">
              Oops, something went wrong at our end!
            </div>
            <div className="error__sub-title">
              Don`t worry.&nbsp;
              <span className="error__link" onClick={handleClickRefresh}>
                Refresh
              </span> page to continue.
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
};
