import React, { useState, Fragment, useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';

import Button from '../Button/Button';
import InfoPanelTime from './InfoPanelTime';
import InfoPanelVirtual from './InfoPanelVirtual';
import DropDownSummon from '../DropDownSummon/DropDownSummon';
import useTeamsFlag, { goAndAuthenticate, notifySuccess } from '../../hooks/useTeams'
import { useMobile } from '../../hooks/useDetectMobile';

import {
  IconSummon,
  IconVirtual,
  SettingsIcon,
  BuildingsIcon,
  CleaningIcon,
  EditIcon
} from '../../services/svg-icons';

import './info-panel.css';

function InfoPanel(props) {
  const {
    buildingName,
    virtualRooms,
    virtualRoom,
    onOpen,
    virtualRO,
    isSummon,
    setViewSummon,
    summons,
    setSummons,
    isJanitor,
    isAdmin,
    isViewAdminPanel,
    setViewAdminPanel,
    currentDate = moment(),
    setCurrentDate,
    userStrId,
    viewDate,
    setViewDate,
    company,
    hideOnFloor,
    setDateChanged
  } = props;

  const isMobile = useMobile()
  const teamsFlag = useTeamsFlag()

  const isHideOtherButtons = isMobile && isSummon

  const currentHour = useMemo(() => {
    return currentDate.hours() < 8 ? 8 : currentDate.hours() > 22 ? 22 : currentDate.hours();
  }, [currentDate]);

  const currentMinutes = useMemo(() => {
    return currentDate.minutes() < 30 ? '00' : 30;
  }, [currentDate]);

  const [date, setDate] = useState(currentDate.clone().toDate());
  const [time, setTime] = useState({ h: currentHour , m: currentMinutes });
  const [viewTime, setViewTime] = useState(false);

  const classesVirtualButton = classNames('info-panel__button', {
    'info-panel__button_active': virtualRoom
  });
  const classesSummon = classNames('info-panel__button', 'info-panel__button_not-margin', {
    'info-panel__button_active-summon': isSummon
  });
  const handleClickSummon = () => {
    localStorage.setItem('isSummon', isSummon ? 0 : 1);
    setViewSummon(!isSummon);
  };
  const textEdit = isViewAdminPanel ? 'Hide Admin Panel' : 'Edit Plan';

  return (
    <div className={classNames("info-panel", {['info-panel-teams']: teamsFlag === 'teams'})}>
      {!isHideOtherButtons && <div className="info-panel__build">
        {
          buildingName &&
          <Fragment>
            <BuildingsIcon className="info-panel__icon"/>
            <span>{buildingName}</span>
          </Fragment>
        }
        {
          virtualRoom &&
          <Fragment>
            <IconVirtual className="info-panel__icon"/>
            <span>{virtualRoom.title}</span>
            { !virtualRO && <SettingsIcon className="info-panel__icon-settings" onClick={onOpen}/>}
          </Fragment>
        }
      </div>}
      <div className="info-panel__buttons">
        {
          isJanitor ?
          <Button link="/janitor" className={classesSummon}>
            <CleaningIcon className="info-panel__icon_light"/>
            <span className="info-panel__button-lable">Janitor Menu</span>
          </Button> :
          <Fragment>
            {!hideOnFloor && !isHideOtherButtons &&
              <InfoPanelTime
                isOpen={viewTime}
                onOpen={setViewTime}
                date={date}
                time={time}
                setTime={setTime}
                setDate={setDate}
                viewDate={viewDate}
                setViewDate={setViewDate}
                setCurrentDate={setCurrentDate}
                setDateChanged={setDateChanged}
              />
            }


            {
              isAdmin && !isHideOtherButtons && !isMobile &&
              <Button className={classesVirtualButton} onClick={() => setViewAdminPanel(!isViewAdminPanel)}>
                <EditIcon style={{width: '18px'}} className="info-panel__icon_light"/>
                <span className="info-panel__button-lable">{textEdit}</span>
              </Button>
            }

            <div className="info-panel__button-holder">
              <Button className={classesSummon} onClick={handleClickSummon}>
                <IconSummon className="info-panel__icon_light"/>
                <span className="info-panel__button-lable">Summon</span>
              </Button>
              {
                isSummon &&
                <DropDownSummon
                  options={summons}
                  setViewSummon={setViewSummon}
                  setSummons={setSummons}
                  company={company}
                />
              }
            </div>
          </Fragment>
        }
      </div>
    </div>
  );
}

export default InfoPanel;


/*

<InfoPanelVirtual
              userId={userStrId}
              virtualRoom={virtualRoom}
              virtualRooms={virtualRooms}
            />

*/
