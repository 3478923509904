import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PopupAddLink from '../../components/PopupAddLink/PopupAddLink';
import Button from '../../components/Button/Button'
import {ClearIcon, ExternalLink } from "../../services/svg-icons";
import useTeamsFlag, { goAndAuthenticate, notifySuccess } from '../../hooks/useTeams'

import './LinksButtons.css';
import cs from 'classnames'
import {useMobile} from '../../hooks/useDetectMobile'



function processedHref(href) {
  if (href.indexOf('http:') === 0 || href.indexOf('https:') === 0) {
    return href
  }
  return `https://${href}`
}



export default function LinksButtons({virtualRoom, addLink, isAdmin, deleteLink}) {
  const { links } = virtualRoom;

  const [isViewPopup, setViewPopup] = useState(false);
  const teamsFlag = useTeamsFlag()
  const isMobile = useMobile()

  const checkTeams = () => {
    return isMobile && teamsFlag === 'teams'
  }

  const closePopup = () => setViewPopup(false);
  const openPopup = () => setViewPopup(true);
  return <div className={cs('links-buttons', {['links-buttons__teams']: teamsFlag === 'teams'})}>
    {isViewPopup && <PopupAddLink onClose={closePopup} addLink={addLink}/>}
    {links?.list && links.list.map && links.list.map((item, index) => (
      item &&
      <div key={index} className='links-buttons__wrapper'>

        <Button outerLink={processedHref(item.href)} target="_blank" >
          <ExternalLink />&nbsp;{item.name}
        </Button>

        {isAdmin && !checkTeams() &&
        <button className='links-buttons__delete'>
          <ClearIcon style={{width: '16px', height: '16px'}} onClick={() => deleteLink(item)}/>
        </button>
        }
      </div>
    ))}

    {isAdmin && !checkTeams() && <Button type="square" onClick={openPopup}>+</Button>}
  </div>;
};

// {isAdmin && <button className='links-buttons__button links-buttons__button_add button' onClick={openPopup}>+</button>}
// <Link className='links-buttons__button button' target="_blank" to={{pathname: ${item.href }}>{}</Link>
//{isAdmin && <button className='links-buttons__button links-buttons__button_add button' onClick={openPopup}>+</button>}
