import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import Loader from '../../components/Loader/Loader';
import CompanyForm from '../../components/CompanyForm/CompanyForm';

import { default as api } from '../../services/api';

const { fetchCompanyByStrId } = api

export default function EditCompany() {
  const { id } = useParams();

  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (id) {
      fetchCompanyByStrId(id).then(setCompany);
    }
  }, [id]);

  return (
    <div>
      {
        company ?
        <CompanyForm company={company} /> :
        <Loader />
      }
    </div>
  );
};
