import React from 'react';
import { Redirect } from 'react-router';

export default function LogoutRedirect() {
  const dataStringCompany = localStorage.getItem('dataCompany');
  const dataCompany = dataStringCompany ? JSON.parse(dataStringCompany) : {};
  const { slug, isAdmin } = dataCompany;
  const link = '/';

  return <Redirect to={link} />;
};
