import React, { useState, useEffect, useRef } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";

import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

// import { isMobile } from 'react-device-detect';
import { useMobile } from '../../hooks/useDetectMobile';

import Button from '../Button/Button';
import useTeamsFlag from '../../hooks/useTeams'

import {
  PhoneIcon,
  ChatIcon,
  CalendarIcon,
  MessageIcon,
  LocationIcon
} from '../../services/svg-icons';

import {
  constraits,
} from '../../services/map-handler';

import { getLocationString } from '../../services/booking';
import { default as api } from '../../services/api';
import useOutsideClick from '../../hooks/useOutsideClick';
import listClassesForStatus from '../../services/list-classes-for-status';

import './popup-info-desk.css';

import { processedHref } from '../../utils'
// import { ContactListCall } from '../ContactOptions'

const { fetchContactLocationByStrId, getImgUrl } = api
function LocationInfo({ locationInfo, setScale }) {
  const history = useHistory();
  const { desk, locationName } = locationInfo;
  const teamsFlag = useTeamsFlag()

  const onClick = () => {
    if (desk) {
      const url =`/floor/${desk.floorStrId}?x=${desk.x}&y=${desk.y}`;
      setScale && setScale(constraits.scale.max)
      history.push(url);
    }
  }

  if (!locationInfo) {
    return;
  }

  return (
    <div className="popup-info-desk__location" onClick={onClick} >
      <LocationIcon className="popup-info-desk__icon"/>
      <span>{locationName}</span>
    </div>
  )
}

// function ContactPhone({ phone }) {
//   if (phone) {
//     const href = `tel:${phone}`
//     return <a href={href} ><PhoneIcon /></a>
//   }
//   return (
//     <span className="contact__disabled"><PhoneIcon /></span>
//   )
// }

function ContactListCall({ contact, company }) {
  const teamsFlag = useTeamsFlag()
  const { email, callLink } = contact
  if (callLink) {
    const href = processedHref(callLink)
    return <a href={href} target="blank" ><PhoneIcon /></a>
  }
  const emails = [email]
  if (emails && emails.length > 0 && (!company || company.hasTeamsIntergration)) {
    const emailStr = emails.join(',')
    const href = `https://teams.microsoft.com/l/call/0/0?users=${emailStr}`

    const onClick = (e) => {
      if (teamsFlag === 'teams') {
        microsoftTeams.executeDeepLink(href)
        e.preventDefault()
      }
    }

    return <a href={href} onClick={onClick} target="blank" ><PhoneIcon /></a>
  }
  return (
    <span className="contact__disabled"><PhoneIcon /></span>
  )
}

function ContactChat({ contact, company }) {
  const teamsFlag = useTeamsFlag()
  const { email, chatLink } = contact
  if (chatLink) {
    const href = processedHref(chatLink)
    return <a href={href} target="blank"><ChatIcon /></a>
  }
  if (email && (!company || company.hasTeamsIntergration)) {
    const href = `https://teams.microsoft.com/l/chat/0/0?users=${email}`

    const onClick = (e) => {
      if (teamsFlag === 'teams') {
        microsoftTeams.executeDeepLink(href)
        e.preventDefault()
      }
    }

    return <a href={href} onClick={onClick} target="blank"><ChatIcon /></a>
  }
  return (
    <span className="contact__disabled"><ChatIcon /></span>
  )
}

function ContactSchedule({ contact, company }) {
  const teamsFlag = useTeamsFlag()
  const { email, calendarLink } = contact

  if (calendarLink) {
    const href = processedHref(calendarLink)
    return <a href={href} target="blank">
      <CalendarIcon className="popup-info-desk__icon-calendar"/>
    </a>
  }

  // https://teams.microsoft.com/l/meeting/new?subject=<meeting subject>&startTime=<date>&endTime=<date>&content=<content>&attendees=<user1>,<user2>,<user3>,...
  if (email && (!company || company.hasTeamsIntergration)) {
    const href = `https://teams.microsoft.com/l/meeting/new?attendees=${email}`

    const onClick = (e) => {
      if (teamsFlag === 'teams') {
        microsoftTeams.executeDeepLink(href)
        e.preventDefault()
      }
    }

    return (
      <a href={href} onClick={onClick} target="blank">
        <CalendarIcon className="popup-info-desk__icon-calendar"/>
      </a>
    )
  }
  return (
    <span className="contact__disabled">
      <CalendarIcon className="popup-info-desk__icon-calendar"/>
    </span>
  )
}

function PopupInfoDesk(props) {
  const {
    x,
    y,
    contact,
    company,
    onClose,
    closeBtn,
    className,
    setViewHotdeskPopup,
    isViewHotDeskButton,
    setViewButtonHotdesk,
    setScale,
  } = props;
  const {
    strId,
    name,
    position,
    message,
    img,
    status='online',
    // phone,
    // email,
    // callLink,
  } = contact;
  const teamsFlag = useTeamsFlag()


  const ref = useRef(null);
  const [locationInfo, setLocationInfo ] = useState({
    locationName: '',
    desk: null,
  });


  const isMobile = useMobile()
  const stylePopup = isMobile ? {} : { left: `${x}px`, top: `${y}px` };
  const styleAvatar = { backgroundImage: `url(${getImgUrl(img)})` };
  const classes = classNames('popup-info-desk', `popup-info-desk_${listClassesForStatus[status]}`, className);

  useEffect(() => {
    fetchContactLocationByStrId(strId).then(({ location }) => {
      const locationInfo = getLocationString(location);
      setLocationInfo(locationInfo);
    }).catch((err) => {
      console.error(err);
    });
  }, [strId])

  const handleClickOutside = ({ target }) => {
    if (ref.current && !ref.current.contains(target)) {
      setViewButtonHotdesk && setViewButtonHotdesk(false);
      onClose();
    }
  };

  const handleClickButton = () => {
    setViewButtonHotdesk && setViewButtonHotdesk(false);
    setViewHotdeskPopup(true);
    onClose();
  };

  useOutsideClick(ref, handleClickOutside);
  const checkTeams = () => {
    return isMobile && teamsFlag === 'teams'
  }

  return (
    <div ref={ref} className={classes} style={stylePopup}>
      <div className="popup-info-desk__top">
        <div className="popup-info-desk__avatar" style={styleAvatar}/>
        <div className="popup-info-desk__info">
          <div className="popup-info-desk__name">{name}</div>
          <div className="popup-info-desk__position">{position}</div>
          <div className="popup-info-desk__status">{status}</div>
        </div>
        {
          closeBtn &&
            <div className="popup-info-desk__close-btn" onClick={() => onClose()}></div>
        }
      </div>
      <div className="popup-info-desk__middle">
        {
          message &&
          <div className="popup-info-desk__message">
            <MessageIcon className="popup-info-desk__icon popup-info-desk__message-icon"/>
            <span>{message}</span>
          </div>
        }
        <LocationInfo locationInfo={locationInfo} setScale={setScale} />
      </div>
      <div className={classNames("popup-info-desk__bottom", {['popup-info-desk__teams']: teamsFlag === 'teams'})}>
      {!checkTeams() &&
          <ContactListCall contact={contact} company={company}/>
        }
        <ContactChat contact={contact} company={company} />
        <ContactSchedule contact={contact} company={company} />
      </div>
      {
        isViewHotDeskButton &&
        <div className="popup-info-desk__button-holder popup-hot-desk__bottom popup-hot-desk__bottom_once">
          <Button className="popup-hot-desk__button" onClick={handleClickButton}>Book</Button>
        </div>
      }
    </div>
  );
}

export default PopupInfoDesk;
