import React, { useEffect, useState } from 'react';
import moment from 'moment';
import find from 'lodash.find';

import { useParams, useHistory, useLocation, Redirect } from 'react-router-dom';

import Map from '../../components/Map/Map';
import Nav from '../../components/Nav/Nav';
import Scan from '../../components/Scan/Scan';
import Loader from '../../components/Loader/Loader';
import InfoPanel from '../../components/InfoPanel/InfoPanel';
import FloorPagination from '../../components/FloorPagination/FloorPagination';

import useUser from '../../hooks/useUser';
import { getMapHandler } from '../../services/map-handler';
import getQueryParam from '../../services/get-query-param';
import { default as api } from '../../services/api';
import { getActualBooking } from '../../services/booking';
import {
  BOOKING_CANCELED,
  BOOKING_CREATED,
  CONTACT_CREATED,
  CONTACT_UPDATED,
  DESK_UPDATED,
  DESK_DELETED,
  MEETING_DELETE,
  RECURRENT_BOOKING_CANCELED,
  RECURRENT_BOOKING_CREATED
} from '../../constants/socketStatuses';

const {
  fetchFloor,
  fetchBuilding,
  fetchDesksForFloor,
  fetchCompanyByStrId,
  fetchBookingForFloor,
  fetchFloorsByBuilding,
  fetchMeetingsForFloor,
  fetchContactsByCompany,
  fetchBuildingsByCompany,
  fetchVirtualFloorsByCompany,
  fetchRecurrentBookingForFloor,
  fetchRecurrentBookingIntervals,
  fetchFloorsByContact,
  getImgUrl,
  createSocket,
} = api

export default function Floor() {
  const { user, isAdmin, isCompanyAdmin, isJanitor } = useUser();
  const history = useHistory();
  const location = useLocation();
  const { floorId } = useParams('');
  const deskId = getQueryParam('deskId');

  const { protocol, hostname, port } = window.location;
  const url = `${protocol}//${hostname}${port ? ':' + port : ''}`;

  const [desks, setDesks] = useState([]);
  const [floors, setFloors] = useState([]);
  const [floor, setFloor] = useState(null);
  const [booking, setBooking] = useState([]);

  window.dbgSetBooking = setBooking

  const [meetings, setMeetings] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [afterTime, setAfterTime] = useState(0);
  const [buildings, setBuildings] = useState([]);
  const [pendingRB, setPendingRB] = useState(true);
  const [dateChanged, setDateChanged] = useState(false);
  const [companySlug, setCompanySlug] = useState('');
  const [viewDate, setViewDate] = useState(false);
  const [beforeTime, setBeforeTime] = useState(0);
  const [currentBuilding, setBuilding] = useState({});
  const [company, setCompany] = useState(null);
  const [virtualRooms, setVirtualRooms] = useState([]);
  const [janitorFloors, setJanitorFloors] = useState([]);
  const [recurrentIntervals, setIntervals] = useState({});
  const [isLoadBooking, setLoadBooking] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment());
  const [updateBooking, setUpdateBooking] = useState(false);
  const [optionCleaning, setOptionCleaning] = useState('off');
  const [recurrentBooking, setRecurrentBooking] = useState([]);
  const [isViewAdminPanel, setViewAdminPanel] = useState(false);
  const [availabilityInvisible, setInvisible] = useState(false);
  const [optionCheckin, setOptionCheckin] = useState('disabled');
  const [pendingIntervals, setPendingIntervals] = useState(false);

  const [viewScan, setViewScan] = useState(false);
  const [isSummon, setViewSummon] = useState(+localStorage.getItem('isSummon') || false);
  const [summons, setSummons] = useState(JSON.parse(localStorage.getItem('summons')) || []);

  const [currentContact, setContact] = useState(null);
  const [currentMeeting, setMeeting] = useState(null);
  const [currentDesk, setDesk] = useState(null);

  const isBuildingCleaning = optionCleaning !== 'off';
  const isJanitorFloor = janitorFloors?.includes(floorId);
  const userStrId = user?.strId;
  const link = `${url}${location.pathname}`;

  const getCompanyByStrId = (id) => fetchCompanyByStrId(id)
    .then((res) => {
      const {
        slug,
        optionCheckin,
        optionCleaning,
        optionCheckinTime,
        allowInvisibleBooking,
        optionCheckinExpireTime
      } = res;

      setCompanySlug(slug);
      setOptionCheckin(optionCheckin);
      setInvisible(allowInvisibleBooking);
      optionCleaning && setOptionCleaning(optionCleaning);
      optionCheckinTime && setBeforeTime(optionCheckinTime);
      optionCheckinExpireTime && setAfterTime(optionCheckinExpireTime);
    })
    .catch(console.log);

  const getBuildingsByCompany = (id) => fetchBuildingsByCompany(id)
    .then((res) => {
      if (res.length) {
        setBuildings(res);
        getFloorsByBuilding(currentBuilding.strId);
      }
    })
    .catch(console.log);

  const getDesksForFloor = (id) => fetchDesksForFloor(id)
    .then(setDesks)
    .catch(console.log);

  const getBookingForFloor = (id, date) => fetchBookingForFloor(id, date)
    .then(setBooking)
    .catch(console.log);

  const getRecurrentBookingForFloor = (id) => fetchRecurrentBookingForFloor(id)
    .then((res) => {
      setRecurrentBooking(res);
      setPendingRB(false);
    })
    .catch(console.log);

  const getRecurrentIntervals = async (list) => {
    const allIntervals = {};
    setPendingIntervals(true);

    for (const item of list) {
      const { entityId, ownerContactId } = item
      const forUser = await setRecurrentInterval(item)
      const { intervals, trigger } = forUser[item.entityId]
      if (intervals.length) {
        if (allIntervals[entityId]) {
          allIntervals[entityId].intervals = allIntervals[entityId].intervals.concat(intervals)
          allIntervals[entityId].ownerContactId = trigger ? ownerContactId : allIntervals[entityId].ownerContactId
        } else {
          allIntervals[entityId] = {
            intervals,
            ownerContactId,
          }
        }
      }
    }
    setPendingIntervals(false);
    setIntervals(allIntervals);
  };

  const setRecurrentInterval = async (item) => {
    let intervals = [];
    let trigger = null
    const { strId, timeFrom, timeTo, entityId, ownerContactId } = item;

    if (timeFrom && timeTo) {
      intervals = await fetchRecurrentBookingIntervals(strId);
      if (intervals) {
        trigger = find(
          intervals,
          (b) => currentDate.clone().add(1, 'h') >= moment(b.timeFrom) && currentDate <= moment(b.timeTo)
        )
      }
    }

    return {
      [entityId]: {
        intervals,
        ownerContactId,
        trigger: Boolean(trigger),
      }
    }
  };

  const getFloorsByBuilding = (id) => fetchFloorsByBuilding(id)
    .then(setFloors)
    .catch(console.log);

  const getMeetingsForFloor = (id) => fetchMeetingsForFloor(id)
    .then(setMeetings)
    .catch(console.log);

  const getVirtualRoomsByCompany = (id) => fetchVirtualFloorsByCompany(id)
    .then(setVirtualRooms)
    .catch(console.log);

  const handleClickBuilding = ({strId}) => {
    fetchFloorsByBuilding(strId).then((res) => {
      if(res.length) {
        setFloors(res);
        history.push(`/floor/${res[0].strId}`);
      }
    });
  };

  const handleClickFloor = (floor) => {
    history.push(`/floor/${floor.strId}`);
  };

  useEffect(() => {
    console.log('Booking updated', booking)
  }, [booking])

  useEffect(() => {
    localStorage.removeItem('qrCodeDesk');
  }, []);

  useEffect(() => {
    if (recurrentBooking && pendingRB || dateChanged && recurrentBooking) {
      getRecurrentIntervals(recurrentBooking);
      setDateChanged(false)
    }
  }, [recurrentBooking, pendingRB, currentDate]);

  useEffect(() => {
    if (deskId && desks.length) {
      const desk = find(desks, (item) => item.strId === deskId);
      desk && setDesk(desk);
    }
  }, [deskId, desks]);

  useEffect(() => {
    const x = getQueryParam('x');
    const y = getQueryParam('y');
    const handler = getMapHandler();

    if (handler) {
      // handler.setPopupFlag('mini', viewMiniPopup)
      handler.navgigateTo(x, y)
    }
  }, [location]);

  useEffect(() => {
    if (!company) {
      return;
    }

    const { strId } = company;

    getBuildingsByCompany(strId);
    getVirtualRoomsByCompany(strId);
    fetchContactsByCompany(strId).then(setContacts);
    getCompanyByStrId(strId);
  }, [company]);

  useEffect(() => {
    if (!isLoadBooking && !updateBooking) {
      return;
    }

    const newDesks = getActualBooking(desks, booking, recurrentBooking);
    const newMeetings = getActualBooking(meetings, booking, recurrentBooking);


    setDesks(newDesks);
    setMeetings(newMeetings);

    setUpdateBooking(false);
  }, [isLoadBooking, updateBooking]);

  useEffect(() => {
    if (!floorId || !currentDate) {
      return;
    }

    setLoadBooking(false);

    Promise.all([
      fetchFloorsByContact(userStrId).then(setJanitorFloors),
      fetchFloor(floorId).then(async (floor) => {
        const building = await fetchBuilding(floor.buildingStrId);
        setFloor(floor);
        setBuilding(building);

        if (!company) {
          const currentCompany = await fetchCompanyByStrId(building.companyStrId);
          setCompany(currentCompany);
        }
      }),
      getDesksForFloor(floorId),
      getBookingForFloor(floorId, currentDate.clone().toDate().toISOString()),
      getMeetingsForFloor(floorId),
      getRecurrentBookingForFloor(floorId)
    ]).then(() => setLoadBooking(true)).catch(console.error);
  }, [floorId, userStrId, currentDate]);

  useEffect(() => {
    if (localStorage.flagNoSockets) {
      return;
    }

    if (!company || !floorId || !userStrId) {
      return;
    }

    const socket = createSocket()

    const handleCreatedBooking = (entity, isRecurrent) => {
      let existsFlag = false;
      const handleSet = isRecurrent ? setRecurrentBooking : setBooking;
      const setActualKind = (desk) => {
        if (desk && desk.strId === entity.entityId) {
          let newBooking = desk.booking || [];
          let newRecurrentBooking = desk.recurrentBooking || [];

          if (isRecurrent) {
            newRecurrentBooking = desk.recurrentBooking ? [...desk.recurrentBooking, entity] : [entity];
          } else {
            newBooking = desk.booking ? [...desk.booking, entity] : [entity];
          }

          const newDesk = getActualBooking([desk], newBooking, newRecurrentBooking);

          return newDesk[0];
        }

        return desk;
      };

      handleSet((bookingRecords) => {
        const existedBooking = find(bookingRecords, { strId: entity.strId })

        if (existedBooking) {
          existsFlag = true;
          return bookingRecords;
        }

        return [entity, ...bookingRecords];
      });

      if (existsFlag) {
        return;
      }

      setUpdateBooking(true);

      if (entity.kind === 'desk') {
        setDesk(setActualKind);
      }

      if (entity.kind === 'meeting') {
        setMeeting(setActualKind);
      }
    };

    const handleCanceledBooking = (entity, isRecurrent) => {
      let alreadyRemoved = false;
      const handleSet = isRecurrent ? setRecurrentBooking : setBooking;
      const setActualKind = (entityDesk) => {
        if (!entityDesk) {
          return entityDesk
        }
        if (isRecurrent && entityDesk.recurrentBooking) {
          entityDesk.recurrentBooking = entityDesk.recurrentBooking.filter(b => b.strId !== entity.strId);
        }

        if (!isRecurrent && entityDesk.booking) {
          entityDesk.booking = entityDesk.booking.filter(b => b.strId !== entity.strId);
        }

        return { ...entityDesk };
      };
      const setActualKinds = (desks) => {
        const entityDesk = desks.find(d => d.strId === entity.entityId);
        setActualKind(entityDesk)
        return desks;
      };

      handleSet(bookingRecords => {
        const newArray = bookingRecords.filter(record => record.strId !== entity.strId);

        if (newArray.length && newArray.length === bookingRecords.length) {
          alreadyRemoved = true;
        }

        return newArray;
      })

      if (entity.kind === 'desk') {
        setDesks(setActualKinds);
        setDesk(setActualKind);
      }

      if (entity.kind === 'meeting') {
        setMeetings(setActualKinds);
        setMeeting(setActualKind);
      }

      if (!alreadyRemoved) {
        setUpdateBooking(true);
      }
    };

    socket.on('connect', () => {
      socket.emit('subscribe', {
        subscriptions: [
          {
            eventName: CONTACT_CREATED,
            labels: { companyStrId: company.strId }
          },
          {
            eventName: CONTACT_UPDATED,
            labels: { companyStrId: company.strId }
          },
          {
            eventName: BOOKING_CREATED,
            labels: { floorId }
          },
          {
            eventName: BOOKING_CANCELED,
            labels: { floorId }
          },
          {
            eventName: RECURRENT_BOOKING_CREATED,
            labels: { floorId }
          },
          {
            eventName: RECURRENT_BOOKING_CANCELED,
            labels: { floorId }
          },
          {
            eventName: DESK_UPDATED,
            labels: {}
          },
          {
            eventName: DESK_DELETED,
            labels: {}
          },
          {
            eventName: MEETING_DELETE,
            labels: {}
          }
        ]
      });
    })

    socket.on(MEETING_DELETE, ({ entity }) => {
      setMeetings((meetings) => meetings.filter(meeting => {
        if (meeting.strId !== entity.strId) {
          return meeting
        }
      }))
    })

    socket.on(DESK_DELETED, ({ entity }) => {
      setDesks((desks) => desks.filter(desk => {
        if (desk.strId !== entity.strId) {
          return desk
        }
      }))
    })

    socket.on(DESK_UPDATED, ({ entity }) => {
      if (floorId !== entity.floorStrId) {
        return;
      }

      // setDesks(desks => desks.map(desk => ({
      //   ...desk.strId === entity.strId ?
      //   { ...entity, booking: desk.booking, recurrentBooking: desk.recurrentBooking } : desk,
      // })));

      setDesks((desks) => {
        // const desksBefore = desks
        let isFound = false
        const desksAfter = desks.map((desk) => {
          if (desk.strId === entity.strId) {
            isFound = true
            return { ...entity, booking: desk.booking, recurrentBooking: desk.recurrentBooking }
          }
          return desk
        })

        if (!isFound) {
          desksAfter.push(entity)
        }


        return desksAfter
      })


      // desks.map(desk => ({
      //   ...desk.strId === entity.strId ?
      //   { ...entity, booking: desk.booking, recurrentBooking: desk.recurrentBooking } : desk,
      // })));

      setDesk(currentDesk => {
        return currentDesk && currentDesk.strId === entity.strId
          ? { ...entity, booking: currentDesk.booking, recurrentBooking: currentDesk.recurrentBooking }
          : currentDesk
      });
    })

    socket.on(CONTACT_CREATED, ({ entity }) => {
      setContacts((contacts) => [...contacts, ...entity]);
    })

    socket.on(CONTACT_UPDATED, ({ entity }) => {
      setContacts((contacts) => {
        for (let i = 0; i < contacts.length; i++) {
          if (contacts[i].strId === entity.strId) {
            contacts[i] = entity
          }
        }
        return [].concat(contacts)
      })

      setContact((contact) => {
        if (contact && contact.strId === entity.strId) {
          return entity
        }
        return contact
      })
    })

    socket.on(BOOKING_CANCELED, ({ entity }) => handleCanceledBooking(entity));
    socket.on(RECURRENT_BOOKING_CANCELED, ({ entity }) => {
      handleCanceledBooking(entity, true);
      setIntervals((intervals) => {
        delete intervals[entity.entityId];
        return intervals;
      });
    });

    socket.on(BOOKING_CREATED, ({ entity }) => handleCreatedBooking(entity));
    socket.on(RECURRENT_BOOKING_CREATED, ({ entity }) => {
      handleCreatedBooking(entity, true);
      setRecurrentInterval(entity).then((interval) => {
        setIntervals({...recurrentIntervals, ...interval});
      });
    });

    return () => {
      if (socket) {
        socket.close();
      }
    }
  }, [floorId, company, userStrId]);

  if (!company) {
    return <Loader />;
  }

  if (
      !user
      ||  (
        user.companyStrId !== company.strId
        && (!user.roles || !user.roles.includes('admin') )
      )
  ) {
    return <Redirect to="/" />;
  }

  const testSetBooking = (info) => {
    console.log('INFO', info)
  }

  return (
    <div className="b-floor">
      <InfoPanel
        userStrId={userStrId}
        viewDate={viewDate}
        setViewDate={setViewDate}
        buildingName={currentBuilding.name}
        virtualRooms={virtualRooms}
        setSummons={setSummons}
        isAdmin={isAdmin || isCompanyAdmin}
        isSummon={isSummon}
        setViewSummon={setViewSummon}
        summons={summons}
        isJanitor={isJanitor && isBuildingCleaning}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        setDateChanged={setDateChanged}
        isViewAdminPanel={isViewAdminPanel}
        setViewAdminPanel={setViewAdminPanel}
        company={company}
      />

      {
        floor && floors.length > 0 &&
        <FloorPagination
          floor={floor}
          floors={floors}
          setFloor={handleClickFloor}
        />
      }
      <Nav
        companySlug={companySlug}
        buildings={buildings}
        setBuilding={handleClickBuilding}
        setViewScan={setViewScan}
        floors={floors}
        setFloor={handleClickFloor}
        avatar={getImgUrl(user.img)}
        userName={user.name}
        isAdmin={isAdmin}
        isCompanyAdmin={isCompanyAdmin}
        userStrId={userStrId}
        companyStrId={company?.strId}
        virtualFloors={virtualRooms}
      />

      {
        viewScan &&
        <Scan
          desks={desks}
          link={link}
          onClose={() => setViewScan(false)}
        />
      }
      {
        floor &&
        <Map
          viewDate={viewDate}
          currentDate={currentDate}
          pendingIntervals={pendingIntervals}
          isAdmin={isAdmin || isCompanyAdmin}
          recurrentIntervals={recurrentIntervals}
          isViewAdminPanel={isViewAdminPanel}
          isJanitor={isJanitor && isBuildingCleaning}
          openedDeskId={deskId}
          afterTime={afterTime}
          beforeTime={beforeTime}
          checkIn={optionCheckin}
          isJanitorFloor={isJanitorFloor}
          isBuildingCleaning={isBuildingCleaning}
          building={currentBuilding}
          floor={floor}
          desks={desks}
          contacts={contacts}
          meetings={meetings}
          user={user}
          companyStrId={company?.strId}
          companySlug={company?.slug}
          company={company}
          isSummon={isSummon}
          summons={summons}
          setSummons={setSummons}
          booking={booking}
          setBooking={testSetBooking}
          setUpdateBooking={setUpdateBooking}
          recurrentBooking={recurrentBooking}
          setIntervals={setIntervals}
          setRecurrentInterval={setRecurrentInterval}
          setRecurrentBooking={setRecurrentBooking}
          currentContact={currentContact} setContact={setContact}
          currentMeeting={currentMeeting} setMeeting={setMeeting}
          currentDesk={currentDesk} setDesk={setDesk}
          availabilityInvisible={availabilityInvisible}
        />
      }
    </div>
  );
}
