import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';

import Input from '../Input/Input';
import Button from '../Button/Button';
import Select from '../Select/Select';

import { default as api } from '../../services/api';
import { BOOKABLE, NON_BOOKABLE } from '../../constants/meetingStatuses';

const { putMeeting } = api
const listStatuses = [
  { value: BOOKABLE, label: BOOKABLE },
  { value: NON_BOOKABLE, label: NON_BOOKABLE }
];

export default function MeetingForm(props) {
  const { meeting } = props;
  const { companyId, floorId } = useParams();
  const history = useHistory();

  const defaultValues = useMemo(() => meeting ? { ...meeting } : {}, [meeting]);

  const [status, setStatus] = useState(meeting?.status ?? BOOKABLE);

  const onSubmit = (values) => {
    const newMeeting = {
      ...values, strId: meeting?.strId, status, x: +values.x,
      y: +values.y, width: +values.width, height: +values.height
    };

    putMeeting(newMeeting).then(() => {
      history.push(`/admin/${companyId}/floors/${floorId}/meetings`);
    }).catch((err) => {
      console.error(err);
    });
  };

  const { handleSubmit, register } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-form">
        <div className="add-form__title">
          edit desk #{meeting?.id}
        </div>

        <Input
          inputRef={register}
          name="title"
          label="Name"
        />

        <Select
          label="Status"
          options={listStatuses}
          value={{ value: status, label: status }}
          onChange={(option) => setStatus(option.value)}
        />

        <Input
          inputRef={register}
          name="azureUpn"
          label="Azure UPN"
        />

        <Input
          inputRef={register}
          name="x"
          label="Position x"
        />

        <Input
          inputRef={register}
          name="y"
          label="Position y"
        />

        <Input
          inputRef={register}
          name="width"
          label="Width"
        />

        <Input
          inputRef={register}
          name="height"
          label="Height"
        />

        <Input
          type="textarea"
          inputRef={register}
          name="points"
          label="Points"
        />

        <Button type="submit">
          Save
        </Button>
      </div>
    </form>
  );
};
