import React, { useRef } from 'react';

import { default as api } from '../../services/api';

import './upload-file.css';

const { uploadFile, getImgUrl } = api

function UploadFile(props) {
  const { title, onLoad, src } = props;
  const fileRef = useRef(null);

  const onFileChange = (e) => {
    if (!fileRef.current) {
      return;
    }

    const fileInput = fileRef.current;
    const file = fileInput.files[0];

    if (!file) {
      return;
    }

    uploadFile(file, (e) => {
      console.log('progress', e.percent);
    }).then((result) => {
      console.log('file uploaded', result);
      onLoad(result.uri);
    }).catch((e) => {
      console.log('upload error', e);
    });
  }

  return (
    <div className="add-form-input">
      <label>
        <span>{title}</span>
        <input type="file" ref={fileRef} onChange={onFileChange} />
      </label>

      {src && <img className="upload-file__image" src={getImgUrl(src)} alt="preview" />}
    </div>
  );
}

export default UploadFile;
