import { JANITOR, USER, ADMIN, COMPANY_ADMIN } from "../constants/userRoles";

export const statuses = [
  { value: 'away', label: 'away' },
  { value: 'busy', label: 'busy' },
  { value: 'offline', label: 'offline' },
  { value: 'available', label: 'available' },
  { value: 'in a call', label: 'in a call' },
  { value: 'out of office', label: 'out of office' },
  { value: 'do not disturb', label: 'do not disturb' }
];

export const roles = [
  { value: USER, label: USER },
  { value: JANITOR, label: JANITOR },
  { value: ADMIN, label: ADMIN },
  { value: COMPANY_ADMIN, label: COMPANY_ADMIN }
];

export const cleaningOptions = [
  { value: 'off', label: 'off' },
  { value: 'soft', label: 'soft' }
];
