import React from 'react';
import cx from 'classnames';

import './switch.css'

export default function Switch({ isActive }) {
  const classes = cx('switch', {
    'switch_active': isActive
  });

  return (
    <div className={classes}>
      <div className="switch__item"></div>
    </div>
  );
}
