import { default as api } from './api';
import { updateMapItem } from './map-items';

const { updateDesk } = api

export default (strId, options, deskEl) => {
  updateDesk(strId, options).then((desk) => {
    updateMapItem(deskEl, desk);
  }).catch((e) => console.error('Could not save desk'));
};
