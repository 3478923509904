const mapDays = {
  repeatMonday: 'MO',
  repeatTuesday: 'TU',
  repeatWednesday: 'WE',
  repeatThursday: 'TH',
  repeatFriday: 'FR',
  repeatSaturday: 'SA',
  repeatSunday: 'SU'
};

export default (recurrentData) => {
  const names = Object.keys(recurrentData);
  const result = names.reduce((str, item) => {
    const day = recurrentData[item] && mapDays[item];

    if (day) {
      return `${str}${day}, `;
    }

    return str;
  } ,'(');

  return `${result.slice(0, -2)})`;
};
