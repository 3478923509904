import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';
import useAuth from '../../auth/useAuth'

import useUser from '../../hooks/useUser';
import { useMobile } from '../../hooks/useDetectMobile';

import useOutsideClick from '../../hooks/useOutsideClick';
import useTeamsFlag, { goAndAuthenticate, notifySuccess } from '../../hooks/useTeams'
import cs from 'classnames'

import { ClearIcon } from '../../services/svg-icons';

import './drop-down-user.css';
import useCompanies from "../../hooks/useCompanies";

export default function DropDownUser(props) {
  const { isAdmin, onClose, userStrId, isCompanyAdmin, companySlug } = props;

  const ref = useRef(null);
  const isMobile = useMobile()
  const { user } = useUser();
  const { logout } = useAuth({ slug: companySlug });
  const { setCompanies } = useCompanies();

  const teamsFlag = useTeamsFlag()

  useOutsideClick(ref, onClose);

  const handleClickLogout = () => {
    localStorage.clear();
    localStorage.setItem('dataCompany', JSON.stringify({ slug: companySlug, isAdmin }));
    // setCompanies([])
    logout({ returnTo: `${window.location.origin}/logout-redirect` });
  };

  return (
    <div ref={ref} className={cs("drop-down-user", {['drop-down-user__teams']: teamsFlag === 'teams'})}>
      <div className="drop-down-user__header">
        <ClearIcon className="drop-down__close" onClick={onClose} />
        <div className="drop-down__header-name">Profile</div>
      </div>

      <div className="drop-down-user__item">
        <Link to={`/booking-history/${userStrId}`}>My booking history</Link>
      </div>

      <div className="drop-down-user__item">
        <Link to={`/profile/${userStrId}`}>Profile</Link>
      </div>

      {
        (isAdmin || isCompanyAdmin) && !isMobile &&
        <div className="drop-down-user__item">
          <Link to={`/${isAdmin ? 'root-' : ''}admin${isCompanyAdmin ? `/${user.companyStrId}` : ''}`}>
            Admin panel
          </Link>
        </div>
      }

      <div className="drop-down-user__item" onClick={handleClickLogout}>
        <span>Logout</span>
      </div>
    </div>
  );
};
