import React, { useEffect, useState } from 'react';

import Static from '../../containers/Static';

import getPrivacyHtml from '../../services/get-privacy-html';

export default function Privacy() {
  const [content, setContent] = useState('');

  useEffect(() => {
    getPrivacyHtml().then(setContent);
  }, []);

  return <Static data={content} />;
};
