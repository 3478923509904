export default (isAdmin, company) => {
  const companyStrId = company ? company.strId : ''
  const prefix = isAdmin ? 'root-admin' : `admin/${companyStrId}`;

  if (company && company.isVirtualOnly) {
    return [
      {
        name: 'Users',
        link: `/${prefix}/users`
      },
      {
        name: 'Virtual floors',
        link: `/${prefix}/virtual`
      },
    ]
  }

  return [
    {
      name: 'Companies',
      link: '/root-admin/companies',
      onlyAdmin: true
    },
    // {
    //   name: 'Admins',
    //   link: '/root-admin/admins',
    //   onlyAdmin: true
    // },
    {
      name: 'Users',
      link: `/${prefix}/users`
    },
    {
      name: 'Buildings',
      link: `/${prefix}/buildings`
    },
    {
      name: 'Floors',
      link: `/${prefix}/floors`
    },
    {
      name: 'Virtual floors',
      link: `/${prefix}/virtual`
    },
    {
      name: 'Cleaning',
      link: `/${prefix}/cleaning`
    },
    {
      name: 'Booking',
      link: `/${prefix}/booking`
    },
    {
      name: 'Domain white list',
      link: `/${prefix}/domains`
    }
  ];
};
