import React from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';

import Input from '../Input/Input';
import Button from '../Button/Button';

import { default as api } from '../../services/api';

const { createDemoCompany } = api
export default function DemoCompanyForm() {
  const history = useHistory();

  const onSubmit = (values) => {
    createDemoCompany({ ...values }).then(() => {
      history.push('/root-admin/companies');
    }).catch((e) => {
      console.log(e);
    });
  };

  const { handleSubmit, register } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-form">
        <div className="add-form__title">
          Created Demo Company
        </div>

        <Input
          inputRef={register}
          name="title"
          label="Name"
        />

        <Input
          inputRef={register}
          name="slug"
          label="Slug"
        />

        <Button type="submit">
          Create
        </Button>
      </div>
    </form>
  );
};
