export const getDefaultPoints = (width, height) => ({
  p1: {
    id: 'p1',
    cx: 0,
    cy: 0,
    child: {
      cx: 0,
      cy: 0
    }
  },
  p2: {
    id: 'p2',
    cx: width,
    cy: 0,
    child: {
      cx: width,
      cy: 0
    }
  },
  p3: {
    id: 'p3',
    cx: width,
    cy: height,
    child: {
      cx: width,
      cy: height
    }
  },
  p4: {
    id: 'p4',
    cx: 0,
    cy: height,
    child: {
      cx: 0,
      cy: height
    }
  }
});
