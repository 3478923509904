const START_HOURS = 8;
const END_HOURS = 22;

export default () => {
  let result = [];

  for (let i = START_HOURS; i <= END_HOURS; i++) {
    const h = i < 10 ? `0${i}` : i;
    const isLast = i === END_HOURS;

    result.push({ h, m: '00' });
    !isLast && result.push({ h, m: 30 });
  }

  return result;
};
