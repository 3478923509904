import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

import Select from '../Select/Select';
import Button from '../Button/Button';

import { default as api } from '../../services/api';
import useUser from '../../hooks/useUser';
import useCompanies from '../../hooks/useCompanies';

const {
  deleteFloor,
  fetchFloorsByBuilding,
  fetchBuildingsByCompany,
  getImgUrl,
} = api

const NAMES = [
  'FloorPlanImage', 'id', 'Number', 'Name', 'Description',
  'Width in px', 'Height in px', '', ''
];

function AdminFloors() {
  const { companies } = useCompanies();
  const { user, isAdmin, isCompanyAdmin } = useUser();

  const savedCompany = JSON.parse(localStorage.getItem('currentCompany'));
  const defaultCompany = savedCompany || companies[0];

  const [floors, setFloors] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [currentBuilding, setCurrentBuilding] = useState({});
  const [currentCompany, setCurrentCompany] = useState(defaultCompany);

  const getTypeLabel = (type) => type.name;
  const getTypeLabelCompany = (type) => type.title;
  const getTypeValue = (type) => type.strId;
  const getFloorsByBuilding = (id) => fetchFloorsByBuilding(id).then(setFloors);

  const handleChange = (option) => {
    setCurrentBuilding(option);
    getFloorsByBuilding(option.strId);
  };

  const getBuildingsByCompany = (id) => fetchBuildingsByCompany(id).then((res) => {
    if (res.length) {
      setBuildings(res);
      setCurrentBuilding(res[0]);
      getFloorsByBuilding(res[0].strId);
    }
  });

  useEffect(() => {
    if (currentCompany) {
      getBuildingsByCompany(currentCompany.strId);
      localStorage.setItem('currentCompany', JSON.stringify(currentCompany));
    }
  }, [currentCompany]);

  useEffect(() => {
    if (isCompanyAdmin) {
      const userCompany = companies.find((item) => item.strId === user.companyStrId);
      setCurrentCompany(userCompany);
    }
  }, [isCompanyAdmin, companies]);

  const link = `/${isAdmin ? 'root-' : ''}admin${isCompanyAdmin ? `/${currentCompany?.strId}` : ''}/floors/add/${currentBuilding?.strId}`;

  return (
    <div>
      {
        !isCompanyAdmin &&
        <Select
          label="Company"
          options={companies}
          value={currentCompany}
          getOptionLabel={getTypeLabelCompany}
          getOptionValue={getTypeValue}
          onChange={setCurrentCompany}
        />
      }

      <Select
        label="Building"
        options={buildings}
        value={currentBuilding}
        getOptionLabel={getTypeLabel}
        getOptionValue={getTypeValue}
        onChange={handleChange}
      />

      <div className="adm-buttons">
        <Link to={link}>
          <Button>
            Create new floor
          </Button>
        </Link>
      </div>

      <table className="table">
        <thead>
          { renderHeader() }
        </thead>
        <tbody>
          { renderFloors(floors, setFloors, currentCompany?.strId, isAdmin) }
        </tbody>
      </table>
    </div>
  );
}

const renderHeader = () => {
  return (
    <tr>
      { NAMES.map((item, index) => <th key={index}>{item}</th>) }
    </tr>
  );
};

const renderFloors = (floors, setFloors, companyStrId, isAdmin) => {
  return (
    floors.map((item, index) => {
      const { number, title, desc, floorPlanImg, strId, width, height } = item;
      const newFloors = cloneDeep(floors);

      const handleDelete = () => {
        deleteFloor(strId);
        newFloors.splice(index, 1);

        setFloors(newFloors);
      };

      return (
        <tr key={index}>
          <td>
            <Link to={`/floor/${strId}`}>
              <div className="adm_floor-img-canv">
              {floorPlanImg ?
                <img className="adm_floor-img" src={getImgUrl(floorPlanImg)} alt="floor-plan"/>
                : 'No image'
              }
              </div>
            </Link>
          </td>

          <td>{strId}</td>
          <td>{number}</td>

          <td>
            <Link
              className="adm__link"
              to={`/${isAdmin ? 'root-' : ''}admin/${companyStrId}/floors/${strId}/desks`}
            >
              {title}
            </Link>
          </td>

          <td>{desc}</td>
          <td>{width}</td>
          <td>{height}</td>

          <td>
            <Link to={`/${isAdmin ? 'root-' : ''}admin${isAdmin ? '' : `/${companyStrId}`}/floors/edit/${strId}`}>
              <Button type="edit">
                Edit
              </Button>
            </Link>
          </td>

          <td>
            <Button type="delete" onClick={handleDelete}>Delete</Button>
          </td>
        </tr>
      );
    })
  );
};

export default AdminFloors;
