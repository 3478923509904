import { useEffect } from 'react';

export default function useOutsideClick(ref, cb, isMobile) {
  useEffect(() => {
    if (isMobile) {
      return;
    }

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, isMobile, cb]);
};
