import getShortNameDays from './get-short-name-days';
import moment from 'moment';

export default (date, isWeek) => {
  const { repeatCount, repeatTimeSlice, repeatUntilCount, repeatUntil } = date;
  const repeatShortNameDays = getShortNameDays(date);
  const repeatDays = isWeek ? repeatShortNameDays : `${repeatTimeSlice}${repeatCount === 1 ? '' : 's'}`;
  const repeatEnd = repeatUntil ?
                    `to ${moment(repeatUntil).format('MMMM DD yyyy')}` :
                    repeatUntilCount ?
                    `to ${repeatUntilCount} occurence${repeatUntilCount > 1 ? 's' : ''}` :
                    '';
  const repeatStart = repeatCount === 1 ? '' : repeatCount === 2 ? 'nd' : repeatCount === 3 ? 'rd' : 'th';

  return `Every${repeatCount === 1 ? '' : ' '+repeatCount+repeatStart} ${repeatDays} ${repeatEnd}`;
};
