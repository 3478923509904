import React, { useEffect, useState, useMemo } from 'react';
import useUser from '../../hooks/useUser';
import {
  // Redirect,
  useParams
} from 'react-router-dom';

import { useForm } from 'react-hook-form';

import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import Header from '../../components/Header/Header';
import UploadFile from '../../components/UploadFile/UploadFile';

import { statuses } from '../../services/lists-contact';

import { default as api } from '../../services/api';

import './Profile.css'

const {
  fetchContact,
  putContact,
  getImgUrl,
} = api
const DEFAULT_STATUS = 'available';

export default function Profile() {
  const { id } = useParams();
  const { user, setUser } = useUser();
  // const [user, setUser] = useState(null);
  const [img, setImg] = useState('');
  const [status, setStatus] = useState(DEFAULT_STATUS);
  const [err, setErr] = useState('')
  const [savedFlag, setSavedFlag] = useState(false)

  const defaultValues = useMemo(() => {
    return user ? { ...user } : {}
  }, [user])


  const { handleSubmit, register, reset } = useForm({defaultValues: {
      defaultValues
    }});

  useEffect(() => {
    if (!id) {
      return;
    }

    fetchContact(id)
    .then((res) => {
      setUser(res);
      if (res && res.img) {
        setImg(getImgUrl(res.img))
      }
      if (res && res.status) {
        setStatus(res.status)
      }
      reset({ ...res })
    })
  }, [id]);


  const onSubmit = async (values) => {
    setSavedFlag(false)

    const newUser = {
      ...values,
      strId: user.strId,
      status,
      img
    };

    try {
      await putContact(newUser).then(data => setUser(data))
      setSavedFlag(true)
      setTimeout(() => setSavedFlag(false), 3000)
    } catch(e) {
      setErr(e.toString())
    }
  }

  return (
    <div className="b-profile">
      <Header
        isBack
        title={`Profile`}
        rightTitle={`${user?.name || ''}`}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="add-form">
          <div className="add-form__title add-form__title__with-header">
            Edit profile
          </div>

          <div className="b-profile_properties">
            {/*<div>{user?.name}</div>*/}
            <div>{user?.email}</div>
          </div>

          <Input
            inputRef={register}
            name="name"
            label="Name"
          />

          <UploadFile
            src={img}
            title="Selected avatar"
            onLoad={setImg}
          />

          <Input
            inputRef={register}
            name="position"
            label="Position"
          />

          <Select
            label="Status"
            options={statuses}
            onChange={(option) => setStatus(option.value)}
            value={{ value: status, label: status }}
          />

          <Input
            inputRef={register}
            name="message"
            label="Status message"
          />

          {/* <Input
            inputRef={register}
            name="phone"
            label="Phone"
          /> */}

          <Input
            inputRef={register}
            name="callLink"
            label="Call link"
          />

          <Input
            inputRef={register}
            name="chatLink"
            label="Chat link"
          />

          <Input
            inputRef={register}
            name="calendarLink"
            label="Calendar link"
          />

          <div className="b-profile_buttons">
            <Button type="submit">
              Save
            </Button>
            {savedFlag && <span className="b-profile_saved">Saved</span>}
          </div>

          {err && <div>{err}</div>}

        </div>
      </form>
    </div>
  )
}
