import { useState, useEffect } from 'react'


function isMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return true
  }

  if (/android/i.test(userAgent)) {
      return true
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true
  }
  return false
}


export function useMobile() {
  let defBool = isMobileOperatingSystem()  
  const [isMobile, setIsMobile] = useState(defBool)

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth < 700) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  return isMobile
}
