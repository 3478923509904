import React from 'react';
import ReactDOM from 'react-dom';
// import { Auth0Provider } from '@auth0/auth0-react';

import * as serviceWorker from './serviceWorker';

import App from './App';
import { ContextUserProvider } from './context/user';
import { ContextCompaniesProvider } from './context/companies';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

// import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from './constants/auth0';

ReactDOM.render(
  <ErrorBoundary>
    <ContextUserProvider>
      <ContextCompaniesProvider>
        <App />
      </ContextCompaniesProvider>
    </ContextUserProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/*
  Unused context

  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    redirectUri={`${window.location.origin}/login`}
    audience={`https://${AUTH0_DOMAIN}/api/v2/`}
    scope="read:current_user update:current_user_metadata"
  >
  </Auth0Provider>

*/
