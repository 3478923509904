import superagent from 'superagent';
import io from 'socket.io-client';

import { PERMANENT } from '../constants/deskStatuses';
import { BOOKABLE } from '../constants/meetingStatuses';

import { API_URL } from '../config'

const tokenFromStorage = () => localStorage.getItem('token');

function getStorageToken() {
  const token = localStorage.getItem('token')
  if (!token) {
    console.log('@Token not found')
  }
  return token
}

function unAuthHandler(err) {
  if (localStorage.getItem('token')) {
    localStorage.removeItem('token')
    localStorage.setItem('authAction', 'refresh')
    localStorage.setItem('returnPath', window.location.pathname)
    window.location.pathname = `/auth/action`
  } else {
    if (localStorage.getItem('returnTo') && !localStorage.getItem('withoutToken')) {
      localStorage.setItem('withoutToken', 'true')
      window.location.href = `${window.location.origin}${localStorage.getItem('returnTo')}`
      return
    }
    localStorage.clear()
    window.location.href = `${window.location.origin}/signin`
  }

  return {}
}

function wihAuth(fn) {
  return async (...args) => {
    return fn(...args, getStorageToken())
      .then(res => res)
      .catch(err => {
        if (err.status === 401) {
          return unAuthHandler(err)
        } else if (err.status === 406) {
          localStorage.setItem('returnTo', '/not-company-profile')
          localStorage.setItem('authAction', 'logout')
          throw err
        }
        return {}
      })
  }
}

export function createSocket() {
  if (API_URL) {
    return io(API_URL)
  }
  return io()
}

export function getImgUrl(url) {
  if (url && url.indexOf('http:') === -1 && url.indexOf('https:') === -1) {
    return API_URL + url
  }
  return url
}

export async function fetchPalette() {
  return Promise.resolve([
    {
      type: 'desk',
      id: 'desk-1',
      title: 'Desk',
      status: PERMANENT,
      width: 70,
      height: 84,
      img: '/demo-assets/desk-2.png',
    },
    {
      type: 'desk',
      id: 'desk-2',
      title: 'Corner desk',
      status: PERMANENT,
      width: 87,
      height: 87,
      img: '/demo-assets/desk-corner-no-shadow.png',
    },
    {
      type: 'meeting',
      id: 'meeting-1',
      title: 'Meeting room',
      status: BOOKABLE,
      width: 168,
      height: 140,
      img: '/demo-assets/table.png',
    }
  ])
}

export async function uploadFile(file, onProgress) {
  const resp = await superagent.post(`${API_URL}/api/file`)
    .attach('file', file)
    .on('progress', onProgress)
    .catch(e => {
      console.log(e);
    });

  return resp.body;
}

/* Start profile */

export async function fetchProfileByToken(token = getStorageToken(), companyStrId) {
  if (token === companyStrId) {
    companyStrId = null
  }
  const resp = await superagent
    .get(`${API_URL}/api/me${companyStrId ? `?companyStrId=${companyStrId}` : ''}`)
    .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

export async function fetchVirtualFloorsByToken(token = tokenFromStorage()) {
  const resp = await superagent
    .get(`${API_URL}/api/my-virtual-floors`)
    .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

/* End profile */

/* Start companies */

export async function fetchCompanies(token = tokenFromStorage()) {
  const resp = await superagent.get(`${API_URL}/api/companies/`)
    .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

export async function fetchCompanyByStrId(strId, token = tokenFromStorage()) {
  const resp = await superagent.get(`${API_URL}/api/companies/${ strId }`)
    .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

export async function fetchCompanyExcludedDomainsByStrId(companyStrId, token = tokenFromStorage()) {
  const resp = await superagent.get(`${API_URL}/api/companies/domains/${ companyStrId }`)
    .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

export async function createCompanyExcludedDomainsByStrId(companyStrId, payload, token = tokenFromStorage()) {
  const resp = await superagent.post(`${API_URL}/api/companies/domains/${ companyStrId }`)
    .set('Authorization', `Bearer ${token}`)
    .send(payload)
  return resp.body;
}

export async function deleteCompanyExcludedDomainsByStrId(companyStrId, payload, token = tokenFromStorage()) {
  const resp = await superagent.delete(`${API_URL}/api/companies/domains/${ companyStrId }`)
    .set('Authorization', `Bearer ${token}`)
    .send(payload)
  return resp.body;
}

export async function fetchCompanyDesksAndMeetings(id, token = tokenFromStorage()) {
  const resp = await superagent.get(`${API_URL}/api/companies/desks-and-meetings/${ id }`)
    .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

export async function fetchCompanyBySlug(slug, token = tokenFromStorage()) {
  const resp = await superagent.get(`${API_URL}/api/companies/by-slug/${ slug }`)
    .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

export async function isCompanyExists(slug) {
  const resp = await superagent.get(`${API_URL}/api/companies/exist/by-slug/${ slug }`);
  return resp.body;
}

export async function putCompany(company, token = tokenFromStorage()) {
  const resp = await superagent.put(`${API_URL}/api/companies/${ company.strId }`).send(company)
    .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

export async function createDemoCompany(company) {
  const resp = await superagent.post(`${API_URL}/api/companies/create-demo`).send(company);
  return resp.body;
}

export async function postCompany(company) {
  const resp = await superagent.post(`${API_URL}/api/companies/`).send(company);
  return resp.body;
}

export async function postCompanyNew(company) {
  const resp = await superagent.post(`${API_URL}/api/companies/create-with-users`).send(company);
  return resp.body;
}

export async function deleteCompany(id, token = tokenFromStorage()) {
  const resp = await superagent.delete(`${API_URL}/api/companies/${ id }`)
    .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

export async function sendEmail(email) {
    try {
      const resp = await superagent.get(`${API_URL}/api/notify-email-company/${email}`);
      return resp.body
    } catch(e) {
      return e.name
    }
}

/* End companies */

/* Start contacts */

export async function contactsPostCheckAvailability(email) {
  const resp = await superagent.post(`${API_URL}/api/contacts/check-availability`)
  .send({ email })
  return resp.body
}

export async function fetchUsersRoleByFloor(id, role) {
  const resp = await superagent.get(`${API_URL}/api/floor-contacts/contacts/for-floor/${ id }?role=${role}`);
  return resp.body;
}

export async function fetchRolesByContact(id, token = tokenFromStorage()) {
  const resp = await superagent.get(`${API_URL}/api/contact-roles/${ id }`)
  .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

export async function putRolesContact(id, roles, token = tokenFromStorage()) {
  const resp = await superagent.put(`${API_URL}/api/contact-roles/${ id }`)
    .send({ roles })
    .set('Authorization', `Bearer ${token}`);

  return resp.body;
}

export async function deleteRolesContact(id, roles, token = tokenFromStorage()) {
  const resp = await superagent.delete(`${API_URL}/api/contact-roles/${ id }`)
    .send({ roles })
    .set('Authorization', `Bearer ${token}`);

  return resp.body;
}

export async function fetchContact(id, token = tokenFromStorage()) {
  const resp = await superagent.get(`${API_URL}/api/contacts/${ id }`)
    .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

export async function fetchDemoContactsByCompany(id) {
  const resp = await superagent.get(`${API_URL}/api/contacts/demo/by-company/${ id }`);
  return resp.body;
}

export async function fetchFloorsByCompany(id) {
  const resp = await superagent.get(`${API_URL}/api/floors/by-company/${ id }`);
  return resp.body;
}

export async function fetchContactsByCompany(id) {
  const resp = await superagent.get(`${API_URL}/api/contacts/by-company/${ id }`);
  return resp.body;
}

export async function fetchContactsWithoutCompany(token = tokenFromStorage()) {
  const resp = await superagent.get(`${API_URL}/api/contacts/without-company`)
    .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

export async function fetchContactByStrId(strId, token = tokenFromStorage()) {
  const resp = await superagent.get(`${API_URL}/api/contacts/${ strId }`)
    .set('Authorization', `Bearer ${token}`);

  return resp.body;
}

export async function fetchContactLocationByStrId(strId, token = tokenFromStorage()) {
  const resp = await superagent.get(`${API_URL}/api/contacts/${ strId }/location`)
    .set('Authorization', `Bearer ${token}`);

  return resp.body;
}

export async function putContact(contact, token = tokenFromStorage()) {
  const resp = await superagent.put(`${API_URL}/api/contacts/${ contact.strId }`).send(contact)
    .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

export async function postContact(contact) {
  const resp = await superagent.post(`${API_URL}/api/contacts/`).send(contact);
  return resp.body;
}

export async function deleteContact(id, token = tokenFromStorage()) {
  const resp = await superagent.delete(`${API_URL}/api/contacts/${ id }`)
    .set('Authorization', `Bearer ${token}`);
  return resp.body;
}

export async function putFloorsForJanitor(id, floorIds) {
  let resp = [];

  floorIds.forEach(async (item) => {
    const { body } = await superagent.put(`${API_URL}/api/floor-contacts/janitor/floor/${ id }/${ item }`);
    resp.push(body);
  });

  return resp;
}

export async function deleteFloorsForJanitor(id, floorIds) {
  let resp = [];

  floorIds.forEach(async (item) => {
    const { body } = await superagent.delete(`${API_URL}/api/floor-contacts/janitor/floor/${ id }/${ item }`);
    resp.push(body);
  });

  return resp;
}

/* End contacts */

/* Start buildings */

export async function fetchBuildings() {
  const resp = await superagent.get(`${API_URL}/api/buildings/`);
  return resp.body;
}

export async function fetchBuilding(buildingId) {
  const resp = await superagent.get(`${API_URL}/api/buildings/${buildingId}`);
  return resp.body;
}

export async function fetchBuildingsByCompany(id) {
  const resp = await superagent.get(`${API_URL}/api/buildings/by-company/${ id }`);
  return resp.body;
}

export async function putBuilding(building) {
  const resp = await superagent.put(`${API_URL}/api/buildings/${ building.strId }`).send(building);
  return resp.body;
}

export async function postBuilding(building) {
  const resp = await superagent.post(`${API_URL}/api/buildings/`).send(building);
  return resp.body;
}

export async function deleteBuilding(id) {
  const resp = await superagent.delete(`${API_URL}/api/buildings/${ id }`);
  return resp.body;
}

/* End buildings */

/* Start virtualFloors */

export async function fetchVirtualFloorById(id) {
  const resp = await superagent.get(`${API_URL}/api/virtual-floors/${ id }`);
  return resp.body;
}

export async function fetchVirtualFloorsByCompany(id) {
  const resp = await superagent.get(`${API_URL}/api/virtual-floors/by-company/${ id }`);
  return resp.body;
}

export async function putVirtualFloor(virtualFloor) {
  const resp = await superagent.put(`${API_URL}/api/virtual-floors/${ virtualFloor.strId }`).send(virtualFloor);
  return resp.body;
}

export async function postVirtualFloor(virtualFloor) {
  const resp = await superagent.post(`${API_URL}/api/virtual-floors/`).send(virtualFloor);
  return resp.body;
}

export async function deleteVirtualFloor(id) {
  const resp = await superagent.delete(`${API_URL}/api/virtual-floors/${ id }`);
  return resp.body;
}

/* End virtualFloors */

/* Start floors */

export async function fetchFloorsByContact(id) {
  if (id) {
    const resp = await superagent.get(`${API_URL}/api/floor-contacts/floors/for-contact/${ id }`)
    .set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return resp.body;
  }

  return null;
}

export async function fetchFloors() {
  const resp = await superagent.get(`${API_URL}/api/floors/`);
  return resp.body;
}

export async function fetchFloor(floorId, token = tokenFromStorage()) {
  const resp = await superagent.get(`${API_URL}/api/floors/${floorId}`)
    .set('Authorization', `Bearer ${token}`)
  return resp.body;
}

export async function fetchFloorsByBuilding(id) {
  const resp = await superagent.get(`${API_URL}/api/floors/by-building/${ id }`);
  return resp.body;
}

export async function putFloor(floor) {
  const resp = await superagent.put(`${API_URL}/api/floors/${ floor.strId }`).send(floor);
  return resp.body;
}

export async function postFloor(floor) {
  const resp = await superagent.post(`${API_URL}/api/floors/`).send(floor);
  return resp.body;
}

export async function deleteFloor(id) {
  const resp = await superagent.delete(`${API_URL}/api/floors/${ id }`);
  return resp.body;
}

/* End floors */

/* Start desks */

export async function fetchDesks() {
  const resp = await superagent.get(`${API_URL}/api/desks`);
  return resp.body;
}

export async function fetchDesksForFloor(id) {
  const resp = await superagent.get(`${API_URL}/api/desks/for-floor/${ id }`);
  return resp.body;
}

export async function fetchDesk(id) {
  const resp = await superagent.get(`${API_URL}/api/desks/${ id }`);
  return resp.body;
}

export async function putDesk(desk) {
  const resp = await superagent.put(`${API_URL}/api/desks/${ desk.strId }`).send(desk);
  return resp.body;
}

export async function updateDesk(deskId, updateWith) {
  const resp = await superagent.put(`${API_URL}/api/desks/${deskId}`).send(updateWith);
  return resp.body;
}

export async function postDesk(desk) {
  const resp = await superagent.post(`${API_URL}/api/desks/`).send(desk);
  return resp.body;
}

export async function deleteDesk(id) {
  const resp = await superagent.delete(`${API_URL}/api/desks/${ id }`);
  return resp.body;
}

/* End desks */

/* Start meetingRooms */

export async function fetchMeetingsForFloor(id) {
  const resp = await superagent.get(`${API_URL}/api/meeting-rooms/for-floor/${ id }`);
  return resp.body;
}

export async function fetchMeetings() {
  const resp = await superagent.get(`${API_URL}/api/meeting-rooms`);
  return resp.body;
}

export async function fetchMeeting(id) {
  const resp = await superagent.get(`${API_URL}/api/meeting-rooms/${ id }`);
  return resp.body;
}

export async function putMeeting(meeting) {
  const resp = await superagent.put(`${API_URL}/api/meeting-rooms/${meeting.strId}`).send(meeting);
  return resp.body;
}

export async function updateMeeting(meetingId, updateWith) {
  const resp = await superagent.put(`${API_URL}/api/meeting-rooms/${meetingId}`).send({
    ...updateWith,
    strId: meetingId,
  });
  return resp.body;
}

export async function postMeeting(meeting) {
  const resp = await superagent.post(`${API_URL}/api/meeting-rooms/`).send(meeting);
  return resp.body;
}

export async function deleteMeeting(id) {
  const resp = await superagent.delete(`${API_URL}/api/meeting-rooms/${ id }`);
  return resp.body;
}

/* End meeting */

/* Start booking */

export async function checkInBooking(id) {
  const resp = await superagent.post(`${API_URL}/api/booking/checkin/${id}`);
  return resp.body;
}

export async function delDemoBooking(contactStrId) {
  const resp = await superagent.post(`${API_URL}/api/booking/demo/clear-all-booking/${contactStrId}`);
  return resp.body;
}

export async function fetchBooking() {
  const resp = await superagent.get(`${API_URL}/api/booking`);
  return resp.body;
}

export async function fetchBookingByContact(id, token = tokenFromStorage()) {
  const resp = await superagent.get(`${API_URL}/api/booking/by-contact/${ id }`)
    .set('Authorization', `Bearer ${token}`);

  return resp.body;
}

export async function fetchBookingForFloor(id, date) {
  const resp = await superagent.get(`${API_URL}/api/booking/for-floor/${id}${date ? `?date=${date}` : ''}`);
  return resp.body;
}

export async function postBooking(data) {
  const resp = await superagent.post(`${API_URL}/api/booking`).send(data);
  return resp.body;
}

export async function deleteBooking(id) {
  const resp = await superagent.delete(`${API_URL}/api/booking/${ id }`);
  return resp.body;
}

/* End booking */

/* Start recurrentBooking */

export async function fetchRecurrentBooking() {
  const resp = await superagent.get(`${API_URL}/api/recurrent-booking`);
  return resp.body;
}

export async function fetchRecurrentBookingByContact(id, token = tokenFromStorage) {
  const resp = await superagent.get(`${API_URL}/api/recurrent-booking/by-contact/${ id }`)
    .set('Authorization', `Bearer ${token}`);

  return resp.body;
}

export async function fetchRecurrentBookingForFloor(id) {
  const resp = await superagent.get(`${API_URL}/api/recurrent-booking/for-floor/${ id }`);
  return resp.body;
}

export async function postRecurrentBooking(data) {
  const resp = await superagent.post(`${API_URL}/api/recurrent-booking`).send(data);
  return resp.body;
}

export async function fetchRecurrentBookingConflicts(id) {
  const resp = await superagent.get(`${API_URL}/api/recurrent-booking/conflicts/${ id }`);
  return resp.body;
}

export async function postRecurrentBookingConflicts(data) {
  const resp = await superagent.post(`${API_URL}/api/recurrent-booking/conflicts`).send(data);
  return resp.body;
}

export async function fetchRecurrentBookingIntervals(id) {
  const resp = await superagent.get(`${API_URL}/api/recurrent-booking/intervals/${ id }`);
  return resp.body;
}

export async function deleteRecurrentBooking(id) {
  const resp = await superagent.delete(`${API_URL}/api/recurrent-booking/${ id }`);
  return resp.body;
}

/* End recurrentBooking */


export async function fetchDeskByQRCode(companySlug, qrId) {
  const resp = await superagent.get(`${API_URL}/api/qr/${companySlug}/${qrId}`)
  return resp.body;
}


export async function postMailchimpIvintedList(strId) {
  const resp = await superagent.post(`${API_URL}/api/send-user-mailchimp/`)
    .send({ strId })
  return resp.body;
}

export default {
  /** tech */
  createSocket,
  getImgUrl,
  fetchPalette,
  uploadFile,
  sendEmail,
  postMailchimpIvintedList,

  /** Contacts */
  fetchProfileByToken: wihAuth(fetchProfileByToken),
  fetchRolesByContact: wihAuth(fetchRolesByContact),
  putRolesContact: wihAuth(putRolesContact),
  deleteRolesContact: wihAuth(deleteRolesContact),
  fetchContact: wihAuth(fetchContact),
  putContact: wihAuth(putContact),
  deleteContact: wihAuth(deleteContact),
  contactsPostCheckAvailability,
  postContact,

  /** Companies */
  fetchCompanies: wihAuth(fetchCompanies),
  fetchCompanyByStrId: wihAuth(fetchCompanyByStrId),
  fetchCompanyExcludedDomainsByStrId: wihAuth(fetchCompanyExcludedDomainsByStrId),
  createCompanyExcludedDomainsByStrId: wihAuth(createCompanyExcludedDomainsByStrId),
  deleteCompanyExcludedDomainsByStrId: wihAuth(deleteCompanyExcludedDomainsByStrId),
  fetchCompanyDesksAndMeetings: wihAuth(fetchCompanyDesksAndMeetings),
  fetchCompanyBySlug: wihAuth(fetchCompanyBySlug),
  putCompany: wihAuth(putCompany),
  deleteCompany: wihAuth(deleteCompany),
  isCompanyExists,
  createDemoCompany,
  postCompany,
  postCompanyNew,
  fetchFloorsByCompany,
  fetchDemoContactsByCompany,
  fetchContactsByCompany,
  fetchContactsWithoutCompany: wihAuth(fetchContactsWithoutCompany),
  fetchContactByStrId,
  fetchContactLocationByStrId,

  /** buildings */
  fetchBuilding,
  fetchBuildings,
  fetchBuildingsByCompany,
  putBuilding,
  postBuilding,
  deleteBuilding,

  /** Virtual Floors */
  fetchUsersRoleByFloor,
  putFloorsForJanitor,
  deleteFloorsForJanitor,
  putVirtualFloor,
  postVirtualFloor,
  deleteVirtualFloor,
  fetchVirtualFloorById,
  fetchVirtualFloorsByCompany,
  fetchFloors,
  fetchFloor: wihAuth(fetchFloor),
  fetchFloorsByBuilding,
  putFloor,
  postFloor,
  deleteFloor,
  fetchFloorsByContact: wihAuth(fetchFloorsByContact),
  fetchVirtualFloorsByToken: wihAuth(fetchVirtualFloorsByToken),

  /** Desks */
  fetchDesks,
  fetchDesksForFloor,
  fetchDesk,
  putDesk,
  updateDesk,
  postDesk,
  deleteDesk,

  /** Meetings */
  fetchMeetingsForFloor,
  fetchMeetings,
  fetchMeeting,
  putMeeting,
  updateMeeting,
  postMeeting,
  deleteMeeting,

  /** Booking */
  fetchRecurrentBookingConflicts,
  fetchRecurrentBookingIntervals,
  fetchRecurrentBookingForFloor,
  checkInBooking,
  delDemoBooking,
  fetchBooking,
  fetchBookingForFloor,
  postBooking,
  deleteBooking,
  fetchRecurrentBooking,
  deleteRecurrentBooking,
  fetchDeskByQRCode,
  postRecurrentBooking,
  fetchRecurrentBookingByContact: wihAuth(fetchRecurrentBookingByContact),
  fetchBookingByContact: wihAuth(fetchBookingByContact),

}
