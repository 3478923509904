import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Start from './pages/Start/Start';
import Login from './pages/Login/Login';
import Floor from './pages/Floor/Floor';
import Terms from './pages/Terms/Terms';
import Admin from './pages/Admin/Admin';
import Janitor from './pages/Janitor/Janitor';
import Virtual from './pages/Virtual/Virtual';
import VirtualReadOnly from './pages/VirtualReadOnly/VirtualReadOnly';
import Privacy from './pages/Privacy/Privacy';
import RootAdmin from './pages/RootAdmin/RootAdmin';
import LoginRedirect from './pages/LoginRedirect/LoginRedirect';
import LoginTeamsRedirect from './pages/LoginTeamsRedirect/LoginTeamsRedirect'
import QRCodeRedirect from './pages/QRCodeRedirect/QRCodeRedirect';
import LogoutRedirect from './pages/LogoutRedirect/LogoutRedirect';
import BookingHistory from './pages/BookingHistory/BookingHistory';
import NotCompanyProfile from './pages/NotCompanyProfile/NotCompanyProfile';
import Profile from './pages/Profile/Profile';
import AdminAdmins from './components/AdminAdmins/AdminAdmins';

import StartPage from './pages/StartPage/StartPage';
import StartPageConfigure from './pages/StartPageConfigure/StartPage';

import Forgot from './pages/Forgot/Forgot';
import SignUp from './pages/SignUp/SignUp';
import SignIn from './pages/SignIn/SignIn';
import SignInTeams from './pages/SignInTeams/SignInTeams';

import CreateCompany from './pages/CreateCompany/CreateCompany'

import ConfigureCompany from './pages/ConfigureCompany/ConfigureCompany'
import ConfigureVirtualFloor from './pages/ConfigureVirtualFloor/StartPage'

import getAdminRoutes from './services/get-admin-routes';
import AuthLoader from './components/AuthLoader/AuthLoader';

const routes = [
  ...getAdminRoutes('root-admin', ['companyId']),
  ...getAdminRoutes('root-admin'),

  {
    path: '/root-admin/admins',
    component: AdminAdmins
  },
  {
    path: '/root-admin',
    component: RootAdmin
  },

  ...getAdminRoutes('admin', ['companyId']),

  {
    path: '/admin/:companyId',
    component: Admin
  },

  {
    path: '/not-company-profile',
    component: NotCompanyProfile
  },

  {
    path: '/floor/:floorId',
    component: Floor
  },

  {
    path: '/virtual/:virtualId',
    component: Virtual
  },

  {
    path: '/virtual-read-only/:virtualId',
    component: VirtualReadOnly
  },

  {
    path: '/janitor',
    component: Janitor
  },

  {
    path: '/booking-history/:id',
    component: BookingHistory
  },

  {
    path: '/profile/:id',
    component: Profile,
  },

  {
    path: '/login/:slug',
    component: Login,
    demoComponent: Start
  },
  {
    path: '/login',
    component: LoginRedirect
  },

  {
    path: '/login-teams',
    component: LoginTeamsRedirect
  },

  {
    path: '/logout-redirect',
    component: LogoutRedirect
  },

  {
    path: '/privacy',
    component: Privacy
  },

  {
    path: '/terms-of-service',
    component: Terms
  },

  {
    path: '/scan-qr/:companySlug/:qrCode',
    component: QRCodeRedirect
  },

  {
    path: '/:companySlug/scan-qr/:qrCode',
    component: QRCodeRedirect
  },

  {
    path: '/signup/company',
    component: CreateCompany,
  },

  {
    path: '/signup',
    component: SignUp,
  },

  {
    path: '/signin',
    component: SignIn,
  },

  {
    path: '/forgot',
    component: Forgot,
  },

  {
    path: '/signin-teams',
    component: SignInTeams,
  },

  {
    path: '/configure',
    component: StartPageConfigure,
  },

  {
    path: '/configure-company/:slug',
    component: ConfigureCompany,
  },

  {
    path: '/configure-virtual-floor/:slug',
    component: ConfigureVirtualFloor,
  },
  {
    path: '/auth/action',
    component: AuthLoader,
  },
  {
    path: '/',
    component: StartPage,
  }
];

function Routes() {
  return (
    <Switch>
      {routes.map((route, index) => <Route key={index} {...route} />)}
      <Redirect to="/login" />
    </Switch>
  );
}

export default Routes;
