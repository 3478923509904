import { useContext } from 'react';

import { default as api } from '../services/api';
import { ContextCompanies } from '../context/companies';

const { fetchCompanies, fetchCompanyByStrId } = api

export default function useCompanies() {
  const { companies, setCompanies } = useContext(ContextCompanies);

  const getCompanies = () => {
    fetchCompanies().then(setCompanies).catch(console.error);
  };

  const getCompanyByStrId = (strId) => {
    fetchCompanyByStrId(strId)
      .then((data) => {
        setCompanies([data])
      })
      .catch(console.error)
  }

  return {
    companies,
    getCompanies,
    getCompanyByStrId,
    setCompanies
  };
};
