import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router';

import Loader from '../../components/Loader/Loader';
import CompanyForm from '../../components/CreateCompany/CreateCompany';

import NavAdmin from '../../components/NavAdmin/NavAdmin';

// import { fetchCompanyByStrId } from '../../services/api';

const menu = [
  {
    name: 'Users',
    link: ``
  },
  {
    name: 'Virtual floors',
    link: ``
  },  
]

export default function CreateCompany() {
  const location = useLocation();

  return (
    <div className="adm">
      <NavAdmin
        isAdmin={false}
        active={location.pathname}
        isCompanyAdmin={true}
        menuArg={menu}
      />
        <div className="adm__content">
          <CompanyForm />
        </div>
    </div>
  );
};
