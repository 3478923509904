import React from 'react';
import { Redirect } from 'react-router';
import useAuth from '../../auth/useAuth'

export default function LoginRedirect() {
  const returnTo = localStorage.getItem('returnTo');
  let args = undefined
  if (localStorage.getItem('wkpgMode') === 'signin') {
    args = { overrideSlug: true }
  }
  /* need fix refresh */
  const { isLoading } = useAuth(args)

  if (isLoading) {
    return <div></div>
  }
  return <Redirect to={returnTo || '/login/demo'} />;
};
