import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Tabs from '../Tabs/Tabs';

import { TrashIcon } from '../../services/svg-icons';
import getNameRecurrentDate from '../../services/get-name-recurrent-date';
import { default as api } from '../../services/api';

import './admin-popup-booking.css';

const { deleteBooking, deleteRecurrentBooking } = api
const TABS = [
  { title: 'Booking' },
  { title: 'Recurrent booking' }
];

export default function AdminPopupBooking(props) {
  const { booking, onClose, contacts, desk, recurrentBooking, setMapBooking, setMapRecurrentBooking } = props;
  const { x, y } = desk;

  const [tabIndex, setTabIndex] = useState(0);
  const mapList = tabIndex ? recurrentBooking : booking;
  const list = mapList[desk.strId];

  const handleClose = ({ target }) => {
    if (target.className === 'admin-popup-booking') {
      onClose();
    }
  };

  const handleDelete = (strId) => {
    const deleteCb = tabIndex ? deleteRecurrentBooking : deleteBooking;
    const setBooking = tabIndex ? setMapRecurrentBooking : setMapBooking;
    const newList = list.filter((item) => item.strId !== strId);
    const isEmptyBooking = !newList.length;
    const newMap = {
      ...mapList,
      [desk.strId]: isEmptyBooking ? null : newList
    };

    setBooking(newMap);
    deleteCb(strId);
  };

  return (
    <div className="admin-popup-booking" onClick={handleClose}>
      <div className="admin-popup-booking__holder">
        <Tabs isLight tabs={TABS} active={tabIndex} setActive={setTabIndex} />

        {
          list ?
          list.map((item, index) => {
            const currentContact = contacts.find((contact) => contact.strId === item.ownerContactId);
            const { timeFrom, timeTo, strId, floorId, entityId, repeatTimeSlice } = item;
            const { name } = currentContact;
            const isWeek = repeatTimeSlice === 'week';
            const dayName = `${moment(timeFrom).format('DD MMMM YYYY')}`;
            const timeName = tabIndex ?
              `${getNameRecurrentDate(item, isWeek)}, ${moment(timeFrom).format('HH:mm')} - ${moment(timeTo).format('HH:mm')}` :
              `${moment(timeFrom).format('HH:mm')} - ${moment(timeTo).format('HH:mm')}`;
            const link = `/floor/${floorId}?x=${x}&y=${y}&deskId=${entityId}`;

            return (
              <div key={index} className="admin-popup-booking__item">
                <div className="admin-popup-booking__item-holder">
                  <Link to={link}>
                      <div className="admin-popup-booking__name">{name}</div>
                      {!tabIndex && <div className="admin-popup-booking__time">{dayName}</div>}
                      <div className="admin-popup-booking__time">{timeName}</div>
                  </Link>
                </div>
                {/* <EditIcon /> */}
                <TrashIcon onClick={() => handleDelete(strId)} />
              </div>
            );
          }) :
          <div className="admin-popup-booking__item-holder admin-popup-booking__item-holder_empty">
            <span>
              Not {tabIndex ? 'recurrent booking' : 'booking'} for desk
            </span>
          </div>
        }
      </div>
    </div>
  );
};
