import { findChild } from './dom-utils';
import listClassesForStatus from './list-classes-for-status';
import { getDefaultPoints } from './default-points-meeting';
import getPathFromPoints from './get-path-from-points';
import { getActiveBooking } from './booking'
import { HOTDESK, PERMANENT } from '../constants/deskStatuses';
import { BOOKABLE, NON_BOOKABLE } from '../constants/meetingStatuses';

import { default as api } from './api'

const { getImgUrl } = api
const createMeetingRoom = (el, item, date) => {
  const { width, height, points: savedPoints } = item;
  const xmlns = 'http://www.w3.org/2000/svg';
  let points = savedPoints ? JSON.parse(savedPoints) : getDefaultPoints(width, height);
  const svg = document.createElementNS(xmlns, 'svg');
  const g = document.createElementNS(xmlns, 'g');
  const path = document.createElementNS(xmlns, 'path');

  const drawPath = () => {
    path.setAttributeNS(null, 'd', getPathFromPoints(points));
    g.appendChild(path);
  }

  drawPath();

  svg.style.position = 'absolute';
  svg.style.top = 0;
  svg.style.left = 0;

  svg.setAttributeNS(null, 'id', 'svg');
  svg.setAttributeNS(null, 'width', width);
  svg.setAttributeNS(null, 'height', height);
  svg.setAttributeNS(null, 'viewBox', `0 0 ${width} ${height}`);

  svg.appendChild(g);
  el.appendChild(svg);

  const booking = item.booking || item.recurrentBooking
  if (getActiveBooking(booking, date)) {
    el.classList.add('b-map-item__booked_meeting')
  }
};

export function updateMapMeeting(el, { width, height }) {
  for (const child of el.childNodes) {
    if (child.tagName && child.tagName.toLowerCase() === 'svg') {
      const svg = child
      svg.setAttributeNS(null, 'width', width);
      svg.setAttributeNS(null, 'height', height);
      svg.setAttributeNS(null, 'viewBox', `0 0 ${width} ${height}`);

    } else if (child.classList.contains('b-map-item_texture')) {
      child.style.width = `${width}px`;
      child.style.height = `${height}px`;
    }
  }
}

const createPermanentContact = (contact) => {
  const { strId, img, status='online', rotation } = contact
  const el = document.createElement('DIV');
  if (strId) {
    el.setAttribute('class', `b-desk b-desk_${rotation || '0'} b-desk_${listClassesForStatus[status]}`);
    el.setAttribute('data-type', PERMANENT);
    el.setAttribute('data-str-id', strId);

    if (img) {
      const contactEl = document.createElement('DIV');
      contactEl.setAttribute('class', 'b-desk_contact');
      contactEl.style.backgroundImage = `url(${getImgUrl(img)})`;
      el.appendChild(contactEl);
    }

    // el.onclick = handleClick;
  } else {
    // el.setAttribute('class', 'b-map-item-contact_empty');
    // el.setAttribute('data-type', 'permanent');
  }

  return el;
};

const createHotDeskContact = (contact) => {
  const { img, strId, status='online', rotation } = contact;
  const el = document.createElement('DIV');
  const className = img ? 'b-desk' : 'b-hot-desk-contact';
  const classNameImage = img ? 'b-desk_contact' : 'b-hot-desk-contact__avatar';

  el.setAttribute('class', `${className} b-desk_${listClassesForStatus[status]}`);
  el.setAttribute('data-type', HOTDESK);
  el.setAttribute('data-str-id', strId);
  el.classList.add(`b-desk_${rotation || '0'}`);
  // el.onclick = handleClick;

  const contactEl = document.createElement('DIV');
  contactEl.setAttribute('class', classNameImage);
  img && (contactEl.style.backgroundImage = `url(${getImgUrl(img)})`);

  if (!strId) {
    contactEl.style.width = '36px';
    contactEl.style.height = '36px';
  }

  el.appendChild(contactEl);

  return el;
};

function getClassName(item) {
  const paletteClassName = item.paletteItemId ? `b-map-item__type-${item.paletteItemId}` : ''
  return `b-map-item b-map-item__${item.status} ${paletteClassName}`
}

function common(el, item) {
  el.setAttribute('class', getClassName(item));
  el.setAttribute('data-desk-status', item.status);
  el.setAttribute('data-rotation', item.rotation);
}

function commonTexture(bgEl, item) {
  // bgEl.style.backgroundImage = `url(${img})`;
  bgEl.style.transform = `rotateZ(${item.rotation}deg) `; // scale(${scale})
}

export function createMapItem(item, contact, date) {
  const { strId, x, y, width, height, rotation, status } = item;

  const isPermanent = status === PERMANENT;
  const isHotdesk = status === HOTDESK;
  const isMeeting = status === BOOKABLE || status === NON_BOOKABLE;
  const deskContact = contact || {};
  const el = document.createElement('DIV');
  let otherEl;

  el.setAttribute('id', `map-el-${strId}`)
  el.setAttribute('data-type', 'map-item');
  el.setAttribute('data-str-id', strId);

  common(el, item)

  el.style.left = `${x}px`;
  el.style.top = `${y}px`;

  const bgEl = document.createElement('DIV');
  bgEl.setAttribute('class', 'b-map-item_texture');
  bgEl.style.width = `${width}px`;
  bgEl.style.height = `${height}px`;

  commonTexture(bgEl, item)

  el.appendChild(bgEl)

  if (isPermanent) {
    otherEl = createPermanentContact({ ...deskContact, rotation });
  }

  if (isHotdesk) {
    otherEl = createHotDeskContact({ ...deskContact, rotation });
  }

  if (isMeeting) {
    createMeetingRoom(el, item, date);
  }

  if (otherEl) {
    el.appendChild(otherEl);
  }

  return el;
};

export function updateMapItem(el, item) {

  common(el, item)
  const bgEl = findChild(el, 'b-map-item_texture')
  if (bgEl) {
    commonTexture(bgEl, item)
  }
}
