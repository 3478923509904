import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Redirect, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Tabs from '../../components/Tabs/Tabs';
import Dialog from '../../components/Dialog/Dialog';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader/Loader';
import BookingHistoryActive from '../../components/BookingHistoryActive/BookingHistoryActive';
import BookingHistoryArchive from '../../components/BookingHistoryArchive/BookingHistoryArchive';

import useUser from '../../hooks/useUser';
import splitBooking from '../../services/split-booking';
import { toObject } from '../../services/entity-format';
import splitArchiveBooking from '../../services/split-archive-booking';
import { default as api } from '../../services/api';

import './booking-history.css';

const {
  fetchContact,
  deleteBooking,
  fetchCompanyDesksAndMeetings,
  fetchBookingByContact,
  fetchRecurrentBookingByContact
} = api
const tabs = [
  { title: 'Active' },
  { title: 'Archive' }
];

export default function BookingHistory() {
  const { id } = useParams();

  const { user } = useUser();

  const [currentUser, setUser] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [mapDesks, setMapDesks] = useState(null);
  const [isPending, setPending] = useState(true);
  const [mapMeetings, setMapMeetings] = useState(null);
  const [canceledData, setCanceledData] = useState({});
  const [isViewDialog, setViewDialog] = useState(false);
  const [mapActiveBooking, setMapActiveBooking] = useState([]);
  const [activeRecurrentBooking, setActiveRecurentBooking] = useState([]);
  const [canceledBooking, setCanceledBooking] = useState([]);
  const [completedBooking, setCompletedBooking] = useState([]);

  const setMapState = (items, cb) => {
    const { data } = toObject(items, { keyField: 'strId' });
    setPending(false);
    cb(data);
  };

  const handleDeleteBooking = () => {
    const { strId, date } = canceledData;

    deleteBooking(strId).then(() => {
      const newMapBooking = {
        ...mapActiveBooking,
        [date]: mapActiveBooking[date].filter((booking) => booking.strId !== strId)
      };

      if (!newMapBooking[date].length) {
        delete newMapBooking[date];
      }

      const currentBooking = mapActiveBooking[date].find((booking) => booking.strId === strId);

      toast(`Booking canceled!`);
      setMapActiveBooking(newMapBooking);
      setCanceledBooking([...canceledBooking, currentBooking]);
    });
  };

  const isActiveBooking = !tabIndex;

  useEffect(() => {
    if (!id) {
      return;
    }

    fetchContact(id).then((res) => {
      setUser(res);

      const { strId, companyStrId } = res;

      if (strId) {
        fetchCompanyDesksAndMeetings(companyStrId).then(({ desks, meetingRooms }) => {
          setMapState(desks, setMapDesks);
          setMapState(meetingRooms, setMapMeetings);
        });
      }
    });
  }, [id]);

  useEffect(() => {
    if (!mapDesks || !mapMeetings || !id) {
      return;
    }

    const fetchAllBooking = async () => {
      setPending(true);

      const toTimeObject = (list, item) => {
        const dateBooking = moment(item.timeFrom).format('MM-DD-YYYY');
        let data = list[dateBooking];

        if (data) {
          data.push(item);
        } else {
          data = [item];
        }
        return { ...list, [dateBooking]: data };
      };

      const fetchBooking = fetchBookingByContact(id);
      const fetchRecurrentBooking = fetchRecurrentBookingByContact(id);

      const [booking, recurrentBooking] = await Promise.all([ fetchBooking, fetchRecurrentBooking ]);

      const { active, archive } = splitBooking(booking);
      const { active: recurrentActive, archive: recurrentArchive } = splitBooking(recurrentBooking);
      const { completed, canceled } = splitArchiveBooking([...recurrentArchive, ...archive]);
      const mapActiveBooking = active.reduce(toTimeObject, {});

      setMapActiveBooking(mapActiveBooking);
      setActiveRecurentBooking(recurrentActive);
      setCompletedBooking(completed);
      setCanceledBooking(canceled);
      setPending(false);
    };

    fetchAllBooking();

  }, [mapDesks, mapMeetings, id]);

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (user.strId !== id) {
    return <Redirect to={`/booking-history/${user.strId}`} />;
  }

  return (
    <div className="booking-history">
      <Header isBack title={`Booking history`} rightTitle={`${currentUser?.name || ''}`}/>

      <main className="booking-history__list">
        <Tabs active={tabIndex} setActive={setTabIndex} tabs={tabs}/>
        {isPending && <Loader />}

        {
          isActiveBooking ?
          <BookingHistoryActive
            id={id}
            mapDesks={mapDesks}
            isPending={isPending}
            mapMeetings={mapMeetings}
            bookings={mapActiveBooking}
            setViewDialog={setViewDialog}
            setCanceledData={setCanceledData}
            recurrentBookings={activeRecurrentBooking}
          /> :
          <BookingHistoryArchive
            mapDesks={mapDesks}
            isPending={isPending}
            mapMeetings={mapMeetings}
            canceledBooking={canceledBooking}
            completedBooking={completedBooking}
          />
        }
      </main>

      {
        isViewDialog &&
        <Dialog
          onClose={() => setViewDialog(false)}
          onSuccess={handleDeleteBooking}
          title="Do you want to cancel your booking?"
        />
      }
    </div>
  );
};
