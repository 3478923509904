import React, { useState } from 'react';
import cx from 'classnames';

import Tabs from '../Tabs/Tabs';
import MapPalette from '../../components/MapPalette/MapPalette';
import MapPaletteEdit from '../../components/MapPaletteEdit/MapPaletteEdit';

import { UndoIcon } from '../../services/svg-icons';

const tabs = [
  { title: 'Create elements' },
  { title: 'Edit element' }
];

export default function AdminMapPalette(props) {
  const { deskEl, setDeskEl, onClickRedo, onClickUndo, paletteItem,
          pushAction, stackUndo, stackRedo, selectPaletteItem, users } = props;

  const [tabIndex, setTabIndex] = useState(0);

  const isCreateItems = tabIndex === 0;
  const isEdit = tabIndex === 1;

  const classesUndo = cx('b-palette__icon', { 'b-palette__icon_inactive': !stackUndo[0] });
  const classesRedo = cx('b-palette__icon', 'b-palette__icon_redo', { 'b-palette__icon_inactive': !stackRedo[0] });

  return (
    <div className="b-palette">
      <div title="Undo" onClick={onClickUndo}>
        <UndoIcon className={classesUndo}/>
      </div>
      <div title="Redo" onClick={onClickRedo}>
        <UndoIcon className={classesRedo}/>
      </div>
      <Tabs active={tabIndex} setActive={setTabIndex} tabs={tabs} isRound/>
      <div className="b-palette__wrapper">
        {isCreateItems && <MapPalette selectedItem={paletteItem} selectItem={selectPaletteItem}/>}
        {isEdit && <MapPaletteEdit pushAction={pushAction} deskEl={deskEl} setDeskEl={setDeskEl} users={users}/>}
      </div>
    </div>
  );
};
