import {useEffect, createContext, useState, useContext} from 'react'

import createAuth0Client from '@auth0/auth0-spa-js'

import { default as api } from '../services/api'
import { hasAuthParams } from './utils'
import { useHistory } from "react-router-dom";

const { fetchCompanyBySlug } = api

// export const AUTH0_DOMAIN = 'dev-cnecjl4q.au.auth0.com';
// export const AUTH0_CLIENT_ID = 'KVHa3n2eyLUFZLhXmlpspj5DkgJu2ESR';

// export const AUTH0_DOMAIN = 'workipelagoprod1.eu.auth0.com'
export const AUTH0_DOMAIN = 'auth.workipelago.com'
export const AUTH0_CLIENT_ID = 'rkJtPjiUaalv71csU6IbMkyjcbEHF7JQ'

export const AUTH0_RETURN_URI = `${window.location.origin}/login`
export const AUTH0_AUDIENCE = `https://auth.workipelago.com`
export const AUTH0_SCOPE = "read:current_user update:current_user_metadata access_type=offline"

export const AuthContext = createContext(null);
export const authContextDefault = {
  companySlug: '',
  client: null,
}

const defaultOnRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};


/*
  slug in arguments comes from auth screen company slug
  But user could by authorized by different slug
  So slug which already been in localStorage is more important
*/
export default function useAuth(options = {}) {
  const { slug, isRoot, overrideSlug } = options
  const { auth, setAuth } = useContext(AuthContext)
  const [clientOpts, setClientOpts] = useState(null)
  const [client, setClient] = useState(auth.client ? auth.client : null)
  const [user, setUser] = useState(false)
  const [localLoading, setLocalLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  /** for refresh token or faster loginWithRedirect */
  if (localStorage.getItem('loginWithRedirect')) {
    const localOpts = JSON.parse(localStorage.getItem('loginWithRedirect'))
    localStorage.setItem('clientOpts', JSON.stringify(localOpts))
    setClientOpts(localOpts)
    localStorage.removeItem('loginWithRedirect')
  }
  if (localStorage.getItem('authAction') && !options.silent) {
    const localOpts = JSON.parse(localStorage.getItem('clientOpts'))
    setClientOpts(localOpts)
  }
  if (localStorage.getItem('withoutToken')) {
    localStorage.removeItem('withoutToken')
  }

  useEffect(() => {
    const isLoadingFinished = !localLoading

    setIsLoading(!isLoadingFinished)
  }, [localLoading])

  useEffect(() => {
    Promise.resolve()
    .then(async () => {
      if (!client) {
        return
      }

      try {
        if (hasAuthParams()) {
          const { appState } = await client.handleRedirectCallback();
          defaultOnRedirectCallback(appState);
        } else {
          await client.checkSession();
        }
        const user = await client.getUser();

        setUser(user)
        setLocalLoading(false)

      } catch (error) {
        console.log('auth0 client err', error)
        setLocalLoading(false)
        // dispatch({ type: 'ERROR', error: loginError(error) });
      }
    })
  }, [client])

  useEffect(() => {
    if (!clientOpts) {
      return
    }

    setLocalLoading(true)
    createAuth0Client({
      domain: AUTH0_DOMAIN,
      client_id: clientOpts.clientId,
      redirect_uri: AUTH0_RETURN_URI,
      audience: AUTH0_AUDIENCE,
      scope: AUTH0_SCOPE,
      cacheLocation: 'localstorage',
      useRefreshTokens: true
    })
    .then(async (client) => {
      setClient(client)
      setAuth({...auth, client})
      localStorage.wkpg_slug = clientOpts.slug
    })
    .catch((err) => {
      setLocalLoading(false)
      console.log('createAuth0Client error', err)
    })

  }, [clientOpts])


  useEffect(() => {
    (async () => {
      let clientOptsLocal = null
      const trySlug = window.localStorage.wkpg_slug
      if (clientOpts && clientOpts.slug) {
        return
      }

      setLocalLoading(true)

      if (slug) {
        try {
          const company = await fetchCompanyBySlug(slug)
          if (company && company.auth0ClientId) {
            clientOptsLocal = {
              clientId: company.auth0ClientId,
              slug: company.slug,
            }
          } else {
            clientOptsLocal = {
              clientId: AUTH0_CLIENT_ID,
              slug: company.slug,
            }
          }
        } catch(err) {
          // ignore
        }
      } else if (isRoot || trySlug === 'root-admin') {
        clientOptsLocal = {
          clientId: AUTH0_CLIENT_ID,
          slug: 'root-admin',
        }
      } else if (trySlug && !overrideSlug) {
        try {
          const company = await fetchCompanyBySlug(trySlug)
          if (company && company.auth0ClientId) {
            clientOptsLocal = {
              clientId: company.auth0ClientId,
              slug: company.slug,
            }
          } else {
            window.localStorage.wkpg_slug = trySlug === 'demo' ? '' : trySlug
          }
        } catch(err) {
          window.localStorage.wkpg_slug = ''
        }
      } else {
        clientOptsLocal = {
          clientId: AUTH0_CLIENT_ID,
          slug: 'signup',
        }
      }

      if (clientOptsLocal) {
        setClientOpts(clientOptsLocal)
      } else {
        setLocalLoading(false)
      }

    })();

  }, [slug, isRoot])

  // useEffect(() => {
  //   if (!window.localStorage.wkpg_slug) {
  //     setLocalLoading(false)
  //     return
  //   }
  //   const trySlug = window.localStorage.wkpg_slug
  //   fetchCompanyBySlug(trySlug)
  //   .then(async (company) => {
  //     setSavedSlug(trySlug)
  //     setCompany(company)
  //   })
  //   .catch((err) => {
  //     window.localStorage.wkpg_slug = ''
  //     setLocalLoading(false)
  //   })
  // }, [])

  return {
    isAuthenticated: Boolean(user),
    isLoading,
    auth,
    loginWithRedirect: (...args) => {
      if (client) {
        localStorage.setItem('loginWithRedirect', JSON.stringify(clientOpts))
        return client.loginWithRedirect(...args)
      }
    },
    buildAuthorizeUrl: (...args) => {
      if (client) {
        localStorage.setItem('loginWithRedirect', JSON.stringify(clientOpts))
        return client.buildAuthorizeUrl(...args)
      }
      return ''
    },
    getIdTokenClaims: (...args) => {
      if (client) {
        const fullArgs = {
          ...args,
          audience: AUTH0_AUDIENCE,
          scope: AUTH0_SCOPE,
        }
        return client.getIdTokenClaims(fullArgs)
      }
    },
    logout: (...args) => {
      if (client) {
        localStorage.clear();
        return client.logout(...args)
      } else {
        if (localStorage.getItem('clientOpts')) {
          localStorage.setItem('authAction', 'logout')
          return window.location.reload()
        }
        localStorage.clear()
        return window.location.pathname = '/'
      }
    },
  }
}
