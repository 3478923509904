import moment from 'moment';

export function getActualBooking (arr, booking, recurrentBooking) {
  return arr.map((item) => {
    const bookingForArr = booking.filter((b) => item.strId === b.entityId && moment(b.timeTo) > moment());

    const rBookingForArr = recurrentBooking.filter((b) => item.strId === b.entityId);

    if (bookingForArr.length) {
      item.booking = bookingForArr.sort((a, b) => moment(a.timeFrom) - moment(b.timeFrom));
    }

    if (rBookingForArr.length) {
      item.recurrentBooking = rBookingForArr;
    }

    return item;
  });
}

export function getActiveBooking(arr, date) {
  if (!arr) {
    return null
  }

  const now = date ? date : moment();
  for (const booking of arr) {
    const timeFrom = moment(booking.timeFrom).subtract(50, 'seconds')
    const timeTo = moment(booking.timeTo)

    if (now >= timeFrom && now <= timeTo) {
      return booking
    }
  }

  return null
}

export function getLocationString(location) {
  if (!location) {
    return ''
  }
  const { permanentDesks, bookedDesks } = location
  if (bookedDesks && bookedDesks.length > 0) {
    const { deskId, buildingName, buildingDesc } = bookedDesks[0];

    return {
      desk: bookedDesks[0],
      locationName: `Hot desk ${deskId}, ${buildingName}${buildingDesc ? ', ' : ''}${buildingDesc}`,
    }
  }
  if (permanentDesks && permanentDesks.length > 0) {
    const { deskId, buildingName, buildingDesc } = permanentDesks[0];

    return {
      desk: permanentDesks[0],
      locationName: `Desk ${deskId}, ${buildingName}${buildingDesc ? ', ' : ''}${buildingDesc}`,
    }
  }
  return {
    desk: null,
    locationName: '',
  }
}
