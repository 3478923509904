import React, { useState, useEffect } from 'react';
import find from 'lodash.find';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';

// import { isMobile } from 'react-device-detect';
import { useMobile } from '../../hooks/useDetectMobile';

import Button from '../Button/Button';
import { IconSummon, SearchIcon } from '../../services/svg-icons';

import './invitees-list.css';

function InviteesList(props) {
  const { contacts, setMembers } = props;

  const [isView, setView] = useState(false);
  const [filter, setFilter] = useState('');
  const [invites, setInvites] = useState(contacts);
  const [filteredInvites, setFilteredInvites] = useState(contacts);

  const sortedInvites = filteredInvites.sort((a, b) => {
    return a.name.toUpperCase() > b.name.toUpperCase() ? 1 :
    a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 0;
  });
  const nameInvites = invites.reduce((str, item) => `${str}${str && item.isActive ? ', ' : ''}${item.isActive ? item.name : ''}`, '');

  const handleClickAdd = (e) => {
    e.stopPropagation()
    e.preventDefault()

    const activeInvites = invites.filter((item) => item.isActive);
    setMembers(activeInvites);
    setView(!isView)
  };

  const handleBlockClick = () => {
    setView(!isView)
  }

  const handleChangeFilter = ({target}) => {
    const filterInvites = invites.filter((item) => item.name.toUpperCase().includes(target.value.toUpperCase()));
    setFilter(target.value);
    setFilteredInvites(filterInvites);
  };

  const mapContacts = sortedInvites.reduce((list, item) => {
    const { name } = item;

    if (name) {
      const currentSymbol = name[0].toUpperCase();
      let data = list[currentSymbol];

      if (data) {
        data.push(item);
      } else {
        data = [item];
      }

      return { ...list, [currentSymbol]: data };
    }

    return list;
  }, {});

  const isMobile = useMobile()

  useEffect(() => {
    if (isView && !isMobile) {
      const popupEl = document.querySelector('.invitees-list__popup');
      const { height, top } = popupEl.getBoundingClientRect();

      if (height + top > window.innerHeight) {
        popupEl.style.top = '-356px';
      } else {
        popupEl.style.top = '40px';
      }
    }
  }, [isView, isMobile]);

  return (
    <div className="invitees-list" onClick={handleBlockClick}>
      {isView && isMobile && <div className="popup__background"/>}
      <input className="input" value={nameInvites} readOnly/>
      <IconSummon className="invitees-list__icon"/>
      {
        isView &&
        <div className="invitees-list__popup">
          <header className="invitees-list__popup-header">Invitees</header>
          <div className="invitees-list__popup-input-holder">
            <input className="input" placeholder="Name" value={filter} onChange={handleChangeFilter} onClick={(e) => {e.stopPropagation();}}/>
            <SearchIcon className="invitees-list__icon invitees-list__icon_search"/>
          </div>
          <div className="invitees-list__popup-body">
            {
              Object.keys(mapContacts).map((item, index) => {
                const currentContacts = mapContacts[item];

                return (
                  <div key={index}>
                    <div className="invitees-list__symbol">{item}</div>
                    <div className="invitees-list__items">
                      {
                        currentContacts.map((contact, contactIndex) => {
                          const { img, name, position, isActive } = contact;
                          const classes = classNames('invitees-list__contact', {
                            'invitees-list__contact_active': isActive
                          });
                          const handleClickContact = (e) => {
                            const newInvites = cloneDeep(invites);
                            const newFilteredInvites = cloneDeep(filteredInvites);
                            const invite = find(newInvites, (item) => item.strId === contact.strId);
                            const filteredInvite = find(newFilteredInvites, (item) => item.strId === contact.strId);
                            invite.isActive = Boolean(!invite.isActive);
                            filteredInvite.isActive = Boolean(!filteredInvite.isActive);

                            setInvites(newInvites);
                            setFilteredInvites(newFilteredInvites);
                            e.stopPropagation()
                            e.preventDefault()
                          };

                          return (
                            <div className={classes} key={contactIndex} onClick={handleClickContact}>
                              <div className="invitees-list__avatar" style={{backgroundImage: `url(${img})`}}/>
                              <div className="invitees-list__contact-info">
                                <div className="invitees-list__name">{name}</div>
                                <div className="invitees-list__position">{position}</div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                );
              })
            }
          </div>
          <footer className="invitees-list__popup-footer">
            <Button onClick={handleClickAdd}>Add invitees</Button>
          </footer>
        </div>
      }
    </div>
  );
}

export default InviteesList;
