import React, { useRef, useEffect, useState } from 'react';
import jsQR from 'jsqr';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { default as api } from '../../services/api';
import { ClearIcon } from '../../services/svg-icons';

import './scan.css';

const { updateDesk } = api
const QR_CODE_SIZE = 228;

const CONFIG = {
  audio: false,
  video: { facingMode: { exact: 'environment' } }
};

const LIGHT_CONFIG = {
  audio: false,
  video: true
};

const getUserMediaError = (e, setError) => {
  let error = 'Webcam not found.';

  if (e.name.indexOf('NotFoundError') === -1) {
    error = `The following error occurred: "${e}" Please check your webcam device(s) and try again.`;
  }

  setError(error);
};

const re1 = /^(.+)\/scan-qr\/([^/]+)\/([^/]+)/i
const re2 = /^https?:\/\/([^/]+)\/([^/]+)\/scan-qr\/([^/]+)/i

function prepareQRData(data) {
  const result = re1.exec(data)
  if (result && result[3]) {
    return result[3]
  }
  const result2 = re2.exec(data)
  if (result2 && result2[3]) {
    return result2[3]
  }
  return data
}

export default function Scan(props) {
  const { onClose, strId, link, desks, companySlug } = props;
  const refVideo = useRef(null);
  const refCanvas = useRef(null);

  const [ctx, setCtx] = useState(null);
  const [error, setError] = useState('');

  const history = useHistory();

  useEffect(() => {
    if (!refVideo || !refCanvas) {
      return;
    }

    const connectCam = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(CONFIG)
        refVideo.current.srcObject = stream;
      } catch(e) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia(LIGHT_CONFIG)
          refVideo.current.srcObject = stream;
        } catch (e) {
          getUserMediaError(e, setError)
        }
      }

      refVideo.current.addEventListener('resize', () => {
        refCanvas.current.width = QR_CODE_SIZE;
        refCanvas.current.height = QR_CODE_SIZE;

        setCtx(refCanvas.current.getContext('2d'));
      });
    }
    connectCam()

    // navigator.mediaDevices.getUserMedia(config).then((stream) => {
    //   refVideo.current.srcObject = stream;
    // }).catch((e) => getUserMediaError(e, setError));

    return () => {
      const { srcObject } = refVideo.current;
      srcObject && srcObject.getTracks().forEach((track) => track.stop());
    };
  }, [refVideo, refCanvas]);

  useEffect(() => {
    if (!ctx) {
      return;
    }

    const { videoWidth, videoHeight } = refVideo.current;
    const offsetX = (videoWidth - QR_CODE_SIZE) / 2;
    const offsetY = (videoHeight - QR_CODE_SIZE) / 2;

    const updaterId = setInterval(() => {
      ctx.drawImage(refVideo.current, offsetX, offsetY, QR_CODE_SIZE, QR_CODE_SIZE, 0, 0, QR_CODE_SIZE, QR_CODE_SIZE);
      const imageData = ctx.getImageData(0, 0, QR_CODE_SIZE, QR_CODE_SIZE);

      const code = jsQR(imageData.data, imageData.width, imageData.height);

      if (code) {
        clearTimeout(updaterId);
        const { data } = code;
        const preparedData = prepareQRData(data)


        if (strId && preparedData) {
          updateDesk(strId, { qrId: preparedData }).then(() => {
            toast('QR code attached!');

            history.push(`/scan-qr/${companySlug}/${preparedData}`);
          });
        } else {
          const currentDesk = desks.find((item) => item.qrId === data);

          if (currentDesk) {
            window.location = `${link}?qrCode=${currentDesk.strId}`;
          } else {
            window.location = data;
          }
        }
      }
    }, 1000);

    return () => {
      clearTimeout(updaterId);
    };
  }, [ctx]);

  return (
    <div className="scan">
      <div className="drop-down__header scan__header">
        <ClearIcon className="scan__icon" onClick={onClose} onTouchStart={onClose}/>
        <div className="drop-down__header-name">Scan code</div>
      </div>
      <div className="scan__video">
        <div className="scan__filter">
          {error}
        </div>
        <video ref={refVideo} autoPlay playsInline/>
      </div>
      <canvas className="scan-canvas" ref={refCanvas} />
    </div>
  );
};
