import React from 'react';

import DemoCompanyForm from '../../components/DemoCompanyForm/DemoCompanyForm';

export default function AddDemoCompany() {
  return (
    <div>
      <DemoCompanyForm />
    </div>
  );
};
