import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import find from 'lodash.find';
import classNames from 'classnames';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash.debounce'

// import { isMobile } from 'react-device-detect';
import { useMobile } from '../../hooks/useDetectMobile';

import { default as api } from '../../services/api';

import {
  centeringEl,
  getPopupPosition
} from '../../services/dom-utils';

import {
  constraits,
  getMapHandler,
  createMapHandler
} from '../../services/map-handler';

import saveDesk from '../../services/save-desk';
import insertItem from '../../services/insert-item';
import { createMapItem } from '../../services/map-items';
import getQueryParam from '../../services/get-query-param';
import saveTargetPosition from '../../services/save-target-position';
import ZoomButton from '../ZoomButton/ZoomButton';
import PopupBooking from '../PopupBooking/PopupBooking';
import PopupInfoDesk from '../PopupInfoDesk/PopupInfoDesk';
import AdminMapPalette from '../AdminMapPalette/AdminMapPalette';
import PopupMiniInfoDesk from '../PopupMiniInfoDesk/PopupMiniInfoDesk';

import { HOTDESK } from '../../constants/deskStatuses';
import { BOOKABLE, NON_BOOKABLE } from '../../constants/meetingStatuses';

const {
  fetchDesk,
  fetchMeeting,
  fetchContactLocationByStrId,
  getImgUrl,
} = api

function Map(props) {
  const {
    viewDate,
    currentDate,
    pendingIntervals,
    openedDeskId,
    floor,
    setIntervals,
    desks,
    contacts,
    user,
    companyStrId,
    companySlug,
    company,
    isSummon,
    meetings,
    setSummons,
    summons,
    booking,
    setBooking,
    recurrentBooking,
    setRecurrentBooking,
    setUpdateBooking,
    building,
    availabilityInvisible,
    currentContact, setContact,
    currentMeeting, setMeeting,
    currentDesk, setDesk,
    isBuildingCleaning,
    isJanitorFloor,
    isJanitor, isAdmin,
    checkIn,
    afterTime, beforeTime,
    recurrentIntervals,
    setRecurrentInterval,
    isViewAdminPanel
  } = props;
  const qrCode = getQueryParam('qrCode');
  const flags = getQueryParam('flags') || '';

  const mapRef = useRef(null);
  const mapWrapRef = useRef(null);
  const mapUnderRef = useRef(null);
  const paletteItemRef = useRef(null);

  const [popupX, setPopupX] = useState(0);
  const [popupY, setPopupY] = useState(0);

  const [mapItem, selectMapItem] = useState(null);
  const [viewPopup, setViewPopup] = useState(false);

  // const [mapX, setMapX] = useState(constraits.x.max);
  // const [mapY, setMapY] = useState(constraits.y.max);

  const [stackUndo, setStackUndo] = useState([]);
  const [stackRedo, setStackRedo] = useState([]);
  const [paletteItem, selectPaletteItem] = useState(null);
  const [currentScale, setScale] = useState(constraits.scale.max);

  const [viewMiniPopup, setViewMiniPopup] = useState(false);
  const [viewButtonHotdesk, setViewButtonHotdesk] = useState(false);
  const [viewHotdeskPopup, setViewHotdeskPopup] = useState(!!openedDeskId);

  const isMobile = useMobile()

  const wrapperClass = classNames({
    'b-map-wrapper': true,
    'b-map-wrapper__admin': isAdmin,
    'small-dots': true,
    [flags]: true,
  }); //`b-map-wrapper ${flags}`
  const mapActions = { insertItem, saveTargetPosition, saveDesk };
  const mapStyleUnder = {
    width: `${floor.width}px`,
    height: `${floor.height}px`,
    willChange: 'transform',
  };
  const mapStyle = {
    backgroundImage: `url(${getImgUrl(floor.floorPlanImg)})`,
    willChange: 'transform',
    ...mapStyleUnder
  };
  const removeActiveElements = () => {
    const activeEl = document.querySelector('.b-map-item_active');
    activeEl && activeEl.classList.remove('b-map-item_active');
  };
  const handleClosePopup = () => {
    removeActiveElements();
    setViewHotdeskPopup(false);
  }
  const handleClosePopupInfo = () => {
    removeActiveElements();
    setViewPopup(false)
  };

  const getContactLocation = (strId) => fetchContactLocationByStrId(strId).then(({contact}) => {
    setContact(contact);
    setViewPopup(true);
  }).catch((e) => {
    console.log('Get contact location error ->', e);
  });

  const pushAction = (action) => {
    let currentStackUndo = cloneDeep(stackUndo);

    if (currentStackUndo.length === 10) {
      currentStackUndo.pop();
    }

    setStackRedo([]);
    setStackUndo([action, ...currentStackUndo]);
  };

  const onClickRedo = () => {
    if (stackRedo[0]) {
      const [action, ...newStackRedo] = stackRedo;
      const { type, newParams } = action;

      mapActions[type](...newParams);
      setStackRedo(newStackRedo);
    }
  };
  const onClickUndo = () => {
    if (stackUndo[0]) {
      const [action, ...newStackUndo] = stackUndo;
      const { type, oldParams } = action;

      mapActions[type](...oldParams);
      setStackRedo([action, ...stackRedo]);
      setStackUndo(newStackUndo);
    }
  };

  const onDeskClicked = (el) => {
    if (viewHotdeskPopup && isMobile) {
      return;
    }

    const strId = el.getAttribute('data-str-id');
    const status = el.getAttribute('data-desk-status');
    const deskEl = el.querySelector('.b-desk');
    const isMeeting = status === BOOKABLE || status === NON_BOOKABLE;
    const oldDesk = find(desks, (item) => item.strId === strId);
    const oldMeeting = find(meetings, (item) => item.strId === strId);
    const popupPosition = getPopupPosition({ el });
    setViewMiniPopup(false);

    if (isSummon && deskEl && !isMeeting) {
      const contactStrId = deskEl.getAttribute('data-str-id');
      const summonEls = mapRef.current.querySelectorAll(`[data-str-id='${contactStrId}']`);
      const summonEl = el.querySelector('.b-desk_contact');
      const summon = find(contacts, (item) => item.strId === contactStrId);
      const summonClass = 'b-desk_contact_summon';

      if (summon) {
        const indexSummon = findIndex(summons, (item) => item.strId === summon.strId);
        let newSummons = [];

        if (summonEl.classList.contains(summonClass)) {
          newSummons = [...summons.slice(0, indexSummon), ...summons.slice(indexSummon + 1)];
          summonEls.forEach((el) => el.firstChild.classList.remove(summonClass));
        } else {
          newSummons = [...summons, summon];
          summonEls.forEach((el) => el.firstChild.classList.add(summonClass));
        }

        localStorage.setItem('summons', JSON.stringify(newSummons));

        setSummons(newSummons)
      }
    }

    if (!isSummon && !isMeeting) {
      fetchDesk(strId)
      .then(async (desk) => {
        if (oldDesk) {
          setPopupX(popupPosition.x);
          setPopupY(popupPosition.y);

          if ((desk.contactStrId || desk.status === HOTDESK) && isMobile) {
            removeActiveElements();
            el.classList.add('b-map-item_active');
          }

          if (desk.status === HOTDESK) {
            setDesk({ ...desk, booking: oldDesk.booking, recurrentBooking: oldDesk.recurrentBooking });
            setMeeting(null);

            if (deskEl && oldDesk.booking && oldDesk.booking[0] && !oldDesk.booking[0].isInvisible && !oldDesk.booking[0].isInactive && !isJanitor) {
              const contactStrId = deskEl.getAttribute('data-str-id');
              getContactLocation(contactStrId).then(() => setViewButtonHotdesk(true));
            } else {
              setViewHotdeskPopup(true);
            }

            if (isMobile) {
              setViewHotdeskPopup(true);
              setScale(constraits.scale.max);
              centeringEl({ el: desk, mapWrapRef, mapRef });
            }
          } else if (desk.contactStrId) {
            const contactStrId = desk.contactStrId;
            setDesk(desk);
            getContactLocation(contactStrId).then(() => setMeeting(null));
          }
        }
      })
      .catch((e) => {
        console.error(e, strId)
      })
    }

    if (isMeeting) {
      if (isMobile) {
        removeActiveElements();
        const mapUnderEl = mapUnderRef.current.querySelector(`[data-str-id='${strId}']`);
        mapUnderEl.classList.add('b-map-item_active');
      }
      fetchMeeting(strId).then((meeting) => {
        let actualMeeting = meeting;
        if (oldMeeting) {
          actualMeeting = {...actualMeeting, booking: oldMeeting.booking,  recurrentBooking: oldMeeting.recurrentBooking};
        }
        setMeeting(actualMeeting);
        setDesk(null);
        setPopupX(popupPosition.x);
        setPopupY(popupPosition.y);
        setViewHotdeskPopup(true);

        if (isMobile) {
          setScale(constraits.scale.max);
          centeringEl({ el: meeting, mapWrapRef, mapRef });
        }
      });
    }
  }

  const clearMap = (map) => {
    while (map.firstChild) {
      map.removeChild(map.firstChild);
    }
  };

  const closeAllPopups = () => {
    setViewHotdeskPopup(false)
    setViewMiniPopup(false)
    setViewPopup(false)
  }

  useEffect(() => {
    const map = mapRef.current;
    const mapUnder = mapUnderRef.current;

    if (!map || !mapUnder) {
      return;
    }

    clearMap(map);
    clearMap(mapUnder);

    // setItems(desks)
    for (const item of desks) {
      const { booking, contactStrId, strId } = item;
      const deskIntervals = recurrentIntervals[strId];
      let contact;

      if (booking) {
        const currentBook = find(
          booking,
          (b) => currentDate.clone().add(1, 'h') >= moment(b.timeFrom) && currentDate <= moment(b.timeTo)
        );

        if (currentBook) {
          contact = find(contacts, (contact) => contact.strId === currentBook.ownerContactId);

          if (currentBook.isInvisible) {
            contact = {
              ...contact,
              strId: null,
              img: '/demo-assets/profile.svg'
            };
          }

          if (currentBook.isInactive) {
            contact = {
              ...contact,
              strId: null,
              img: '/demo-assets/ban.svg'
            };
          }
        }
      }

      if (deskIntervals) {
        const { intervals, ownerContactId } = deskIntervals;
        const currentBook = find(
          intervals,
          (b) => currentDate.clone().add(1, 'h') >= moment(b.timeFrom) && currentDate <= moment(b.timeTo)
        );

        if (currentBook) {
          contact = find(contacts, (contact) => contact.strId === ownerContactId);
        }
      }

      if (!booking && !deskIntervals) {
        contact = find(contacts, (contact) => contact.strId === contactStrId);
      }

      const el = createMapItem(item, contact, currentDate) //, handleClickHotDesk, handleClickDesk
      map.appendChild(el)
    }
    meetings.forEach((room) => {
      const el1 = createMapItem(room, null, currentDate);
      const el2 = createMapItem(room, null, currentDate);

      map.appendChild(el1);
      mapUnder.appendChild(el2);
    });

    if (isSummon) {
      summons.forEach((summon) => {
        const summonEls = mapRef.current.querySelectorAll(`[data-str-id='${summon.strId}']`);
        summonEls.forEach((el) => {
          const avatarEl = el.firstChild;
          avatarEl.classList.add('b-desk_contact_summon');
        });
      });
    }

    if (isMobile && qrCode) {
      const activeEl = document.querySelector(`[data-str-id='${qrCode}']`);
      activeEl && activeEl.classList.add('b-map-item_active');
    }

    if (openedDeskId) {
      const el = mapRef.current.querySelector(`#map-el-${openedDeskId}`);

      if (el) {
        const popupPosition = getPopupPosition({ el });

        setPopupX(popupPosition.x);
        setPopupY(popupPosition.y);

        if (isMobile) {
          let temp = null;
          const meeting = find(meetings, (item) => item.strId === openedDeskId);

          if (meeting) {
            temp = meeting;
          } else {
            const desk = find(desks, (item) => item.strId === openedDeskId);

            if (desk) {
              temp = desk;
            }
          }

          el.classList.add('b-map-item_active');
          centeringEl({ el: temp, mapWrapRef, mapRef });
        }
      }
    }
  }, [
    desks,
    mapRef,
    summons,
    meetings,
    contacts,
    isSummon,
    mapUnderRef,
    openedDeskId,
    recurrentIntervals,
    currentDate
  ])

  useEffect(() => {
    paletteItemRef.current = paletteItem
  }, [paletteItem]);

  useEffect(() => {
    const mapWrap = mapWrapRef.current
    const map = mapRef.current
    const mapUnder = mapUnderRef.current

    if (!mapWrap || !map|| !mapUnder || !floor) {
      return
    }

    const initX = getQueryParam('x') ? parseInt(getQueryParam('x'), 10) : null;
    const initY = getQueryParam('y') ? parseInt(getQueryParam('y'), 10) : null;

    const options = {
      mapWrap,
      map,
      mapUnder,
      onDeskClicked,

      isAdmin,
      isViewAdminPanel,
      paletteItemRef,
      contacts,

      selectMapItem,
      floor,

      setContact,
      setPopupX,
      setPopupY,

      setViewMiniPopup,
      closeAllPopups,

      pushAction
    }

    if (initX !== null && !isNaN(initX)) {
      options.initX = initX
    }
    if (initY !== null && !isNaN(initY)) {
      options.initY = initY
    }

    const handler = createMapHandler(options)

    handler.on('scale', setScale)
    return () => {
      handler.destroy()
    }
  }, [
    mapWrapRef,
    mapRef,
    floor,
    mapUnderRef,
    isAdmin,
    isViewAdminPanel,
    paletteItemRef,
    contacts,
    viewHotdeskPopup,
    isSummon,
    summons,
    stackUndo
  ]);

  useEffect(() => {
    const handler = getMapHandler()
    if (handler) {
      handler.resetPosition()
    }
    /* temporary fix for get query param */
    setScale(getQueryParam('x') ? constraits.scale.max : constraits.scale.mid)
  }, [floor])

  useEffect(() => {
    const handler = getMapHandler()
    if (handler) {
      handler.setPopupFlag('mini', viewMiniPopup)
    }
  }, [viewMiniPopup])

  useEffect(() => {
    const handler = getMapHandler()
    if (handler) {
      handler.setPopupFlag('popup', viewPopup)
    }
  }, [viewPopup])

  useEffect(() => {
    const handler = getMapHandler()
    if (handler) {
      handler.setPopupFlag(HOTDESK, viewHotdeskPopup)
    }
  }, [viewHotdeskPopup])

  useEffect(() => {
    const handler = getMapHandler()
    if (handler) {
      handler.setScale(currentScale, true)
    }
  }, [currentScale])

  useEffect(() => {
    if (!isSummon) {
      const summonsEl = mapRef.current.querySelectorAll('.b-desk_contact_summon');
      summonsEl.forEach((el) => {
        el.classList.remove('b-desk_contact_summon');
      });
    } else {
      summons.forEach((summon) => {
        const summonEls = mapRef.current.querySelectorAll(`[data-str-id='${summon.strId}']`);
        summonEls.forEach((el) => {
          const avatarEl = el.firstChild;
          avatarEl.classList.add('b-desk_contact_summon');
        });
      });
    }
  }, [isSummon]);

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    const meeting = find(meetings, (item) => item.strId === qrCode);
    let temp;

    if (meeting) {
      temp = meeting;
      setMeeting(meeting);
    } else {
      const desk = find(desks, (item) => item.strId === qrCode);

      if (desk && desk.status === HOTDESK) {
        temp = desk;
        setDesk(desk);
      }
    }

    if (temp) {
      setViewHotdeskPopup(true);
      setScale(constraits.scale.max);
      centeringEl({ el: temp, mapWrapRef, mapRef });
    }
  }, [qrCode]);

  return (
    <>
      <div ref={mapWrapRef} className={wrapperClass}>
        <div ref={mapUnderRef} className="b-map-under" style={mapStyleUnder} />
        <div ref={mapRef} className="b-map" style={mapStyle} />

        <ZoomButton
          scale={currentScale}
          options={constraits}
          setScale={setScale}
        />
        {
          viewPopup && currentContact &&
          <PopupInfoDesk
            contact={currentContact}
            company={company}
            x={popupX}
            y={popupY}
            onClose={handleClosePopupInfo}
            setViewHotdeskPopup={setViewHotdeskPopup}
            isViewHotDeskButton={viewButtonHotdesk}
            setViewButtonHotdesk={setViewButtonHotdesk}
            setScale={setScale}
          />
        }
        {
          viewMiniPopup && currentContact &&
          <PopupMiniInfoDesk
            contact={currentContact}
            x={popupX}
            y={popupY}
          />
        }
        {
          viewHotdeskPopup && (currentDesk || currentMeeting) &&
          <PopupBooking
            companySlug={companySlug}
            viewDate={viewDate}
            currentDate={currentDate}
            isAdmin={isAdmin}
            afterTime={afterTime}
            beforeTime={beforeTime}
            checkIn={checkIn}
            pendingIntervals={pendingIntervals}
            recurrentIntervals={recurrentIntervals}
            isBuildingCleaning={isBuildingCleaning}
            isJanitor={isJanitor}
            isJanitorFloor={isJanitorFloor}
            desk={currentDesk || currentMeeting}
            floorStrId={floor.strId}
            building={building}
            x={popupX}
            y={popupY}
            setIntervals={setIntervals}
            companyStrId={companyStrId}
            onClose={handleClosePopup}
            userStrId={user.strId}
            contacts={contacts}
            booking={booking}
            setBooking={setBooking}
            recurrentBooking={recurrentBooking}
            setRecurrentBooking={setRecurrentBooking}
            setUpdateBooking={setUpdateBooking}
            setContact={setContact}
            setViewPopup={setViewPopup}
            qrCode={qrCode}
            setRecurrentInterval={setRecurrentInterval}
            availabilityInvisible={availabilityInvisible}
            setViewHotdeskPopup={setViewHotdeskPopup}
          />
        }
      </div>
        {
          isViewAdminPanel &&
          <AdminMapPalette
            desks={desks}
            deskEl={mapItem}
            meetings={meetings}
            pushAction={pushAction}
            setDeskEl={selectMapItem}
            paletteItem={paletteItem}
            onClickRedo={onClickRedo}
            onClickUndo={onClickUndo}
            stackUndo={stackUndo}
            stackRedo={stackRedo}
            selectPaletteItem={selectPaletteItem}
            users={contacts}
          />
        }
    </>
  )
};

export default Map;
