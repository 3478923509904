import React, { useState, useEffect, Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';

import Tabs from '../Tabs/Tabs';
import Select from '../Select/Select';
import Button from '../Button/Button';
import JanitorDesks from '../JanitorDesks/JanitorDesks';

import { default as api } from '../../services/api';
import useUser from '../../hooks/useUser';
import useCompanies from '../../hooks/useCompanies';
import { toArray, toObject } from '../../services/entity-format';

import { JANITOR } from '../../constants/userRoles';
import { HOTDESK } from '../../constants/deskStatuses';
import { WAITING_FOR_CLEANING } from '../../constants/cleaningStatuses';
import { BOOKABLE, NON_BOOKABLE } from '../../constants/meetingStatuses';

const {
  putContact,
  fetchFloorsByBuilding,
  fetchContactsByCompany,
  fetchBuildingsByCompany,
  putFloorsForJanitor,
  deleteFloorsForJanitor,
  fetchDesksForFloor,
  fetchMeetingsForFloor,
  getImgUrl,
} = api

const NAMES = ['Avatar', 'Name', 'Position', 'E-Mail', 'Phone', 'Floors', 'Status', ''];

function AdminCleaning() {
  const { user, isCompanyAdmin } = useUser();
  const { companies } = useCompanies();

  const savedCompany = JSON.parse(localStorage.getItem('currentCompany'));
  const defaultCompany = savedCompany || companies[0];

  const [floors, setFloors] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [hotdesks, setHotdesks] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [mapFloors, setMapFloors] = useState({});
  const [nonBookable, setNonBookable] = useState([]);
  const [mapBuildings, setMapBuildings] = useState({});
  const [currentFloor, setCurrentFloor] = useState(null);
  const [currentBuilding, setCurrentBuilding] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(defaultCompany);

  const filterDesk = (item, status) => {
    return item.status === status && item.cleaningStatus === WAITING_FOR_CLEANING;
  };

  const getTypeName = (type) => type.name;
  const getTypeLabel = (type) => type.title;
  const getTypeValue = (type) => type.strId;
  const getContactsByCompany = (id) => fetchContactsByCompany(id).then((res) => {
    setContacts(res.filter(item => item.roles.includes(JANITOR)));
  });
  const getFloorsByBuilding = (id) => fetchFloorsByBuilding(id).then((res) => {
    setFloors(res);
    setCurrentFloor(res[0]);
  });
  const getBuildingsByCompany = (id) => fetchBuildingsByCompany(id).then(async (buildings) => {
    const { data } = toObject(buildings, { keyField: 'strId' });
    let floors = [];

    for (let building of buildings) {
      const currentFloors = await fetchFloorsByBuilding(building.strId);
      floors = [...floors, ...currentFloors];
    }

    const { data: mFloors } = toObject(floors, { keyField: 'strId' });
    setMapFloors(mFloors);
    setMapBuildings(data);
    setCurrentBuilding(buildings[0]);
  });

  useEffect(() => {
    if (currentCompany) {
      getContactsByCompany(currentCompany.strId);
      getBuildingsByCompany(currentCompany.strId);
      localStorage.setItem('currentCompany', JSON.stringify(currentCompany));
    }
  }, [currentCompany]);

  useEffect(() => {
    if (currentBuilding) {
      getFloorsByBuilding(currentBuilding.strId);
    }
  }, [currentBuilding]);

  useEffect(() => {
    if (currentFloor) {
      fetchDesksForFloor(currentFloor.strId).then((res) => {
        const desks = res.filter((item) => filterDesk(item, HOTDESK));
        setHotdesks(desks);
      });

      fetchMeetingsForFloor(currentFloor.strId).then((res) => {
        const bookable = res.filter((item) => filterDesk(item, BOOKABLE));
        const nonBookable = res.filter((item) => filterDesk(item, NON_BOOKABLE));

        setMeetings(bookable);
        setNonBookable(nonBookable);
      });
    }
  }, [currentFloor]);

  useEffect(() => {
    if (isCompanyAdmin) {
      const userCompany = companies.find((item) => item.strId === user.companyStrId);
      setCurrentCompany(userCompany);
    }
  }, [isCompanyAdmin, companies]);

  const tabs = [
    { title: 'Janitors' },
    { title: 'Hot desks' },
    { title: 'Non Bookable' },
    { title: 'Meeting rooms' }
  ];

  const isJanitor = !tabIndex;
  const isMeeting = tabIndex === 3;
  const isNonBookable = tabIndex === 2;
  const currentList = isNonBookable ? nonBookable : isMeeting ? meetings : hotdesks;
  const buildings = toArray(mapBuildings, Object.keys(mapBuildings));
  const notDesksTitle = `Not ${isMeeting ? 'meeting rooms' : isNonBookable ? 'non bookable rooms' : 'hot desks'}`;

  return (
    <div>
      {
        !isCompanyAdmin &&
        <Select
          label="Company"
          options={companies}
          value={currentCompany}
          onChange={setCurrentCompany}
          getOptionLabel={getTypeLabel}
          getOptionValue={getTypeValue}
        />
      }

      {
        !isJanitor &&
        <Fragment>
          <Select
            label="Building"
            options={buildings}
            value={currentBuilding}
            onChange={setCurrentBuilding}
            getOptionLabel={getTypeName}
            getOptionValue={getTypeValue}
          />

          <Select
            label="Floor"
            options={floors}
            value={currentFloor}
            onChange={setCurrentFloor}
            getOptionLabel={getTypeLabel}
            getOptionValue={getTypeValue}
          />
        </Fragment>
      }

      <Tabs tabs={tabs} active={tabIndex} setActive={setTabIndex} notSticky />

      {
        isJanitor ?
        <JanitorTable contacts={contacts} setContacts={setContacts}
                      mapFloors={mapFloors} mapBuildings={mapBuildings} /> :
        currentList.length ?
        <JanitorDesks list={currentList} mapFloors={mapFloors} mapBuildings={mapBuildings}
                      isMeeting={isMeeting} isNonBookable={isNonBookable} setNonBookable={setNonBookable}
                      setMeetings={setMeetings} setHotdesks={setHotdesks} /> :
        <div className="adm-not-text">{notDesksTitle}</div>
      }
    </div>
  );
}

const JanitorTable = (props) => {
  const { contacts, setContacts, mapFloors, mapBuildings } = props;

  return (
    <table className="table">
      <thead>
        { renderHeader() }
      </thead>
      <tbody>
        { renderContacts(contacts, setContacts, mapFloors, mapBuildings) }
      </tbody>
    </table>
  );
};

const renderHeader = () => {
  return (
    <tr>
      { NAMES.map((item, index) => <th key={index}>{item}</th>) }
    </tr>
  );
};

const renderContacts = (contacts, setContacts, floorsByCompany, mapBuildings) => {
  const generateItems = (items, type) => {
    const genItems = items.reduce((acc, item) => {
      const floor = floorsByCompany[item];

      if (floor) {
        const { buildingStrId, title } = floor;
        const currentBuilding = mapBuildings[buildingStrId];
        const name = `${currentBuilding && currentBuilding.name} (${title})`;

        return [...acc, { title: name, strId: item }];
      }

      return acc;
    }, []);

    if (type === 'string') {
      return genItems.reduce((acc, item) => acc + `${ acc ? ', ' : '' }${ item.title }`, '');
    } else {
      return genItems;
    }
  };

  return (
    contacts.map((item, index) => {
      const { strId, name, position, email, phone, img, status, floors, isEdit } = item;
      const newContacts = cloneDeep(contacts);
      const fullFloors = Object.keys(floorsByCompany).map((item) => {
        const floor = floorsByCompany[item];
        const { buildingStrId, strId, title } = floor;
        const currentBuilding = mapBuildings[buildingStrId];
        const name = `${currentBuilding && currentBuilding.name} (${title})`;

        return { title: name, strId };
      });
      const formatedFloors = generateItems(floors)
      const nameFloors = generateItems(floors, 'string');
      const handleSave = () => {
        newContacts[index].isEdit = false;

        const oldFloors = difference(floors, newContacts[index].floors);
        const newFloors = difference(newContacts[index].floors, floors);

        putContact(newContacts[index]);
        newFloors.length && putFloorsForJanitor(strId, newFloors);
        oldFloors.length && deleteFloorsForJanitor(strId, oldFloors);

        setContacts(newContacts);
      };
      const handleEdit = () => {
        newContacts[index].isEdit = true;

        setContacts(newContacts);
      };
      const handleChange = (floor) => {
        if (floor) {
          newContacts[index].floors = floor.map((item) => item.strId);
        }
      };
      const getTypeLabel = (type) => type.title;
      const getTypeValue = (type) => type.strId;
      const handleClick = isEdit ? handleSave : handleEdit;
      const textButton = isEdit ? 'Save' : 'Edit';
      const typeButton = isEdit ? 'save' : 'edit';

      return (
        <tr key={index}>
          <td><img className="adm_user-ava" src={getImgUrl(img)} alt="avatar"/></td>
          <td>{name}</td>
          <td>{position}</td>
          <td>{email}</td>
          <td>{phone}</td>
          <td className="tbl_assigned-floors">
            {
              isEdit ?
              <Select className="select-multi" isMulti options={fullFloors} onChange={handleChange}
                      getOptionLabel={getTypeLabel} getOptionValue={getTypeValue} defaultValue={formatedFloors} /> :
              <span>{nameFloors}</span>
            }
          </td>
          <td>{status}</td>
          <td>
            <Button type={typeButton} onClick={handleClick}>
              {textButton}
            </Button>
          </td>
        </tr>
      );
    })
  );
};

export default AdminCleaning;
