import { WAITING_FOR_CLEANING, CLEANED } from "../constants/cleaningStatuses";
import { HOTDESK, PERMANENT } from "../constants/deskStatuses";

export const listStatusesDesk = [
  { value: PERMANENT, label: PERMANENT },
  { value: HOTDESK, label: HOTDESK }
];

export const listStatusesCheckIn = [
  { value: 'disabled', label: 'disabled' },
  { value: 'hard', label: 'hard' },
  { value: 'soft', label: 'soft' }
];

export const listStatusesCleaning = [
  { value: WAITING_FOR_CLEANING, label: 'waiting for cleaning' },
  { value: 'not cleaned', label: 'not cleaned' },
  { value: CLEANED, label: CLEANED }
];
