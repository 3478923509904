import React, { useState, useRef, Fragment } from 'react';
import moment from 'moment';
import find from 'lodash.find';
import { nanoid } from 'nanoid';
import classNames from 'classnames';

// import { isMobile } from 'react-device-detect';
import { useMobile } from '../../hooks/useDetectMobile';

import Button from '../Button/Button';
import PopupHeader from './PopupHeader';
import BookingForm from './BookingForm';
import ScheduleItem from './ScheduleItem';

import { default as api } from '../../services/api';
import { getActiveBooking } from '../../services/booking';
import { findPopup } from '../../services/dom-utils'
import useOutsideClick from '../../hooks/useOutsideClick';
import minutesToHours from '../../services/mitutes-to-hours';
import getNameRecurrentDate from '../../services/get-name-recurrent-date';

import { WAITING_FOR_CLEANING, CLEANED } from '../../constants/cleaningStatuses';
import { HOTDESK } from '../../constants/deskStatuses';
import { NON_BOOKABLE, BOOKABLE } from '../../constants/meetingStatuses';
import { TimerIcon } from '../../services/svg-icons';

import './popup-booking.css';
import useTeamsFlag from '../../hooks/useTeams'

const {
  putDesk,
  postBooking,
  checkInBooking,
  postRecurrentBooking,
  getImgUrl
} = api
const textOfStatus = {
  'not cleaned': 'Clean',
  [WAITING_FOR_CLEANING]: 'End cleaning'
};

function PopupBooking(props) {
  const {
    // title,
    viewDate,
    companySlug,
    currentDate: timeTravelDate,
    x, y,
    checkIn,
    onClose,
    pendingIntervals,
    floorStrId,
    userStrId,
    companyStrId,
    desk,
    contacts,
    isJanitor,
    isJanitorFloor,
    recurrentBooking: allRecurrentBooking,
    setRecurrentBooking,
    booking: allBooking,
    setBooking,
    setUpdateBooking,
    setContact,
    setViewPopup,
    building,
    qrCode,
    availabilityInvisible,
    isBuildingCleaning,
    afterTime,
    beforeTime,
    isAdmin,
    setIntervals,
    recurrentIntervals,
    setRecurrentInterval,
    setViewHotdeskPopup
  } = props;
  /*
    TODO

    PopupBooking is mega-component now. Prop 'desk' could contain
    desk or bookable meeting room or non bookable meeting room. Hopefully,
    this entities all much the same interface.

    But we should make this behaviour more explicit.
  */
  const { strId, kind, booking=[], status, recurrentBooking=[], cleaningStatus } = desk;
  const isMobile = useMobile()
  const stylePopup = isMobile ? {} : { left: `${x}px`, top: `${y}px` };

  const ref = useRef(null);

  const [step, setStep] = useState(0);
  const [user, setUser] = useState(null);
  const [date, setDate] = useState(null);
  const [title, setTitle] = useState('');
  const [members, setMembers] = useState([]);
  const [endTime, setEndTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [isInactive, setInactive] = useState(false);
  const [isInvisible, setInvisible] = useState(false);
  const [isRecurrent, setRecurrent] = useState(false);
  const [recurrentData, setRecurrentData] = useState(null);
  const [bookingState, setBookingState] = useState('pending');
  const [nonBookableBooking, setNonBookableBooking] = useState(null);
  const [isViewShedule, setViewShedule] = useState(booking.length || recurrentBooking.length);

  const isSureCheckIn = checkIn === 'hard' || checkIn === 'soft';
  const isMeBook = booking[0] && booking[0].ownerContactId === userStrId;
  const teamsFlag = useTeamsFlag()


  const isNonBookable = status === NON_BOOKABLE;

  let isDisableButton = true
  if (!isNonBookable) {
    if (isInactive || isAdmin) {
      isDisableButton = ((!date && !isRecurrent) || (isRecurrent && !recurrentData) || !startTime || !endTime || (status === BOOKABLE && !title) || (isAdmin && null)) && !isViewShedule;
    } else {
      isDisableButton = ((!date && !isRecurrent) || (isRecurrent && !recurrentData) || !startTime || !endTime || (status === BOOKABLE && !title) || (isAdmin && !user)) && !isViewShedule;
    }
  } else {
    isDisableButton = !startTime || !endTime
  }

  const isFailedBooking = bookingState === 'failed';
  const isNotCleaned = cleaningStatus && cleaningStatus !== CLEANED;
  const classesBottom = classNames('popup-booking__bottom', {
    'popup-booking__bottom_once': isViewShedule
  });
  const classesButton = classNames('popup-booking__button', {
    'popup-booking__button_non-bookable': (isNonBookable || isFailedBooking) && isMobile
  });
  const textButton = 'Close';
  const activeBooking = getActiveBooking(booking, timeTravelDate);
  const isMeCheckInBooking = isMeBook &&
                             moment().add(beforeTime, 'm') >= moment(booking[0].timeFrom) &&
                             moment() <= moment(booking[0].timeFrom).add(afterTime, 'm');
  const isMeActiveBooking = activeBooking && activeBooking.ownerContactId === userStrId;
  const isCleaning = isBuildingCleaning && isMeActiveBooking && cleaningStatus === WAITING_FOR_CLEANING;
  const formatedAfterTime = minutesToHours(afterTime);
  const formatedBeforeTime = minutesToHours(beforeTime);


  const mapBooking = booking.reduce((list, item) => {
    const dateBooking = moment(item.timeFrom).format('MM-DD-YYYY');
    let data = list[dateBooking];

    if (data) {
      data.push(item);
    } else {
      data = [item];
    }
    return { ...list, [dateBooking]: data };
  }, {});

  const handleBooking = (propData) => {
    const { start, end, date: currentDate } = propData;

    if (isViewShedule && !start) {
      setViewShedule(false);
      const activeEl = document.querySelector('.b-map-item_active');
      activeEl && activeEl.classList.remove('b-map-item_active');
      return;
    }

    let newDate = moment(date || (Date.now() - 60 * 1000));
    let newStartH = startTime && startTime.h;
    let newStartM = startTime && startTime.m;
    let newEndH = endTime && endTime.h;
    let newEndM = endTime && endTime.m;

    if (start) {
      newDate = currentDate;
      newStartH = start.h;
      newStartM = start.m;
      newEndH = end.h;
      newEndM = end.m;
    }

    const timeFrom = newDate.clone().set({ hours: newStartH, minutes: newStartM });
    const timeTo = newDate.clone().set({ hours: newEndH, minutes: newEndM });
    const strMembers = JSON.stringify(members.map((item) => item.strId));
    const ownerContactId = (isAdmin && user) ? user.strId : userStrId;
    const data = {
      strId: nanoid(),
      companyStrId,
      ownerContactId,
      floorId: floorStrId,
      kind,
      entityId: strId,
      timeFrom,
      timeTo,
      meetingTitle: title,
      members: strMembers,
      isInvisible,
      isInactive
    };

    const fetchResponse = isRecurrent ? postRecurrentBooking({...data, ...recurrentData}) : postBooking(data);

    return fetchResponse.then((res) => {
      if (isNonBookable) {
        setNonBookableBooking(res);
      }

      if (isRecurrent) {
        setRecurrentBooking([...allRecurrentBooking.filter((item) => item.strId !== res.strId), res]);
        setRecurrentInterval(res).then((interval) => {
          setIntervals({...recurrentIntervals, ...interval});
        });

      } else {
        setBooking([...allBooking.filter((item) => item.strId !== res.strId), res]);
      }

      setUpdateBooking(true);

      setBookingState('successful');

      return res;
    }).catch((err) => {
      console.log(err);
      setBookingState('failed');
    });
  };

  const handleChangeDate = (date, e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    setDate(date);
    setStartTime(null);
    setEndTime(null);
  };

  const handleClickOutside = ({ target }) => {
    const popup = findPopup(target);

    if (popup || !target || !target.className) {
      return;
    }

    if (!target.className.includes) {
      onClose();
      return;
    }

    if (
      ref.current && !ref.current.contains(target) &&
      (typeof(target.className) !== 'string' || !target.className.includes('react-datepicker'))
      && !target.className.includes('invitees-list')
      && !target.className.includes('button')
    ) {
      onClose();
    }
  };

  const handleClickContact = (owner) => {
    setViewPopup(true);
    setViewHotdeskPopup(false);
    setContact(owner);
    isMobile && onClose();
  };

  const handleClickCalendar = () => {
    if (booking.length || recurrentBooking.length) {
      setViewShedule(!isViewShedule);
    }
  };

  useOutsideClick(ref, handleClickOutside);

  if (isJanitor) {
    const handleClick = () => {
      if (cleaningStatus === WAITING_FOR_CLEANING && isJanitorFloor) {
        putDesk({...desk, cleaningStatus: CLEANED});
      }
      onClose();
    };

    return (
      <div ref={ref} className="popup-booking" style={stylePopup}>
        <PopupHeader desk={desk} isNotCleaned={isNotCleaned} building={building} isJanitor/>
        <div className="popup-booking__bottom popup-booking__bottom_once popup-booking__bottom_cleaning">
          <Button className={isMobile ? 'popup-booking__button' : ''} type="ghost" id={'id-for-touch-click'} onClick={handleClick}>
            {isJanitorFloor ? textOfStatus[cleaningStatus] || 'Close' : 'Close'}
          </Button>
        </div>
      </div>
    );
  }

  if (bookingState === 'successful' || bookingState === 'successful-checkin') {
    const isAfterCheckin = bookingState === 'successful-checkin';
    const checkinAt = booking[0] && booking[0].checkinAt;
    const nameBookingObject = status === HOTDESK ? 'Hot desk' : 'Room';
    const isMeetingRoom = Boolean( status !== HOTDESK )
    const isCheckInText = (isSureCheckIn && !isNonBookable) || (!qrCode && isNonBookable);
    const textBeforeCheckin = isNonBookable ?
      'Please, check in to the room in 5 mins.' :
      `You need to check in ${formatedBeforeTime} before & no later than ${formatedAfterTime} from the start of your reservation.`;

    return (
      <div ref={ref} className="popup-booking" style={stylePopup}>
        <PopupHeader desk={desk} activeBooking={activeBooking || nonBookableBooking} floorStrId={floorStrId}
                     building={building} isNotCleaned={isNotCleaned} companySlug={companySlug}/>
        <div className="popup-booking__middle popup-booking__middle__message">
          <div className="popup-booking_message-header" >
            <div>{nameBookingObject} is reserved</div>
          </div>
          {
            !isMeetingRoom && isCheckInText && !checkinAt && ! isAfterCheckin && !isInactive &&
            <div className="popup-booking_message-text">
              {textBeforeCheckin}
            </div>
          }
          {
            isAfterCheckin &&
            <div className="popup-booking_message-text">
              You’ve checked in
            </div>
          }
        </div>
        <div className="popup-booking__bottom popup-booking__bottom_once">
          <Button className={classesButton} type="ghost" id={'id-for-touch-click'} onClick={onClose}>
            {textButton}
          </Button>
        </div>
      </div>
    )
  }

  if (isFailedBooking) {
    return (
      <div ref={ref} className="popup-booking" style={stylePopup}>
        <PopupHeader desk={desk} activeBooking={activeBooking} building={building}
                     isNotCleaned={isNotCleaned} companySlug={companySlug} floorStrId={floorStrId}/>
        <div className="popup-booking__middle popup-booking__middle__message popup-booking__middle__message__failed">
          <div className="popup-booking_message-header">Reservation failed</div>
          <div className="popup-booking_message-text">Please try again</div>
        </div>
        <div className="popup-booking__bottom popup-booking__bottom_once">
          <Button className={classesButton} type="ghost" id={'id-for-touch-click'} onClick={onClose}>
            {textButton}
          </Button>
        </div>
      </div>
    )
  }

  if (isNonBookable && activeBooking) {
    const owner = find(contacts, (contact) => contact.strId === activeBooking.ownerContactId);
    const currentImg = activeBooking.isInvisible ? '/demo-assets/profile.svg' : activeBooking.isInactive ? '/demo-assets/ban.svg' : getImgUrl(owner.img);
    const currentName = activeBooking.isInvisible ? 'Invisible booking' : activeBooking.isInactive ? 'Inactive booking' : owner.name;

    return (
      <div ref={ref} className="popup-booking" style={stylePopup}>
        <PopupHeader desk={desk} activeBooking={activeBooking} building={building}
                     isNotCleaned={isNotCleaned} companySlug={companySlug} floorStrId={floorStrId}/>
        {
          qrCode && isMeBook ?
          <Fragment>
            <div className="popup-booking__middle popup-booking__middle__message">
              <div className="popup-booking_message-header" >
                <div>Room is reserved by you</div>
              </div>
              <div className="popup-booking_message-text" >
                You’ve checked in
              </div>
            </div>
            <div className="popup-booking__bottom popup-booking__bottom_once">
              <Button className={classesButton} type="ghost" id={'id-for-touch-click'} onClick={onClose}>
                Close
              </Button>
            </div>
          </Fragment> :
          <div className="popup-booking_non-bookable-owner" onClick={() => handleClickContact(owner)}>
            <div className="popup-booking__owner">
              <img className="popup-booking__owner-avatar" src={currentImg} alt="avatar" />
              <div className="popup-booking__owner-name">{currentName}</div>
            </div>
          </div>
        }
      </div>
    )
  }


  if (isMeActiveBooking && isMobile && qrCode === strId && activeBooking.checkinAt && !step) {
    const classesCurrentButton = classNames(classesButton, 'popup-booking__button_50');
    const handleClickNext = () => {
      setStep(1);
      setViewShedule(false);
    };
    const handleExtend = (additionalMinutes) => {
      const maxHours = 22;
      const isMax = additionalMinutes === 'max';
      const currentDate = moment(activeBooking.timeTo);
      const h = currentDate.hours();
      const m = currentDate.minutes();

      !isMax && currentDate.add(additionalMinutes, 'minutes');

      const endH = isMax ? maxHours : currentDate.hours();
      const endM = isMax ? 0 : currentDate.minutes();
      const date = moment(Date.now()).set({'hour': 0, 'minute': 0});
      const data = { start: { h, m }, end: { h: endH, m: endM }, date };

      handleBooking(data).then((booking) => {
        if (booking) {
          checkInBooking(booking.strId).then((res) => {
            setBooking([...allBooking.filter((item) => item.strId !== res.strId), res]);
            setUpdateBooking(true);
            setBookingState('successful');
          }).catch(() => {
            setBookingState('failed');
          });
        }
      });
    };
    const buttons = [
      { label: '1 hour', value: 60 },
      { label: '2 hours', value: 120 },
      { label: '4 hours', value: 240 },
      { label: 'Rest of the\u00A0day', value: 'max' }
    ];

    return (
      <div ref={ref} className="popup-booking" style={stylePopup}>
        <PopupHeader desk={desk} activeBooking={activeBooking} isNotCleaned={isNotCleaned} floorStrId={floorStrId}
                     building={building} isCleaning={isCleaning} companySlug={companySlug}/>
        <div style={{ overflow: 'scroll' }}>
          <div className="popup-booking__middle popup-booking__middle__message">
            <div align="center" className="popup-booking_message-header">
              Hot desk is reserved by you
            </div>
            <div align="center" className="popup-booking_message-text">
              Extend your reservation
            </div>
          </div>
          <div className="popup-booking__bottom">
            {
              buttons.map((item, index) => {
                return (
                  <Button key={index} id={'id-for-touch-click'} className={classesCurrentButton} onClick={() => handleExtend(item.value)}>
                    {item.label}
                  </Button>
                );
              })
            }
            <Button className={classesButton} id={'id-for-touch-click'} type="ghost" onClick={handleClickNext}>
              Choose another time
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (isMeCheckInBooking && isSureCheckIn && isMobile && qrCode === strId && booking[0] && !booking[0].checkinAt) {
    const handleCheckInClick = () => checkInBooking(booking[0].strId).then((res) => {
      setBooking([...allBooking.filter((item) => item.strId !== res.strId), res]);
      isCleaning && putDesk({...desk, cleaningStatus: CLEANED});
      setUpdateBooking(true);
      setBookingState('successful-checkin');
    }).catch(() => {
      setBookingState('failed');
    });
    const textBeforeCheckin = isNonBookable ?
      'Please, check in to the room in 5 mins.' :
      `You need to check in ${formatedBeforeTime} before & no later than ${formatedAfterTime} from the start of your reservation.`;

    return (
      <div ref={ref} className="popup-booking" style={stylePopup}>
        <PopupHeader desk={desk} activeBooking={activeBooking} building={building}
                     companySlug={companySlug} floorStrId={floorStrId}/>
        <div className="popup-booking__middle popup-booking__middle__message">
          <div align="center" className="popup-booking_message-header">
            Hot desk is reserved
          </div>
          <div align="center" className="popup-booking_message-text">
            {textBeforeCheckin}
          </div>
        </div>
        <div className="popup-booking__bottom popup-booking__bottom_once">
          <Button className={classesButton} id={'id-for-touch-click'} onClick={handleCheckInClick}>
            {isCleaning ? 'Waive cleaning & check in' : 'Check in'}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div ref={ref} className="popup-booking" style={stylePopup}>
      <PopupHeader desk={desk} isNotCleaned={isNotCleaned} building={building} activeBooking={activeBooking}
                   onClickShedule={handleClickCalendar} isViewShedule={isViewShedule} isCleaning={isCleaning}
                   isAdmin={isAdmin} companySlug={companySlug} floorStrId={floorStrId}/>
      <div className="popup-booking__mobile-holder" id="popup-booking">
        {
          isViewShedule ? (
            <div className="popup-booking__shedule-holder">
              {
                viewDate &&
                <div className="popup-booking__time-travel">
                  <TimerIcon />
                  <span>{timeTravelDate.format('DD.MM.YYYY HH:mm')}</span>
                </div>
              }
              {
                !!recurrentBooking.length &&
                <>
                  <div className="popup-booking__day">Recurrent booking</div>
                  <div className="popup-booking__shedule">
                    {
                      recurrentBooking.map((item, index) => {
                        const { ownerContactId, meetingTitle, timeFrom, timeTo, repeatTimeSlice } = item;
                        const classesName = classNames('popup-booking__owner-name', {
                          'popup-booking__owner-name_meeting': meetingTitle
                        });
                        const classes = classNames('popup-booking__shedule-item', {
                          'popup-booking__shedule-item_meeting': meetingTitle && isMobile
                        });
                        const owner = find(contacts, (contact) => contact.strId === ownerContactId);

                        if (!owner) {
                          return null;
                        }

                        const isWeek = repeatTimeSlice === 'week';
                        const timeName = `${getNameRecurrentDate(item, isWeek)}, ${moment(timeFrom).format('HH:mm')} - ${moment(timeTo).format('HH:mm')}`;

                        return (
                          <div key={index}>
                            <div className={classes} onClick={() => handleClickContact(owner)}>
                            <div className="popup-booking__time">{timeName}</div>
                              {meetingTitle && <div className="popup-booking__title">{meetingTitle}</div>}
                              <div className="popup-booking__owner">
                                {owner.img && <img className="popup-booking__owner-avatar" src={getImgUrl(owner.img)} alt="avatar" />}
                                <div className={classesName}>{owner.name}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </>
              }
              {
                Boolean(contacts.length) && Object.keys(mapBooking).map((item) => {
                  return (
                    <ScheduleItem
                      key={item}
                      currentDate={timeTravelDate}
                      contactsBooking={mapBooking[item]}
                      setViewPopup={setViewPopup}
                      setContact={setContact}
                      contacts={contacts}
                      handleClickContact={handleClickContact}
                    />
                  )
                })
              }
            </div>
          ) : (
            <BookingForm
              deskStrId={strId}
              date={date}
              isAdmin={isAdmin}
              user={user}
              setUser={setUser}
              isRecurrent={isRecurrent}
              isInvisible={isInvisible}
              setInactive={setInactive}
              isInactive={isInactive}
              setInvisible={setInvisible}
              recurrentData={recurrentData}
              setRecurrent={setRecurrent}
              handleChangeDate={handleChangeDate}
              setRecurrentData={setRecurrentData}
              startTime={startTime}
              setStartTime={setStartTime}
              endTime={endTime}
              setEndTime={setEndTime}
              booking={booking}
              status={status}
              title={title}
              setTitle={setTitle}
              contacts={contacts}
              setMembers={setMembers}
              pendingIntervals={pendingIntervals}
              recurrentIntervals={recurrentIntervals}
              availabilityInvisible={availabilityInvisible}
            />
          )
        }
        <div className={classesBottom}>
          {
            (!isMobile || (isMobile && !isViewShedule)) &&
            <Button className="popup-booking__button" onClick={handleBooking} disabled={isDisableButton}>
              Book
            </Button>
          }
          {
            !isViewShedule && !isMobile &&
            <Button className="popup-booking__button" type="ghost" onClick={onClose}>
              Cancel
            </Button>
          }
        </div>
      </div>
    </div>
  );
}

export default PopupBooking;
