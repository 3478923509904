import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

import Button from '../Button/Button';

import useUser from '../../hooks/useUser';
import useCompanies from '../../hooks/useCompanies';
import { default as api } from '../../services/api';
import minutesToHours from '../../services/mitutes-to-hours';

const { deleteCompany } = api

const NAMES = ['Name', 'Description', 'Checkin', 'Checkin before time, min', 'Checkin after time, min',
               'Option cleaning', 'Invisible booking', 'Slug', 'Is demo', '', ''];
const DEFAULT_AFTER_TIME = 15;
const DEFAULT_BEFORE_TIME = 5;

function AdminCompanies() {
  const history = useHistory();

  const { isCompanyAdmin, user } = useUser();
  const { companies, setCompanies } = useCompanies();

  if (isCompanyAdmin) {
    history.replace(`/admin/${user.companyStrId}/users`);
  }

  return (
    <div>
      <div className="adm-buttons">
        <Link to="/root-admin/companies/add">
          <Button>
            Create new company
          </Button>
        </Link>

        <Link to="/root-admin/companies/add-demo">
          <Button>
            Create new Demo company
          </Button>
        </Link>
      </div>

      <table className="table">
        <thead>
          { renderHeader() }
        </thead>
        <tbody>
          { renderCompanies(companies, setCompanies) }
        </tbody>
      </table>
    </div>
  );
}

const renderHeader = () => {
  return (
    <tr>
      { NAMES.map((item, index) => <th key={index}>{item}</th>) }
    </tr>
  );
};

const renderCompanies = (companies = [], setCompanies)=> {
  return (
    companies.map((item, index) => {
      const {
        title,
        desc,
        slug,
        strId,
        isDemo,
        allowInvisibleBooking,
        optionCleaning,
        optionCheckin,
        optionCheckinTime,
        optionCheckinExpireTime
      } = item;
      const beforeTime = minutesToHours(optionCheckinTime || DEFAULT_BEFORE_TIME);
      const afterTime = minutesToHours(optionCheckinExpireTime || DEFAULT_AFTER_TIME);
      const newCompanies = cloneDeep(companies);

      const handleDelete = () => {
        deleteCompany(strId);
        newCompanies.splice(index, 1);

        setCompanies(newCompanies);
      };

      return (
        <tr key={index}>
          <td>{title}</td>
          <td>{desc}</td>
          <td>{optionCheckin}</td>
          <td>{beforeTime}</td>
          <td>{afterTime}</td>
          <td>{optionCleaning}</td>
          <td><input type="checkbox" disabled checked={allowInvisibleBooking}/></td>
          <td>{slug}</td>
          <td><input type="checkbox" disabled checked={isDemo}/></td>
          <td>
            <Link to={`/root-admin/companies/edit/${strId}`}>
              <Button type="edit">Edit</Button>
            </Link>
          </td>
          <td>
            <Button type="delete" onClick={handleDelete}>Delete</Button>
          </td>
        </tr>
      );
    })
  );
};

export default AdminCompanies;
