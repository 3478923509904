import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';

// import { isMobile } from 'react-device-detect';
import { useMobile } from '../../hooks/useDetectMobile';

import { useHistory } from 'react-router';
import cs from 'classnames'

import DropDown from '../DropDown/DropDown';
import DropDownUser from '../DropDownUser/DropDownUser';
import useTeamsFlag, { goAndAuthenticate, notifySuccess } from '../../hooks/useTeams'


import {
  LogoIcon,
  BigLogoIcon,
  DeskIcon,
  FloorsIcon,
  SearchIcon,
  BuildingsIcon,
  ArrowLongIcon,
  ProfileIcon,
  ScanIcon,
  ClearIcon,
  IconVirtual,
} from '../../services/svg-icons';

import './nav.css';

function Nav(props) {
  const {
    buildings, floors, avatar, userName, companyStrId, isCompanyAdmin, setBuilding,
    setFloor, setViewScan, isAdmin, userStrId, companySlug, virtualFloors
  } = props;

  const history = useHistory();

  const [viewBuildings, setViewBuilding] = useState(false);
  const [viewFloors, setViewFloors] = useState(false);
  const [viewUserInfo, setViewUserInfo] = useState(false);
  const [viewVirtualFloors, setViewVirtualFloors] = useState(false);

  const hideFloors = () => setViewFloors(false);
  const hideUserInfo = () => setViewUserInfo(false);
  const hideBuildings = () => setViewBuilding(false);
  const hideVirtualRooms = () => setViewVirtualFloors(false);
  const handleClickFloor = (item) => {
    hideFloors();
    setFloor(item);
  };

  const isMobile = useMobile()

  const handleClickBuilding = (item) => {
    hideBuildings();
    setBuilding(item);
    isMobile && setViewFloors(true);
  };

  const handleClickVirtualFloor = (item) => {
    hideVirtualRooms();
    history.push(`/virtual/${item.strId}`)
  }
  const handleClickBuildingIcon = () => {
    setViewBuilding(!viewBuildings)
    setViewVirtualFloors(false)
    setViewUserInfo(false)
    setViewFloors(false)
  }
  const handleClickFloorIcon = () => {
    setViewFloors(!viewFloors);
    setViewBuilding(false)
    setViewVirtualFloors(false)
    setViewUserInfo(false)
  }
  const handleClickUserIcon = () => {
    setViewUserInfo(!viewUserInfo)
    setViewVirtualFloors(false)
    setViewFloors(false)
    setViewBuilding(false)
  }
  const handleClickVirtualFloorIcon = () => {
    setViewVirtualFloors(!viewVirtualFloors)
    setViewUserInfo(false)
    setViewFloors(false)
    setViewBuilding(false)
  }
  const teamsFlag = useTeamsFlag()

  let link = '';

  const buildingDisabled = !buildings || buildings.length === 0
  const floorsDisabled = !floors || floors.length === 0

  if (isAdmin) {
    link = '/root-admin';
  } else if (isCompanyAdmin) {
    link = `/admin/${companyStrId}`;
  }

  const buildingsClassName = cs({
    ['nav-item']: true,
    ['nav-item__disabled']: !buildings || buildings.length === 0,
    ['nav-item__teams']: teamsFlag === 'teams' && !isMobile,
  })

  const floorsClassName = cs({
    ['nav-item']: true,
    ['nav-item__disabled']: !floors || floors.length === 0,
    ['nav-item__teams']: teamsFlag === 'teams',
  })

  const navTeams = cs({
    ['nav']: true,
    ['nav__teams']: teamsFlag === 'teams'
  })

  return (
    <nav className={navTeams}>
      {
        !isMobile && link && teamsFlag === 'teams' &&
        <Link to={link}>
          <BigLogoIcon className="nav-logo_big"/>
        </Link>
      }
      {
        !isMobile && link && teamsFlag === 'no-teams' &&
        <Link to={link}>
          <LogoIcon className="nav-logo"/>
        </Link>
      }
      {
        !isMobile && !link &&
        <LogoIcon className="nav-logo"/>
      }
      <div className="nav-items">
        {
          !isMobile &&
          <div className={cs("nav-item", {['nav-item__teams']: teamsFlag === 'teams'})} onClick={handleClickVirtualFloorIcon}>
            <IconVirtual />
            {teamsFlag === 'teams' && <p>Virtual floor</p> }
          </div>
        }
        {
          viewVirtualFloors && virtualFloors && virtualFloors.length > 0 &&
          <DropDown
            className="nav-item__drop-down-buildings drop_floors "
            options={virtualFloors}
            Icon={IconVirtual}
            onHide={hideVirtualRooms}
            onClick={handleClickVirtualFloor}
            MobileHeader={() => virtualMobileHeader(hideVirtualRooms)}
          />
        }
        <div className={buildingsClassName} >
          <BuildingsIcon onClick={handleClickBuildingIcon}/>
          {isMobile && <span>Buildings</span>}
          {buildingDisabled && <div className={cs("nav-item nav-item__disabled-desc", {['nav-item nav-item__disabled-desc--teams']: teamsFlag === 'teams'})}>
            <p>Upgrade to Enterprise account to use full Hybrid functionality.</p>
            <p><a href="https://www.ofistic8.com/contact-us/" target="blank">Contact us</a> to learn more</p>
          </div>}
          {teamsFlag === 'teams' && !isMobile && <p onClick={handleClickBuildingIcon}>Buildings</p> }
        </div>
        {
          viewBuildings && buildings && buildings.length > 0 &&
          <DropDown
            className="nav-item__drop-down-buildings drop_building"
            options={buildings}
            Icon={BuildingsIcon}
            onHide={hideBuildings}
            onClick={handleClickBuilding}
            MobileHeader={() => buildingsMobileHeader(hideBuildings)}
          />
        }
        { isMobile &&
        <Fragment>
          <div className="nav-item">
            <IconVirtual onClick={handleClickVirtualFloorIcon}/>
            <span>Virtual</span>
          </div>
            { teamsFlag !== 'teams' &&
            <div className="nav-item" onClick={() => setViewScan(true)}>
              <ScanIcon/>
              <span>Scan</span>
            </div>
            }
            <div className={cs(
            "nav-item nav-item__disabled",
          {"__hide": teamsFlag === 'teams'})}>
            <DeskIcon/>
            <div className="nav-item nav-item__disabled-desc">
            <p>Coming soon</p>
            </div>
            <span>Booking</span>
            </div>
          {/*<div className="nav-item">*/}
          {/*  <SearchIcon/>*/}
          {/*  <span>Search</span>*/}
          {/*</div>*/}
            <div className="nav-item" onClick={handleClickUserIcon}>
              <ProfileIcon />
              <span>Profile</span>
            </div>
          </Fragment>
        }
        {
          !isMobile &&
          <div className={floorsClassName} >
            <FloorsIcon onClick={handleClickFloorIcon}/>
            {floorsDisabled && <div className={cs("nav-item nav-item__disabled-desc", {['nav-item nav-item__disabled-desc--teams']: teamsFlag === 'teams'})}>
              <p>Upgrade to Enterprise account to use full Hybrid functionality.</p>
              <p><a href="https://www.ofistic8.com/contact-us/" target="blank">Contact us</a> to learn more</p>
            </div>}
            {teamsFlag === 'teams' && <p onClick={handleClickFloorIcon}>Floor</p>}
          </div>
        }
        {
          viewFloors && floors && floors.length > 0 &&
          <DropDown
            className="nav-item__drop-down-floors"
            options={floors}
            Icon={FloorsIcon}
            isModifyIcons
            onHide={hideFloors}
            onClick={handleClickFloor}
            MobileHeader={() => floorsMobileHeader(handleClickBuildingIcon)}
          />
        }
      </div>
      {!isMobile &&
        <div className={cs("nav-item nav-item__disabled", {['nav-item__teams']: teamsFlag === 'teams'}, {"__hide": teamsFlag === 'teams'})}>
          <DeskIcon/>
          <div className={cs("nav-item nav-item__disabled-desc", {['nav-item__teams-disabled-desc']: teamsFlag === 'teams'})}>
            <p>Coming soon</p>
          </div>
          {teamsFlag === 'teams' && <p>Booking</p>}
        </div>
      }
      {
        !isMobile &&
        <div className={cs("nav-item nav-item__disabled", {['nav-item__teams']: teamsFlag === 'teams'}, {"__hide": teamsFlag === 'teams'})}>
          <SearchIcon/>
          <div className={cs("nav-item nav-item__disabled-desc", {['nav-item__teams-disabled-desc']: teamsFlag === 'teams'})}>
            <p>Coming soon</p>
          </div>
          {teamsFlag === 'teams' && <p>Search</p>}
        </div>
      }
      {
        !isMobile &&
        <div className={cs("nav-user", {['nav-user__teams']: teamsFlag === 'teams'})} onClick={handleClickUserIcon}>
          <div className="nav-avatar-image" style={{ backgroundImage: `url(${avatar})` }}/>
          {teamsFlag === 'teams' && <p>{userName}</p>}
        </div>
      }
      {
        viewUserInfo &&
        <DropDownUser
          isAdmin={isAdmin}
          userStrId={userStrId}
          onClose={hideUserInfo}
          companySlug={companySlug}
          isCompanyAdmin={isCompanyAdmin}
        />
      }
    </nav>
  );
}

const buildingsMobileHeader = (hideBuildings) => {
  return (
    <div className="drop-down__header">
      <ClearIcon className="drop-down__close" onClick={hideBuildings} />
      <div className="drop-down__header-name">Book in</div>
      <SearchIcon className="drop-down__header-icon"/>
    </div>
  );
};

const floorsMobileHeader = (hideFloors) => {
  return (
    <div className="drop-down__header">
      <ArrowLongIcon className="drop-down__icon-back" onClick={hideFloors}/>
      <div className="drop-down__header-name">Floors</div>
      <SearchIcon className="drop-down__header-icon"/>
    </div>
  );
};

const virtualMobileHeader = (hideRooms) => {
  return (
    <div className="drop-down__header">
      <ArrowLongIcon className="drop-down__icon-back" onClick={hideRooms}/>
      <div className="drop-down__header-name">Virtual floors</div>
      <SearchIcon className="drop-down__header-icon"/>
    </div>
  );
};

export default Nav;
