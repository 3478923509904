import React from 'react';
import useAuth from '../../auth/useAuth'
import useTeamsFlag, { notifySuccess } from '../../hooks/useTeams'

/* page for redirect in popup teams after login */
export default function LoginRedirect() {
  let args = undefined
  if (localStorage.getItem('wkpgMode') === 'signin') {
    args = { overrideSlug: true }
  }
  useTeamsFlag()

  /* need fix refresh */
  const {
    isLoading,
    getIdTokenClaims,
    isAuthenticated
  } = useAuth(args)

  if (isLoading) {
    return <div></div>
  }

  return isAuthenticated && getIdTokenClaims()
    .then(r => {
      return notifySuccess(r)
    })
    .catch(e => {
      return notifySuccess()
    })
};
