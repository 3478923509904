import React, { useRef, useState } from 'react';

import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button'
import { default as api } from '../../services/api'

const { sendEmail } = api

export default function Forgot() {
  const inputRef = useRef()
  const [flagNoEmail, setFlagNoEmail] = useState(false)
  const [flagNoValidEmail, setFlagNoValidEmail] = useState(false)
  const [flagCheckEmail, setFlagCheckEmail] = useState(false)
  const [infoText, setInfoText] = useState('')


  const onSubmit = async (e) => {
    if (e) {
      e.preventDefault()
    }

    if (!inputRef.current) {
      return
    }

    const input = inputRef.current
    const userEmail = input.value
    if (!userEmail.length) {
      setFlagNoEmail(true)
      setTimeout(() => setFlagNoEmail(false), 2000)
      return
    }

    const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailValid.test(String(userEmail).toLowerCase())) {
      setFlagNoValidEmail(true)
      setTimeout(() => setFlagNoValidEmail(false), 2000)
      return
    }

    const answer = await sendEmail(userEmail)
    if (answer === 'Error') {
      setInfoText('This email was not found')
    } else {
      setInfoText('Please check your email')
    }

    setFlagCheckEmail(true)
    setTimeout(() => setFlagCheckEmail(false), 10000)
  }

  return (
    <div className="login login__row login__forgot">
      <div className="login__form login__form__row">
        <div className="login__form-wrapper">
          <form onSubmit={onSubmit}>
            <section className="sp-section">
              <div className="sp-header">Forgot company address?</div>

              <div className="sp-subheader">Enter your email</div>
              <Input
                noLabel
                name="email"
                placeholder="mycompany@mail.ru"
                inputRef={inputRef}
              />

              <div className="sp-buttons">
                <Button
                  className="login-button"
                  onClick={onSubmit}
                >
                  Next
                </Button>
                { flagNoEmail && <span className="sp-submit-msg">
                  Enter your email
                </span>}
                { flagNoValidEmail && <span className="sp-submit-msg">
                  Invalid email
                </span>}
              </div>
                { flagCheckEmail &&
                  <p className="sp-submit-msg">{infoText}</p>
                }
            </section>
          </form>
        </div>
      </div>
    </div>
  )
}
