import React, { useState, useRef } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';

// import { isMobile } from 'react-device-detect';
import { useMobile } from '../../hooks/useDetectMobile';

import Button from '../Button/Button';
import DatePickerInput from '../DatePickerInput/DatePickerInput';
import DatePickerHeader from '../DatePickerHeader/DatePickerHeader';

import { TimerIcon, ClearIcon } from '../../services/svg-icons';
import generateHours from '../../services/generate-hours';

import useOutsideClick from '../../hooks/useOutsideClick';
import useTeamsFlag from '../../hooks/useTeams'

export default function InfoPanelTime(props) {
  const {
    isOpen,
    date,
    onOpen,
    time,
    setTime,
    setDate,
    setCurrentDate,
    viewDate,
    setViewDate,
    setDateChanged
  } = props;

  const ref = useRef(null);
  const [viewTimePicker, setViewTimePicker] = useState(false);

  const { h, m } = time;
  const resultDate = moment(date).set({ h, m, s: 0 });

  const times = generateHours();
  const handleChangeDate = (date, e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    setDate(date);
  };

  const handleViewPlan = () => {
    onOpen(false);
    setViewDate(true);
    setCurrentDate(resultDate);
    setDateChanged(true);
  };

  const handleClickClearTime = () => {
    setViewDate(false);
    setCurrentDate(moment());
    setDateChanged(true);
  };

  const setHide = () => {
    if (!viewTimePicker) {
      onOpen(false);
    }
  };

  const teamsFlag = useTeamsFlag()
  const isMobile = useMobile()

  const checkTeams = () => {
    return isMobile && teamsFlag === 'teams'
  }
  useOutsideClick(ref, setHide, isMobile);

  const classesTimeButton = classNames('info-panel__button', {
    'info-panel__button_active': viewDate
  });

  return (
    <div className="info-panel__time">
      { !checkTeams() &&
        <Button className={classesTimeButton}>
          <div className="info-panel__time-holder" onClick={() => onOpen(true)}>
            <TimerIcon className="info-panel__icon_light"/>
            <span className="info-panel__button-lable">
              {
                viewDate ?
                  resultDate.format('DD.MM.YYYY HH:mm') :
                  'Time travel'
              }
            </span>
          </div>

          {
            viewDate &&
            <ClearIcon
              onClick={handleClickClearTime}
              className="info-panel__icon_light info-panel__icon_mini"
            />
          }
        </Button>
      }
      {
        isOpen && isMobile &&
        <div className="popup__background" onClick={() => onOpen(false)}/>
      }

      {
        isOpen &&
        <div className="info-panel__time-form" ref={ref}>
          <div className="info-panel__control">
            <span className="info-panel__label">Date</span>
            <DatePicker
              customInput={<DatePickerInput />}
              renderCustomHeader={DatePickerHeader}
              dateFormat="MMMM dd, yyyy"
              selected={date}
              onChange={handleChangeDate}
            />
          </div>

          <div className="info-panel__control">
            <span className="info-panel__label">Time</span>
            <input className="input" value={time.h ? `${time.h}:${time.m}` : ''} readOnly
                    onClick={() => setViewTimePicker(!viewTimePicker)}/>
            <TimerIcon className="info-panel__icon-input" />

            {
              viewTimePicker &&
              <div className="time-picker__popup">
                <header className="time-picker__popup-header">Select time</header>
                <div className="time-picker__popup-body">
                  {
                    times.map((item) => {
                      const { h, m } = item;
                      const classesTimePickerButton = classNames('time-picker__button time-picker__button_inverse', {
                        'time-picker__button_select-info': h === time.h && m === time.m
                      });
                      const handleClickTimeButton = () => {
                        setTime(item);
                        setViewTimePicker(false);
                      };

                      return (
                        <Button className={classesTimePickerButton} onClick={handleClickTimeButton}>
                          {h}:{m}
                        </Button>
                      );
                    })
                  }
                </div>
              </div>
            }
          </div>

          <div className="info-panel__time-buttons">
            <Button onClick={handleViewPlan} disabled={!date || !time.h}>View</Button>
          </div>
        </div>
      }
    </div>
  );
};
