import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

import Select from '../Select/Select';
import Button from '../Button/Button';

import { default as api } from '../../services/api';
import useUser from '../../hooks/useUser';
import useCompanies from '../../hooks/useCompanies';
import { ADMIN } from '../../constants/userRoles';

const {
  fetchContactsByCompany,
  fetchContactsWithoutCompany,
  deleteContact,
  getImgUrl,
} = api

const NAMES = ['Avatar', 'StrId', 'Name', 'Roles', 'Status', 'Position', 'Message', 'E-Mail', 'Call Link', '', ''];

function AdminContacts(props) {
  const { isOnlyAdmins } = props;

  const { companies } = useCompanies();
  const { user, isCompanyAdmin, isAdmin } = useUser();

  const savedCompany = JSON.parse(localStorage.getItem('currentCompany'));
  const defaultCompany = savedCompany || companies[0];

  const [contacts, setContacts] = useState([]);
  const [currentCompany, setCurrentCompany] = useState(defaultCompany);

  const getTypeLabel = (type) => type.title;
  const getTypeValue = (type) => type.strId;
  const getContactsByCompany = (id) => fetchContactsByCompany(id).then((res) => {
    const resultContacts = isOnlyAdmins ? res.filter((item) => !item.companyStrId) : res;
    setContacts(resultContacts);
  });

  const getUsersWithoutCompany = () => fetchContactsWithoutCompany().then(res => {
    const resultContacts = isOnlyAdmins ? res.filter((item) => !item.companyStrId) : res;
    setContacts(resultContacts);
  })

  useEffect(() => {
    if (currentCompany) {
      if (currentCompany.withoutCompany) {
        getUsersWithoutCompany()
      } else {
        getContactsByCompany(currentCompany.strId);
        localStorage.setItem('currentCompany', JSON.stringify(currentCompany));
      }
    }
  }, [currentCompany]);

  useEffect(() => {
    if (isCompanyAdmin) {
      const userCompany = companies.find((item) => item.strId === user.companyStrId);
      setCurrentCompany(userCompany);
    }
  }, [isCompanyAdmin, companies]);

  const link = `/${isAdmin ? 'root-' : ''}admin${isCompanyAdmin ? `/${currentCompany?.strId}` : ''}/users/add/${!isCompanyAdmin ? currentCompany?.strId : ''}`;

  return (
    <div>
      {
        !isCompanyAdmin &&
        <Select
          label="Company"
          options={[
            ...companies,
            {
              withoutCompany: true,
              slug: "Users without company",
              title: "Users without company",
            }
          ]}
          value={currentCompany}
          getOptionLabel={getTypeLabel}
          getOptionValue={getTypeValue}
          onChange={setCurrentCompany}
        />
      }

      {
        currentCompany && !isOnlyAdmins &&
        <div className="adm-buttons">
          <Link to={link}>
            <Button>
              Create new user
            </Button>
          </Link>
        </div>
      }

      <table className="table">
        <thead>
          { renderHeader() }
        </thead>
        <tbody>
          { renderContacts(contacts, setContacts, isCompanyAdmin, isAdmin, currentCompany?.strId) }
        </tbody>
      </table>
    </div>
  );
}

const renderHeader = () => {
  return (
    <tr>
      { NAMES.map((item, index) => <th key={index}>{item}</th>) }
    </tr>
  );
};

const renderContacts = (contacts, setContacts, isCompanyAdmin, isAdmin, companyStrId) => {
  return (
    contacts.map((item, index) => {
      const { name, position, message, email, img, strId, status, roles, callLink } = item;
      const isAdminContact = roles ? roles.includes(ADMIN) : false;
      const isHighLevel = (isCompanyAdmin && !isAdminContact) || !isCompanyAdmin;
      const strRoles = roles ? roles.reduce((acc, item) => acc + `${acc ? ', ' : ''}${item}`,'') : '';
      const newContacts = cloneDeep(contacts);
      const handleDelete = () => {
        deleteContact(strId);
        newContacts.splice(index, 1);

        setContacts(newContacts);
      };
      const link = `/${isAdmin ? 'root-' : ''}admin${!isAdmin ? `/${companyStrId}` : ''}/users/edit/${strId}`;

      return (
        <tr key={index}>
          <td>
            {img && <img className="adm_user-ava" src={getImgUrl(img)} alt="avatar"/>}
          </td>
          <td>{strId}</td>
          <td>{name}</td>
          <td>{strRoles}</td>
          <td>{status}</td>
          <td>{position}</td>
          <td>{message}</td>
          <td>{email}</td>
          <td>{callLink}</td>
          <td>
            {
              isHighLevel &&
              <Link to={link}>
                <Button type="edit">
                  Edit
                </Button>
              </Link>
            }
          </td>
          <td>
            {
              isHighLevel &&
              <Button type="delete" onClick={handleDelete}>Delete</Button>
            }
          </td>
        </tr>
      );
    })
  );
};

export default AdminContacts;
