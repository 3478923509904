import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './button.css';

const Button = (props) => {
  const {
    className, link, outerLink, onClick, onTouch, children, disabled, type, target, id
  } = props;

  const classes = classNames('button', className, {
    [`button_${type}`]: type
  });

  if (outerLink) {
    return (
      <a className={ classes } id={id} href={ outerLink } onClick={ onClick } onTouchStart={ onTouch } target={ target }>
        {children}
      </a>
    )
  }

  if (link) {
    return (
      <Link className={ classes } id={id} to={ link } onClick={ onClick } onTouchStart={ onTouch } >
        {children}
      </Link>
    )
  }

  return (
    <button className={ classes } id={id} onClick={ onClick } onTouchStart={ onTouch } disabled={ disabled }>
      {children}
    </button>
  )
}

export default Button;
