export default [
  {
    id: 1,
    name: 'day'
  },
  {
    id: 2,
    name: 'week'
  }
];
