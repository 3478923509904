import React from 'react';

import BuildingForm from '../../components/BuildingForm/BuildingForm';

export default function AddBuilding() {
  return (
    <div>
      <BuildingForm />
    </div>
  );
};
