import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { default as api } from '../../services/api'

const { fetchPalette } = api
function PaletteItem({ item, selectItem, isSelected }) {
  const imgStyle = {
    backgroundImage: `url(${item.img})`,
  }

  const cs = classNames({
    'b-palette-item': true,
    'b-palette-item__selected': isSelected,
  })

  const onClick = () => {
    if (isSelected) {
      selectItem(null)
    } else {
      selectItem(item)
    }
  }

  return (
    <div className={cs} onClick={onClick} >
      <div className="b-palette-item_image" style={imgStyle} ></div>
      <div className="b-palette-item_title" >{item.title}</div>
    </div>
  )
}

export default function MapPalette({ selectedItem, selectItem }) {
  const [paletteItems, setPaletteItems] = useState([])

  useEffect(() => {
    selectItem(null)
    fetchPalette()
    .then((items) => setPaletteItems(items))
    .catch((err) => { /* TODO: error handling */ })
    return () => selectItem(null)
  }, [])

  return (
    <div className="b-palette_items">
      {paletteItems.map(i => (
        <PaletteItem
          key={i.id}
          item={i}
          selectItem={selectItem}
          isSelected={selectedItem === i}
        />
      ))}
    </div>
  )
}
