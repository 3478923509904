import React, { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import ContactItem from './ContactItem';
import Button from '../../components/Button/Button';
import AdminAccess from '../../components/AdminAccess'

import { default as api } from '../../services/api';
import useUser from '../../hooks/useUser';
import { toObject } from '../../services/entity-format';
import { JANITOR, COMPANY_ADMIN } from '../../constants/userRoles';

import './Start.css';

const {
  delDemoBooking,
  fetchUsersRoleByFloor,
  fetchFloorsByBuilding,
  fetchContactsByCompany,
  fetchDemoContactsByCompany,
  fetchBuildingsByCompany,
  fetchCompanyByStrId,
  fetchRolesByContact
} = api
const DEMO_TITLE = 'Workipelago staging';
const DEMO_COMPANY_ID = 'K3Tk5linyK1yDah0scoVQ';
const DEMO_FLOOR_ID = '6NK-VJYdvJMjVaFFqpTIk';

export default function Start(props) {
  const { company, desk } = props;
  const history = useHistory();
  const { user, setUser, redirectToFloor } = useUser();

  const [title, setTitle] = useState('');
  const [admins, setAdmins] = useState([]);
  const [janitors, setJanitors] = useState([]);
  const [deskList, setDeskList] = useState([]);
  const [noDeskList, setNoDeskList] = useState([]);
  const [demoFloorId, setDemoFloorId] = useState('');
  const [demoCompanyId, setDemoCompanyId] = useState('');
  const [isPendingUsers, setPendingUsers] = useState(true);
  const [isPendingAdmins, setPendingAdmins] = useState(true);
  const [isPendingJanitors, setPendingJanitors] = useState(true);

  const setDemoCompanyInfo = () => {
    setDemoCompanyId(DEMO_COMPANY_ID);
    setDemoFloorId(DEMO_FLOOR_ID);
    setTitle(DEMO_TITLE);
  };

  useEffect(() => {
    if (user) {
      redirectToFloor(desk);
    }
  }, [user, desk]);

  useEffect(() => {
    if (company) {
      fetchCompanyByStrId(company.strId).then((c) => {
        const { isDemo, strId, title } = c;

        if (isDemo) {
          setTitle(`Company "${title}"`);
          setDemoCompanyId(strId);

          fetchBuildingsByCompany(strId).then((buildings) => {
            if (buildings[0]) {
              fetchFloorsByBuilding(buildings[0].strId).then((floors) => {
                if (floors[0]) {
                  setDemoFloorId(floors[0].strId);
                }
              });
            }
          });
        } else {
          setDemoCompanyInfo();
        }
      }).catch(setDemoCompanyInfo);
    } else {
      setDemoCompanyInfo();
    }
  }, [company]);

  const refreshDemoUsers = () => {
    setPendingUsers(true);

    fetchDemoContactsByCompany(demoCompanyId)
      .then(async ({ withPermanentDesk, withoutPermanentDesk }) => {
        const deskList = [];
        const noDeskList = [];

        for (let desk of withPermanentDesk) {
          const res = await fetchRolesByContact(desk.strId);
          deskList.push(res);
        }

        for (let desk of withoutPermanentDesk) {
          const res = await fetchRolesByContact(desk.strId);
          noDeskList.push(res);
        }

        setNoDeskList(noDeskList);
        setDeskList(deskList);
        setPendingUsers(false);
      })
      .catch((e) => {
        setPendingUsers(false);
        console.log('error', e);
      });
  };

  const refreshDemoJanitors = () => {
    setPendingJanitors(true);

    fetchContactsByCompany(demoCompanyId).then((contacts) => {
      const { data } = toObject(contacts, { keyField: 'strId' });

      fetchUsersRoleByFloor(demoFloorId, JANITOR).then((janitors) => {
        const currentJanitors = janitors.reduce((acc, item) => data[item] && acc.length < 4 ? [...acc, data[item]] : acc, []);

        setJanitors(currentJanitors);
        setPendingJanitors(false);
      })
    }).catch((e) => {
      setPendingJanitors(false);
      console.log('error', e);
    });
  };

  const refreshDemoAdmins = () => {
    setPendingAdmins(true);

    fetchContactsByCompany(demoCompanyId).then((contacts) => {
      let result = [];

      for (let contact of contacts) {
        if (contact.roles.includes(COMPANY_ADMIN)) {
          result.push(contact);

          if (result.length === 4) {
            break;
          }
        }
      }

      setAdmins(result);
      setPendingAdmins(false);
    }).catch((e) => {
      setPendingAdmins(false);
      console.log('error', e)
    });
  };

  useEffect(() => {
    if (demoFloorId && demoCompanyId) {
      refreshDemoUsers();
      refreshDemoJanitors();
      refreshDemoAdmins();
    }
  }, [demoCompanyId, demoFloorId]);

  const deleteDemoBooking = ({ strId, url }) => delDemoBooking(strId)
    .then(() => history.push(url))
    .catch((e) => {
      console.log('demo delete error', e);
      history.push(url);
    });

  const onClick = (contact, isJanitor) => {
    const { strId } = contact;
    const url = isJanitor ? '/janitor' : `/floor/${demoFloorId}`;

    setUser(contact);
    localStorage.setItem('userStrId', strId);
    localStorage.setItem('companyStrId', company.strId);
    deleteDemoBooking({ strId, url });
  }

  const onClickJanitor = (contact) => onClick(contact, true);

  const blocks = [
    {
      title: 'Users with permanent desk',
      list: deskList,
      isPending: isPendingUsers,
      handleClick: onClick
    },
    {
      title: 'Users without permanent desk',
      list: noDeskList,
      isPending: isPendingUsers,
      handleClick: onClick
    },
    {
      title: 'Janitors',
      list: janitors,
      isPending: isPendingJanitors,
      handleClick: onClickJanitor
    },
    {
      title: 'Company admins',
      list: admins,
      isPending: isPendingAdmins,
      handleClick: onClick
    }
  ];

  return (
    <div className="b-start">
      <AdminAccess/>
      <header className="b-start_header">{title}</header>
      <div className="b-start_subheader">Login as</div>
      <div className="b-start_login-options">
        {
          blocks.map((block, index) => {
            const { title, list, handleClick, isPending } = block;
            return (
              <Fragment key={index}>
                <header className="b-start_login-option_header">{title}</header>
                {
                  isPending ?
                  <ContentLoader
                    width={360}
                    height={97}
                    viewBox="0 0 360 97"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#cbcbcb"
                  >
                    <circle cx="45" cy="45" r="35" />
                    <circle cx="135" cy="45" r="35" />
                    <circle cx="225" cy="45" r="35" />
                    <circle cx="315" cy="45" r="35" />
                  </ContentLoader> :
                  <div className="b-start_login-option_accounts">
                    {
                      list.map((i, key) =>
                        
                        <ContactItem key={key} contact={i} onLoginAs={handleClick} />
                      )
                    }
                  </div>
                }
              </Fragment>
            );
          })
        }
        <div className="b-start_buttons">
          <Button type="ghost" onClick={refreshDemoUsers}>
            Reshuffle users
          </Button>
        </div>
      </div>
    </div>
  );
};
