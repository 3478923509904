// import './StartPage.css'
import React, { useRef, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import find from 'lodash.find'

import useUser from '../../hooks/useUser';
import { BigLogoIcon } from '../../services/svg-icons';

import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button'

import useCompanies from '../../hooks/useCompanies';

function hasRole(roles, role) {
  return Boolean(roles && roles.indexOf(role) > -1)
}

export default function SignIn() {
  const { user } = useUser()
  const { companies } = useCompanies()
  const inputRef = useRef()

  const [redirectTo, setRedirectTo] = useState('')
  const [flagNotFound, setFlagNotFound] = useState(false)

  // useEffect(() => {
  //   setFlagNotFound(false)
  // }, [companyName])

  const onNext = async (e) => {
    if (e) {
      e.preventDefault()
    }

    if (!inputRef.current) {
      return
    }

    const input = inputRef.current
    const companyName = input.value
    const company = find(companies, { slug: companyName })

    if (company) {
      setTimeout(() => setRedirectTo(`/configure-company/${companyName}`), 0)
    } else {
      setFlagNotFound(true)
      setTimeout(() => setFlagNotFound(false), 2000)
    }
  }

  useEffect(() => {
    /*
      Если юзер авторизован
      И у него есть компания
      То редиректить сразу на

      и там проверить что авторизация правильно подтягивается
    */
    if (!user || !user.companyStrId) {
      return
    }

    const company = find(companies, { strId: user.companyStrId })
    if (company) {
      setRedirectTo(`/configure-virtual-floor/${company.slug}`)
    }

  }, [user, companies])


  /* */

  // const onViewDemo = async(e) => {
  //   setRedirectTo('/login/demo')
  // }

  if (redirectTo) {
    // alert(`Redirect: ${redirectTo}`)
    return <Redirect to={redirectTo} />
  }

  // if (user && user.roles && hasRole(user.roles, 'admin')) {
  //   return <Redirect to="/root-admin" />
  // }

  // if (user) {
  //   return <Redirect to="/not-company-profile" />
  // }

  return (
      <div className="login login__row">
      <div className="login__form login__form__row">
        <div className="login__form-wrapper">
          <form onSubmit={onNext}>
            <section className="sp-section">
              <div className="sp-header">Tab configuration</div>
              <div className="sp-subheader">Continue to your company</div>

              <div className="sp-label">
                Company address
                <div className="sp-company-address">
                  <Input
                    noLabel
                    name="company-name"
                    placeholder="mycompany"
                    inputRef={inputRef}
                  />
                  <span>
                    .workipelago.com
                  </span>
                </div>
              </div>


              <div className="sp-buttons">
                <Button
                  className="login-button"
                  onClick={onNext}
                >
                  Next
                </Button>

                { flagNotFound && <span className="sp-submit-msg">
                  Company not found
                </span>}
              </div>

              <div className="sp-subheader sp-section__margined">
                New to Workipelago? <a href="/signup" className="login_link" >Get Started</a>
                <br/>
                <br/>
                <a href="/forgot" className="login_link" >Forgot company address?</a>
              </div>

            </section>
          </form>
        </div>
      </div>
    </div>
  )
}
