import React from 'react';
import ReactSelect from 'react-select';

export default function Select(props) {
  const { label, ...restProps } = props;

  return (
    <div className="add-form-select">
      {label && <span>{label}</span>}

      <ReactSelect {...restProps} />
    </div>
  );
};
