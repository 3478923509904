import React, { useEffect, useState } from 'react';

import Static from '../../containers/Static';

import getTermsHtml from '../../services/get-terms-html';

export default function Terms() {
  const [content, setContent] = useState('');

  useEffect(() => {
    getTermsHtml().then(setContent);
  }, []);

  return <Static data={content} />;
};
