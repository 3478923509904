import AdminBooking from '../components/AdminBooking/AdminBooking';
import AdminBuildings from '../components/AdminBuildings/AdminBuildings';
import AdminCleaning from '../components/AdminCleaning/AdminCleaning';
import AdminCompanies from '../components/AdminCompanies/AdminCompanies';
import AdminContacts from '../components/AdminContacts/AdminContacts';
import AdminDesks from '../components/AdminDesks/AdminDesks';
import AdminFloors from '../components/AdminFloors/AdminFloors';
import AdminMeetings from '../components/AdminMeetings/AdminMeetings';
import AdminDomains from "../components/AdminDomains/AdminDomains";
import AdminVirtualFloors from '../components/AdminVirtualFloors/AdminVirtualFloors';
import AddBuilding from '../pages/AddBuilding/AddBuilding';
import AddCompany from '../pages/AddCompany/AddCompany';
import AddDemoCompany from '../pages/AddDemoCompany/AddDemoCompany';
import AddFloor from '../pages/AddFloor/AddFloor';
import AddUser from '../pages/AddUser/AddUser';
import AddVirtualRoom from '../pages/AddVirtualRoom/AddVirtualRoom';
import EditBuilding from '../pages/EditBuilding/EditBuilding';
import EditCompany from '../pages/EditCompany/EditCompany';
import EditDesk from '../pages/EditDesk/EditDesk';
import EditFloor from '../pages/EditFloor/EditFloor';
import EditMeeting from '../pages/EditMeeting/EditMeeting';
import EditUser from '../pages/EditUser/EditUser';
import EditVirtualRoom from '../pages/EditVirtualRoom/EditVirtualRoom';

import withAdmin from '../hoc/withAdmin';

export default (name, params) => [
  {
    path: '/virtual/edit/:companyId/:id',
    component: EditVirtualRoom
  },
  {
    path: '/virtual/add/:companyId',
    component: AddVirtualRoom
  },
  {
    path: '/virtual',
    component: AdminVirtualFloors
  },

  {
    path: '/floors/edit/:id',
    component: EditFloor
  },
  {
    path: '/floors/add/:buildingId',
    component: AddFloor
  },
  {
    path: '/:companyId/floors/:floorId/desks/:id/edit',
    component: EditDesk
  },
  {
    path: '/:companyId/floors/:floorId/desks',
    component: AdminDesks
  },
  {
    path: '/:companyId/floors/:floorId/meetings/:id/edit',
    component: EditMeeting
  },
  {
    path: '/:companyId/floors/:floorId/meetings',
    component: AdminMeetings
  },
  {
    path: '/floors',
    component: AdminFloors
  },

  {
    path: '/users/edit/:id',
    component: EditUser
  },
  {
    path: '/users/add/:companyId',
    component: AddUser
  },
  {
    path: '/users',
    component: AdminContacts
  },

  {
    path: '/buildings/edit/:id',
    component: EditBuilding
  },
  {
    path: '/buildings/add/:companyId',
    component: AddBuilding
  },
  {
    path: '/buildings',
    component: AdminBuildings
  },

  {
    path: '/companies/edit/:id',
    component: EditCompany
  },
  {
    path: '/companies/add',
    component: AddCompany
  },
  {
    path: '/companies/add-demo',
    component: AddDemoCompany
  },
  {
    path: '/companies',
    component: AdminCompanies
  },

  {
    path: '/cleaning',
    component: AdminCleaning
  },

  {
    path: '/booking',
    component: AdminBooking
  },

  {
    path: '/domains',
    component: AdminDomains
  }
].map(({ path, component }) => {
  let splitedPath = path.split('/');
  const prefix = `/${name}${params ? `/:${params.join('/:')}` : ''}`;

  if (params) {
    splitedPath = splitedPath.filter((item) => !params.includes(item.slice(1)));
  }

  const newPath = `${prefix}${splitedPath.join('/')}`;

  return { path: newPath, component: withAdmin(component) }
});
