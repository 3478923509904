import { BOOKABLE, NON_BOOKABLE } from '../constants/meetingStatuses';

export default (desk) => {
  const isNonBookable = desk?.status === NON_BOOKABLE;
  const isMeeting = desk?.status === BOOKABLE;

  let shortTitle = 'HD';
  let headerTitle = 'Hot desk';

  if (isMeeting) {
    shortTitle = 'MR';
    headerTitle = 'Meeting Room';
  }

  if (isNonBookable) {
    shortTitle = 'NB';
    headerTitle = 'Non-bookable Room';
  }

  return {
    shortTitle,
    headerTitle
  };
};
