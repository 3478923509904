import React from 'react'

// import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect } from "react";

import useAuth from '../../auth/useAuth'
// import { useTeamsFlag } from '../../hooks/useTeams'

import '../Login/login.css';

export default function SignUpTeams(props) {


  const {
    // isAuthenticated,
    isLoading,
    // loginWithRedirect,
    buildAuthorizeUrl,
    // getIdTokenClaims
  } = useAuth({ overrideSlug: true });

  // const teamsFlag = useTeamsFlag()
  // const [redirectTo, setRedirectTo] = useState('')

  // const [isPending, setPending] = useState(false);
  // const [isPendingUser, setPendingUser] = useState(true);

  useEffect(() => {
    if (isLoading) {
      return
    }

    buildAuthorizeUrl({
      redirect_uri: `${window.location.origin}/login-teams`
    })
    .then((url) => {
      window.location = url
    })
  }, [isLoading])

  return (
    <div>Teams</div>
  )
};
