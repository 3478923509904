import React, { useEffect, useState, Fragment } from 'react';
import find from 'lodash.find';
// import { useAuth0 } from '@auth0/auth0-react';
import useAuth from '../../auth/useAuth'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import Start from '../Start/Start';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import AdminAccess from '../../components/AdminAccess';

import useUser from '../../hooks/useUser';
import useCompanies from '../../hooks/useCompanies';
import { BigLogoIcon } from '../../services/svg-icons';
import useTeamsFlag, { goAndAuthenticate, notifySuccess } from '../../hooks/useTeams'

import { default as api } from '../../services/api';
import { ADMIN } from '../../constants/userRoles';

import './login.css';

const {
  fetchCompanyBySlug,
  fetchCompanyByStrId,
  fetchProfileByToken,
  fetchRolesByContact,
  fetchCompanyExcludedDomainsByStrId
} = api

export default function Login(props) {
  const { isRoot, isCompanyRoot } = props;

  const history = useHistory();
  const location = useLocation();
  const { companies } = useCompanies();
  const { companyId, slug } = useParams();
  const { user, setUser, redirectToFloor, isAdmin, isCompanyAdmin } = useUser();
  const { isAuthenticated, isLoading, loginWithRedirect, getIdTokenClaims, logout } = useAuth({ slug, isRoot });

  const savedCompany = JSON.parse(localStorage.getItem('currentCompany'));

  const [check, setCheck] = useState(false);
  const [company, setCompany] = useState(null);
  const [isPending, setPending] = useState(false);
  const [isPendingUser, setPendingUser] = useState(true);

  const teamsFlag = useTeamsFlag()

  const companyStrId = localStorage.getItem('companyStrId');
  const qrCodeDesk = localStorage.getItem('qrCodeDesk');
  const desk = qrCodeDesk ? JSON.parse(qrCodeDesk) : null;

  const login = () => {
    const { pathname } = location;

    localStorage.removeItem('wkpgMode')

    if (teamsFlag === 'loading') {
      return
    }
    if (teamsFlag === 'teams') {
      localStorage.page = 'main'
      goAndAuthenticate({
        successCallback: async (result) => {
          // localStorage.forceAuthToken = JSON.stringify(result)
          // history.push('/signup/company')
          // alert('Success resp')
          localStorage.removeItem('page')
          getIdTokenClaims()
            .then((res) => {
              onGotResource(res)
            })
        },
        failureCallback: (reason) => {
          // alert('Failure resp')
          // alert(`err1 ${reason.toString()}`)
          // dont know how to handle
        },
      })
    } else {
      loginWithRedirect();
      localStorage.setItem('returnTo', pathname);
    }
  };

  const onGotResource = async (res) => {
    const { __raw: token } = res;
    // alert(`onGotResource@token ${token}`)
    if (!token) {
      return
    }

    localStorage.setItem('token', token);
    setPending(true);

    try {
      const fetchCompanyStrId = company ? company.strId : '';
      const { companyStrId, strId } = await fetchProfileByToken(token, fetchCompanyStrId)
      if (!companyStrId && !strId) {
        throw new Error('Contact not found')
      }
      if (fetchCompanyStrId) {
        await fetchCompanyExcludedDomainsByStrId(fetchCompanyStrId)
      }
      if (!companyStrId) {
        if (localStorage.getItem('wkpgmode') === 'signin') {
          localStorage.forceAuthToken = JSON.stringify(res)
          history.push('/signup/company')
          return;
        }
        history.push('/not-company-profile');
        return;
      }

      const user = await fetchRolesByContact(strId)
      setUser(user);

      setPending(false);

      if (isRoot) {
        if (user.roles && user.roles.includes(ADMIN)) {
          history.push('/root-admin/companies');
          return;
        }
        logout({ returnTo: `${window.location.origin}/logout-redirect` });
        return;
      }
      if (companyStrId && !isRoot) {
        return redirectToFloor(desk, user);
      }
    } catch (err) {
      const returnTo = localStorage.getItem('returnTo')
      if (returnTo) {
        if (returnTo === '/not-company-profile') {
          localStorage.setItem('authAction', 'logout')
        }
        history.push(returnTo)
      } else {
        localStorage.setItem('authAction', 'logout')
        history.push('/not-company-profile')
      }
      return handleError(err)
    }
  }

  const handleError = (err) => {
    console.error(err);
    setPending(false);
  };

  useEffect(() => {

    if (teamsFlag === 'loading') {
      return
    }

    if (user && isPendingUser) {

      const { companyStrId } = user;
      const isMyCompany = companyId === companyStrId;
      const defaultCompany = find(companies, (item) => item.strId === companyStrId);
      const currentCompany = defaultCompany || savedCompany;

      setPendingUser(false);

      if (isRoot && isAdmin) {
        history.push('/root-admin/companies');
        return;
      }

      if (!companyStrId || !currentCompany) {
        history.push('/not-company-profile');
        return;
      }

      if (isCompanyRoot && (isAdmin || (isCompanyAdmin && isMyCompany))) {
        history.push(`/admin/${companyId}/users`);
        return;
      }

      if (!isRoot && !isCompanyRoot) {
        fetchCompanyExcludedDomainsByStrId(companyId)
          .then(() => {
            redirectToFloor(desk);
          })
          .catch(() => {
            localStorage.setItem('authAction', 'logout')
            history.push('/not-company-profile');
          })
        return;

      }
    }

    if (!isLoading && !isPending && isAuthenticated && isPendingUser) {
      setPendingUser(false);
      getIdTokenClaims()
      .then((res) => {
        if (teamsFlag === 'teams') {
          try {
            notifySuccess(res)
          } catch(err) {
            /* do nothing */
          }
          // return
        }

        return onGotResource(res)
      })
      .catch((err) => {
        alert(`Loading err ${err}`)
      })
    }

    if (!user && !isLoading && !isPending && !isAuthenticated && isPendingUser) {
      console.log('Force reset pending', { isLoading, isAuthenticated, user })
      setPendingUser(false);
    }

    if (!user && !isLoading && !isPending && !isPendingUser && !company && !isRoot) {
      window.location = '/login/demo';
    }
  }, [
    isAuthenticated, company, isLoading, isPending, user, companyId,
    isRoot, isCompanyRoot, isAdmin, history, isCompanyAdmin,
    getIdTokenClaims, isPendingUser, desk, companies,
    teamsFlag,
  ]);

  const isNotFoundCompany = (!slug && !isCompanyRoot) || (!isPending && !company);

  useEffect(() => {
    if (companyStrId) {
      setPending(true);
      fetchCompanyByStrId(companyStrId).then((c) => {
        c && setCompany(c);
        setPending(false);
      }).catch(handleError);
    }
  }, [companyStrId]);

  useEffect(() => {
    const splitedPath = window.location.pathname.split('/');
    if (splitedPath[1] && (splitedPath[1] === 'login' || splitedPath[1] === 'admin') &&
        splitedPath[2] && !splitedPath[3]) {
      const param = splitedPath[2];
      const request = splitedPath[1] === 'login' ? fetchCompanyBySlug : fetchCompanyByStrId;
      setPending(true);

      request(param).then((c) => {
        setCompany(c);
        setPending(false);
      }).catch(handleError);
    }
  }, []);

  if (isPendingUser || isPending) {
    return <Loader />;
  }

  if (company?.isDemo && !isRoot && !isCompanyRoot) {
    return <Start company={company} desk={desk} />;
  }

  return (
    <div className="login">
      {!isRoot && <AdminAccess />}

      {
        isRoot ?
        <div className="login__company-name">
          Admin for Workipelago
        </div> :
        <Fragment>
          <div className="login__company-name">
            { company && `${isCompanyRoot ? 'Admin for ' : ''}Company "${company.title}"` }
            { isNotFoundCompany && 'Company not found' }
          </div>

          {
            isNotFoundCompany &&
            <div className="login__company-help">
              Invalid url, check the address bar
            </div>
          }
        </Fragment>
      }

      <div className="login__form">
        <div className="login__form-wrapper">
          <header>
            <BigLogoIcon />
          </header>

          <footer>
            {
              (isPending || isLoading) ?
              <Loader isAbsolute /> :
              <Button
                disabled={(isNotFoundCompany && !isRoot) || !check}
                onClick={login}
                className="login__button"
              >
                Sign In
              </Button>
            }
          </footer>

          <label>
            <input
              type="checkbox"
              checked={check}
              onChange={({target}) => setCheck(target.checked)}
            />
            <span>
              I agree to Workipelago <Link target="_blank" to="/documents/tos.pdf">Terms Of Service</Link> and <Link target="_blank" to="/documents/privacy.pdf">Privacy Policy</Link> and confirm<br/>I am over the age 18 years olds
            </span>
          </label>
        </div>
      </div>

      <div className="login__links">
        <a href="https://www.ofistic8.com" target="_blank" rel="noopener noreferrer">
          Workipelago website
        </a>
      </div>
    </div>
  );
};
