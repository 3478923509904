import React from 'react';

import FloorForm from '../../components/FloorForm/FloorForm';

export default function AddFloor() {
  return (
    <div>
      <FloorForm />
    </div>
  );
};
