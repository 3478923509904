import React, { useRef } from 'react';
import classNames from 'classnames';

import { useMobile } from '../../hooks/useDetectMobile';
import useOutsideClick from '../../hooks/useOutsideClick';

import './drop-down.css';

function DropDown(props) {
  const { options, className, onClick, onHide, Icon, isModifyIcons, MobileHeader } = props;
  
  const isMobile = useMobile()
  const viewModifyIcons = isMobile && isModifyIcons;
  const classes = classNames('drop-down', className);
  const ref = useRef(null);

  useOutsideClick(ref, onHide, isMobile);

  return (
    <div className={classes} ref={ref}>
      {isMobile && MobileHeader && <MobileHeader />}
      {
        options.map((item, index) => {
          const { title, name, number, desc } = item;
          return (
            <div className="drop-down__item" key={index} onClick={() => onClick(item)}>
              {Icon && !viewModifyIcons && <Icon className="drop-down__icon" />}
              {viewModifyIcons && <div className="drop-down__number-floor">{number}</div>}
              <div className="drop-down__name">{title || name || number}</div>
              {desc && <div className="drop-down__desc">{desc}</div>}
            </div>
          );
        })
      }
    </div>
  );
}

export default DropDown;
