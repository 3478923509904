import React from 'react';
import { useHistory } from "react-router-dom";

import { ArrowLongIcon } from '../../services/svg-icons';

import './header.css';

export default function Header(props) {
  const { title, rightTitle, isBack } = props;
  const history = useHistory();

  const handleClickBack = () => {
    history.goBack();
  };

  return (
    <div className="header">
      {isBack && <ArrowLongIcon onClick={handleClickBack} className="header__icon" />}
      <div className="header__title">
        {title}
      </div>
      <div className="header__title header__title_right">
        {rightTitle}
      </div>
    </div>
  );
};
