import React, { Fragment } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";

import classNames from 'classnames';
import _ from 'lodash'

import Button from '../Button/Button';
import { PhoneIcon, ChatIcon, CalendarIcon } from '../../services/svg-icons';

import { default as api } from '../../services/api'

// import { ContactListCall } from '../ContactOptions'

import './drop-down-summon.css';
import useTeamsFlag from '../../hooks/useTeams'
import { useMobile } from '../../hooks/useDetectMobile';

const { getImgUrl } = api

function ContactListCall({ emails, company }) {
  const teamsFlag = useTeamsFlag()

  if (emails && emails.length > 0 && (!company || company.hasTeamsIntergration)) {
    const emailStr = emails.join(',')

    const href = `https://teams.microsoft.com/l/call/0/0?users=${emailStr}`

    const onClick = (e) => {
      if (teamsFlag === 'teams') {
        microsoftTeams.executeDeepLink(href)
        e.preventDefault()
      }
    }

    return <a href={href} onClick={onClick} target="blank" ><PhoneIcon /></a>
  }
  return (
    <span className="contact__disabled"><PhoneIcon /></span>
  )
}

function ContactChat({ emails, company }) {
  const teamsFlag = useTeamsFlag()

  if (emails && emails.length > 0 && (!company || company.hasTeamsIntergration)) {
    const emailStr = emails.join(',')
    const href = `https://teams.microsoft.com/l/chat/0/0?users=${emailStr}`

    const onClick = (e) => {
      if (teamsFlag === 'teams') {
        microsoftTeams.executeDeepLink(href)
        e.preventDefault()
      }
    }

    return <a href={href} onClick={onClick} target="blank"><ChatIcon /></a>
  }
  return (
    <span className="contact__disabled"><ChatIcon /></span>
  )
}

function ContactSchedule({ emails, company }) {
  const teamsFlag = useTeamsFlag()

  if (emails && emails.length > 0 && (!company || company.hasTeamsIntergration)) {
    const emailStr = emails.join(',')
    const href = `https://teams.microsoft.com/l/meeting/new?attendees=${emailStr}`

    const onClick = (e) => {
      if (teamsFlag === 'teams') {
        microsoftTeams.executeDeepLink(href)
        e.preventDefault()
      }
    }

    return (
      <a href={href} onClick={onClick} target="blank">
        <CalendarIcon className="popup-info-desk__icon-calendar"/>
      </a>
    )
  }
  return (
    <span className="contact__disabled">
      <CalendarIcon className="popup-info-desk__icon-calendar"/>
    </span>
  )
}

function DropDownSummon(props) {
  const { options, className, setViewSummon, setSummons, company } = props;
  const teamsFlag = useTeamsFlag()
  const isMobile = useMobile()

  const classes = classNames('drop-down-summon', className);
  const handleClickCancel = () => {
    setViewSummon(false);
    setSummons && setSummons([]);
    localStorage.setItem('isSummon', 0);
    localStorage.setItem('summons', JSON.stringify([]));
  };

  const emails = _.compact(_.map(options, i => i.email))

  const checkTeams = () => {
    return isMobile && teamsFlag === 'teams'
  }

  return (
    <div className={classes}>
      {
        !options.length ?
        <div className="drop-down-summon__empty">Select employees from the floorplan</div> :
        <Fragment>
          <div className="drop-down-summon__items">
            {
              options.map((item, index) =>
                <div className="drop-down-summon__item" key={index} style={{ backgroundImage: `url(${getImgUrl(item.img)})` }}/>
              )
            }
          </div>
          <div className={classNames("popup-info-desk__bottom", {['popup-info-desk__teams']: teamsFlag === 'teams'})}>
            { !checkTeams() &&
              <ContactListCall emails={emails} company={company}/>
            }
            <ContactChat emails={emails} company={company} />
            <ContactSchedule emails={emails} company={company} />
          </div>
          <div className="popup-info-desk__button-holder popup-hot-desk__bottom popup-hot-desk__bottom_once">
            <Button onClick={handleClickCancel}>Cancel</Button>
          </div>
        </Fragment>
      }
    </div>
  );
}

export default DropDownSummon;
