import React from 'react';
import QRCode from 'qrcode.react';

import './popup-qr-code.css';

export default function PopupQRCode({ data, onClose, title }) {
  const handleClick = ({ target }) => {
    if (target.className === 'popup-qr-code') {
      onClose();
    }
  };

  return (
    <div className="popup-qr-code" onClick={handleClick}>
      <div className="popup-qr-code__holder">
        <span>{title}</span>
        <QRCode value={data} size={300}/>
      </div>
    </div>
  );
};
