import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import Loader from '../../components/Loader/Loader';
import BuildingForm from '../../components/BuildingForm/BuildingForm';

import { default as api } from '../../services/api';

const { fetchBuilding } = api

export default function EditBuilding() {
  const { id } = useParams();

  const [building, setBuilding] = useState(null);

  useEffect(() => {
    if (id) {
      fetchBuilding(id).then(setBuilding);
    }
  }, [id]);

  return (
    <div>
      {
        building ?
        <BuildingForm building={building} /> :
        <Loader />
      }
    </div>
  );
};
