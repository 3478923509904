import React, { useState } from 'react';

import { ClearIcon } from '../../services/svg-icons';

import './popup-settings-virtual.css';

function PopupSettingsVirtual(props) {
  const { virtualRoom, onClose } = props;
  const [name, setName] = useState(virtualRoom.title);

  return (
    <div className="popup-settings-virtual">
      <div className="popup-settings-virtual__top">
        <span>Settings virtual floor</span>
        <ClearIcon className="popup-settings-virtual__close" onClick={onClose}/>
      </div>
      <div className="popup-settings-virtual__body">
        <label className="popup-settings-virtual__label">
          <span>Name</span>
          <input className="popup-settings-virtual__input" value={name} onChange={({target}) => setName(target.value)}/>
        </label>
      </div>
    </div>
  );
}

export default PopupSettingsVirtual;
