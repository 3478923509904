import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Link } from 'react-router-dom';

import Select from '../Select/Select';
import Button from '../Button/Button';

import { default as api } from '../../services/api';
import useUser from '../../hooks/useUser';
import useCompanies from '../../hooks/useCompanies';

const {
  fetchVirtualFloorsByCompany,
  deleteVirtualFloor,
  fetchContactsByCompany,
  getImgUrl,
  fetchDeskByQRCode,
} = api
const NAMES = ['Name', 'Description', 'Arrangement', 'BackgroundColor', 'BackgroundImage', 'Members', '', ''];

function AdminVirtualFloors() {
  const { companies } = useCompanies();
  const { user, isAdmin, isCompanyAdmin } = useUser();

  const savedCompany = JSON.parse(localStorage.getItem('currentCompany'));
  const defaultCompany = savedCompany || companies[0];

  const [contacts, setContacts] = useState([]);
  const [virtualFloors, setVirtualFloors] = useState([]);
  const [currentCompany, setCurrentCompany] = useState(defaultCompany);

  const getTypeLabel = (type) => type.title;
  const getTypeValue = (type) => type.strId;
  const getVirtualFloorsByCompany = (id) => fetchVirtualFloorsByCompany(id).then(setVirtualFloors);
  const getContactsByCompany = (id) => fetchContactsByCompany(id).then(setContacts);

  useEffect(() => {
    if (currentCompany) {
      getVirtualFloorsByCompany(currentCompany.strId);
      getContactsByCompany(currentCompany.strId);
      localStorage.setItem('currentCompany', JSON.stringify(currentCompany));
    }
  }, [currentCompany]);

  useEffect(() => {
    if (isCompanyAdmin) {
      const userCompany = companies.find((item) => item.strId === user.companyStrId);
      setCurrentCompany(userCompany);
    }
  }, [isCompanyAdmin, companies]);

  const link = `/${isAdmin ? 'root-' : ''}admin${isCompanyAdmin ? `/${currentCompany?.strId}` : ''}/virtual/add/${!isCompanyAdmin ? currentCompany?.strId : ''}`;

  return (
    <div>
      {
        !isCompanyAdmin &&
        <Select
          label="Company"
          options={companies}
          value={currentCompany}
          getOptionLabel={getTypeLabel}
          getOptionValue={getTypeValue}
          onChange={setCurrentCompany}
        />
      }

      {
        currentCompany && (!virtualFloors || virtualFloors.length < 3 || !currentCompany.isVirtualOnly) &&
        <div className="adm-buttons">
          <Link to={link}>
            <Button>
              Create new virtual floor
            </Button>
          </Link>
        </div>
      }
      {
        currentCompany && (!virtualFloors || (virtualFloors.length >= 3 && currentCompany.isVirtualOnly)) &&
        <p className="adm-desc"> 3 virtual floors max. <br/> <a href="https://www.ofistic8.com/contact-us/" target="blank">Contact us</a> to upgrade</p>
      }

      <table className="table">
        <thead>
          { renderHeader() }
        </thead>
        <tbody>
          { renderVirtualFloors(virtualFloors, setVirtualFloors, contacts, currentCompany?.strId, isAdmin) }
        </tbody>
      </table>
    </div>
  );
}

const renderHeader = () => {
  return (
    <tr>
      { NAMES.map((item, index) => <th key={index}>{item}</th>) }
    </tr>
  );
};

const renderVirtualFloors = (virtualFloors, setVirtualFloors, contacts, companyStrId, isAdmin)=> {
  return (
    virtualFloors.map((item, index) => {
      const { title, desc, arrangement, backgroundColor, backgroundImg, members, strId } = item;
      const parseMembers = JSON.parse(members);
      const listNameMembers = parseMembers.reduce((acc, item) => {
        const contact = contacts.find((contact) => contact.id === item);
        if (contact) {
          return acc + `${ acc ? ', ' : '' }${ contact.name }`;
        }

        return acc;
      }, '');
      const newVirtualFloors = cloneDeep(virtualFloors);
      const handleDelete = () => {
        deleteVirtualFloor(strId);
        newVirtualFloors.splice(index, 1);

        setVirtualFloors(newVirtualFloors);
      };
      const link = `/${isAdmin ? 'root-' : ''}admin${!isAdmin ? `/${companyStrId}` : ''}/virtual/edit${isAdmin ? `/${companyStrId}` : ''}/${strId}`;
      const style = { width: '15px', height: '15px', backgroundColor, borderRadius: '50%' };

      return (
        <tr key={index}>
            <td><Link className="adm__link" to={`/virtual/${strId}`} >{title}</Link></td>
            <td>{desc}</td>
            <td>{arrangement}</td>
            <td>
              <div className="adm" style={style}/>
            </td>
            <td>
              {getImgUrl(backgroundImg) ?
                <img
                  className="adm_virtual-floor-img"
                  src={getImgUrl(backgroundImg)}
                  alt="virtual-floor"
                />
                : 'No image'
              }

            </td>
            <td>{listNameMembers}</td>
            <td>
              <Link to={link}>
                <Button type="edit">
                  Edit
                </Button>
              </Link>
            </td>
          <td>
            <Button type="delete" onClick={handleDelete}>Delete</Button>
          </td>
        </tr>
      );
    })
  );
};

export default AdminVirtualFloors;
