import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';

import Select from '../Select/Select';
import Button from '../Button/Button';

import { default as api } from '../../services/api';
import withAdmin from '../../hoc/withAdmin';
import useCompanies from '../../hooks/useCompanies';
import { roles as globalRoles } from '../../services/lists-contact';

const {
  deleteRolesContact,
  fetchContactsByCompany,
  putContact,
  putRolesContact,
  getImgUrl,
} = api
const NAMES = ['Avatar', 'StrId', 'Name', 'Roles', 'Status', 'Position', 'Message', 'E-Mail', 'Phone', ''];

function AdminAdmins() {
  const storageCurrentCompany = localStorage.getItem('currentCompany');

  const { setCurrentCompany, companies } = useCompanies();
  const currentCompany = JSON.parse(storageCurrentCompany);

  const [contacts, setContacts] = useState([]);

  const getTypeLabel = (type) => type.title;
  const getTypeValue = (type) => type.strId;
  const getContactsByCompany = (id) => fetchContactsByCompany(id).then((res) => {
    const resultContacts = res.filter((item) => !item.companyStrId);
    setContacts(resultContacts);
  });

  useEffect(() => {
    if (currentCompany) {
      getContactsByCompany(currentCompany.strId);
    }
  }, [currentCompany]);

  return (
    <div>
      <Select
        label="Company"
        options={companies}
        value={currentCompany}
        getOptionLabel={getTypeLabel}
        getOptionValue={getTypeValue}
        onChange={setCurrentCompany}
      />

      <table className="table">
        <thead>
          { renderHeader() }
        </thead>
        <tbody>
          { renderContacts(contacts, setContacts) }
        </tbody>
      </table>
    </div>
  );
}

const renderHeader = () => {
  return (
    <tr>
      { NAMES.map((item, index) => <th key={index}>{item}</th>) }
    </tr>
  );
};

const renderContacts = (contacts, setContacts) => {
  return (
    contacts.map((item, index) => {
      const { name, position, message, email, phone, img, strId, status, roles, isEdit } = item;
      const newContacts = cloneDeep(contacts);
      const strRoles = roles.reduce((acc, item) => acc + `${acc ? ', ' : ''}${item}`,'')
      const formatedRoles = roles.map((item) => ({ label: item, value: item }));
      const handleSave = () => {
        newContacts[index].isEdit = false;

        const oldRoles = difference(roles, newContacts[index].roles);
        const newRoles = difference(newContacts[index].roles, roles);

        newRoles.length && putRolesContact(strId, newRoles);
        oldRoles.length && deleteRolesContact(strId, oldRoles);

        putContact(newContacts[index]);
        setContacts(newContacts);
      };
      const handleEdit = () => {
        newContacts[index].isEdit = true;

        setContacts(newContacts);
      };
      const handleChange = (currentRoles) => {
        newContacts[index].roles = currentRoles ? currentRoles.map((item) => item.value) : [];
      };
      const handleClick = isEdit ? handleSave : handleEdit;
      const textButton = isEdit ? 'Save' : 'Edit';
      const typeButton = isEdit ? 'save' : 'edit';

      return (
        <tr key={index}>
          <td>{ img && <img className="adm_user-ava" src={getImgUrl(img)} alt="avatar"/>}</td>
          <td>{strId}</td>
          <td>{name}</td>
          <td>
            {
              isEdit ?
              <Select className="select-multi" isMulti options={globalRoles}
                      defaultValue={formatedRoles} onChange={handleChange}/> :
              <span>{strRoles}</span>
            }
          </td>
          <td>{status}</td>
          <td>{position}</td>
          <td>{message}</td>
          <td>{email}</td>
          <td>{phone}</td>
          <td>
            <Button type={typeButton} onClick={handleClick}>
              {textButton}
            </Button>
          </td>
        </tr>
      );
    })
  );
};

export default withAdmin(AdminAdmins);

