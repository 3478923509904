import { useState, useEffect } from "react"

import * as microsoftTeams from "@microsoft/teams-js";

export default function useTeamsFlag() {
  const [teamsFlag, setTeamsFlag] = useState('loading')

  useEffect(() => {
    microsoftTeams.initialize(() => {
      setTeamsFlag('teams')
    });
  }, [])

  useEffect(() => {
    if (teamsFlag !== 'loading') {
      return
    }

    const t = setTimeout(() => {
      setTeamsFlag('no-teams')
    }, 300)
    return () => {
      clearTimeout(t)
    }
  }, [teamsFlag])

  // return 'teams'
  return teamsFlag
}

export function goAndAuthenticate(opts) {
  microsoftTeams.authentication.authenticate({
    url: '/signin-teams',
    width: 420,
    height: 570,
    successCallback: (resultStr) => {
      if (opts.successCallback) {
        if (localStorage.page === 'main') {
          opts.successCallback()
        }
      } else {
        window.location.pathname = '/login'
      }
    },
    failureCallback: (resultStr) => {
      if (window.location.origin.match(/localhost/)) {
        if (opts.successCallback) {
          if (localStorage.page === 'main') {
            opts.successCallback()
          }
        } else {
          window.location.pathname = '/login'
        }
      } else {
        if (opts.failureCallback) {
          opts.failureCallback()
        }
      }
    },
  })
}

export function notifySuccess(res) {
  microsoftTeams.authentication.notifySuccess(JSON.stringify(res))
}
