import React, { useEffect, useState } from 'react';

import { ToastContainer, Slide } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';

import { AuthContext, authContextDefault } from './auth/useAuth'

import Routes from './routes';
import Loader from './components/Loader/Loader';

import { default as api } from './services/api';
import useUser from './hooks/useUser';
import useCompanies from './hooks/useCompanies';

import './App.css';
import './Admin.css';
import 'react-toastify/dist/ReactToastify.css';


const {
  fetchContactByStrId,
  fetchProfileByToken,
  fetchRolesByContact
} = api

function App() {
  const { user, setUser } = useUser();
  const { companies, getCompanies, getCompanyByStrId } = useCompanies();

  const [isPending, setPending] = useState(false);
  const [isPendingUser, setPendingUser] = useState(true);

  const [auth, setAuth] = useState(authContextDefault)

  const token = localStorage.getItem('token');
  const storageUserStrId = localStorage.getItem('userStrId');

  const handleError = (err) => {
    console.error(err);
    setPending(false);
    setPendingUser(false);
  };

  useEffect(() => {
    if (!companies.length && user && Object.keys(user).length) {
      if (user.roles.includes("admin") && localStorage.getItem('token')) {
        getCompanies();
      } else {
        getCompanyByStrId(user.companyStrId);
      }
    }
  }, [companies, user]);

  useEffect(() => {
    if ((!user || !Object.keys(user).length) && (token || storageUserStrId)) {
      const getUserInfo = token ? fetchProfileByToken : fetchContactByStrId;
      setPendingUser(true);
      getUserInfo(token ? token : storageUserStrId)
        .then(profile => {
          if (profile && profile.strId) {
            fetchRolesByContact(profile.strId)
              .then(userInfo => {
                setUser(userInfo);
                setPendingUser(false);
              })
          } else {
            throw new Error('Fetch user failed')
          }
      })
        .catch(handleError);
    } else {
      setPendingUser(false);
    }
  }, [token, storageUserStrId, user]);

  if (isPending || isPendingUser) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        transition={Slide}
        hideProgressBar
      />

      <Router>
        <Routes />
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
