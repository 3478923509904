import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import Loader from '../../components/Loader/Loader';
import UserForm from '../../components/UserForm/UserForm';

import { default as api } from '../../services/api';

const { fetchContactByStrId } = api

export default function EditUser() {
  const { id } = useParams();

  const [user, setUser] = useState(null);

  useEffect(() => {
    if (id) {
      fetchContactByStrId(id).then(setUser);
    }
  }, [id]);

  return (
    <div>
      {
        user ?
        <UserForm user={user} /> :
        <Loader />
      }
    </div>
  );
};
