import './StartPage.css'
import React, { useEffect, useRef, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import * as microsoftTeams from "@microsoft/teams-js";

import find from 'lodash.find'

import useUser from '../../hooks/useUser';
import { BigLogoIcon } from '../../services/svg-icons';

import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button'

import useCompanies from '../../hooks/useCompanies';

function hasRole(roles, role) {
  return Boolean(roles && roles.indexOf(role) > -1)
}

export default function StartPage() {
  const { user } = useUser()
  const { companies } = useCompanies()
  const inputRef = useRef()

  const [redirectTo, setRedirectTo] = useState('')
  const [flagNotFound, setFlagNotFound] = useState(false)

  // useEffect(() => {
  //   setFlagNotFound(false)
  // }, [companyName])

  // useEffect(() => {
  //   try {
  //     // alert('try init')
  //     microsoftTeams.initialize(() => {
  //       // alert('try inited')
  //       microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
  //         microsoftTeams.settings.setSettings({
  //           websiteUrl: "https://wkpgo-web-0.azurewebsites.net",
  //           contentUrl: "https://wkpgo-web-0.azurewebsites.net",
  //           entityId: "WorkipelagoTab",
  //           suggestedDisplayName: "Workipelago"
  //         });
  //         saveEvent.notifySuccess();
  //       })
  //       microsoftTeams.settings.setValidityState(true)
  //     })
  //   } catch(e) {
  //     // alert(e.toString())
  //   }
  // }, [])

  const onNext = async (e) => {
    if (e) {
      e.preventDefault()
    }

    if (!inputRef.current) {
      return
    }

    const input = inputRef.current
    const companyName = input.value
    const company = find(companies, { slug: companyName })

    if (company) {
      setTimeout(() => setRedirectTo(`/login/${companyName}`), 0)
    } else {
      setFlagNotFound(true)
      setTimeout(() => setFlagNotFound(false), 2000)
    }
  }

  const onViewDemo = async(e) => {
    setRedirectTo('/login/demo')
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  if (user && user.roles && hasRole(user.roles, 'admin')) {
    return <Redirect to="/root-admin" />
  }

  if (user && Object.keys(user).length) {
    return <Redirect to="/not-company-profile" />
  }

  return (
    <div className="sp-container">
      <div className="sp-nav">
        <a href="https://www.workipelago.com/" target="_blank">
          <div className="sp-logo"></div>
        </a>
        <div className="sp-nav_span"></div>
        <div className="sp-nav_links">
          <a href="https://www.workipelago.com/" target="blank" className='sp-nav_button'>Home</a>
          <a href="https://www.workipelago.com/contact-us/" target="blank" className='sp-nav_button'>Contact Us</a>

          <Link to="/signin" className='sp-nav_button'>Sign In</Link>

          <a href="https://www.workipelago.com/get-started/" target="blank">
            <Button >Sign Up Free</Button>
          </a>
        </div>
      </div>
      <div className="login login__row login__start">
        <div className="sp-image">
          <Link to="/login/demo" >
            <div className="sp-floor-img"></div>
          </Link>
        </div>
        <div className="sp-msg">
          <div className="sp-msg_header">
            Bridging the gap<br/>
            between physical and<br/>
            digital workspace
          </div>
          <div>
            We create the ultimate solution to manage hybrid working.<br/>
            It is now possible to remove silos by bringing remote teams<br/>
            closer, help them feel present and fulfil that missing feeling<br/>
            of belonging.
          </div>
          <div className="sp-msg_buttons">
            <Link to="/signup" >
              <Button>Start free trial</Button>
            </Link>
            <a href="https://www.workipelago.com/contact-us/">
              <button className="button button_ghost">Request demo</button>
            </a>
          </div>
        </div>
    </div>
    </div>
  )
}
