import React from 'react';
import { Link } from 'react-router-dom';

import { TrashIcon } from '../../services/svg-icons';
import getBookingParams from '../../services/get-booking-params';

import './booking-history-item.css';

export default function BookingHistoryItem(props) {
  const { item, desk, isActiveBooking, onClickTrashIcon } = props;
  const { strId, floorId, entityId } = item;
  if (desk) {
    const {id: deskId, x, y} = desk;
    const {shortTitle, headerTitle, timeName, dayName, isRecurrent} = getBookingParams(item, desk);

    const link = isActiveBooking ? `/floor/${floorId}?x=${x}&y=${y}&deskId=${entityId}` : '#';
    const handleClickTrashIcon = () => {
      onClickTrashIcon({strId});
    };

    return (
      <div className="booking-history-item">
        <Link className="booking-history-item__link" to={link}>
          <div className="booking-history-item__avatar">
            <div className="booking-history-item__avatar-name">{shortTitle}</div>
            <div className="booking-history-item__avatar-name">{deskId}</div>
          </div>
          <div className="booking-history-item__text">
            <div className="booking-history-item__title">{headerTitle} #{deskId}</div>
            {!isActiveBooking && <div className="booking-history-item__desc">{dayName}</div>}
            <div className="booking-history-item__desc">{timeName}</div>
          </div>
        </Link>
        {
          isActiveBooking && !isRecurrent &&
          <TrashIcon
            className="booking-history-item__cancel-icon"
            onClick={handleClickTrashIcon}
          />
        }
      </div>
    );
  }
  return <></>;
};
