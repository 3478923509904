import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import find from 'lodash.find'

import { useMobile } from '../../hooks/useDetectMobile';
// import { isMobile } from 'react-device-detect';

import Button from '../../components/Button/Button';

import saveDesk from '../../services/save-desk';
import { listStatusesDesk } from '../../services/desks';
import { updateMapMeeting } from '../../services/map-items';
import getPathFromPoints from '../../services/get-path-from-points';
import { BOOKABLE, NON_BOOKABLE } from '../../constants/meetingStatuses';
import { default as api } from '../../services/api';

const {
  deleteDesk,
  deleteMeeting,
  fetchDesk,
  fetchMeeting,
  updateMeeting
} = api

const emptyValues = {
  kind: '',
  strId: '',
  number: '',
  rotation: '',
  status: '',
  width: '',
  height: '',
  points: {}
}

const RADIUS = 8;
// const MAX_SIZE = 1000;
const xmlns = 'http://www.w3.org/2000/svg';

export default function MapPaletteEdit(props) {
  const { deskEl, setDeskEl, pushAction, users } = props;
  const [kind, setKind] = useState('')
  const [strId, setStrId] = useState('');
  const [width, setWidth] = useState('');
  const [number, setNumber] = useState('');
  const [points, setPoints] = useState({});
  const [status, setStatus] = useState('');
  const [height, setHeight] = useState('');
  const [rotation, setRotation] = useState('');
  const [params, setParams] = useState([]);
  const [member, setMember] = useState(null);

  const getTypeLabel = (type) => type.name;
  const getTypeValue = (type) => type.strId;

  const onBlurRotation = (value) => {
    deskEl.setAttribute('data-rotation', value);

    const deskTexture = deskEl.querySelector('.b-map-item_texture');
    const hotdesk = deskEl.querySelector('.b-hot-desk-contact');

    if (deskTexture) {
      deskTexture.style.transform = `rotate(${value}deg)`;
    }

    if (hotdesk) {
      hotdesk.className = `b-hot-desk-contact b-desk_${value}`;
    }
  };

  const clearMeetingEl = () => {
    const els = deskEl.querySelectorAll('circle, line');

    deskEl.onmousedown = deskEl.onmousemove = deskEl.onmouseup = null;
    deskEl.ontouchstart = deskEl.ontouchmove = deskEl.ontouchend = null;

    els.forEach((el) => {
      el.remove();
    });

    clearPallete();
    setDeskEl(null);
  };

  const clearPallete = () => {
    setKind(emptyValues.kind);
    setStrId(emptyValues.strId);
    setStatus(emptyValues.status);
    setNumber(emptyValues.number);
    setPoints(emptyValues.points);
    setRotation(emptyValues.rotation);
    setWidth(emptyValues.width);
    setHeight(emptyValues.height);
  };

  const drawLines = (currentPoints, strId, el) => {
    Object.keys(currentPoints).forEach((key) => {
      const line = document.createElementNS(xmlns, 'line');
      const { id, cx, cy, child } = currentPoints[key];

      line.setAttributeNS(null, 'id', `line-${strId}-${id}`);
      line.setAttributeNS(null, 'x1', cx);
      line.setAttributeNS(null, 'y1', cy);
      line.setAttributeNS(null, 'x2', child.cx);
      line.setAttributeNS(null, 'y2', child.cy);

      el.appendChild(line);
    });
  };

  const drawCircles = (currentPoints, el) => {
    const first = el.firstChild;
    Object.keys(currentPoints).forEach((key) => {
      const { id, cx, cy, child } = currentPoints[key];
      const circle = document.createElementNS(xmlns, 'circle');
      const miniCircle = document.createElementNS(xmlns, 'circle');

      circle.setAttributeNS(null, 'id', id);
      circle.setAttributeNS(null, 'cx', cx);
      circle.setAttributeNS(null, 'cy', cy);
      circle.setAttributeNS(null, 'r', RADIUS);

      miniCircle.setAttributeNS(null, 'id', `mini-${id}`);
      miniCircle.setAttributeNS(null, 'cx', child.cx);
      miniCircle.setAttributeNS(null, 'cy', child.cy);
      miniCircle.setAttributeNS(null, 'r', RADIUS / 2);

      el.appendChild(miniCircle);
      el.insertBefore(circle, first);
    });
  };

  const updateLines = (currentPoints, strId, path, target) => {
    const point = currentPoints[target.id.split('-')[1] || target.id];
    const { id, cx, cy, child } = point;
    const line = document.getElementById(`line-${strId}-${id}`);

    line.setAttributeNS(null, 'x1', child.cx);
    line.setAttributeNS(null, 'y1', child.cy);
    line.setAttributeNS(null, 'x2', cx);
    line.setAttributeNS(null, 'y2', cy);

    path.setAttributeNS(null, 'd', getPathFromPoints(currentPoints));
  };

  const handleDelete = () => {
    if (!strId) {
      return;
    }

    if (kind === 'meeting') {
      deleteMeeting(strId);
    }

    if (kind === 'desk') {
      deleteDesk(strId);
      clearPallete();
    }
  };

  const onSaveDesk = (e) => {
    if (!strId) {
      return;
    }

    if (status === BOOKABLE || status === NON_BOOKABLE) {

      const data = {
        width: Number(width),
        height: Number(height),
        points: JSON.stringify(points)
      };

      updateMeeting(strId, data)
      .then((item) => {
        updateMapMeeting(
          deskEl,
          {
            width: item.width,
            height: item.height,
          }
        )
      })
      .then(clearMeetingEl)
      .catch((e) => console.error('Could not save meeting', e));
    } else {
      const newParams = [strId, { rotation, status, contactStrId: member ? member.strId : null }, deskEl];

      pushAction({ type: 'saveDesk', oldParams: params, newParams });
      saveDesk(...newParams);
    }
  };

  useEffect(() => {
    if (!deskEl) {
      clearPallete();
      return;
    }

    let dPoint = null, drag = null, dragUnder = null, pathUnder = null;
    const strId = deskEl.getAttribute('data-str-id');
    const currentStatus = deskEl.getAttribute('data-desk-status');
    const isMeeting = (currentStatus === BOOKABLE || currentStatus === NON_BOOKABLE);
    const path = deskEl.querySelector('path');
    const mapUnder = document.querySelector('.b-map-under');
    const underEl = mapUnder.querySelector(`#map-el-${strId}`);
    const g = deskEl.querySelector('g');
    let currentPoints = {};

    if (underEl) {
      pathUnder = underEl.querySelector('path');
    }

    const dragEl = (e) => {
      e.stopPropagation();
      let t = e.target, id = t.id.split('-')[1] || t.id, et = e.type, m = { cx: e.layerX, cy: e.layerY };

      // start drag
      if (!drag && typeof(currentPoints[id]) !== 'undefined' && (et === 'mousedown' || et === 'touchstart')) {
        drag = t;
        dPoint = m;
        dragUnder = pathUnder;
      }

      // drag
      if (drag && (et === 'mousemove' || et === 'touchmove')) {

        const point = drag.id.split('-')[1] ? currentPoints[drag.id.split('-')[1]].child : currentPoints[drag.id];

        point.cx += m.cx - dPoint.cx;
        point.cy += m.cy - dPoint.cy;
        dPoint = m;

        drag.setAttributeNS(null, 'cx', point.cx);
        drag.setAttributeNS(null, 'cy', point.cy);

        dragUnder.setAttributeNS(null, 'd', getPathFromPoints(currentPoints));

        updateLines(currentPoints, strId, path, drag);
        setPoints(currentPoints);
      }

      // stop drag
      if (drag && (et === 'mouseup' || et === 'touchend')) {
        drag = null;
        dragUnder = null;
      }
    }

    setStrId(strId);

    if (isMeeting) {
      setKind('meeting')
      fetchMeeting(strId).then((meeting) => {
        const { id, status, rotation, points } = meeting;
        const savedPoints = JSON.parse(points);

        currentPoints = savedPoints;

        setNumber(id);
        setStatus(status);
        setRotation(rotation);
        setParams([strId, { rotation, status }, deskEl]);

        setWidth(meeting.width);
        setHeight(meeting.height);

        deskEl.onmousedown = deskEl.onmousemove = deskEl.onmouseup = dragEl;
        deskEl.ontouchstart = deskEl.ontouchmove = deskEl.ontouchend = dragEl;

        const d = getPathFromPoints(currentPoints);

        setPoints(currentPoints);
        drawCircles(currentPoints, g);
        drawLines(currentPoints, strId, g);

        path.setAttributeNS(null, 'd', d);
        pathUnder.setAttributeNS(null, 'd', d);
      }).catch((e) => {
        console.log('fetch meeting room error', e)
      });
    } else {
      setKind('desk')
      fetchDesk(strId).then((desk) => {
        const { id, status, rotation, contactStrId } = desk;
        setNumber(id)
        setStatus(status)
        setRotation(rotation)
        if (contactStrId) {
          setMember(find(users, (item) => item.strId === contactStrId) || null)
        } else {
          setMember(null)
        }
        setParams([strId, { rotation, status }, deskEl]);
      }).catch((e) => {
        console.log('fetch desk error', e)
      });
    }
  }, [deskEl]);

  const isMobile = useMobile()

  return (
    <div className="b-palette__edit">
      {
        deskEl ?
        <div style={{ width: isMobile ? '100%' : '250px' }}>
          <div>ID: {strId}</div>
          <div>Number: {number}</div>
          {
            kind !== 'meeting' &&
            <div className="popup-booking__input-holder">
              <span className="popup-booking__label">
                Status
              </span>
              <Select
                options={listStatusesDesk}
                value={{ value: status, label: status }}
                onChange={(e) => setStatus(e.value)}
              />
            </div>
          }
          <div className="popup-booking__input-holder">
            <label>
              <span className="popup-booking__label">Rotation</span>
              <input
                type="text"
                value={rotation}
                className="input"
                onBlur={(e) => onBlurRotation(e.target.value)}
                onChange={(e) => setRotation(e.target.value)}
              />
            </label>
          </div>
          {
            (kind === 'desk' && status === 'permanent') &&
            <div className="popup-booking__input-holder">
              <span className="popup-booking__label">
                Member
              </span>
              <Select
                menuPlacement='top'
                isClearable
                options={users}
                value={member}
                onChange={setMember}
                getOptionLabel={getTypeLabel}
                getOptionValue={getTypeValue}
              />
            </div>
          }
          {
            kind === 'meeting' &&
            <>
              <div className="popup-booking__input-holder">
                <label>
                  <span className="popup-booking__label">Width</span>
                  <input
                    type="text"
                    value={width}
                    className="input"
                    onChange={(e) => setWidth(e.target.value)}
                  />
                </label>
              </div>
              <div className="popup-booking__input-holder">
                <label>
                  <span className="popup-booking__label">Height</span>
                  <input
                    type="text"
                    value={height}
                    className="input"
                    onChange={(e) => setHeight(e.target.value)}
                  />
                </label>
              </div>
            </>
          }
          <div className="popup-booking__buttons-wrapper">
            <Button className="popup-booking__button" onClick={onSaveDesk}>Save</Button>
            <div className="popup-booking__button-space" />
            <Button className="popup-booking__button" type="delete" onClick={handleDelete}>Delete</Button>
          </div>
        </div> :
        <div>Select element in map</div>
      }
    </div>
  )
}
