import { getMapHandler } from './map-handler';

export function findParent(target, targetClassName) {
  let el = target
  while (el) {
    if (el.classList && el.classList.contains(targetClassName)) {
      return el
    }
    if (!el.parentNode || el === document.body) {
      return null
    }
    el = el.parentNode
  }
  return null
}

export function findChild(target, targetClassName) {
  for (const child of target.childNodes) {
    if (child.classList.contains(targetClassName)) {
      return child
    }
  }
  return null
}

export function findPopup(target) {
  let popup = findParent(target, 'popup-info-desk')
  if (popup) {
    return popup
  }
  popup = findParent(target, 'popup-booking')
  if (popup) {
    return popup
  }
  return false
}

export function centeringEl({ el, mapWrapRef, mapRef }) {
  const { x, y, width, height } = el;
  const { width: w, height:h } = mapWrapRef.current.getBoundingClientRect();
  const { width: wMap, height: hMap } = mapRef.current.getBoundingClientRect();

  const popup = mapWrapRef.current.querySelector('.popup-booking');
  let heightPopup
  if (popup) {
    const rect = popup.getBoundingClientRect();
    heightPopup = rect.height
  } else {
    heightPopup = 400
  }
  // const { height: heightPopup } =
  const handler = getMapHandler();
  const scale = 1;
  const dx = wMap * (1 / scale - 1);
  const dy = hMap * (1 / scale - 1);
  const resultX = w / 2 - x * scale - (width * scale) / 2 - dx / 2;
  const resultY = h / 2 - y * scale - (height * scale) / 2 - heightPopup / 2 - dy / 2;

  if (handler) {
    handler.setPosition(resultX, resultY);
  }
}

export function getPopupPosition({
  el,
  // mapContext,
}) {

  let picEl = null
  const deskEl = findChild(el, 'b-desk')
  if (deskEl) {
    picEl = findChild(deskEl, 'b-desk_contact')
  }

  // const { x, y, width } = contactImgEl ? contactImgEl.getBoundingClientRect() : el.getBoundingClientRect()
  // const { x, y, width } = el.getBoundingClientRect()
  if (!picEl) {

    const tryHotDesk = findChild(el, 'b-hot-desk-contact')
    if (tryHotDesk) {

      const { x, y } = tryHotDesk.getBoundingClientRect()

      return {
        x: x - 4 -  (16 + 46 + 16), //- width,
        y: y - 4,
      }
    }



    const { x, y } = el.getBoundingClientRect()

    return {
      x: x - (16 + 46 + 16), //- width,
      y: y,
    }
  }
  const { x, y } = picEl.getBoundingClientRect()

  /*
    We have got already unscaled rect
    We need to make adjustments:
      (16 + 46 + 16) for left menu
      8px for picture border, which have different radius than popup
  */

  return {
    x: x - 8 -  (16 + 46 + 16), //- width,
    y: y - 8,
  }
}
