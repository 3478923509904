import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import Loader from '../../components/Loader/Loader';
import VirtualRoomForm from '../../components/VirtualRoomForm/VirtualRoomForm';

import { default as api } from '../../services/api';

const { fetchContactsByCompany } = api

export default function AddVirtualRoom() {
  const { companyId } = useParams();

  const [users, setUsers] = useState(null);

  useEffect(() => {
    if (companyId) {
      fetchContactsByCompany(companyId).then(setUsers);
    }
  }, [companyId]);

  return (
    <div>
      {
        users ?
        <VirtualRoomForm users={users} /> :
        <Loader />
      }
    </div>
  );
};
