import React from 'react';

import Button from '../Button/Button';

import './dialog.css';

export default function Dialog(props) {
  const { onClose, onSuccess, title, subTitle } = props;

  return (
      <div className="dialog" onClick={onClose}>
        <div className="dialog__wrapper">
          {title && <div className="dialog__title">{title}</div>}
          {subTitle && <div className="dialog__sub-title">{subTitle}</div>}
          <div className="dialog__buttons">
            <Button type="ghost" className="dialog__button" onClick={onClose}>
              Disagree
            </Button>
            <Button className="dialog__button" onClick={onSuccess}>
              Agree
            </Button>
          </div>
        </div>
      </div>
  );
};
