import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';

import Tabs from '../Tabs/Tabs';
import Button from '../Button/Button';
import AdminPopupBooking from '../AdminPopupBooking/AdminPopupBooking';

import { default as api } from '../../services/api';
import useUser from '../../hooks/useUser';

const {
  deleteMeeting,
  fetchMeetingsForFloor,
  fetchBookingForFloor,
  fetchRecurrentBookingForFloor,
  fetchContactsByCompany,
  fetchFloor,
  fetchCompanyByStrId
} = api
const NAMES = ['Id', 'Code', 'Name', 'Status', 'Azure UPN', 'Position', 'Points', '', ''];
const TABS = [
  { title: 'Desks' },
  { title: 'Meeting rooms' }
];

function AdminMeetings() {
  const history = useHistory();
  const { companyId, floorId } = useParams();
  const { isAdmin } = useUser();

  const [meeting, setMeeting] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [floor, setFloor] = useState(null);
  const [company, setCompany] = useState(null);
  const [mapBooking, setMapBooking] = useState({});
  const [mapRecurrentBooking, setMapRecurrentBooking] = useState({});

  const handleError = (err) => {
    console.error(err);
  };

  const handleBookingLoad = (arr, setCb) => {
    if (arr.length) {
      const bookingForDesks = arr.reduce((list, item) => {
        const { entityId } = item;
        const desk = list[entityId];
        let booking = [item];

        if (desk) {
          booking = [...desk, item];
        }

        return { ...list, [entityId]: booking };
      }, {});

      setCb(bookingForDesks);
    }
  };

  const handleClickTab = (index) => {
    if (!index) {
      history.push(`/${isAdmin ? 'root-' : ''}admin/${companyId}/floors/${floorId}/desks`);
    }
  };

  const getMeetingsByFloor = (id) => fetchMeetingsForFloor(id)
    .then(setMeetings)
    .catch(handleError);

  const getBookingForFloor = (id) => fetchBookingForFloor(id)
    .then((res) => handleBookingLoad(res, setMapBooking))
    .catch(handleError);

  const getRecurrentBookingForfloor = (id) => fetchRecurrentBookingForFloor(id)
    .then((res) => handleBookingLoad(res, setMapRecurrentBooking))
    .catch(handleError);

  useEffect(() => {
    if (floorId) {
      fetchFloor(floorId).then(setFloor);
    }
  }, [floorId]);

  useEffect(() => {
    if (companyId) {
      fetchCompanyByStrId(companyId).then(setCompany);
    }
  }, [companyId]);

  useEffect(() => {
    if (company) {
      fetchContactsByCompany(company.strId).then(setContacts);
    }
  }, [company]);

  useEffect(() => {
    if (floor) {
      const { strId } = floor;

      getMeetingsByFloor(strId);
      getBookingForFloor(strId);
      getRecurrentBookingForfloor(strId);
    }
  }, [floor]);

  return (
    <div>
      <div className="breadcrumbs">
        {company && <span>{company.title}</span>}
        {floor && <span> / {floor.title}</span>}
      </div>

      <Tabs tabs={TABS} active={1} setActive={handleClickTab} />

      <table className="table">
        <thead>
          { renderHeader() }
        </thead>
        <tbody>
          { renderMeetings(meetings, setMeetings, setMeeting, companyId, floorId, isAdmin) }
        </tbody>
      </table>

      {
        meeting &&
        <AdminPopupBooking
          desk={meeting}
          contacts={contacts}
          booking={mapBooking}
          setMapBooking={setMapBooking}
          setMapRecurrentBooking={setMapRecurrentBooking}
          recurrentBooking={mapRecurrentBooking}
          onClose={() => setMeeting(null)}
        />
      }
    </div>
  );
}

const renderHeader = () => {
  return (
    <tr>
      { NAMES.map((item, index) => <th key={index}>{item}</th>) }
    </tr>
  );
};

const renderMeetings = (meetings, setMeetings, setMeeting, companyId, floorId, isAdmin)=> {
  return (
    meetings.map((item, index) => {
      const { id, status, strId, title, x, y, width, height, points, azureUpn } = item;
      const newMeetings = cloneDeep(meetings);
      const handleDelete = () => {
        deleteMeeting(strId);
        newMeetings.splice(index, 1);

        setMeetings(newMeetings);
      };

      return (
        <tr key={index}>
          <td
            style={{ cursor: 'pointer' }}
            onClick={() => setMeeting(item)}
          >
            {id}
          </td>

          <td
            style={{ cursor: 'pointer' }}
            onClick={() => setMeeting(item)}
          >
            {strId}
          </td>

          <td>{title}</td>
          <td>{status}</td>
          <td>{azureUpn || ''}</td>

          <td>
            <div>x {x}</div>
            <div>y {y}</div>
            <div>width {width}</div>
            <div>height {height}</div>
          </td>

          <td>
            <div className="adm-points">{points}</div>
          </td>

          <td>
            <Link to={`/${isAdmin ? 'root-' : ''}admin/${companyId}/floors/${floorId}/meetings/${strId}/edit`}>
              <Button type="edit">
                Edit
              </Button>
            </Link>
          </td>

          <td>
            <Button type="delete" onClick={handleDelete}>
              Delete
            </Button>
          </td>
        </tr>
      );
    })
  );
};

export default AdminMeetings;
