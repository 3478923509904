import React, { useMemo, useState } from 'react';
import find from 'lodash.find';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';

import Input from '../Input/Input';
import Button from '../Button/Button';
import Select from '../Select/Select';

import { default as api } from '../../services/api';
import { listStatusesCleaning, listStatusesDesk } from '../../services/desks';

const { putDesk } = api
const listKinds = [{ value: 'desk', label: 'desk' }];

export default function DeskForm(props) {
  const { users, desk } = props;
  const { companyId, floorId } = useParams();
  const history = useHistory();

  const deskMember = find(users, (item) => item.strId === desk.contactStrId);
  const defaultValues = useMemo(() => desk ? { ...desk } : {}, [desk]);

  const [kind, setKind] = useState(desk?.kind);
  const [status, setStatus] = useState(desk?.status);
  const [member, setMember] = useState(deskMember);
  const [cleaningStatus, setCleaningStatus] = useState(desk?.cleaningStatus);

  const getTypeLabel = (type) => type.name;
  const getTypeValue = (type) => type.strId;
  const handleChange = (option, cb) => cb(option.value);

  const onSubmit = (values) => {
    const newDesk = {
      ...values, strId: desk?.strId, kind, status, x: +values.x,
      contactStrId: member ? member.strId : member, cleaningStatus, y: +values.y
    };
    putDesk(newDesk).then(() => {
      history.push(`/admin/${companyId}/floors/${floorId}/desks`);
    }).catch((err) => {
      console.error(err);
    });
  };

  const { handleSubmit, register } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-form">
        <div className="add-form__title">
          edit desk #{desk?.id}
        </div>

        <Select
          label="Kind"
          options={listKinds}
          value={{ value: kind, label: kind }}
          onChange={(option) => handleChange(option, setKind)}
        />

        <Select
          label="Status"
          options={listStatusesDesk}
          value={{ value: status, label: status }}
          onChange={(option) => handleChange(option, setStatus)}
        />

        <Select
          label="Cleaning status"
          options={listStatusesCleaning}
          value={{ value: cleaningStatus, label: cleaningStatus }}
          onChange={(option) => handleChange(option, setCleaningStatus)}
        />

        <Select
          isClearable
          label="Member"
          options={users}
          value={member}
          onChange={setMember}
          getOptionLabel={getTypeLabel}
          getOptionValue={getTypeValue}
        />

        <Input
          type="number"
          inputRef={register}
          name="x"
          label="Position x"
        />

        <Input
          type="number"
          inputRef={register}
          name="y"
          label="Position y"
        />

        <Input
          inputRef={register}
          name="scale"
          label="Scale"
        />

        <Input
          type="number"
          inputRef={register}
          name="rotation"
          label="Rotation"
        />

        <Button type="submit">
          Save
        </Button>
      </div>
    </form>
  );
};
