import React, { useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';

import find from 'lodash.find'

import useUser from '../../hooks/useUser';

import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button'

import useCompanies from '../../hooks/useCompanies';
import {fetchCompanyBySlug, isCompanyExists} from "../../services/api";

function hasRole(roles, role) {
  return Boolean(roles && roles.indexOf(role) > -1)
}

export default function SignIn() {
  const { user } = useUser()
  // const { companies } = useCompanies()
  const inputRef = useRef()

  const [redirectTo, setRedirectTo] = useState('')
  const [flagNotFound, setFlagNotFound] = useState(false)

  // useEffect(() => {
  //   setFlagNotFound(false)
  // }, [companyName])

  const onNext = async (e) => {
    if (e) {
      e.preventDefault()
    }

    if (!inputRef.current) {
      return
    }

    const input = inputRef.current
    const companyName = input.value
    const isCompany = await isCompanyExists(companyName)
      .catch(() => null)
    // const company = find(companies, { slug: companyName })

    if (isCompany && isCompany.exist) {
      setTimeout(() => setRedirectTo(`/login/${companyName}`), 0)
    } else {
      setFlagNotFound(true)
      setTimeout(() => setFlagNotFound(false), 2000)
    }
  }

  const onViewDemo = async(e) => {
    setRedirectTo('/login/demo')
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  if (user && user.roles && hasRole(user.roles, 'admin')) {
    return <Redirect to="/root-admin" />
  }

  if (user) {
    return <Redirect to="/not-company-profile" />
  }

  return (
      <div className="login login__row">
      <div className="login__form login__form__row">
        <div className="login__form-wrapper">
          <form onSubmit={onNext}>
            <section className="sp-section">
              <div className="sp-header">Sign in</div>
              <div className="sp-subheader">Continue to your company</div>

              <div className="sp-label">
                Company address
                <div className="sp-company-address">
                  <Input
                    noLabel
                    name="company-name"
                    placeholder="mycompany"
                    inputRef={inputRef}
                  />
                  <span>
                    .workipelago.com
                  </span>
                </div>
              </div>


              <div className="sp-buttons">
                <Button
                  className="login-button"
                  onClick={onNext}
                >
                  Next
                </Button>

                { flagNotFound && <span className="sp-submit-msg">
                  Company not found
                </span>}
              </div>

              <div className="sp-subheader sp-section__margined">
                New to Workipelago? <a href="/signup" className="login_link" >Get Started</a>
                <br/>
                <br/>
                <a href="/forgot" className="login_link" >Forgot company address?</a>
              </div>

            </section>
          </form>
        </div>
      </div>
    </div>
  )
}
