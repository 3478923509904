import React, { useEffect } from 'react';
import find from 'lodash/find';
import { Redirect } from 'react-router';
// import { useAuth0 } from '@auth0/auth0-react';
import useAuth from '../../auth/useAuth'

import Static from '../../containers/Static';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';

import useUser from '../../hooks/useUser';
import useCompanies from '../../hooks/useCompanies';

import { default as api } from '../../services/api';

import './not-company-profile.css';

const {
  fetchProfileByToken,
  fetchRolesByContact,
  getImgUrl,
} = api

export default function NotCompanyProfile() {
  const { logout, isAuthenticated, auth } = useAuth({
    slug: localStorage.getItem('wkpg_slug') || ''
  });
  const { companies } = useCompanies();
  const { user, redirectToFloor, setUser } = useUser();
  const token = localStorage.getItem('token');

  localStorage.removeItem('authAction')

  useEffect(() => {
    if ((!user || !Object.keys(user).length) && isAuthenticated && auth) {
      auth.client.getTokenSilently()
        .then(() => auth.client.getIdTokenClaims())
        .then(({ __raw: token }) => {
          fetchProfileByToken(token)
            .then(profile => {
              if (profile && profile.strId) {
                fetchRolesByContact(profile.strId)
                  .then(userInfo => {
                    setUser(userInfo);
                    redirectToFloor()
                  })
              }
        })
      })
        .catch(console.error);
    }
  }, [isAuthenticated, user]);

  if (!user && !isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!user) {
    return <Loader />;
  }

  const currentCompany = find(companies, (item) => item.strId === user.companyStrId);
  const handleClickLogout = () => {
    logout({ returnTo: `${window.location.origin}/logout-redirect` });
  };

  if (currentCompany && (localStorage.getItem('returnTo') !== '/not-company-profile')) {
    redirectToFloor();
    return null;
  }

  return (
    <Static>
      <div className="not-company-profile">
        {
          user?.img ?
          <img className="not-company-profile__img" src={getImgUrl(user.img)} alt="avatar" /> :
          <div className="not-company-profile__img not-company-profile__img_not" />
        }

        <div className="not-company-profile__name">
          {user?.name}
        </div>

        <div className="not-company-profile__title">
          {
            localStorage.getItem('returnTo') === '/not-company-profile' ?
            'Your domain is not registered in this company. Please, contact the administrator.' :
            'You have no workspaces'
          }
        </div>

        <Button onClick={handleClickLogout} className="not-company-profile__button">
          Logout
        </Button>
      </div>
    </Static>
  );
};

