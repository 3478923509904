import React from 'react';
import classNames from 'classnames';

import './zoom-button.css';

const STEP = 0.1;

function ZoomButton(props) {
  const { scale, options, setScale } = props;
  const { min, max } = options.scale;
  const isMax = +scale.toFixed(1) === max;
  const isMin = +scale.toFixed(1) === min;
  const classesButtonInc = classNames('zoom-button__inc', { 'zoom-button__disabled': isMax });
  const classesButtonDec = classNames('zoom-button__dec', { 'zoom-button__disabled': isMin });
  const handleInc = () => !isMax && setScale(scale + STEP);
  const handleDec = () => !isMin && setScale(scale - STEP);

  return (
    <div className="zoom-button">
      <div className={classesButtonInc} onClick={handleInc} onTouchStart={handleInc}/>
      <div className={classesButtonDec} onClick={handleDec} onTouchStart={handleDec}/>
    </div>
  );
}

export default ZoomButton;
