import React, { useState } from 'react';
import useAuth from '../../auth/useAuth'
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import { BigLogoIcon } from '../../services/svg-icons';
import useTeamsFlag, {goAndAuthenticate, notifySuccess} from '../../hooks/useTeams'

import '../Login/login.css';

export default function SignUp(props) {
  const {
    isLoading,
    loginWithRedirect,
    getIdTokenClaims,
    logout
  } = useAuth({ overrideSlug: true });

  const history = useHistory();

  const [check, setCheck] = useState(false);
  const teamsFlag = useTeamsFlag()

  const login = () => {
    if (teamsFlag === 'loading') {
      return
    } if (teamsFlag === 'teams') {
      localStorage.wkpgMode = 'signin'
      localStorage.page = 'main'
      goAndAuthenticate({
        successCallback: (result) => {
          getIdTokenClaims()
            .then((res) => {
              localStorage.removeItem('page')
              localStorage.forceAuthToken = JSON.stringify(res)
              history.push('/signup/company')
            })
            .catch(() => logout())
        },
        failureCallback: (reason) => {
          logout()
          // alert(`err1 ${reason.toString()}`)
          // dont know how to handle
        },
      })
    } else {
      loginWithRedirect({ screen_hint: 'signup' });
      localStorage.wkpgMode = 'signin'
      localStorage.setItem('returnTo', '/signup/company');
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="login">
      <div className="login__form">
        <div className="login__form-wrapper">
          <header>
            <BigLogoIcon />
          </header>

          <footer>
            {
              (isLoading) ?
              <Loader isAbsolute /> :
              <Button
                disabled={!check}
                onClick={login}
                className="login__button"
              >
                Sign Up
              </Button>
            }
          </footer>

          <label>
            <input
              type="checkbox"
              checked={check}
              onChange={({target}) => setCheck(target.checked)}
            />
            <span>
            I agree to Workipelago <Link target="_blank" to="/documents/tos.pdf">Terms Of Service</Link> and <Link target="_blank" to="/documents/privacy.pdf">Privacy Policy</Link> and confirm<br/>I am over the age 18 years olds
            </span>
          </label>
        </div>
      </div>

      <div className="login__links">
        <a href="https://www.ofistic8.com" target="_blank" rel="noopener noreferrer">
          Workipelago website
        </a>
      </div>
    </div>
  );
};
