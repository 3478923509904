import React, {Fragment, useEffect, useState} from 'react';
import moment from 'moment';
import classNames from 'classnames';

// import { isMobile } from 'react-device-detect';
import { useMobile } from '../../hooks/useDetectMobile';

import { CalendarIcon, CleaningIcon, ScanIcon } from '../../services/svg-icons';

import Scan from '../Scan/Scan';
import Button from '../Button/Button';
import PopupQRCode from '../PopupQRCode/PopupQRCode';

import { default as api } from '../../services/api';
import getTitleDesk from '../../services/get-title-desk';
import { CLEANED } from '../../constants/cleaningStatuses';
import { BOOKABLE, NON_BOOKABLE } from '../../constants/meetingStatuses';
import useTeamsFlag from '../../hooks/useTeams'

const { putDesk } = api

export default function PopupHeader(props) {
  const {
    desk, activeBooking = null, building, onClickShedule, companySlug,
    isViewShedule, isJanitor, isNotCleaned, isCleaning, isAdmin, floorStrId
  } = props;
  const { id, status, title, strId, cleaningStatus, qrId } = desk;
  const teamsFlag = useTeamsFlag()
  const isMobile = useMobile()

  const checkTeams = () => {
    return isMobile && teamsFlag === 'teams'
  }

  const [isViewQRCode, setViewQRCode] = useState(false);
  const [isViewScanner, setViewScanner] = useState(false);

  const { headerTitle, shortTitle } = getTitleDesk(desk);

  const isMeeting = status === BOOKABLE;
  const isNonBookable = status === NON_BOOKABLE;

  const classes = classNames('popup-booking__top', {
    'popup-booking__top__busy': activeBooking !== null,
    'popup-booking__top_cleaning': isNotCleaned
  });

  let bookingStatus = 'Free';

  if (activeBooking !== null){
    const time = moment(activeBooking.timeTo);
    bookingStatus = `Busy until ${time.format('HH:mm')}`;
  }

  if (isNotCleaned || isJanitor) {
    bookingStatus = cleaningStatus;
  }

  const handleClickCleaning = () => putDesk({ ...desk, cleaningStatus: CLEANED });

  return (
    <div className={classes}>
      {
        isMobile ?
        renderMobileHeader({
          headerTitle, isJanitor, isNotCleaned, isMeeting, isNonBookable, id, title, isCleaning,
          bookingStatus, building, isViewShedule, onClickShedule, handleClickCleaning, isAdmin,
          isViewScanner, setViewScanner, strId, companySlug, floorStrId, qrId, checkTeams
        }) :
        renderHeader({
          headerTitle, isMeeting, id, isCleaning, title, bookingStatus, shortTitle, floorStrId,
          strId, isViewQRCode, setViewQRCode, handleClickCleaning, qrId, companySlug
        })
      }
    </div>
  )
}

const renderHeader = (props) => {
  const {
    headerTitle,
    isMeeting,
    id,
    qrId,
    title,
    bookingStatus,
    shortTitle,
    strId,
    isViewQRCode,
    setViewQRCode,
    isCleaning,
    companySlug,
    // floorStrId,
    handleClickCleaning
  } = props;
  const { origin } = window.location;
  const link = `${origin}/scan-qr/${companySlug}/${qrId || strId}`;
  const fullName = `${headerTitle} #${id}`;

  const handleViewQRCode = () => setViewQRCode(true);
  const handleHideQRCode = () => setViewQRCode(false);

  return (
    <Fragment>
      {
        isViewQRCode &&
        <PopupQRCode
          data={link}
          onClose={handleHideQRCode}
          title={fullName}
        />
      }

      <div className="popup-booking__avatar" onClick={handleViewQRCode}>
        <div className="popup-booking__avatar-name">{shortTitle}</div>
        <div className="popup-booking__avatar-name">{id}</div>
      </div>
      <div className="popup-booking__info">
        <div className="popup-booking__name">{headerTitle}</div>
        {!isMeeting && <div className="popup-booking__name">#{id}</div>}
        {title && <div className="popup-booking__name">{title}</div>}
        <div className="popup-booking__status">{bookingStatus}</div>
      </div>
      {
        isCleaning &&
        <CleaningIcon className="popup-booking__cleaning-icon" onClick={handleClickCleaning}/>
      }
    </Fragment>
  );
};

const renderMobileHeader = (props) => {
  const {
    headerTitle, isMeeting, id, bookingStatus, building, onClickShedule, isAdmin, isViewScanner,
    isViewShedule, isNonBookable, isJanitor, isNotCleaned, isCleaning, handleClickCleaning,
    setViewScanner, strId, qrId, companySlug, floorStrId, checkTeams
  } = props;
  const { name, desc } = building;

  const classes = classNames('popup-booking__button-calendar', {
    'popup-booking__button-calendar_active': !isViewShedule
  });
  const classesName = classNames('popup-booking__name-mobile', {
    'popup-booking__name-mobile_non-bookable': isNonBookable,
    'popup-booking__name-mobile_cleaning': isNotCleaned
  });
  const { origin } = window.location;
  const link = `${origin}/scan-qr/${companySlug}/${qrId || strId}`;

  const handleViewScanner = () => setViewScanner(true);
  const handleHideScanner = () => setViewScanner(false);

  return (
    <div className="popup-booking__info-mobile">
      {
         !isNonBookable && !isJanitor &&
        <Button className={classes} onClick={onClickShedule} onTouch={onClickShedule}>
          <CalendarIcon className="popup-booking__calendar-icon"/>
        </Button>
      }
      {
        isAdmin && !checkTeams() &&
        <Button className="popup-booking__button-qr" onClick={handleViewScanner} onTouch={handleViewScanner}>
          <ScanIcon className="popup-booking__calendar-icon"/>
        </Button>
      }
      <div className={classesName}>
        {headerTitle}
        {!isMeeting && <span className="popup-booking__id"> #{id}</span>}
      </div>
      <div className="popup-booking__building">{name}</div>
      {desc && <div className="popup-booking__building">{desc}</div>}
      <div className="popup-booking__status-mobile">{bookingStatus}</div>
      {
        isCleaning &&
        <CleaningIcon className="popup-booking__cleaning-icon" onClick={handleClickCleaning}/>
      }
      {
        isViewScanner &&
        <Scan onClose={handleHideScanner} floorStrId={floorStrId}
              strId={strId} companySlug={companySlug} link={link} />
      }
    </div>
  );
};
