import React, { useState } from 'react';
import classNames from 'classnames';

import { default as api } from '../../services/api'

const { getImgUrl } = api

export default function ContactItem(props) {
  const { contact, onLoginAs } = props;
  const [isSelected, setSelectedFlag] = useState(false);

  const onClick = () => {
    onLoginAs(contact);
    setSelectedFlag(true);
  };

  const classes = classNames('b-demo-contact', {
    'b-demo-contact__selected': isSelected,
  });

  const src = contact.img ? getImgUrl(contact.img) : '/demo-assets/profile.svg';

  return (
    <div className={classes} onClick={onClick}>
      <img className="b-demo-contact_image" src={src} alt="avatar" />
    </div>
  );
}
