import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import find from 'lodash.find';
import get from 'lodash/get'
import trim from 'lodash/trim'

// import { isMobile } from 'react-device-detect';
import { useMobile } from '../../hooks/useDetectMobile';

import getDayName from '../../services/get-day-name';
import { default as api } from '../../services/api'

const { getImgUrl } = api
const DEFAULT_IMG = '/demo-assets/profile2.svg'

export default function ScheduleItem(props) {
  const { contactsBooking, handleClickContact, contacts, currentDate } = props;
  const dayName = getDayName(contactsBooking[0], currentDate);

  const isMobile = useMobile()

  return (
    <div>
      <div className="popup-booking__day">{dayName}</div>
      <div className="popup-booking__shedule">
        {
          contactsBooking.map((booking, contactIndex) => {
            const {
              timeFrom, timeTo, ownerContactId, meetingTitle,
              isInvisible, isInactive, azureEventRaw
            } = booking;

            let owner = find(contacts, (contact) => contact.strId === ownerContactId);

            if (!owner && !azureEventRaw) {
              return null;
            }

            const ownerName = owner ? owner.name : get(azureEventRaw, 'organizer.emailAddress.name')
            const ownerImage = owner ? getImgUrl(owner.img) : DEFAULT_IMG

            const currentImg = isInvisible
              ? '/demo-assets/profile2.svg'
              : isInactive
                ? '/demo-assets/ban.svg'
                : ownerImage || '/demo-assets/profile2.svg'

            let currentName = isInvisible ? 'Invisible booking' : isInactive ? 'Inactive booking' : ownerName;
            currentName = trim(currentName)

            const orgEmail = get(booking, 'azureEventRaw.organizer.emailAddress.address')

            if (trim(meetingTitle) === currentName && orgEmail) {
              currentName = orgEmail
            }

            const handleCurrentClick = isInvisible || isInactive || !owner
              ? null
              : () => handleClickContact(owner);
            const timeName = `${moment(timeFrom).format('HH:mm')} - ${moment(timeTo).format('HH:mm')}`;
            const classesName = classNames('popup-booking__owner-name', {
              'popup-booking__owner-name_meeting': meetingTitle
            });
            const classes = classNames('popup-booking__shedule-item', {
              'popup-booking__shedule-item_meeting': meetingTitle && isMobile
            });

            return (
              <div key={contactIndex} className="pb-item">
                {
                  isMobile && !meetingTitle ?
                  <div className="popup-booking__owner-holder" onClick={handleCurrentClick}>
                    <img className="popup-booking__owner-avatar" src={currentImg} alt="avatar" />
                    <div className="popup-booking__owner">
                      <div className="popup-booking__time">{timeName}</div>
                      <div className={classesName}>{currentName}</div>
                    </div>
                  </div> :
                  <div className={classes} onClick={handleCurrentClick}>
                    <div className="popup-booking__time">{timeName}</div>
                    {meetingTitle && <div className="popup-booking__title">{meetingTitle}</div>}
                    <div className="popup-booking__owner">
                      <img className="popup-booking__owner-avatar" src={currentImg} alt="avatar" />
                      <div className={classesName}>{currentName}</div>
                    </div>
                  </div>
                }
              </div>
            );
          })
        }
      </div>
    </div>
  );
};
