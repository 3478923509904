import React from 'react';

import AdminLayout from '../containers/AdminLayout';

export default function withAdmin(Component) {
  return () => (
    <AdminLayout>
      <Component />
    </AdminLayout>
  );
};
