import React from 'react';
import { useForm } from 'react-hook-form';

import Button from '../Button/Button';
import { ClearIcon } from '../../services/svg-icons';

import './popup-add-link.css';

function PopupAddLink(props) {
  const {onClose, addLink} = props;
  const {register, handleSubmit} = useForm();
  const onSubmit = (data) => {
    addLink(data);
    onClose();
  }

  return (
    <form name='add_link' onSubmit={handleSubmit(onSubmit)}>
      <div className="popup-add-link">
        <div className="popup-add-link__top">
          <span>Add link</span>
          <ClearIcon className="popup-add-link__close" onClick={onClose}/>
        </div>
        <div className="popup-add-link__body">
          <label className="popup-add-link__label">
            <span>Name</span>
            <input ref={register({ required: true })} name="name" type="text" className="popup-add-link__input"/>
          </label>
          <label className="popup-add-link__label">
            <span>Link</span>
            <input ref={register({ required: true })} name="href" type="text" className="popup-add-link__input"/>
          </label>
          <Button type="submit">Save</Button>
        </div>
      </div>
    </form>
  );
}

export default PopupAddLink;
