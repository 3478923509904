import React from 'react';

import UserForm from '../../components/UserForm/UserForm';

export default function AddUser() {
  return (
    <div>
      <UserForm />
    </div>
  );
};
