import React from 'react';

import CompanyForm from '../../components/CompanyForm/CompanyForm';

export default function AddCompany() {
  return (
    <div>
      <CompanyForm />
    </div>
  );
};
