import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import Loader from '../../components/Loader/Loader';
import VirtualRoomForm from '../../components/VirtualRoomForm/VirtualRoomForm';

import { default as api } from '../../services/api';

const { fetchContactsByCompany, fetchVirtualFloorById } = api

export default function EditVirtualRoom() {
  const { id, companyId } = useParams();

  const [virtualRoom, setVirtualRoom] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if (id) {
      fetchVirtualFloorById(id).then(setVirtualRoom);
    }
  }, [id]);

  useEffect(() => {
    if (companyId) {
      fetchContactsByCompany(companyId).then(setUsers);
    }
  }, [companyId]);

  return (
    <div>
      {
        virtualRoom && users ?
        <VirtualRoomForm virtualRoom={virtualRoom} users={users} /> :
        <Loader />
      }
    </div>
  );
};
